import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { number, object, string } from 'yup';
import { selectAuth, selectErrorMessage, createLoadingSelector } from '../../selectors';
import {
    registerByEmailAndNumber, clearRegisterError,
    REGISTER_EMAIL_AND_ACCOUNT_NUMBER
}
    from '../../actions/accountNumberRegisterActions';
import IconInput from '../../components/common/Formik/IconInput';
import Button from '../../components/common/Buttons/Button';
import GuestLayout from '../Layout/GuestLayout';
import PropTypes from "prop-types";

const registerSchema = object().shape({
    accountNumber: string().min(4, 'Min 4 characters').matches(/^\d+$/, 'Account Number must be digits').required('Required'),
    email: string().min(1, 'Min 1 character').required('Required')
});

class Register extends Component {
    static propTypes = {
        isRegistering: PropTypes.bool.isRequired,
        formClosed: PropTypes.bool,
    };

    componentDidMount() {
    }

    componentWillUnmount() {
        this.props.clearRegisterError();
    }

    handleOnSubmit = (values) => {
        const { registerByEmailAndNumber } = this.props;
        registerByEmailAndNumber(values.accountNumber, values.email);
    };


    render() {
        const { isRegistering, registerError, formClosed } = this.props;

        return (
            <GuestLayout>
                <div className="cp-page cp-page-login justify-content-start">
                    <div className="card-guest card-full-height">
                        <div className="card-guest-body">
                            {!formClosed ? (
                                <Formik
                                    initialValues={{
                                        accountNumber: '',
                                        email: '',
                                    }}
                                    validationSchema={registerSchema}
                                    onSubmit={this.handleOnSubmit}
                                >
                                    {({
                                        handleChange,
                                        handleSubmit,
                                        handleBlur,
                                        values,
                                        errors,
                                        touched,
                                    }) => (
                                        <Form onSubmit={handleSubmit} className="needs-validation cp-form">
                                            <div className="form-section fx-fadein">

                                                <h3>Register Now</h3>

                                                <div style={{ marginBottom: 30 }}>
                                                    <p> Provide your account number and matching email address. We will send you an email with instructions. </p>
                                                </div>

                                                <div className="form-group">
                                                    <label>Account Number</label>
                                                    <IconInput
                                                        type="text"
                                                        name="accountNumber"
                                                        value={values.accountNumber}
                                                        errors={errors}
                                                        touched={touched}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        divClass='input-group'
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <IconInput
                                                        type="email"
                                                        name="email"
                                                        value={values.email}
                                                        errors={errors}
                                                        touched={touched}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        autoComplete="off"
                                                        divClass='input-group'
                                                    />
                                                </div>


                                                {registerError &&
                                                    <p style={{ color: 'red' }}>
                                                        {registerError}
                                                    </p>
                                                }


                                                <div className="form-group form-group-button text-right" style={{ position: "relative" }}>
                                                    <Link style={{ textDecoration: 'none', paddingRight: 20 }} to={{ pathname: '/login' }}>Go Back</Link>
                                                    <Button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                        disabled={isRegistering}
                                                    >
                                                        Continue{' '}
                                                        {isRegistering && (
                                                            <i className="fas fa-fw fa-spin fa-spinner" />
                                                        )}
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            )
                                :
                                (<div className="form-sections-wrapper">
                                    <div className="form-section fx-from-right" id="fs4">

                                        <div className="message">
                                            <div className="message-left">
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className="message-right">
                                                <span style={{ color: 'green' }}>
                                                    <h4>Your request was submitted!</h4>
                                                </span>
                                                <p>Please check your email for follow up instructions.</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                )}
                        </div>
                    </div>
                </div>
            </GuestLayout>
        );
    }
}

const getRegisterLoader = createLoadingSelector(['REGISTER_EMAIL_AND_ACCOUNT_NUMBER']);
const getRegisterError = selectErrorMessage(['REGISTER_EMAIL_AND_ACCOUNT_NUMBER']);

const mapStateToProps = (state) => {
    const registrationSuccess = selectAuth(state).isRegisterSuccess,
        isRegistering = getRegisterLoader(state),
        registerError = getRegisterError(state)

    return {
        formClosed: registrationSuccess && !isRegistering,
        isRegistering,
        registerError,
    };
};

const mapDispatchToProps = {
    registerByEmailAndNumber,
    clearRegisterError,
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
import axiosInstance from '../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../utils/helpers';
import { getErrorMessage } from '../utils/helpers';

export const ACCOUNT_INFO = createRequestTypes('ACCOUNT_INFO');
export const GET_ACCOUNT_CREDIT_BALANCE = createRequestTypes('GET_ACCOUNT_CREDIT_BALANCE');

export const getAccountInfoById = (id) => async (dispatch, getState) => {
	dispatch(actionCreator.request(ACCOUNT_INFO));
	try {
		const response = await axiosInstance.get(`/account/${id}`);
		// dispatch(actionCreator.success(ACCOUNT_INFO, response.data.account_info));
		dispatch(actionCreator.success(ACCOUNT_INFO, response.data));
	} catch (err) {
		let language = getState().language.language;
		let mockError = getErrorMessage(err.response, language);
		dispatch(actionCreator.failure(ACCOUNT_INFO, mockError));
	}
};

export const emptyAccountInfoById = () => (dispatch) => {
	dispatch(actionCreator.empty(ACCOUNT_INFO));
};

export const getAccountCreditBalance = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_ACCOUNT_CREDIT_BALANCE));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/credit/balance`);
		dispatch(actionCreator.success(GET_ACCOUNT_CREDIT_BALANCE, response.data.balance));
	} catch (err) {
		dispatch(actionCreator.failure(GET_ACCOUNT_CREDIT_BALANCE));
	}
};

export const emptyAccountCreditBalance = () => async (dispatch) => {
	dispatch(actionCreator.empty(GET_ACCOUNT_CREDIT_BALANCE));
};

import React, { Component } from 'react';

import {
	SelectServiceStep,
	ServiceDetailsStep,
	SuccessStep,
	NotAvailableStep,
} from './Steps';

class StepContent extends Component {

	componentDidUpdate(prevProps) {
		if (prevProps.location !== this.props.location) {

			// Reset initial form values, and wizard step
			this.props.handleResetWizard(this.props.formProps.resetForm, this.props.formProps.setTouched);
		}
		// IF only one plan we want to skip to serviceDetailsStep
		if(this.props.availablePlans.plans !== prevProps.availablePlans.plans) {
			if(this.props.availablePlans.plans.length === 1) {
				(async () => {
					await this.props.formProps.setFieldValue('order.plans', this.props.availablePlans.plans);
				})()
			}
		}
	}

	render() {

		const {
			activeStep,
			availablePlans,
			plansDetails,
			formProps,
			handleChangeStep,
			populateServiceAndLines,
			handleBackStep,
			planDetailsError,
		} = this.props;

		let customerAddress = availablePlans.validatedAddress && availablePlans.validatedAddress.fullAddress || '';

		return (
			<>

				{activeStep === 'not-available' ?
					<NotAvailableStep
						availablePlans={availablePlans}
						{...formProps}
					/>
				:
				activeStep === 'select-service' ?
					<SelectServiceStep
						availablePlans={availablePlans}
						handleChangeStep={handleChangeStep}
						populateServiceAndLines={populateServiceAndLines}
						customerAddress={customerAddress}
						{...formProps}
						{...this.props}
					/>
				:
				activeStep === 'service-details' ?
					<ServiceDetailsStep
						availablePlans={availablePlans}
						handleBackStep={handleBackStep}
						handleChangeStep={handleChangeStep}
						plansDetails={plansDetails}
						planDetailsError={planDetailsError}
						customerAddress={customerAddress}
						{...formProps}
					/>
				:
				activeStep === 'success' ?
					<SuccessStep
						{...formProps}
					/>
				: ""
				}

			</>
		)
	}
}

export default StepContent;

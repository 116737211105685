import React, { Component } from 'react';

import {
	SelectServiceStep,
	ServiceDetailsStep,
	ScheduleAppointmentStep,
	ConfirmationStep,
	SuccessStep,
	NotAvailableStep,
} from './Steps';

class StepContent extends Component {

	componentDidUpdate(prevProps) {

		if (prevProps.location !== this.props.location) {

			// Reset initial form values, and wizard step
			this.props.handleResetWizard(this.props.formProps.resetForm, this.props.formProps.setTouched);
		}
	}

	render() {

		const {
			activeStep,
			createOrderLoader,
			createOrderError,
			formProps,
			handleChangeStep,
			getOrderAvailability,
			availabilityLoading,
			technicianAvailability,
		} = this.props;

		return (
			<>

				{activeStep === 'schedule-appointment' ?
					<ScheduleAppointmentStep
						handleChangeStep={handleChangeStep}
						getOrderAvailability={getOrderAvailability}
						availabilityLoading={availabilityLoading}
						technicianAvailability={technicianAvailability}
						{...formProps}
					/>
					:
					activeStep === 'confirmation' ?
						<ConfirmationStep
							createOrderLoader={createOrderLoader}
							createOrderError={createOrderError}
							handleChangeStep={handleChangeStep}
							{...formProps}
						/>
						:
					activeStep === 'success' ?
					<SuccessStep
						{...formProps}
					/>
				: ""
				}

			</>
		)
	}
}

export default StepContent;

import React, {Component} from 'react';
import IncPackage from './Options/IncludedPackage';
import Feature from "./Options/Feature";

class IncludedPackages extends Component {

    state = {
        isPlanCollapsed: false
    };

    collapsePlan = () => {
        this.setState((prevProps) => ({
            isPlanCollapsed: !prevProps.isPlanCollapsed
        }));
    };

    hideSection = (planFeature) => {
        let showSection = false;
        planFeature.map(planFeature => {
            planFeature.featurePackageOptions.map(featurePackageOption => {
                if(typeof featurePackageOption.hideCustomerPortal == 'undefined' ||
                    !featurePackageOption.hideCustomerPortal){
                    showSection = true;
                }
            })
        })
        if(!showSection){
            return  "d-none";
        }
    }

    render() {
        const { isPlanCollapsed } = this.state;
        const { planFeature } = this.props;

        return (
            <div className={(this.hideSection(planFeature))}>
                <div className={'product-item' + (isPlanCollapsed ? ' product-item-collapsed' : '')}>

                    <div className="header">

                        <div className="header-title">Included Packages</div>

                        <button className="btn header-collapse" type="button" onClick={() => this.collapsePlan()}>
                            <i className="fas fa-angle-up"></i>
                        </button>

                    </div>

                    <div className="details">

                        <div className="description">
                            {planFeature.map((featurePackage, index) => (<IncPackage featurePackage={featurePackage}/>))}
                        </div>

                    </div>

                </div>
            </div>
        );
    }
}

export default IncludedPackages;

import isEmpty from "../utils/helpers";

export const selectLanguage = (state) => state.language || {};
export const selectAuth = (state) => state.auth || {};
export const selectAccountInfo = (state) => state.accountInfo || {};
export const selectGuestOrder = (state) => state.guestOrder || {};
export const selectHomeInfo = (state) => state.homeInfo || {};
export const selectSignUp = (state) => state.signUp || {};
export const getUser = (state) => state.user || {};
export const getPayment = (state) => state.payment || {};
export const getSignupToService = (state) => state.signupToService || {};
export const getBilling = (state) => state.billing || {};
export const getPaymentProviders = (state) => state.paymentProviders || {}
export const getAccountAutoPayStatus = (state) => state.accountAutopay || {};

export const selectErrorMessage = (actions) => (state) => {
	const errors = actions.map((action) => state.errors[action]);

	if (isEmpty(errors)) {
		return '';
	}

	return errors.find((error) => !isEmpty(error));
};

export const getUserPermission = (state, moduleName, permissionName) => {
	const findPermission = state.auth.userPermission.find((x) => x.name === moduleName)
		? state.auth.userPermission.find((x) => x.name === moduleName).permissions.includes(permissionName)
		: false;

	return findPermission;
};

export const createLoadingSelector = (actions) => (state) => actions.some((action) => state.loading[action]);
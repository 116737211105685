import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import GuestLayout from '../Layout/GuestLayout';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import IconInput from '../../components/common/Formik/IconInput';
import { forgottenPassword, emptyForgottenPassword } from '../../actions/authActions';
import { selectAuth, createLoadingSelector, selectErrorMessage } from '../../selectors'
import isEmpty from "../../utils/helpers";

const forgotPasswordSchema = object().shape({
    email: string().min(3, 'Min 3 characters').required('Required'),
});

export class ForgotPassword extends Component {

    state = {
        isForgottenPasswordInputVissible: true
    }

    componentWillUnmount() {
        const { emptyForgottenPassword } = this.props;
        emptyForgottenPassword();
    }
    
    handleOnSubmit = (values) => {
        const { forgottenPassword } = this.props;
        forgottenPassword(values.email)
        // forgottenPassword(values.email).then((response) => {
        //     if (response) {
        //         this.setState({
        //             isForgottenPasswordInputVissible: false
        //         })
        //     }

        // });
    }

    render() {
        const { isForgottenPasswordInputVissible, isForgottenPasswordInputSubmitting, forgottenPasswordError } = this.props;
        return (
            <GuestLayout>
                <div className="cp-page cp-page-login justify-content-start">
                    <div className="card-guest card-full-height">
                            {isForgottenPasswordInputVissible && !forgottenPasswordError ? <div className="card-guest-body">
                                <Formik initialValues={{
                                    email: ''
                                }}
                                    validationSchema={forgotPasswordSchema}
                                    onSubmit={this.handleOnSubmit}
                                >
                                    {({
                                        handleChange,
                                        handleSubmit,
                                        handleBlur,
                                        values,
                                        errors,
                                        touched,
                                        isSubmitting
                                    }) => (
                                            <Form onSubmit={handleSubmit} className="cp-form">
                                                <div className="form-section fx-fadein">

                                                    <h3>Reset Password</h3>

                                                    <p>Don't worry, just provide your username or email address and we will send you a password recovery information.</p>

                                                    <div className="form-group">
                                                        <div className="input-group mb-3">
                                                            <label>Username or Email</label>
                                                            <IconInput
                                                                type='text'
                                                                name='email'
                                                                divClass='input-group'
                                                                value={values.email}
                                                                errors={errors}
                                                                touched={touched}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur} />
                                                        </div>
                                                    </div>

                                                    <div className="form-group form-group-footer">
                                                        <Link to={{ pathname: '/login' }}><button type="cancel" className="btn btn-quick">Go Back</button></Link>
                                                        <button type="submit" className="btn btn-with-shadow btn-primary">Reset Password {isForgottenPasswordInputSubmitting && <i className="fas fa-fw fa-spin fa-spinner" />}</button>
                                                    </div>

                                                </div>
                                            </Form>

                                        )}
                                </Formik>
                            </div>
                            :
                            <div className="message">

                                <div className="message-left">
                                    <i className="fas fa-check"/>
                                </div>

                                <div className="message-right">
                                    <h4>Thanks!</h4>
                                    <p>If there's an account associated with this username or email, we'll send the password reset instructions immediately.</p>
                                    <Link to={{ pathname: '/login' }}><button type="cancel" className="btn btn-outline-secondary">Go Back</button></Link>
                                </div>

                            </div>
                            }
                    </div>
                </div>
            </GuestLayout>
        )
    }
}

const forgottenPasswordSelector = 'FORGOTTEN_PASSWORD';
const getSendEmailLoader = createLoadingSelector([forgottenPasswordSelector]);
const getInvalidEmailError = selectErrorMessage([forgottenPasswordSelector])

const mapStateToProps = (state) => {
    const isForgottenPasswordInputVissible = selectAuth(state).isForgottenPasswordInputVissible,
        isForgottenPasswordInputSubmitting = getSendEmailLoader(state),
        forgottenPasswordError = getInvalidEmailError(state)

    return {
        isForgottenPasswordInputVissible,
        isForgottenPasswordInputSubmitting,
        forgottenPasswordError
    }
}

const mapDispatchToProps = {
    forgottenPassword,
    emptyForgottenPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)

import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import isEmpty from "../../../../utils/helpers";

class IncludedPackage extends Component {
	state = {
		isDescriptionToggled: false
	};

	isPriceRendered = () => {
		const { accountInitialStatus, featurePackage } = this.props;

		if (accountInitialStatus === 'SIGNUP') {
			if (featurePackage.featurePackageOptions[0].features.some((item) => item.hideSignupPrice === true )) {
				return false;
			}
		} else if (accountInitialStatus === 'SURVEY') {
			if (featurePackage.featurePackageOptions[0].features.some((item) => item.hideSurveyPrice === true )) {
				return false;
			}
		}
		return true;
	};

	toggleDescription = (e) => {
		e.stopPropagation();
		this.setState((prevState) => ({
			isDescriptionToggled: !prevState.isDescriptionToggled
		}));
	};
	render() {
		const { isDescriptionToggled } = this.state;
		const { featurePackage } = this.props;

		return (
			<div className="package">
				<div className="package-header custom-switch-with-button">
					<div className="custom-control custom-switch">
						<input
							type="checkbox"
							className="custom-control-input"
							// id="b1in1"
							checked={true}
							disabled={true}
						/>
						<label className="custom-control-label" htmlFor="">
							<span>{featurePackage.featurePackageOptions[0].description}</span>
							<span>
								{this.isPriceRendered() &&
									`${featurePackage.featurePackageOptions[0].recurringMonthlyPrice
										.toFixed(2)
										.replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo`}
							</span>
						</label>
					</div>
					{!isEmpty(featurePackage.featurePackageOptions[0].note) &&
					<button
						type="button"
						onClick={this.toggleDescription}
						className={
							isDescriptionToggled ? (
								'btn btn-link btn-link-text btn-toggle-package-desc toggled'
							) : (
								'btn btn-link btn-link-text btn-toggle-package-desc'
							)
						}
					>
						<i className="fas fa-info-circle"/>
					</button>
					}
				</div>
				{isDescriptionToggled && (
					<div className="package-description text-sm">
						{ReactHtmlParser(featurePackage.featurePackageOptions[0].note)}
					</div>
				)}
			</div>
		);
	}
}

export default IncludedPackage;

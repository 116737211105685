import React, {Component, Fragment} from 'react';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import ReactGA from 'react-ga';
import {Offline, Online} from 'react-detect-offline';
import CacheBuster from './CacheBuster';
// Layouts
import MainLayout from './containers/Layout/MainLayout';
import GuestLayout from './containers/Layout/GuestLayout';
import {trans} from './utils/helpers';

// Components
import NetworkErrorMessage from './components/NetworkErrorMessage';
import OfflinePage from './containers/OfflinePage';
import SignIn from './containers/SignIn/SignIn';
import SignUp from './containers/SignUp';
import Register from "./containers/AccountNumberRegister/accountNumberRegister";
import GuestOrder from './containers/GuestOrder';
import ForgotPassword from './containers/ForgotPassword/ForgotPassword'
import ResetPassword from './containers/ResetPassword/ResetPassword'
import EmailSignUp from "./containers/SignUp/EmailSignUp";
import EmailOrder from './containers/EmailOrder';
import VerifyEmail from './containers/Verifications/VerifyEmail';
import SignupToService from './containers/SignupToService';
import Scheduling from './containers/Scheduling';
import {API_ROOT} from "./utils/api-config";
import ImpersonateUser from "./containers/ImpersonateUser/ImpersonateUser";

class App extends Component {
    state={
        title:''
    }
    componentDidMount() {
        (async() => {
            const title = await trans('en-us', 'siteTitle');

            this.setState({title})
        })()
        ReactGA.pageview(window.location.pathname);
    }

    render() {
        if (window.enableOffline) {
            return <OfflinePage/>;
        }
        document.title=this.state.title;
        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    // if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        refreshCacheAndReload();
                    }

                    return (
                        <Fragment>
                            {/*<Offline polling={{interval: 10000, url: API_ROOT+'/public/order/plan/?locationId=0&planId=0'}}>*/}
                            {/*    <NetworkErrorMessage/>*/}
                            {/*</Offline>*/}
                            <Switch>
                                <Route path="/login" render={(props) => <SignIn {...props} />}/>
                                <Route path="/impersonate/:userId" render={(props) => <ImpersonateUser {...props} />}/>
                                <Route path="/register" render={(props) => <SignUp {...props} />}/>
                                <Route path="/confirm-order" render={(props) => <SignupToService {...props} />}/>
                                <Route path="/schedule-appointment" render={(props) => <Scheduling {...props} />}/>
                                <Route path="/guest-order" render={(props) => <GuestOrder {...props} />}/>
                                <Route path="/password/forgotten" render={(props) => <ForgotPassword {...props} />}/>
                                <Route path="/account-number/register" render={(props) => <Register {...props} />}/>
                                <Route path="/resetpassword" render={(props) => <ResetPassword {...props} />}/>
                                <Route path="/emailregister" render={(props) => <EmailSignUp {...props} />}/>
                                <Route path="/emailorder" render={(props) => <EmailOrder {...props} />}/>
                                <Route path="/verifyemail" render={(props) => <VerifyEmail {...props} />}/>
                                <Route path="/" render={(props) => <MainLayout {...props} />}/>
                            </Switch>
                        </Fragment>
                    );
                }}
            </CacheBuster>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

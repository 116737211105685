import { CHECK_BY_EMAIL_OR_NUMBER, CHECK_BY_ACCOUNT_AND_ZIPCODE, CHEKC_ACCOUNT, VALIDATE_REGISTER_EMAIL} from '../actions/signUpActions';
 
 let initialState = {
 	checkedAccount: {},
 	receivedEmail: ''
 };
 
 export const signUpReducer = (state = initialState, action) => {
 	switch (action.type) {
 		case CHEKC_ACCOUNT.SUCCESS:
 			return {
 				...state,
 				checkedAccount: action.payload
 			};
 		case CHEKC_ACCOUNT.EMPTY:
 			return {
 				...state,
 				checkedAccount: {}
 			}
		case VALIDATE_REGISTER_EMAIL.SUCCESS:
			return {
				...state,
				receivedEmail: action.payload.message
			};
 		default:
 			return state;
 	}
	 };
import React, {Component, Fragment} from 'react';
import ReactHtmlParser from 'react-html-parser';
import {trans} from "../../../../utils/helpers";
import Loader from "../../../../components/common/Spinners/Loader";
import moment from "moment";
import Button from "../../../../components/common/Buttons/Button";

class ConfirmationStep extends Component {
	state = {
		title: '',
		termsAndConditions: ''
	}

	componentDidMount() {
		(async () => {
			const title = await trans('en-us', 'scheduleInstall.confirmOrder.title');
			const termsAndConditions = await trans('en-us', 'scheduleInstall.confirmOrder.termsAndConditions');
			const termsAndConditionsLink = await trans('en-us', 'scheduleInstall.confirmOrder.termsAndConditionsLink');

			this.setState({title, termsAndConditions, termsAndConditionsLink});
		})()
	}

	render() {

		const {
			createOrderLoader,
			createOrderError,
			handleBlur,
			setFieldValue,
			values,
			errors,
			touched,
			handleChangeStep,
			isSubmitting,
		} = this.props;

		return (
			<div className="form-section">

				<h4>{this.state.title}</h4>

				<p className="form-divider">
					<i className="fas fa-map-marker-alt"/> {values.formattedAddress}
				</p>

				{createOrderLoader
					? <Loader/>
					:
					<>
						<dl className="card-datalist">

							{values.account.accountHolder.firstName &&
							<div className="card-datalist-row">
								<dt>First Name</dt>
								<dd>{values.account.accountHolder.firstName}</dd>
							</div>
							}

							{values.account.accountHolder.lastName &&
							<div className="card-datalist-row">
								<dt>Last Name</dt>
								<dd>{values.account.accountHolder.lastName}</dd>
							</div>
							}


							{values.account.accountHolder.company &&
							<div className="card-datalist-row">
								<dt>Company</dt>
								<dd>{values.account.accountHolder.company}</dd>
							</div>
							}

							<div className="card-datalist-row">
								<dt>Phone Number</dt>
								<dd>{values.account.accountHolder.mobileNumber.number}</dd>
							</div>

							<div className="card-datalist-row">
								<dt>Email</dt>
								<dd>{values.account.accountHolder.personalEmail}</dd>
							</div>

							<div className="card-datalist-row">
								<dt>Appointment Date</dt>
								<dd>
									{
										values.appointmentDate
											? moment(values.appointmentDate).format("MM/DD/YYYY") +
											(moment(values.appointmentDate).format("HH:mm") !== "00:00"
													? " " + moment(values.appointmentDate).format("hA") + "-" + moment(values.appointmentDate).add(2, 'hour').format("hA")
													: ""
											)
											: "No appointment created"
									}
								</dd>
							</div>

						</dl>

						<p className="text-xs">{this.state.termsAndConditions}</p>
						<div className="form-group custom-checkbox">
							<input
								type="checkbox"
								className="custom-control-input"
								id="agree-tc"
								name="account.termsAndCondition"
								checked={values.account.termsAndCondition}
								onChange={(e) => setFieldValue('account.termsAndCondition', e.target.checked)}
								onBlur={handleBlur}
								disabled={isSubmitting}
							/>
							<label className="custom-control-label" htmlFor="agree-tc">
								{ReactHtmlParser(
									this.state.termsAndConditionsLink
								)}
							</label>
							{errors.account && errors.account.termsAndCondition && touched.account && touched.account.termsAndCondition && (
								<div style={{display: 'block'}} className="invalid-feedback">
									{errors.account.termsAndCondition}
								</div>
							)}
						</div>

						{createOrderError && <p style={{color: 'red'}}>{createOrderError}</p>}

						<div className="form-group form-group-footer">

							<button
								disabled={isSubmitting}
								onClick={() => handleChangeStep('schedule-appointment')}
								type="button"
								className="btn btn-quick"
							>
								Back
							</button>

							<button
								disabled={isSubmitting || (errors.account && errors.account.termsAndCondition && touched.account && touched.account.termsAndCondition)}
								type="submit"
								className="btn btn-primary"
							>
								Schedule
								{isSubmitting && <>&nbsp;<i className="fas fa-fw fa-spin fa-spinner"/></>}
							</button>

						</div>
					</>
				}

			</div>
		);
	}
}

export default ConfirmationStep;

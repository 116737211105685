import axiosInstance from '../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../utils/helpers';
import { getErrorMessage } from '../utils/helpers';

// export const CHECK_BY_EMAIL_OR_NUMBER = createRequestTypes('CHECK_BY_EMAIL_OR_NUMBER');
// export const CHECK_BY_ACCOUNT_AND_ZIPCODE = createRequestTypes('CHECK_BY_ACCOUNT_AND_ZIPCODE');
export const CHEKC_ACCOUNT = createRequestTypes('CHECK_ACCOUNT');
export const AUTH_CODE = createRequestTypes('AUTH_CODE');
export const VALIDATE_REGISTER_EMAIL = createRequestTypes('VALIDATE_REGISTER_EMAIL');
export const REGISTER_USER_BY_TOKEN = createRequestTypes('REGISTER_USER_BY_TOKEN');
export const VALIDATE_ORDER_TOKEN = createRequestTypes('VALIDATE_ORDER_TOKEN');

export const checkByEmailOrNumber = (emailOrNumber) => async (dispatch, getState) => {
    dispatch(actionCreator.request(CHEKC_ACCOUNT));
    try {
        const response = await axiosInstance.get(`/public/account/contact/emailOrNumber/primary?emailOrNumber=${encodeURIComponent(emailOrNumber)}`);
        dispatch(actionCreator.success(CHEKC_ACCOUNT, response.data));
    } catch (err) {
        let language = getState().language.language;
        let mockError = getErrorMessage(err.response, language);
        dispatch(actionCreator.failure(CHEKC_ACCOUNT, mockError));
    }
};

export const emptycheckByEmailOrNumber = () => async (dispatch) => {
    dispatch(actionCreator.failure(CHEKC_ACCOUNT));
};

export const checkByAcountAndZipcode = (accountNumber, billingZipCode) => async (dispatch, getState) => {
    dispatch(actionCreator.request(CHEKC_ACCOUNT));
    try {
        const response = await axiosInstance.get(`/public/account/contact/accountAndBillingZip/primary/${accountNumber}/${billingZipCode}`);
        dispatch(actionCreator.success(CHEKC_ACCOUNT, response.data));
    } catch (err) {
        console.log(err);
        let language = getState().language.language;
        let mockError = getErrorMessage(err.response, language);
        dispatch(actionCreator.failure(CHEKC_ACCOUNT, mockError));
    }
};

export const emptycheckByAcountAndZipcode = () => async (dispatch) => {
    dispatch(actionCreator.failure(CHEKC_ACCOUNT));
};

export const sendAuthCode = (data) => async (dispatch, getState) => {
    dispatch(actionCreator.request(AUTH_CODE));
    try {
        const response = await axiosInstance.post('/public/authcode', data)
        dispatch(actionCreator.success(AUTH_CODE, response.authcode))
        return response.data
    } catch (err) {
        let language = getState().language.language;
        let mockError = getErrorMessage(err.response, language);
        dispatch(actionCreator.failure(CHEKC_ACCOUNT, mockError));
    }
}

export const validateRegisterEmail = (verificationToken) => async (dispatch, getState) => {
    dispatch(actionCreator.request(VALIDATE_REGISTER_EMAIL));
    const data = {
        verificationToken
    }
    try {
        const response = await axiosInstance.post(`/verification/email/registertokenverify`, data);
        dispatch(actionCreator.success(VALIDATE_REGISTER_EMAIL, response.data));
        return response;
    } catch (err) {
        console.log(err.response);
        let language = getState().language.language;
        let mockError = getErrorMessage(err.response, language);
        dispatch(actionCreator.failure(VALIDATE_REGISTER_EMAIL, mockError));
        return err.response;
    }
};

export const registerUserByToken = (values, verificationToken) => async (dispatch, getState) => {
    dispatch(actionCreator.request(REGISTER_USER_BY_TOKEN));
    const data = {
        verificationToken: verificationToken,
        newPassword: values.newPassword
    }
    try {
        const response = await axiosInstance.post(`/verification/email/register`, data);
        dispatch(actionCreator.success(REGISTER_USER_BY_TOKEN, response.data));
        return response;
    } catch (err) {
        let language = getState().language.language;
        let mockError = getErrorMessage(err.response, language);
        dispatch(actionCreator.failure(REGISTER_USER_BY_TOKEN, mockError));
        return err.response;
    }
};

export const validateOrderToken = (verificationToken) => async (dispatch, getState) => {
    dispatch(actionCreator.request(VALIDATE_ORDER_TOKEN));
    const data = {
        verificationToken
    }
    try {
        const response = await axiosInstance.post(`/verification/email/ordertokenverify`, data);
        dispatch(actionCreator.success(VALIDATE_ORDER_TOKEN, response.data));
        return response;
    } catch (err) {
        console.log(err.response);
        let language = getState().language.language;
        let mockError = getErrorMessage(err.response, language);
        dispatch(actionCreator.failure(VALIDATE_ORDER_TOKEN, mockError));
        return err.response;
    }
};

export const checkForServiceLine = (params) => async () => {
    try {
        const response = await axiosInstance.get(`/public/account/serviceline/active/address/count`, {params})
        return response
    } catch(error) {
        if (error.response.status === 409) {
            let errorMessage = error.response.data.error.message
            return {errorMessage: errorMessage, activeServiceLines: true}
        }
    }
}

export const checkForServiceOrder = (params) =>  async () => {
    try {
        const response = await axiosInstance.get(`public/account/serviceorder/pending/address/count`, {params})
        return response
    } catch(error) {
        if (error.response.status === 409) {
            let errorMessage = error.response.data.error.message
            return {errorMessage: errorMessage}
        }
    }
}
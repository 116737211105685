import React, {Component} from 'react';
import ReactHtmlParser from "react-html-parser";
import PackageTooltip from "../../../../../components/PackageTooltip";
import isEmpty from "../../../../../utils/helpers";

class OptionPackage extends Component {

    state = {
        isTooltipOpen: false
    };

    handleTooltip = () => {
        this.setState((prevProps) => ({
            isTooltipOpen: !prevProps.isTooltipOpen
        }));
    };

    render() {
        const { isTooltipOpen } = this.state;
        const { features, lineIndex, handleOptionalPackages, packageOption, featurePackage } = this.props;

        return (
            <div className={"package" + (packageOption.hideCustomerPortal ? " d-none" : "")}>
                <div className="package-header custom-switch-with-button">

                    <div className="custom-control custom-switch"
                         onClick={() =>
                        handleOptionalPackages(packageOption.featurePackageOptionId, lineIndex, {
                            featurePackageId: featurePackage.featurePackageId,
                            featurePackageDescription: featurePackage.description,
                            featurePackageNote: featurePackage.note,
                            action: 'ENABLE',
                            ...packageOption
                        })}>
                        <input type="checkbox"
                               className="custom-control-input"
                               checked={features.findIndex((x) => x.featurePackageOptionId === packageOption.featurePackageOptionId) !== -1}/>
                        <label className="custom-control-label" htmlFor="">
                            <span>{packageOption.description}</span>
                            <span>
								${packageOption.recurringMonthlyPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo
							</span>
                        </label>
                    </div>

                    {!isEmpty(packageOption.note) &&
                    <button type="button" className="btn btn-toggle-package-desc" onClick={() => this.handleTooltip()}>
                        <i className="fas fa-info-circle"/>
                    </button>
                    }

                </div>

                <PackageTooltip isVisible={isTooltipOpen}>{ReactHtmlParser(packageOption.note)}</PackageTooltip>

            </div>
        );
    }
}

export default OptionPackage;

import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

const IconInput = ({ type, name, iconClass, errors, touched, placeholder, value, onChange, onBlur, divClass }) => {
	return (
		<div className={divClass}>
			{iconClass && (
				<div className="input-group-prepend">
					<span className="input-group-text">
						<i className={iconClass} />
					</span>
				</div>
			)}

			<input
				className={
					get(errors, `${name}`) && get(touched, `${name}`) ? 'form-control is-invalid' : 'form-control'
				}
				name={name}
				placeholder={placeholder}
				type={type}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				autoComplete="off"
			/>
			{get(errors, `${name}`) &&
			get(touched, `${name}`) && <div className="invalid-feedback">{get(errors, `${name}`)}</div>}
		</div>
	);
};

IconInput.propTypes = {
	type: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	errors: PropTypes.object.isRequired,
	touched: PropTypes.object.isRequired,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	iconClass: PropTypes.string
};

export default IconInput;

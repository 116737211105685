import React, { Component } from 'react';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/core';



class Loader extends Component {
	render() {
		return (
			<div className="loader">
				<ClipLoader sizeUnit={'px'} size={80} color={'#6ab3c1'} loading={true} />
			</div>
		);
	}
}

export default Loader;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
	getMyServiceInfo,
	getMyOrders,
	getMyInvoices,
	getMyAmounts,
	getMyAutoPayInfo,
	getAutoPayAvailableCC,
	getAccountDistributionMethod,
	getAccountTypeDistributionMethods
} from '../../actions/homeInfoActions'
import CustomerServices from './CustomerServices';
import {createLoadingSelector, selectHomeInfo, getAccountAutoPayStatus} from '../../selectors'
import {getCurrentPaymentProviderSupportsPaymentMethodStorage} from "../../actions/paymentProviderActions";
import {getUserPermissions} from "../../actions/authActions";
import {getAccountAutoPay} from "../../actions/autoPayActions";

class Home extends Component {

	static propTypes = {
		accountInfo: PropTypes.object.isRequired
	};

	componentDidMount() {
		const {
			accountInfo,
			getMyServiceInfo,
			getMyOrders,
			getMyInvoices,
			getMyAmounts,
			getMyAutoPayInfo,
			getAutoPayAvailableCC,
			getAccountDistributionMethod,
			getAccountTypeDistributionMethods,
			getAccountAutoPay,
			getUserPermissions
		} = this.props;

		getMyServiceInfo(accountInfo.id);
		getMyOrders(accountInfo.id);
		getMyInvoices(accountInfo.id);
		getMyAmounts(accountInfo.id);
		getMyAutoPayInfo(accountInfo.id);
		getAutoPayAvailableCC(accountInfo.id);
		getAccountAutoPay(accountInfo.id)
		getAccountDistributionMethod(accountInfo.id);
		getAccountTypeDistributionMethods(accountInfo.accountType.name);
		getUserPermissions()
	}
	render() {

		const {
			accountInfo,
			myServiceInfoLoader,
			myOrdersInfoLoader,
			myInvoicesInfoLoader
		} = this.props;

		return (
			<div className="cp-page cp-page-dashboard">

				{/*<div className="container-alert-primary" />*/}

				<CustomerServices
					myServiceInfoLoader={myServiceInfoLoader}
					myOrdersInfoLoader={myOrdersInfoLoader}
					myInvoicesInfoLoader={myInvoicesInfoLoader}
					{...this.props}
				/>

				{/* <CustomerMenagment /> */}

			</div>
		);
	}
}
const getMyServiceInfoLoader = createLoadingSelector(['MY_SERVICE']);
const getMyOrdersInfoLoader = createLoadingSelector(['MY_ORDERS']);
const getMyInvoicesInfoLoader = createLoadingSelector(['MY_INVOICES']);
const getMyAmountsLoader = createLoadingSelector(['MY_AMOUNTS']);

const mapStateToProps = (state) => {

	const
		myServiceInfo = selectHomeInfo(state).myServiceInfo,
		myServiceInfoLoader = getMyServiceInfoLoader(state),
		myOrders = selectHomeInfo(state).myOrders,
		myOrdersInfoLoader = getMyOrdersInfoLoader(state),
		myInvoices = selectHomeInfo(state).myInvoices,
		myInvoicesInfoLoader = getMyInvoicesInfoLoader(state),
		myAmounts = selectHomeInfo(state).myAmounts,
		myAmountsLoader = getMyAmountsLoader(state),
		myAutoPayInfo = selectHomeInfo(state).myAutoPayInfo,
		myAutoPayAvailableCC = selectHomeInfo(state).myAutoPayAvailableCC,
		myDistributionMethods = selectHomeInfo(state).myDistributionMethods,
		myCurrentDistributionMethod = selectHomeInfo(state).myCurrentDistributionMethod,
		myAutoPayStatus = getAccountAutoPayStatus(state),
		myAutoPayWarning = getAccountAutoPayStatus(state).autoPayWarningMessage;

		return {
		myServiceInfo,
		myServiceInfoLoader,
		myOrders,
		myOrdersInfoLoader,
		myInvoices,
		myInvoicesInfoLoader,
		myAmounts,
		myAmountsLoader,
		myAutoPayInfo,
		myAutoPayAvailableCC,
		myDistributionMethods,
		myCurrentDistributionMethod,

	}
};

const mapDispatchToProps = {
	getMyServiceInfo,
	getMyOrders,
	getMyInvoices,
	getMyAmounts,
	getMyAutoPayInfo,
	getAccountAutoPay,
	getAutoPayAvailableCC,
	getAccountDistributionMethod,
	getAccountTypeDistributionMethods,
	getCurrentPaymentProviderSupportsPaymentMethodStorage,
	getUserPermissions
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

import axiosInstance from '../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../utils/helpers';

export const GET_PAYMENT_FORM = createRequestTypes('GET_PAYMENT_FORM');
export const GET_PAYMENT_STATUS = createRequestTypes('GET_PAYMENT_STATUS');
export const SET_PAYMENT_BY_ACH = createRequestTypes('SET_PAYMENT_BY_ACH');
export const SET_PAYMENT_BY_TOKEN = createRequestTypes('SET_PAYMENT_BY_TOKEN');

export const getPaymentFormData = (accountId, data) => async (dispatch) => {

	dispatch(actionCreator.request(GET_PAYMENT_FORM));
	try {
		const response = await axiosInstance.post(`/payment/${accountId}/form`, data);
		dispatch(actionCreator.success(GET_PAYMENT_FORM, response.data));
		return response;
	} catch (err) {
		dispatch(actionCreator.failure(GET_PAYMENT_FORM));
		return err;
	}
};

export const clearPaymentFormData = () => async (dispatch) => {
	dispatch(actionCreator.clear(GET_PAYMENT_FORM));
};

export const getPaymentStatus = (referenceId) => async (dispatch) => {

	dispatch(actionCreator.request(GET_PAYMENT_STATUS));
	try {
		const response = await axiosInstance.get(`/payment/${referenceId}/status`);
		dispatch(actionCreator.success(GET_PAYMENT_STATUS, response.data));
		return response;
	} catch (err) {
		dispatch(actionCreator.failure(GET_PAYMENT_STATUS));
		return err;
	}
};

export const clearPaymentStatus = () => async (dispatch) => {
	dispatch(actionCreator.clear(GET_PAYMENT_STATUS));
};

export const paymentByACH = (accountId, data) => async (dispatch) => {
	dispatch(actionCreator.request(SET_PAYMENT_BY_ACH));
	try {
		const response = await axiosInstance.post(`/account/${accountId}/payment/ach`, data);
		dispatch(actionCreator.success(SET_PAYMENT_BY_ACH, response.data));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(SET_PAYMENT_BY_ACH));
		return err.response.data;
	}
};

export const paymentByToken = (accountId, data) => async (dispatch) => {
	dispatch(actionCreator.request(SET_PAYMENT_BY_TOKEN));
	try {
		const response = await axiosInstance.post(`/account/${accountId}/payment/token`, data);
		if (response.data.status === 'SUCCESS') {
			dispatch(actionCreator.success(SET_PAYMENT_BY_TOKEN, response.data));
		} else if (response.data.status === 'FAIL') {
			dispatch(actionCreator.failure(SET_PAYMENT_BY_TOKEN));
		} else {
			dispatch(actionCreator.failure(SET_PAYMENT_BY_TOKEN));
		}
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(SET_PAYMENT_BY_TOKEN));
		return err.response.data;
	}
}

import React, { Component } from 'react';
import {trans} from "../../../../utils/helpers";

class NotAvailableConfirmation extends Component {
	state={
		title:'',
		subTitle:''
	}

	componentDidMount(){
		(async() => {
			const title= await trans('en-us', 'guestOrderScreen.notAvailableServiceConfirmation.title');
			const subTitle= await trans('en-us', 'guestOrderScreen.notAvailableServiceConfirmation.subTitle');

			this.setState({title,subTitle})
		})()
	}
	render() {
		const { resetForm, setTouched, handleResetWizard } = this.props;

		return (
			<div className="form-section fx-fadein" id="">
				<p className="text-center text-success">
					<i className="fas fa-4x fa-check-circle" />
				</p>
				<h3 className="text-center text-success">
				{this.state.title}
				</h3>
				<p className="text-center text-sm text-muted">
				{this.state.subTitle}
				</p>
				<div className="form-group form-group-footer">
					<button
						onClick={() => handleResetWizard(resetForm, setTouched)}
						type="button"
						id="sendlogin"
						className="btn btn-primary btn-fs-action"
						data-target="fs1"
					>
						Check Another Address
					</button>
				</div>
			</div>
		);
	}
}

export default NotAvailableConfirmation;

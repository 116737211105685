import axios from 'axios';
import axiosInstance from '../utils/axios-instance';
import { API_ROOT } from '../utils/api-config';
import { actionCreator, createRequestTypes, generateLocalStorageItem, getErrorMessage } from '../utils/helpers';
import history from '../utils/history';

export const SIGN_IN = createRequestTypes('SIGN_IN');
export const AUTH_LOGOUT = createRequestTypes('AUTH_LOGOUT');
export const FORGOTTEN_PASSWORD = createRequestTypes('FORGOTTEN_PASSWORD');
export const RESET_FORGOTEN_PASSWORD_TOKEN = createRequestTypes('RESET_FORGOTEN_PASSWORD_TOKEN');
export const RESET_PASSWORD = createRequestTypes('RESET_PASSWORD');
export const IMPERSONATE_USER = createRequestTypes('IMPERSONATE_USER');
export const SET_USER_PERMISSIONS = createRequestTypes('SET_USER_PERMISSIONS');


export const login = (username, password) => async (dispatch, getState) => {
	dispatch(actionCreator.request(SIGN_IN));

	let data = {
		j_username: username,
		j_password: password
	};

	const headers = {
		headers: {
			'Content-Type': 'application/json'
		},
		method: 'GET',
		url: `${API_ROOT}/auth/customer/login`,
		params: data
	};

	try {
		const response = await axios(headers);
		generateLocalStorageItem(response.data);
		dispatch(actionCreator.success(SIGN_IN, response.data));
		// history.push('/home')
	} catch (err) {

		console.log(err.response);
		let language = getState().language.language;
		let mockError = getErrorMessage(err.response, language);
		dispatch(actionCreator.failure(SIGN_IN, mockError));

	}
};

export const forgottenPassword = (email) => async (dispatch) => {
	dispatch(actionCreator.request(FORGOTTEN_PASSWORD))

	let data = {
		email: email
	}

	const headers = {
		headers: {
			'Content-Type': 'application/json'
		},
		method: 'GET',
		url: `${API_ROOT}/auth/customer/password/forgotten`,
		params: data
	};

	try {
		const response = await axios(headers);
		dispatch(actionCreator.success(FORGOTTEN_PASSWORD, response.data));
		return response.data;
	} catch (err) {

		let mockError = {
			message: err.response.data.error.message
		}

		dispatch(actionCreator.failure(FORGOTTEN_PASSWORD, mockError))
		return err.response.data;
	}
}

export const emptyForgottenPassword = () => (dispatch) => {

	dispatch(actionCreator.empty(FORGOTTEN_PASSWORD)); // empty reducer
	dispatch(actionCreator.clear(FORGOTTEN_PASSWORD)); // empty error message

};

// export const forgottenPassword = (email) => async (dispatch, getState) => {
// 	dispatch(actionCreator.request(FORGOTTEN_PASSWORD));

// 	let data = {
// 		email: email
// 	};

// 	const headers = {
// 		headers: {
// 			'Content-Type': 'application/json'
// 		},
// 		method: 'GET',
// 		url: `${API_ROOT}/auth/password/forgotten`,
// 		params: data
// 	};

// 	try {
// 		const response = await axios(headers);
// 		dispatch(actionCreator.success(FORGOTTEN_PASSWORD, response.data));
// 		return response.data;
// 	} catch (err) {
// 		// let language = getState().language.language;
// 		// let mockError = getErrorMessage(err.response, language);

// 		let mockError = {
// 			message: err.response.data.message
// 		};

// 		dispatch(actionCreator.failure(FORGOTTEN_PASSWORD, mockError));
// 		// return err.response.data;
// 	}
// };

export const resetTokenValidation = (token) => async (dispatch, getState) => {
	dispatch(actionCreator.request(RESET_FORGOTEN_PASSWORD_TOKEN));

	let data = {
		token: token
	};

	const headers = {
		headers: {
			'Content-Type': 'application/json'
		},
		method: 'GET',
		url: `${API_ROOT}/auth/password/resettokenvalidation`,
		params: data
	};

	try {
		const response = await axios(headers);
		dispatch(actionCreator.success(RESET_FORGOTEN_PASSWORD_TOKEN, response.data));
		return response.data;
	} catch (err) {
		// let language = getState().language.language;
		// let mockError = getErrorMessage(err.response, language);

		let mockError = {
			message: err.response.data.message
		};

		dispatch(actionCreator.failure(RESET_FORGOTEN_PASSWORD_TOKEN, mockError));
		// return err.response.data;
	}
};

export const resetPassword = (newPassword, verificationToken) => async (dispatch) => {
	dispatch(actionCreator.request(RESET_PASSWORD));

	let data = {
		newPassword,
		verificationToken
	};

	const headers = {
		headers: {
			'Content-Type': 'application/json'
		},
		method: 'POST',
		url: `${API_ROOT}/auth/customer/password/reset`,
		data: data
	};

	try {
		const response = await axios(headers);
		dispatch(actionCreator.success(RESET_PASSWORD, response.data));
		return response.data;
	} catch (err) {
		let mockError = {
			message: err.response.data.error.message
		};
		dispatch(actionCreator.failure(RESET_PASSWORD, mockError));
		//console.log(err.response.data)
		return err.response.data;
	}
};

export const checkAuthStatus = () => {
	return (dispatch) => {
		const user = localStorage.getItem('currentUser');
		if (!user) {
			localStorage.removeItem('currentUser');
			localStorage.removeItem('location');
			dispatch(actionCreator.success(AUTH_LOGOUT));
		} else {
			dispatch(actionCreator.success(SIGN_IN, JSON.parse(user)));
		}
	};
};

export const logout = (history) => {
	return (dispatch) => {
		localStorage.removeItem('currentUser');
		localStorage.removeItem('location');
		dispatch(actionCreator.success(AUTH_LOGOUT));
		if (history) {
			history.push('/login')
		}
	};
};


export const clearSignInError = () => (dispatch) => {
	dispatch(actionCreator.clear(SIGN_IN));
};

export const verifyEmail = (verificationToken) => async (dispatch) => {
	let data = {
		verificationToken: verificationToken
	};

	try {
		const response = await axiosInstance.post('/verification/email/tokenverify', data);
		return response.data;
	} catch (err) {
		return err.response.data;
	}
};

export const impersonateUser = (userId, apiToken) => async (dispatch, getState) => {
	dispatch(actionCreator.request(IMPERSONATE_USER));

	const headers = {
		headers: {
			'Content-Type': 'application/json',
			'X-API-Token': apiToken
		},
		method: 'GET',
		url: `${API_ROOT}/auth/customer/impersonate/${userId}`
	};

	localStorage.removeItem('currentUser');
	localStorage.removeItem('location');
	dispatch(actionCreator.success(AUTH_LOGOUT));

	try {
		const response = await axios(headers);
		generateLocalStorageItem(response.data);
		dispatch(actionCreator.success(IMPERSONATE_USER, response.data));
		history.push('/home')

		return response;
	} catch (err) {

		let language = getState().language.language;
		let mockError = getErrorMessage(err.response, language);
		dispatch(actionCreator.failure(IMPERSONATE_USER, mockError));

		return err;
	}
};

export const getUserPermissions = () => async(dispatch) => {
	dispatch(actionCreator.request(SET_USER_PERMISSIONS));

	try {
		const response = await axiosInstance('/user/permissions')
		dispatch(actionCreator.success(SET_USER_PERMISSIONS, response.data.modulePermissions))

	} catch(err) {
		dispatch(actionCreator.failure(SET_USER_PERMISSIONS, err))
		return err
	}

}

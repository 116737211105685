import axiosInstance from '../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../utils/helpers';

export const CREATE_PAYMENT_METHOD_FORM = createRequestTypes('CREATE_PAYMENT_METHOD_FORM');

export const createPaymentMethodForm = (accountId, data) => async (dispatch) => {

    let url = `/account/${accountId}/createPaymentMethod`
    let responseIsForm = true;
    if (data.submitType === "managePaymentMethods") {
        url = `/account/${accountId}/managePaymentMethods`
    } else if (data.submitType === "deletePaymentMethod") {
        url = `/account/${accountId}/deletePaymentMethod`
        responseIsForm = false;
    } else if (data.submitType === "oneTimePayment") {
        url = `/payment/${accountId}/oneTime`
        data.paymentOption = "ALL_UNPAID_INVOICES"
        data.submitType = "oneTimePayment"
    }
    dispatch(actionCreator.request(CREATE_PAYMENT_METHOD_FORM));
    try {
        const response = await axiosInstance.post(url, data);
        response.data.submitType = data.submitType
        if (responseIsForm) {
            dispatch(actionCreator.success(CREATE_PAYMENT_METHOD_FORM, response.data));
        } else {
            //dispatch(actionCreator.success(CREATE_PAYMENT_METHOD_FORM));
            response.data.success = response.data.result
        }
        return response;
    } catch (err) {
        dispatch(actionCreator.failure(CREATE_PAYMENT_METHOD_FORM));
        return err;
    }
};

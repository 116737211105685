import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = ({ icon, title, titleSuffix, value, color, percentage, note }) => {

    return (
        <div className={"cp-progress-bar" + (percentage === false ? " mb-3" : "")}>
            <div className="progress-header">
                <span className="ph-left">
                    <i className={icon !== `` && icon !== undefined ? icon : `fas fa-info-circle`}/>
                    {title}
                    <span>{titleSuffix}</span>
                </span>
                <span className="ph-right">
                    {value}
                </span>
            </div>
            {percentage>=0 &&
            <div className="progress">
                <div
                    className={`progress-bar` + (color ? ` progress-bar-`+color : ``)}
                    role="progressbar"
                    style={{width: percentage + '%'}}
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                />
            </div>
            }
            <div className="progress-footer">
                <p className="mb-0 text-right">{note}</p>
            </div>
        </div>
    );
};

ProgressBar.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    percentage: PropTypes.number,
    color: PropTypes.string,
    note: PropTypes.string,
};

export default ProgressBar;
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { object, string } from 'yup';
import { login, logout, clearSignInError } from '../../actions/authActions';
import { selectAuth, selectErrorMessage, createLoadingSelector } from '../../selectors';

import IconInput from '../../components/common/Formik/IconInput';
import Button from '../../components/common/Buttons/Button';
import GuestLayout from '../Layout/GuestLayout';

const signInSchema = object().shape({
	username: string().min(3, 'Min 3 characters').required('Required'),
	password: string().min(1, 'Min 1 character').required('Required')
});

class SignIn extends Component {
	static propTypes = {
		isLoggedIn: PropTypes.bool.isRequired,
		logout: PropTypes.func.isRequired,
		history: PropTypes.object.isRequired,
		isSignInFormSubmiting: PropTypes.bool
	};

	componentDidMount() {
		const { isLoggedIn, logout } = this.props;
		if (isLoggedIn) {
			logout();
		}
	}

	componentDidUpdate(prevProps) {
		const { history } = this.props;

		if (prevProps.isLoggedIn !== this.props.isLoggedIn && this.props.isLoggedIn === true) {
			const location = history.location;

			if (location.state && location.state.from) {
				history.push(location.state.from);
			} else {
				history.push('/home');
			}
		}
	}

	componentWillUnmount() {
		this.props.clearSignInError();
	}

	handleOnSubmit = (values) => {
		const { login } = this.props;
		login(values.username, values.password);
	};

	render() {
		const { isSignInFormSubmiting, signInError, isResetPasswordSuccessful } = this.props;

		return (
			<GuestLayout>
				<div className="cp-page cp-page-login justify-content-start">
					<div className="card-guest card-full-height">
						{/* <div className="card-guest-header">
								<div className="brand-container" />
								<p className="brand-header">Sign In</p>
							</div> */}
						<div className="card-guest-body">
							<Formik
								initialValues={{
									username: '',
									password: ''
								}}
								validationSchema={signInSchema}
								onSubmit={this.handleOnSubmit}
							>
								{({
									handleChange,
									handleSubmit,
									handleBlur,
									values,
									errors,
									touched,
									isSubmitting
								}) => (
										<Form onSubmit={handleSubmit} className="needs-validation cp-form">
											<div className="form-section fx-fadein">

												<h3>Sign In</h3>

												{/*<div className="form-group form-group-margin">
													Not registered yet? <Link to={{ pathname: '/register' }}>Register now!</Link>
												</div>*/}

												<div className="form-group">
													<label>Username or Email</label>
													<IconInput
														type="text"
														name="username"
														value={values.username}
														errors={errors}
														touched={touched}
														onChange={handleChange}
														onBlur={handleBlur}
														divClass='input-group'
													/>
												</div>

												<div className="form-group">
													<label>Password</label>
													<IconInput
														type="password"
														name="password"
														value={values.password}
														errors={errors}
														touched={touched}
														onChange={handleChange}
														onBlur={handleBlur}
														divClass='input-group'
													/>
												</div>

												{isResetPasswordSuccessful && <p>Your password has been saved!</p>}
												{signInError && <p style={{ color: 'red' }}>{signInError}</p>}

												<div className="form-group form-group-button">
													<Button
														type="submit"
														className="btn btn-primary btn-block"
														disabled={isSignInFormSubmiting}
													>
														Sign In{' '}
														{isSignInFormSubmiting && (
															<i className="fas fa-fw fa-spin fa-spinner" />
														)}
													</Button>
												</div>

												{/*<div className="form-group form-group-margin custom-control custom-checkbox">
													<input type="checkbox" className="custom-control-input" id="remember_me" />
													<label className="custom-control-label" htmlFor="remember_me">
														Remember me on this device
													</label>
												</div>*/}

												<div className="form-group form-divider">
													<Link to={{ pathname: '/password/forgotten' }}>Forgot password?</Link>
													<div style={{marginTop: 10}}>
														<Link to={{ pathname: '/account-number/register' }}>Register Now</Link>
													</div>
												</div>


												{/*<div className="form-group">
													<p>Just need to make a payment?</p>
													<Link to={{ pathname: '/guest-order' }}>Continue without signing in</Link>
												</div>*/}

											</div>
										</Form>
									)}
							</Formik>
						</div>
					</div>
				</div>
			</GuestLayout>
		);
	}
}

const getSignInLoader = createLoadingSelector(['SIGN_IN']);
const getSignInError = selectErrorMessage(['SIGN_IN']);

const mapStateToProps = (state) => {
	const isLoggedIn = selectAuth(state).isLoggedIn,
		isSignInFormSubmiting = getSignInLoader(state),
		signInError = getSignInError(state),
		isResetPasswordSuccessful = selectAuth(state).isResetPasswordSuccessful

	return {
		isLoggedIn,
		signInError,
		isSignInFormSubmiting,
		isResetPasswordSuccessful
	};
};

const mapDispatchToProps = {
	login,
	logout,
	clearSignInError
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
import React, { Component, Fragment } from 'react';
import IncludedFeatures from '../../ProductsItem/IncludedFeatures';
import IncludedPackages from '../../ProductsItem/IncludedPackages';
import MandatoryPackages from '../../ProductsItem/MandatoryPackages';
import MandatoryFeatures from '../../ProductsItem/MandatoryPackages';
import OptionalPackages from '../../ProductsItem/OptionalPackages';
import OptionalFeatures from '../../ProductsItem/OptionalFeatures';

class PlanServicelines extends Component {
	handleMandatoryPackages = (packageId, lineIndex, item) => {
		const { planIndex, values, setFieldValue } = this.props;

		let updateFeatures = values.order.plans[planIndex].servicelines[lineIndex].features.filter(
			(feature) => feature.featurePackageId !== item.featurePackageId
		);

		updateFeatures = [ ...updateFeatures, item ];
		setFieldValue(`order.plans.${planIndex}.servicelines.${lineIndex}.features`, updateFeatures);
	};

	handleOptionalPackagesCheckbox = (packageId, lineIndex, item) => {
		const { planIndex, values, setFieldValue } = this.props;
		let updateFeatures;
		if (
			values.plans[planIndex].servicelines[lineIndex].features.findIndex(
				(feature) => feature.featurePackageId === packageId
			) !== -1
		) {
			updateFeatures = values.plans[planIndex].servicelines[lineIndex].features.filter(
				(feature) => feature.featurePackageId !== item.featurePackageId
			);
		} else {
			updateFeatures = [ ...values.plans[planIndex].servicelines[lineIndex].features ];
			updateFeatures = [ ...updateFeatures, item ];
		}

		setFieldValue(`plans.${planIndex}.servicelines.${lineIndex}.features`, updateFeatures);
	};

	handleOptionalFeature = (featureId, lineIndex, item) => {
		const { planIndex, values, setFieldValue } = this.props;

		let updateFeatures;
		if (
			values.order.plans[planIndex].servicelines[lineIndex].features.findIndex(
				(feature) => feature.id === featureId
			) !== -1
		) {
			updateFeatures = values.order.plans[planIndex].servicelines[lineIndex].features.filter(
				(feature) => feature.id !== item.id
			);
		} else {
			updateFeatures = [ ...values.order.plans[planIndex].servicelines[lineIndex].features ];
			updateFeatures = [ ...updateFeatures, item ];
		}

		setFieldValue(`order.plans.${planIndex}.servicelines.${lineIndex}.features`, updateFeatures);
	};

	handleOptionalPackages = (packageId, lineIndex, item) => {
		const { planIndex, values, setFieldValue } = this.props;
		let updateFeatures;

		let findItem = values.order.plans[planIndex].servicelines[lineIndex].features.find(
			(feature) => feature.featurePackageId === item.featurePackageId
		);
		if (findItem) {
			if (
				values.order.plans[planIndex].servicelines[lineIndex].features.find(
					(feature) =>
						feature.featurePackageId === item.featurePackageId &&
						feature.featurePackageOptionId === item.featurePackageOptionId
				)
			) {
				updateFeatures = values.order.plans[planIndex].servicelines[lineIndex].features.filter(
					(feature) =>
						feature.featurePackageId !== item.featurePackageId &&
						feature.featurePackageOptionId !== item.featurePackageOptionId
				);
			} else {
				updateFeatures = values.order.plans[planIndex].servicelines[lineIndex].features.filter(
					(feature) => feature.featurePackageId !== item.featurePackageId
				);
				updateFeatures = [ ...updateFeatures, item ];
			}
		} else {
			updateFeatures = [ ...values.order.plans[planIndex].servicelines[lineIndex].features ];
			updateFeatures = [ ...updateFeatures, item ];
		}

		setFieldValue(`order.plans.${planIndex}.servicelines.${lineIndex}.features`, updateFeatures);
	};

	renderFeatures = () => {
		const { lineInfo, planInfo, lineIndex, values } = this.props;

		let line = [];

		if (lineInfo.serviceLineRelation === 'Main') {
			if (planInfo.mainIncludedFeatures) {
				line.push(
					<IncludedFeatures
						lineInfo={lineInfo}
						lineIndex={lineIndex}
						planFeature={planInfo.mainIncludedFeatures}
						accountInitialStatus={values.account.initialStatus}
					/>
				);
			}
			if (planInfo.mainIncludedFeaturePackages) {
				line.push(
					<IncludedPackages
						lineInfo={lineInfo}
						lineIndex={lineIndex}
						planFeature={planInfo.mainIncludedFeaturePackages}
						accountInitialStatus={values.account.initialStatus}
					/>
				);
			}
			if (planInfo.mainMandatoryFeatures) {
				line.push(
					<MandatoryFeatures
						lineInfo={lineInfo}
						lineIndex={lineIndex}
						planFeature={planInfo.mainMandatoryFeatures}
						accountInitialStatus={values.account.initialStatus}
					/>
				);
			}
			if (planInfo.mainMandatoryFeaturePackages) {
				line.push(
					<MandatoryPackages
						lineInfo={lineInfo}
						lineIndex={lineIndex}
						planFeature={planInfo.mainMandatoryFeaturePackages}
						handleMandatoryPackages={this.handleMandatoryPackages}
						accountInitialStatus={values.account.initialStatus}
					/>
				);
			}
			if (planInfo.mainOptionalFeaturePackages) {
				line.push(
					<OptionalPackages
						lineInfo={lineInfo}
						lineIndex={lineIndex}
						planFeature={planInfo.mainOptionalFeaturePackages}
						handleOptionalPackages={this.handleOptionalPackages}
						accountInitialStatus={values.account.initialStatus}
					/>
				);
			}
			if (planInfo.mainOptionalFeatures) {
				line.push(
					<OptionalFeatures
						lineInfo={lineInfo}
						lineIndex={lineIndex}
						planFeature={planInfo.mainOptionalFeatures}
						handleOptionalFeature={this.handleOptionalFeature}
						accountInitialStatus={values.account.initialStatus}
					/>
				);
			}
		}

		return line.map((item, index) => <Fragment key={index}>{item}</Fragment>);
	};

	render() {
		return <Fragment>{this.renderFeatures()}</Fragment>;
	}
}

export default PlanServicelines;

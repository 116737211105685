import React, {Component} from 'react'
import Loader from "../../components/common/Spinners/Loader";
import {getOrderDetails} from "../../actions/homeInfoActions";
import {connect} from "react-redux";
import isEmpty from "../../utils/helpers";
import FlyInRightPanel from '../../components/FlyInRightPanel'
import moment from 'moment';

export class MyOrdersAndSupportCenter extends Component {

    state = {
        completedCount: 0,
        pendingCount: 0,
        serviceOrders: [],
        loadingServiceOrders: false,
        showOrderDetails: false,
        shownServiceOrderId: 0
    }

    componentDidMount() {

        const {myOrders, showOrderDetails} = this.props;

        var pending = 0;
        var completed = 0;

        myOrders.forEach((order) => {
            if (order.status === 'FINISHED') {
                completed += order.serviceOrdersInfo.length;
            } else {
                pending += order.serviceOrdersInfo.length;
            }
        });

        this.setState({
            completedCount: completed,
            pendingCount: pending
        });
    }

    componentDidUpdate() {

        // Lock / unlock body scrolling
        setTimeout(() => {
            if (this.state.showOrderDetails || this.props.hasActivePanel)
                document.body.classList.add('sidebar-open')
            else
                document.body.classList.remove('sidebar-open')
        }, 0);
    }

    showHideOrderDetails = () => {
        const {myOrders} = this.props;

        if (this.state.showOrderDetails) {
            this.setState({showOrderDetails: false});
            return;
        }

        this.setState({
            loadingServiceOrders: true,
            serviceOrders: [],
            showOrderDetails: true,
        }, () => {

            myOrders.forEach((orderInfo, i) => {

                this.props.getOrderDetails(orderInfo.id).then((response) => {

                    if (response && response.serviceOrders) {
                        this.setState({
                            serviceOrders: [
                                ...this.state.serviceOrders,
                                ...response.serviceOrders
                            ]
                        })
                    }

                    if (i === myOrders.length - 1) {
                        this.setState({loadingServiceOrders: false});
                    }
                })
            })

        });
    }

    showServiceOrderDetails = (id) => {
        if (this.state.shownServiceOrderId == id) {
            this.setState({shownServiceOrderId: 0});
        } else {
            this.setState({shownServiceOrderId: id});
        }
    }

    render() {
        const {loadingServiceOrders} = this.state;
        const {myOrdersInfoLoader} = this.props;

        const serviceOrders = this.state.serviceOrders.sort((a, b) => {
            if (isEmpty(a.modifiedDateTime)) {
                return 1;
            }
            if (isEmpty(b.modifiedDateTime)) {
                return -1;
            }
            return b.modifiedDateTime.localeCompare(a.modifiedDateTime);
        });

        return (
            <>
                <div className="cp-col col-12 col-lg-6 d-flex">
                    <div className="card card-service">

                        <div className="card-caption">
                            <div className="card-left">

                                <div className="card-icon">
                                    <i className="far fa-file-alt"/>
                                </div>

                            </div>
                            <div className="card-right">

                                <div className="card-header">
                                    My Orders
                                    {!myOrdersInfoLoader &&
                                    <span
                                        className="badge badge-warning float-right">{this.state.pendingCount} Pending</span>
                                    }
                                </div>

                                <div className="card-description">
                                </div>

                            </div>
                        </div>

                        {myOrdersInfoLoader &&
                        <Loader/>
                        }

                        {!myOrdersInfoLoader && (this.state.pendingCount + this.state.completedCount > 0) && (
                            <div className="card-body">
                                <div className="list">
                                    <p className="border-bottom">
                                        <span className="status-dot status-dot-warning mr-1" />
                                        {this.state.pendingCount} Pending
                                    </p>
                                    <p>
                                        <span className="status-dot status-dot-success mr-1" />
                                        {this.state.completedCount} Completed
                                    </p>
                                </div>
                            </div>
                        )}

                        {!myOrdersInfoLoader && (this.state.pendingCount + this.state.completedCount > 0) && (
                            <div className="card-footer">
                                <div className="card-footer-toolbar">
                                    <button
                                        className="btn btn-sm btn-fw-enable btn-primary"
                                        data-target="view-order"
                                        onClick={this.showHideOrderDetails}
                                    >
                                        View My Orders
                                    </button>
                                </div>
                            </div>
                        )}

                    </div>
                </div>

                <FlyInRightPanel
                    title="My Orders"
                    onClose={this.showHideOrderDetails}
                    isVissible={this.state.showOrderDetails}
                >
                    <div id="view-order">
                        <div className="table-responsive">
                            <table className="table cp-table cp-table-primary table-customer-orders">

                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Date</th>
                                        <th>Order</th>
                                        <th>Status</th>
                                        {/*<th>ETA</th>*/}
                                    </tr>
                                </thead>

                                {serviceOrders.map((serviceOrder) => (
                                <tbody key={serviceOrder.id}>

                                    <tr>
                                        <td className="v-align-m">
                                            <button type="button"
                                                    className="btn btn-outline-secondary btn-expand-row btn-sm pl-2 pr-2"
                                                    data-target="view-order"
                                                    onClick={() => this.showServiceOrderDetails(serviceOrder.id)}
                                            >
                                                {this.state.shownServiceOrderId === serviceOrder.id ?
                                                    <i className="fas fa-minus"/> :
                                                    <i className="fas fa-plus"/>}
                                            </button>
                                        </td>
                                        <td className="v-align-m">
                                            {moment(serviceOrder.modifiedDateTime).format("MM/DD/YYYY")}
                                        </td>
                                        <td className="v-align-m">
                                            <span className="font-weight-bold">{serviceOrder.description}</span>
                                        </td>
                                        <td className="v-align-m text-warning">
                                            {serviceOrder.workflowStatusChain.find((wsc) => !isEmpty(wsc.currentStatus))
                                                ? serviceOrder.workflowStatusChain.find((wsc) => !isEmpty(wsc.currentStatus)).currentStatus.name
                                                : serviceOrder.workflowStatusChain[serviceOrder.workflowStatusChain.length - 1].groupState
                                            }
                                        </td>
                                        {/*<td className="v-align-m">
                                            <div className="nowrap">
                                                In 1 Day
                                            </div>
                                        </td>*/}
                                    </tr>

                                    {this.state.shownServiceOrderId === serviceOrder.id && (
                                    <tr className="tr-child">
                                        <td colSpan="5" className="td-row-expand">
                                            <div className="row-expand">
                                                <div>
                                                    <div className="cp-datarow-container mr-3 ml-3">
                                                        <div className="cp-datarow-wrapper">

                                                            <div className="order-label">ORDER DETAILS</div>

                                                            <div className="cp-datarow-row">
                                                                <div className="cp-datarow-label">ID</div>
                                                                <div
                                                                    className="cp-datarow-value">{serviceOrder.id}</div>
                                                            </div>

                                                            <div className="cp-datarow-row">
                                                                <div className="cp-datarow-label">Updated</div>
                                                                <div
                                                                    className="cp-datarow-value">{moment(serviceOrder.modifiedDateTime).format("MM/DD/YYYY hh:mm A")}</div>
                                                            </div>

                                                            <div className="cp-datarow-row">
                                                                <div className="cp-datarow-label">Type</div>
                                                                <div
                                                                    className="cp-datarow-value">{serviceOrder.type}</div>
                                                            </div>

                                                            <div className="cp-datarow-row">
                                                                <div className="cp-datarow-label">Address</div>
                                                                <div
                                                                    className="cp-datarow-value">{serviceOrder.serviceAddress}</div>
                                                            </div>

                                                            <div className="cp-datarow-row">
                                                                <div className="cp-datarow-label">Status</div>
                                                                <div className="cp-datarow-value">
                                                                    {serviceOrder.workflowStatusChain.find((wsc) => !isEmpty(wsc.currentStatus))
                                                                        ? serviceOrder.workflowStatusChain.find((wsc) => !isEmpty(wsc.currentStatus)).currentStatus.name
                                                                        : serviceOrder.workflowStatusChain[serviceOrder.workflowStatusChain.length - 1].groupState
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    )}

                                </tbody>
                                ))}

                            </table>
                        </div>

                        {loadingServiceOrders &&
                            <Loader/>
                        }

                    </div>
                </FlyInRightPanel>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
    getOrderDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(MyOrdersAndSupportCenter);

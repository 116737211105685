import React, {Component} from 'react'
import Loader from "../../../components/common/Spinners/Loader";
import moment from "moment";
import axiosInstance from '../../../utils/axios-instance';
import fileDownload from 'js-file-download';
import {formatAmount} from "../../../utils/helpers";
export class Invoices extends Component {

    state = {
        shownInvoiceId: '',
        pdfViewIconLoading: false,
        pdfViewButtonLoading: false,
        pdfDownloadLoading: false
    }

    showInvoiceDetails = (id) => {
        if (this.state.shownInvoiceId === id) {
            this.setState({shownInvoiceId: 0});
        } else {
            this.setState({shownInvoiceId: id});
        }
    }

    showPdf = (id) => {

        axiosInstance

            .get(`/account/invoice/${id}/pdf`, {
                // method: 'GET',
                responseType: 'blob' //Force to receive data in a Blob Format
                // responseType: 'arraybuffer'
            })

            .then((response) => {

                this.setState({
                    pdfViewIconLoading: false,
                    pdfViewButtonLoading: false,
                    pdfDownloadLoading: false
                });

                //Create a Blob from the PDF Stream
                const file = new Blob([response.data], {type: 'application/pdf'});

                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(file, 'my.pdf');
                } else if (navigator.userAgent.match('FxiOS')) {
                    alert('Cannot display on FF iOS');
                } else if (navigator.userAgent.match('CriOS')) {
                    let reader = new FileReader();
                    reader.onloadend = function () {
                        window.open(reader.result);
                    };
                    reader.readAsDataURL(file);
                } else if (navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i)) {
                    let url = window.URL.createObjectURL(file);
                    window.location.href = url;
                } else {

                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file);

                    // Open the URL on new Window
                    let newWindow = window.open(fileURL, '_blank');

                    // Alert the user of blocked popup
                    if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {

                        let browser = 'Your web browser';

                        if (navigator.userAgent.toLowerCase().indexOf('safari/') > -1) browser = 'Safari';
                        if (navigator.userAgent.toLowerCase().indexOf('firefox/') > -1) browser = 'Firefox';

                        alert(browser + ' blocked the PDF Invoice window from opening. Please allow pop ups and try again.');
                        // toastr.error(browser + ' blocked the PDF Invoice window from opening. Please allow pop ups and try again.', {timeOut: 0, position: 'top-center'});
                    }
                }
            })

            .catch((error) => {

                this.setState({
                    pdfViewIconLoading: false,
                    pdfViewButtonLoading: false,
                    pdfDownloadLoading: false
                });

                if (error.response && error.response.data) {
                    error.response.data.text().then(response => {
                        const resp = JSON.parse(response);

                        if (resp.error.code === 'INV0001') {
                            const errorMessage = resp.error.message;
                            alert(errorMessage);
                            // toastr.error(errorMessage, {timeOut: 0, position: 'top-center'});
                        }
                    });
                }
            });
    };

    downloadPdf = (id) => {

        axiosInstance

            .get(`/account/invoice/${id}/pdf`, {
                responseType: 'blob'
            })

            .then((response) => {
                this.setState({pdfDownloadLoading: false});

                fileDownload(response.data, `Invoice-${id}.pdf`);
            })

            .catch((error) => {
                this.setState({pdfDownloadLoading: false});

                if (error.response && error.response.data) {

                    error.response.data.text().then(response => {
                        const resp = JSON.parse(response);

                        if (resp.error.code === 'INV0001') {
                            const errorMessage = resp.error.message;
                            alert(errorMessage);
                        }
                    });
                }
            });
    }

    render() {
        const { shownInvoiceId, pdfViewIconLoading, pdfViewButtonLoading, pdfDownloadLoading } = this.state;
        const { myInvoices, myInvoicesInfoLoader, myAmounts, myAmountsLoader } = this.props;

        const amountDue = myAmounts && myAmounts.amountDue;

        let dateToday = moment().format('YYYY-MM-DD');

        return (
            <div className="cp-form">
                <div className="card-product">
                    <div className="card-body">

                        <div className="form-section">
                            <h4>My Invoices</h4>
                            {(!myInvoicesInfoLoader && !myAmountsLoader) && (
                            (myInvoices && myInvoices.length > 0)
                                    ? <p className="form-divider">Check your invoices and make a payment.</p>
                                    : <p className="form-divider">No invoices found at this moment.</p>
                            )}
                        </div>

                        {myInvoicesInfoLoader || myAmountsLoader ?
                            <Loader/>
                        : <>

                        {(myInvoices && myInvoices.length > 0) && <>
                        <div className="table-responsive">
                            <table className="table cp-table cp-table-primary table-customer-invoices">

                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Date</th>
                                        <th>Balance Due</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>

                                {myInvoices.map((myInvoice) => (

                                <tbody key={myInvoice.id}>
                                    <tr>

                                        <td className="v-align-m">
                                            <button type="button"
                                                className="btn btn-outline-secondary btn-expand-row btn-sm pl-2 pr-2"
                                                data-target="view-invoice"
                                                onClick={() => this.showInvoiceDetails(myInvoice.id)}
                                            >
                                                {this.state.shownInvoiceId === myInvoice.id ?
                                                    <i className="fas fa-minus"/> : <i className="fas fa-plus"/>}
                                            </button>
                                        </td>

                                        <td className="v-align-m">
                                            {moment(myInvoice.invoiceDate).format("MM/DD/YYYY")}
                                        </td>

                                        <td className="v-align-m">
                                            <strong>
                                                {'$' + formatAmount(myInvoice.balance)}
                                            </strong>
                                        </td>

                                        <td className="v-align-m">
                                            {myInvoice.balance > 0?
                                                moment(dateToday).isAfter(myInvoice.dueDate)?
                                                    <div className="nowrap text-danger">Overdue</div>
                                                    :
                                                    <div className={"nowrap" + ((- moment(dateToday).diff(myInvoice.dueDate, 'days')) >= 5 ? ' text-success' : ' text-warning')}>
                                                        {- moment(dateToday).diff(myInvoice.dueDate, 'days') === 0
                                                            ? "Today"
                                                            : "In "+(- moment(dateToday).diff(myInvoice.dueDate, 'days'))+" day"+(- moment(dateToday).diff(myInvoice.dueDate, 'days') > 1 ? 's' : '')
                                                        }
                                                    </div>
                                                :
                                                <div className={'nowrap text-success'}>
                                                    Paid
                                                </div>
                                            }
                                        </td>

                                        <td className="v-align-m">
                                            <button
                                                className="btn btn-sm btn-white"
                                                disabled={pdfViewIconLoading}
                                                onClick={() => {
                                                    this.showPdf(myInvoice.id);
                                                    this.setState({pdfViewIconLoading: myInvoice.id});
                                                }}
                                            >
                                                {pdfViewIconLoading === myInvoice.id ? <i className="fas fa-fw fa-spinner fa-spin"/> : <i className="fa fa-fw fa-file-pdf"/>}
                                            </button>
                                        </td>

                                    </tr>

                                    {this.state.shownInvoiceId === myInvoice.id && (
                                    <tr className="tr-child" id="view-invoice">
                                        <td colSpan="5" className="td-row-expand">
                                            <div className="row-expand">
                                                <div>
                                                    <div className="cp-datarow-container mr-3 ml-3">
                                                        <div className="cp-datarow-wrapper">

                                                            <div className="order-label">INVOICE DETAILS</div>

                                                            {/*<div className="cp-datarow-row">
                                                                <div className="cp-datarow-label">Invoice Period</div>
                                                                <div className="cp-datarow-value">{moment(myInvoice.invoiceDate).format("MM/DD/YYYY hh:mm A")}</div>
                                                            </div>*/}

                                                            <div className="cp-datarow-row">
                                                                <div className="cp-datarow-label">Invoice Total</div>
                                                                <div className="cp-datarow-value">
                                                                    {'$' + formatAmount(myInvoice.amount)}
                                                                </div>
                                                            </div>

                                                            <div className="cp-datarow-row">
                                                                <div className="cp-datarow-label">Due Date</div>
                                                                <div className="cp-datarow-value">{moment(myInvoice.dueDate).format("MM/DD/YYYY")}</div>
                                                            </div>

                                                            <div className="cp-datarow-row">
                                                                <div className="cp-datarow-label">Last Payment</div>
                                                                <div className="cp-datarow-value">
                                                                    {myInvoice.lastPaymentDate?
                                                                        myInvoice.lastPaymentDate && moment(myInvoice.lastPaymentDate).format("MM/DD/YYYY")
                                                                        + ', $' + formatAmount(myInvoice.lastPaymentAmount)
                                                                        : ''
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className="order-label">Actions</div>

                                                            <div className="td-row-expand-toolbar">

                                                                <div>
                                                                    <button
                                                                        className="btn btn-sm btn-white"
                                                                        disabled={pdfViewButtonLoading}
                                                                        onClick={() => {
                                                                            this.showPdf(myInvoice.id);
                                                                            this.setState({pdfViewButtonLoading: true});
                                                                        }}
                                                                    >
                                                                        {pdfViewButtonLoading ? <i className="fas fa-spinner fa-spin"/> : "View PDF"}
                                                                    </button>
                                                                    <button
                                                                        className="btn btn-sm btn-white"
                                                                        disabled={pdfDownloadLoading}
                                                                        onClick={() => {
                                                                            this.downloadPdf(myInvoice.id);
                                                                            this.setState({pdfDownloadLoading: true});
                                                                        }}
                                                                    >
                                                                        {pdfDownloadLoading ? <i className="fas fa-spinner fa-spin"/> : "Download PDF"}
                                                                    </button>
                                                                </div>

                                                                {/*<div>*/}
                                                                {/*    <button className="btn btn-sm btn-primary">*/}
                                                                {/*        <i className="fa fa-credit-card"/> Pay Now*/}
                                                                {/*    </button>*/}
                                                                {/*</div>*/}

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    )}

                                </tbody>

                                ))}

                            </table>
                        </div>
                        </>}

                        {amountDue !== undefined && amountDue !== 0 &&
                        <div className="product-price">
                            <div className="package">
                                <div className="package-header">
                                    <span className="package-left">Total Amount Due</span>
                                    <span className="package-right">{'$' + formatAmount(amountDue)}</span>
                                </div>
                            </div>
                        </div>
                        }

                        </> }

                    </div>

                    {/*{!myInvoicesInfoLoader && !myAmountsLoader && amountDue !== undefined && amountDue !== 0 &&*/}
                    {/*<div className="card-footer">*/}
                    {/*    <button type="submit" className="btn btn-primary">*/}
                    {/*        Make a Payment*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                    {/*}*/}

                </div>
            </div>
        )
    }
}

export default Invoices;

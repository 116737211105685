import { defaultTranslations } from "./default-translations";
import { get, isNumber } from "lodash";
import axiosInstance from "../utils/axios-instance";
// Helper function to create simple action
export const action = (type, payload = {}) => {
  return { type, ...payload };
};

export const verboseAction = (type, payload = {}) => {
  return {
    type: type,
    payload: { ...payload },
  };
};
export const generateLocalStorageItem = (authResponse) => {
  let userLocalStorage = { ...authResponse };
  localStorage.setItem("currentUser", JSON.stringify(userLocalStorage));
};
export const getToken = () => {
  let string = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser"))["token"]
    : "";
  return string;
};

export const getRefreshToken = () => {
  let string = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser"))["refreshToken"]
    : "";
  return string;
};

export const getLocation = () => {
  let location = localStorage.getItem("location")
    ? JSON.parse(localStorage.getItem("location"))
    : "";
  return location;
};

const REQUEST = "REQUEST";
const SUCCESS = "SUCCESS";
const FAILURE = "FAILURE";
const EMPTY = "EMPTY";
const CLEAR = "CLEAR";

export const createRequestTypes = (base) => {
  return [REQUEST, SUCCESS, FAILURE, CLEAR, EMPTY].reduce((acc, type) => {
    acc[type] = `${base}_${type}`;
    return acc;
  }, {});
};

export const actionCreator = {
  request: (type) => action(type.REQUEST),
  success: (type, payload) => action(type.SUCCESS, { payload }),
  failure: (type, error) => verboseAction(type.FAILURE, error),
  clear: (type) => action(type.CLEAR),
  empty: (type) => action(type.EMPTY),
};

export const generateId = () => {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return (
    Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
  ).toUpperCase();
};

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

export default isEmpty;

export const getErrorMessage = (error, language) => {
  let mockErrorMessage = { message: window.errorCodes[language].generic };
  if (
    error &&
    error.data &&
    error.data.error &&
    error.data.error.code &&
    window.errorCodes[language].hasOwnProperty(error.data.error.code) &&
    window.errorCodes[language][error.data.error.code] !== ""
  ) {
    mockErrorMessage.message =
      window.errorCodes[language][error.data.error.code];
  }

  return mockErrorMessage;
};

export const trans = async (lang, code) => {
  let result = get(window.translations[lang], code);
  if (isEmpty(result)) {
    result = get(defaultTranslations[lang], code);
  }
  if (isEmpty(result)) {
    return axiosInstance({
      method: "POST",
      url: "public/portal/portalConfiguration/keys",
      data: [`${code}`],
    })
      .then((data) => data?.data[0]?.value || "")
      .catch(() => {
        return "";
      });
  }else{
	return result
  }
};

export const formatAmount = (amount) => {
  return amount !== undefined && amount !== null
    ? amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
    : "0.00";
};

export const asNoneNegative = (num) => {
  if (!isNumber(num)) {
    num = 0;
  }

  return Math.max(num, 0);
};

import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {getAccountInfoById} from "../../../actions/accountInfoActions";
import {getAccountDistributionMethod, updateAccountDistributionMethod} from "../../../actions/homeInfoActions";
import {selectAccountInfo} from "../../../selectors";
import {Field, Form, Formik} from "formik";
import isEmpty from "../../../utils/helpers";
import {DISTRIBUTION_METHOD, DISTRIBUTION_METHOD_DESCRIPTION, DISTRIBUTION_METHOD_DESCRIPTION_SHORT} from "../CustomerServices";
import * as Yup from "yup";
import Loader from "react-spinners/BeatLoader";
import {toastr} from "react-redux-toastr";
import ReactHtmlParser from "react-html-parser";

const paperlessValidationSchema = Yup.object().shape({
    method: Yup.string().required('Required'),
    email: Yup.string().when(['method'], {
        is: (method) => (method === DISTRIBUTION_METHOD.EMAIL || method === DISTRIBUTION_METHOD.PAPERLESS),
        then: Yup.string().required("Email is required").email('Invalid email')
    })
});

export class Paperless extends Component {

    state = {
        myPaperlessInfoLoading: false,
        paperlessUpdated: false
    }

    getEmail = () => {
        const {contactEmails} = this.props;

        return  contactEmails.find((x) => x.type === 'DISTRIBUTION') ||
                contactEmails.find((x) => x.type === 'PERSONAL') ||
                contactEmails.find((x) => x.type === 'BUSINESS') ||
                contactEmails.find((x) => x.type === 'OTHER') ||
                {};
    }

    getErrorMessage = (response, defaultError) => {

        if (response.data && response.data.error && response.data.error.message) {
            return response.data.error.message;
        }

        if (response.message) {
            return response.message;
        }

        return defaultError || "Something went wrong";
    }

    handlePaperlessOptionChange = (method, values, setErrors, setFieldValue) => {
        const { paperlessUpdated } = this.state;

        setErrors({});

        // Switch option after errors are cleared to avoid momentarily invalid field
        setTimeout(() => {
            setFieldValue("paperlessOption", method.distributionMethod);
            setFieldValue('method', method.distributionMethod.method);
        }, 0)

        // Clear errors again, because setFieldValue triggers revalidation
        setTimeout(() => {
            setErrors({});
        }, 0)

        if (paperlessUpdated && values.paperlessOption.name !== method.distributionMethod.name) {
            this.setState({paperlessUpdated: false});
        }
    }

    handleFormSubmit = (values, actions) => {
        const { accountId } = this.props;

        const method = values.paperlessOption;

        const currentEmail = this.getEmail();

        const email = isEmpty(values.email)
            ? {...currentEmail, type: 'DISTRIBUTION'}
            : { email: values.email, type: 'DISTRIBUTION' };

        const data = {
            distributionMethod: method,
            email: email
        };

        this.setState({myPaperlessInfoLoading: method.name});

        this.props.updateAccountDistributionMethod(accountId, data).then((response) => {

            if (response.status === 200) {
                this.setState({paperlessUpdated: true, myPaperlessInfoLoading: 'info'});

                actions.setFieldValue('email', '');
                // toastr.success('Distribution method has been updated', { timeOut: 1000, position: 'top-center' });

                this.props.getAccountInfoById(accountId);
                this.props.getAccountDistributionMethod(accountId).then(response => {
                    this.setState({myPaperlessInfoLoading: false});
                });
            }
            else {
                this.setState({myPaperlessInfoLoading: false});

                const errorMessage = this.getErrorMessage(response, 'Could not save distribution method');
                toastr.error(errorMessage, { timeOut: 1000, position: 'top-center' });
            }
        });
    }

    render() {

        const {
            myPaperlessInfoLoading,
            paperlessUpdated
        } = this.state;

        const {
            myDistributionMethods,
            myCurrentDistributionMethod,
        } = this.props;

        if (!isEmpty(myDistributionMethods)) {

            // Move paper to first place
            myDistributionMethods.unshift(
                myDistributionMethods.splice(
                    myDistributionMethods.findIndex(e => e.distributionMethod.name === 'paper')
                    , 1)[0])
        }

        const currentEmail = this.getEmail();

        return (
            <div className="cp-form cp-form-paperless">

                {myCurrentDistributionMethod &&
                <Formik
                    initialValues={{
                        paperlessOption: myCurrentDistributionMethod || {},
                        method: myCurrentDistributionMethod ? myCurrentDistributionMethod.method : '',
                        email: '',
                    }}
                    validationSchema={paperlessValidationSchema}
                    onSubmit={this.handleFormSubmit}
                    render={({
                                 values,
                                 touched,
                                 errors,
                                 handleChange,
                                 handleSubmit,
                                 setErrors,
                                 setFieldValue
                    }) => (
                        <Form onSubmit={handleSubmit}>

                            <div className="form-section">
                                <h4>Paperless Billing</h4>
                                <p className="form-divider">
                                    {myPaperlessInfoLoading === 'info'
                                        ? <p><Loader size={5} color={'#258C9F'}/></p>
                                        : (
                                            ReactHtmlParser(myCurrentDistributionMethod.note) ||
                                            DISTRIBUTION_METHOD_DESCRIPTION[myCurrentDistributionMethod.name && myCurrentDistributionMethod.name.toUpperCase()]
                                        )
                                    }
                                </p>
                            </div>

                            {myDistributionMethods.map((method) => (
                            <Fragment key={method.id}>

                                <div className="form-group">
                                    <div className="custom-switch-with-button">
                                        <div className="custom-control custom-switch">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={"paperlessOption-"+method.distributionMethod.name}
                                                name={"paperlessOption-"+method.distributionMethod.name}
                                                checked={values.paperlessOption.name === method.distributionMethod.name}
                                                disabled={myPaperlessInfoLoading && 'disabled'}
                                                onChange={() => this.handlePaperlessOptionChange(method, values, setErrors, setFieldValue)}
                                            />
                                            <label className="custom-control-label custom-control-label-opaque d-block"
                                                   htmlFor={"paperlessOption-"+method.distributionMethod.name}
                                            >
                                                {method.distributionMethod.description}
                                                {method.price !== 0 && " ($"+method.price.toFixed(2)+")"}
                                                {myPaperlessInfoLoading === method.distributionMethod.name &&
                                                <Loader css={'float: right'} size={5} color={'#258C9F'}/>}
                                                <p className="text-muted text-xs mb-0">
                                                    {ReactHtmlParser(method.distributionMethod.note) ||
                                                        DISTRIBUTION_METHOD_DESCRIPTION_SHORT[method.distributionMethod.name.toUpperCase()]}
                                                </p>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {method.distributionMethod.name === values.paperlessOption.name && <>
                                <div className="cp-table-primary form-group">
                                    <div className="row-expand">
                                        <div className="cp-datarow-container mr-3 ml-3">
                                            <div className="cp-datarow-wrapper">

                                                {method.distributionMethod.method.toUpperCase() === DISTRIBUTION_METHOD.PAPER && <>
                                                <div className="order-label">Details</div>
                                                <div className="cp-datarow-row">
                                                    <div className="cp-datarow-value">Invoice will be sent to your billing address.</div>
                                                </div>
                                                </> }

                                                {(method.distributionMethod.method.toUpperCase() === DISTRIBUTION_METHOD.PAPERLESS || method.distributionMethod.method.toUpperCase() === DISTRIBUTION_METHOD.EMAIL) &&
                                                <>

                                                    {myPaperlessInfoLoading === 'info' && <Loader size={5} color={'#258C9F'}/>}

                                                    {!myPaperlessInfoLoading && myCurrentDistributionMethod.name && method.distributionMethod.name === myCurrentDistributionMethod.name &&
                                                    <>
                                                        <div className="order-label">Details</div>
                                                        <div className="cp-datarow-row">
                                                            <div className="cp-datarow-label">Current Email</div>
                                                            <div className="cp-datarow-value">{currentEmail.email}</div>
                                                        </div>
                                                    </>
                                                    }

                                                    {!paperlessUpdated &&
                                                    <>
                                                        <div className="order-label">New Email</div>
                                                        <div className="form-group">
                                                            <Field
                                                                type="text"
                                                                id="email"
                                                                name="email"
                                                                placeholder="Enter email"
                                                                className={"form-control form-control-sm" + (errors.email && touched.email ? " is-invalid" : "")}
                                                                disabled={myPaperlessInfoLoading && 'disabled'}
                                                                onChange={handleChange}
                                                            />
                                                            {errors.email && touched.email &&
                                                                <div className="invalid-feedback">{errors.email}</div>
                                                            }
                                                        </div>
                                                    </>
                                                    }

                                                </>
                                                }

                                            </div>
                                        </div>
                                    </div>

                                </div>

                                {!paperlessUpdated &&
                                <div className="form-group text-right">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        disabled={myPaperlessInfoLoading && 'disabled'}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                                }

                                </>}

                            </Fragment>
                            ))}

                        </Form>
                    )}
                />
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    getAccountInfoById,
    getAccountDistributionMethod,
    updateAccountDistributionMethod,
};

const mapStateToProps = (state) => {
    const accountId = selectAccountInfo(state).accountInfo.id,
        accountType = selectAccountInfo(state).accountInfo.accountType.name,
        contactEmails = selectAccountInfo(state).accountInfo.primaryContact.contactEmails;

    return {
        accountId,
        accountType,
        contactEmails
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Paperless);
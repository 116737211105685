import React, { Component } from 'react';
import {trans} from "../../../utils/helpers";

export class Footer extends Component {
	state = {
		language: 'en-us',
		footerMessage: ''
	};

	test = () => {
		this.setState({
			language: 'sp-es'
		});
	};

	componentDidMount(){
		(async() => {
			const footerMessage = await trans(this.state.language, 'footerMessage')
			this.setState({footerMessage})
		})()
	}

	render() {
		const { language } = this.state;

		return (
			<footer className="cp-footer" onClick={this.test}>
				<div className="cp-copyrights">
					{this.state.footerMessage}
					<span className="cp-app-version">v. {global.appVersion}</span>
				</div>
			</footer>
		);
	}
}

export default Footer;

import React, { Component } from 'react';
import isEmpty from '../../../../utils/helpers';
import Bundle from './Bundle';
import Plan from './Plan';
import { generateId } from '../../../../utils/helpers';
import {trans} from "../../../../utils/helpers";

class SelectServiceStep extends Component {
	state = {
		signuptitle:'',
		surveytitle:'',
		servicetitle:'',
		slicedItems: 3
	};

	componentDidMount(){
		(async() => {
			const signuptitle=await trans('en-us', 'guestOrderScreen.signUpSelectedService.title');
			const surveytitle=await trans('en-us', 'guestOrderScreen.surveySelectedService.title');
			const servicetitle=await trans('en-us', 'guestOrderScreen.serviceSelectedService.title');

			this.setState({signuptitle,surveytitle,servicetitle})
		})()
	}

	setSelectedItem = async (item, type) => {
		let allPlans = [];
		if (type === 'bundle') {
			item.plans.forEach((plan) => {
				let currentPlan = {
					planBundleId: item.id,
					planBundleDescription: item.description,
					planBundleName: item.name,
					planDescription: plan.description,
					planId: plan.id,
					planName: plan.name,
					servicelines: []
				};

				allPlans.push(currentPlan);
			});
		} else if (type === 'plan') {
			let currentPlan = {
				planDescription: item.description,
				planId: item.id,
				planName: item.name,
				servicelines: []
			};

			allPlans.push(currentPlan);
		}

		await this.props.setFieldValue('order.plans', allPlans);
		this.props.populateServiceAndLines(this.props.values, this.props.setFieldValue);
	};

	addMoreItems = () => {
		this.setState((prevState) => ({
			slicedItems: prevState.slicedItems + 1
		}));
	};

	renderTitle = () => {
		const { availablePlansData } = this.props;
		if (availablePlansData.validatedAddress.allowedServiceOrderType === 'NEW_SIGNUP') {
			return this.state.signuptitle;
		} else if (availablePlansData.validatedAddress.allowedServiceOrderType === 'NEW_SURVEY') {
			return this.state.surveytitle;
		} else if (availablePlansData.validatedAddress.allowedServiceOrderType === 'NEW_SERVICE') {
			return this.state.servicetitle;
		}
	};

	render() {
		const { slicedItems } = this.state;
		const { availablePlansData, handleChangeStep, values } = this.props;
		let bundleAndPlans = [];

		if (isEmpty(availablePlansData)) {
			return null;
		}

		if (!isEmpty(availablePlansData.planBundles)) {
			bundleAndPlans.push(...availablePlansData.planBundles);
		}

		if (!isEmpty(availablePlansData.plans)) {
			bundleAndPlans.push(...availablePlansData.plans);
		}

		return (
			<div className="form-section fx-fadein" id="fs4">
				<h3 className="text-center">{this.renderTitle()}</h3>
				<p className="text-center text-sm text-muted">
					<i className="fas fa-map-marker-alt" /> {values.validatedAddress && values.validatedAddress.addressSearchFormatted}
				</p>
				<p className="text-center text-xs text-muted d-block d-sm-none">Scroll down to see map</p>
				<div className="card-product">
					<div className="card-body">
						{!isEmpty(bundleAndPlans) &&
							bundleAndPlans.slice(0, slicedItems).map((item, index) => {
								return item.plans ? (
									<Bundle
										key={generateId()}
										bundle={item}
										handleChangeStep={handleChangeStep}
										setSelectedItem={this.setSelectedItem}
										initialStatus={values.account.initialStatus}
									/>
								) : (
										<Plan
											key={generateId()}
											plan={item}
											handleChangeStep={handleChangeStep}
											setSelectedItem={this.setSelectedItem}
											initialStatus={values.account.initialStatus}
										/>
									);
							})}
					</div>
					<div className="card-footer">
						{bundleAndPlans.length > slicedItems && (
							<button
								type="button"
								onClick={this.addMoreItems}
								className="btn btn-sm btn-outline-secondary btn-product-more"
							>
								<i className="fas fa-plus" /> More
							</button>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default SelectServiceStep;

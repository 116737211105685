import React, { Component, Fragment } from 'react'
import PlanServicelines from './PlanServicelines';

class PlanList extends Component {

    render() {
        const { planInfo, planIndex, values } = this.props;
         
        return (
            <div className="product-item">
            <div className="header">
                <div className="header-title">
                    <i className={`${planInfo.serviceModel.icon} text-primary`} /> {planInfo.description}
                </div>
            </div>
            <div className="details">
                <div className="description">
                {values.order.plans[planIndex] &&
						values.order.plans[planIndex].servicelines.map((line, index) => (
							<PlanServicelines
								lineInfo={line}
								key={index}
								lineIndex={index}
								planIndex={planIndex}
								{...this.props}
							/>
						))}
                   
                </div>
            </div>
        </div>
        )
    }
}

export default PlanList

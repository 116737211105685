import React from 'react';
import PropTypes from 'prop-types';

const PackageTooltip = ({ color, isVisible, children }) => {

    return (
        <div className={'package-tooltip' + (color ? ' package-tooltip-white' : '') + (isVisible ? ' visible' : '')}>{children}</div>
    );
};

PackageTooltip.propTypes = {
    color: PropTypes.string,
    isVisible: PropTypes.bool,
};

export default PackageTooltip;
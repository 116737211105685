import React, { Component, Fragment } from 'react'

class IframeAddress extends Component {
    render() {
        const {iframeAddressUrl, iframeLoader, handleHideIframeLoader, handleIframeError} = this.props;
        return (
            <Fragment>
            {iframeAddressUrl.length > 0 && (
                <iframe
                    id="map-canvas"
                    className={iframeLoader === true ? 'cp-with-loader' : undefined}
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                    src={iframeAddressUrl}
                    onLoad={handleHideIframeLoader}
                    onError={handleIframeError}
                />
            )}
            {iframeLoader && (
                <div className="cp-loader">
                    <div className="loader" />
                    <p>Loading Map</p>
                </div>
            )}
            </Fragment>
        )
    }
}

export default IframeAddress

import {isEmpty} from "./helpers";

export const transformPlanDetails = (planRelation, planDetails) => {
    let transformedData = {};
    transformedData['id'] = planDetails['id'];
    transformedData['description'] = planDetails['description'];
    transformedData['serviceModel'] = planDetails['serviceModel'];
    transformedData['planRelation'] = planRelation;

    if (planRelation === 'Main') {
        if (!isEmpty(planDetails.mainIncludedFeatures)) {
            transformedData['mainIncludedFeatures'] = planDetails['mainIncludedFeatures'];
        }

        if (!isEmpty(planDetails.mainIncludedFeaturePackages)) {
            transformedData['mainIncludedFeaturePackages'] = planDetails['mainIncludedFeaturePackages'];
        }

        if (!isEmpty(planDetails.mainMandatoryFeatures)) {
            transformedData['mainMandatoryFeatures'] = planDetails['mainMandatoryFeatures'];
        }

        if (!isEmpty(planDetails.mainMandatoryFeaturePackages)) {
            transformedData['mainMandatoryFeaturePackages'] = planDetails['mainMandatoryFeaturePackages'];
        }

        if (!isEmpty(planDetails.mainOptionalFeatures)) {
            transformedData['mainOptionalFeatures'] = planDetails['mainOptionalFeatures'];
        }

        if (!isEmpty(planDetails.mainOptionalFeaturePackages)) {
            transformedData['mainOptionalFeaturePackages'] = planDetails['mainOptionalFeaturePackages'];
        }
    } else if (planRelation === 'Required') {
        if (!isEmpty(planDetails.requiredIncludedFeatures)) {
            transformedData['requiredIncludedFeatures'] = planDetails['requiredIncludedFeatures'];
        }

        if (!isEmpty(planDetails.requiredIncludedFeaturePackages)) {
            transformedData['requiredIncludedFeaturePackages'] = planDetails['requiredIncludedFeaturePackages'];
        }

        if (!isEmpty(planDetails.requiredMandatoryFeatures)) {
            transformedData['requiredMandatoryFeatures'] = planDetails['requiredMandatoryFeatures'];
        }

        if (!isEmpty(planDetails.requiredMandatoryFeaturePackages)) {
            transformedData['requiredMandatoryFeaturePackages'] = planDetails['requiredMandatoryFeaturePackages'];
        }

        if (!isEmpty(planDetails.requiredOptionalFeatures)) {
            transformedData['requiredOptionalFeatures'] = planDetails['requiredOptionalFeatures'];
        }

        if (!isEmpty(planDetails.requiredOptionalFeaturePackages)) {
            transformedData['requiredOptionalFeaturePackages'] = planDetails['requiredOptionalFeaturePackages'];
        }
    } else if (planRelation === 'Additional') {
        if (!isEmpty(planDetails.additionalIncludedFeatures)) {
            transformedData['additionalIncludedFeatures'] = planDetails['additionalIncludedFeatures'];
        }

        if (!isEmpty(planDetails.additionalIncludedFeaturePackages)) {
            transformedData['additionalIncludedFeaturePackages'] = planDetails['additionalIncludedFeaturePackages'];
        }

        if (!isEmpty(planDetails.additionalMandatoryFeatures)) {
            transformedData['additionalMandatoryFeatures'] = planDetails['additionalMandatoryFeatures'];
        }

        if (!isEmpty(planDetails.additionalMandatoryFeaturePackages)) {
            transformedData['additionalMandatoryFeaturePackages'] = planDetails['additionalMandatoryFeaturePackages'];
        }

        if (!isEmpty(planDetails.additionalOptionalFeatures)) {
            transformedData['additionalOptionalFeatures'] = planDetails['additionalOptionalFeatures'];
        }

        if (!isEmpty(planDetails.additionalOptionalFeaturePackages)) {
            transformedData['additionalOptionalFeaturePackages'] = planDetails['additionalOptionalFeaturePackages'];
        }
    }

    return transformedData;
};

export const mapInitialState = (planRelation, transformedData, activeFeatures) => {
    let features = [];

    if (planRelation === 'Main') {
        if (activeFeatures.mainIncludedFeatures && transformedData.mainIncludedFeatures) {
            activeFeatures.mainIncludedFeatures.forEach((element) => {
                let currentFeature = transformedData.mainIncludedFeatures.find((x) => x.id === element.featureId);
                let feature = {
                    ...currentFeature,
                    action: 'ENABLE'
                };
                features.push(feature);
            });
        }

        if (activeFeatures.mainIncludedFeaturePackages && transformedData.mainIncludedFeaturePackages) {
            activeFeatures.mainIncludedFeaturePackages.forEach((element) => {
                let currentFeaturePackage = transformedData.mainIncludedFeaturePackages.find(
                    (x) => x.featurePackageId === element.featurePackageId
                );

                let featurePackage = {
                    featurePackageId: currentFeaturePackage.featurePackageId,
                    featurePackageDescription: currentFeaturePackage.description,
                    featurePackageNote: currentFeaturePackage.note,
                    ...currentFeaturePackage.featurePackageOptions.find(
                        (x) => x.featurePackageOptionId === element.featurePackageOptions[0].featurePackageOptionId
                    ),
                    action: 'ENABLE'
                };

                features.push(featurePackage);
            });
        }

        if (activeFeatures.mainMandatoryFeatures && transformedData.mainMandatoryFeatures) {
            activeFeatures.mainMandatoryFeatures.forEach((element) => {
                let currentFeature = transformedData.mainMandatoryFeatures.find((x) => x.id === element.featureId);
                let feature = {
                    ...currentFeature,
                    action: 'ENABLE'
                };
                features.push(feature);
            });
        }

        if (activeFeatures.mainMandatoryFeaturePackages && transformedData.mainMandatoryFeaturePackages) {
            activeFeatures.mainMandatoryFeaturePackages.forEach((element) => {
                let currentFeaturePackage = transformedData.mainMandatoryFeaturePackages.find(
                    (x) => x.featurePackageId === element.featurePackageId
                );

                let featurePackage = {
                    featurePackageId: currentFeaturePackage.featurePackageId,
                    featurePackageDescription: currentFeaturePackage.description,
                    featurePackageNote: currentFeaturePackage.note,
                    ...currentFeaturePackage.featurePackageOptions.find(
                        (x) => x.featurePackageOptionId === element.featurePackageOptions[0].featurePackageOptionId
                    ),
                    action: 'ENABLE'
                };

                features.push(featurePackage);
            });
        }

        if (activeFeatures.mainOptionalFeatures && transformedData.mainOptionalFeatures) {
            activeFeatures.mainOptionalFeatures.forEach((element) => {
                let currentFeature = transformedData.mainOptionalFeatures.find((x) => x.id === element.featureId);

                let feature = {
                    ...currentFeature,
                    action: 'ENABLE'
                };
                features.push(feature);
            });
        }

        if (activeFeatures.mainOptionalFeaturePackages && transformedData.mainOptionalFeaturePackages) {
            activeFeatures.mainOptionalFeaturePackages.forEach((element) => {
                let currentFeaturePackage = transformedData.mainOptionalFeaturePackages.find(
                    (x) => x.featurePackageId === element.featurePackageId
                );

                let featurePackage = {
                    featurePackageId: currentFeaturePackage.featurePackageId,
                    featurePackageDescription: currentFeaturePackage.description,
                    featurePackageNote: currentFeaturePackage.note,
                    ...currentFeaturePackage.featurePackageOptions.find(
                        (x) => x.featurePackageOptionId === element.featurePackageOptions[0].featurePackageOptionId
                    ),
                    action: 'ENABLE'
                };

                features.push(featurePackage);
            });
        }
    } else if (planRelation === 'Required') {
        if (activeFeatures.requiredIncludedFeatures && transformedData.requiredIncludedFeatures) {
            activeFeatures.requiredIncludedFeatures.forEach((element) => {
                let currentFeature = transformedData.requiredIncludedFeatures.find((x) => x.id === element.featureId);
                let feature = {
                    ...currentFeature,
                    action: 'ENABLE'
                };
                features.push(feature);
            });
        }

        if (activeFeatures.requiredIncludedFeaturePackages && transformedData.requiredIncludedFeaturePackages) {
            activeFeatures.requiredIncludedFeaturePackages.forEach((element) => {
                let currentFeaturePackage = transformedData.requiredIncludedFeaturePackages.find(
                    (x) => x.featurePackageId === element.featurePackageId
                );

                let featurePackage = {
                    featurePackageId: currentFeaturePackage.featurePackageId,
                    featurePackageDescription: currentFeaturePackage.description,
                    featurePackageNote: currentFeaturePackage.note,
                    ...currentFeaturePackage.featurePackageOptions.find(
                        (x) => x.featurePackageOptionId === element.featurePackageOptions[0].featurePackageOptionId
                    ),
                    action: 'ENABLE'
                };

                features.push(featurePackage);
            });
        }

        if (activeFeatures.requiredMandatoryFeatures) {
            activeFeatures.requiredMandatoryFeatures.forEach((element) => {
                let currentFeature = transformedData.requiredMandatoryFeatures.find((x) => x.id === element.featureId);
                let feature = {
                    ...currentFeature,
                    action: 'ENABLE'
                };
                features.push(feature);
            });
        }

        if (activeFeatures.requiredMandatoryFeaturePackages) {
            activeFeatures.requiredMandatoryFeaturePackages.forEach((element) => {
                let currentFeaturePackage = transformedData.requiredMandatoryFeaturePackages.find(
                    (x) => x.featurePackageId === element.featurePackageId
                );

                let featurePackage = {
                    featurePackageId: currentFeaturePackage.featurePackageId,
                    featurePackageDescription: currentFeaturePackage.description,
                    featurePackageNote: currentFeaturePackage.note,
                    ...currentFeaturePackage.featurePackageOptions.find(
                        (x) => x.featurePackageOptionId === element.featurePackageOptions[0].featurePackageOptionId
                    ),
                    action: 'ENABLE'
                };

                features.push(featurePackage);
            });
        }

        if (activeFeatures.requiredOptionalFeatures) {
            activeFeatures.requiredOptionalFeatures.forEach((element) => {
                let currentFeature = transformedData.requiredOptionalFeatures.find((x) => x.id === element.featureId);

                let feature = {
                    ...currentFeature,
                    action: 'ENABLE'
                };
                features.push(feature);
            });
        }

        if (activeFeatures.requiredOptionalFeaturePackages) {
            activeFeatures.requiredOptionalFeaturePackages.forEach((element) => {
                let currentFeaturePackage = transformedData.requiredOptionalFeaturePackages.find(
                    (x) => x.featurePackageId === element.featurePackageId
                );

                let featurePackage = {
                    featurePackageId: currentFeaturePackage.featurePackageId,
                    featurePackageDescription: currentFeaturePackage.description,
                    featurePackageNote: currentFeaturePackage.note,
                    ...currentFeaturePackage.featurePackageOptions.find(
                        (x) => x.featurePackageOptionId === element.featurePackageOptions[0].featurePackageOptionId
                    ),
                    action: 'ENABLE'
                };

                features.push(featurePackage);
            });
        }
    } else if(planRelation === 'Additional'){
        if (activeFeatures.additionalIncludedFeatures) {
            activeFeatures.additionalIncludedFeatures.forEach((element) => {
                let currentFeature = transformedData.additionalIncludedFeatures.find((x) => x.id === element.featureId);
                let feature = {
                    ...currentFeature,
                    action: 'ENABLE'
                };
                features.push(feature);
            });
        }

        if (activeFeatures.additionalIncludedFeaturePackages) {
            activeFeatures.additionalIncludedFeaturePackages.forEach((element) => {
                let currentFeaturePackage = transformedData.additionalIncludedFeaturePackages.find(
                    (x) => x.featurePackageId === element.featurePackageId
                );

                let featurePackage = {
                    featurePackageId: currentFeaturePackage.featurePackageId,
                    featurePackageDescription: currentFeaturePackage.description,
                    featurePackageNote: currentFeaturePackage.note,
                    ...currentFeaturePackage.featurePackageOptions.find(
                        (x) => x.featurePackageOptionId === element.featurePackageOptions[0].featurePackageOptionId
                    ),
                    action: 'ENABLE'
                };

                features.push(featurePackage);
            });
        }

        if (activeFeatures.additionalMandatoryFeatures) {
            activeFeatures.additionalMandatoryFeatures.forEach((element) => {
                let currentFeature = transformedData.additionalMandatoryFeatures.find((x) => x.id === element.featureId);
                let feature = {
                    ...currentFeature,
                    action: 'ENABLE'
                };
                features.push(feature);
            });
        }

        if (activeFeatures.additionalMandatoryFeaturePackages) {
            activeFeatures.additionalMandatoryFeaturePackages.forEach((element) => {
                let currentFeaturePackage = transformedData.additionalMandatoryFeaturePackages.find(
                    (x) => x.featurePackageId === element.featurePackageId
                );

                let featurePackage = {
                    featurePackageId: currentFeaturePackage.featurePackageId,
                    featurePackageDescription: currentFeaturePackage.description,
                    featurePackageNote: currentFeaturePackage.note,
                    ...currentFeaturePackage.featurePackageOptions.find(
                        (x) => x.featurePackageOptionId === element.featurePackageOptions[0].featurePackageOptionId
                    ),
                    action: 'ENABLE'
                };

                features.push(featurePackage);
            });
        }

        if (activeFeatures.additionalOptionalFeatures) {
            activeFeatures.additionalOptionalFeatures.forEach((element) => {
                let currentFeature = transformedData.additionalOptionalFeatures.find((x) => x.id === element.featureId);

                let feature = {
                    ...currentFeature,
                    action: 'ENABLE'
                };
                features.push(feature);
            });
        }

        if (activeFeatures.additionalOptionalFeaturePackages) {
            activeFeatures.additionalOptionalFeaturePackages.forEach((element) => {
                let currentFeaturePackage = transformedData.additionalOptionalFeaturePackages.find(
                    (x) => x.featurePackageId === element.featurePackageId
                );

                let featurePackage = {
                    featurePackageId: currentFeaturePackage.featurePackageId,
                    featurePackageDescription: currentFeaturePackage.description,
                    featurePackageNote: currentFeaturePackage.note,
                    ...currentFeaturePackage.featurePackageOptions.find(
                        (x) => x.featurePackageOptionId === element.featurePackageOptions[0].featurePackageOptionId
                    ),
                    action: 'ENABLE'
                };

                features.push(featurePackage);
            });
        }
    }

    return features;
};

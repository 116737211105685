import React, { Component } from 'react';
import {trans} from "../../../../utils/helpers";

class NotAvailableConfirmation extends Component {
	state={
		title:'',
		subTitle:''
	}
	componentDidMount(){
		(async() => {
			const title=await trans('en-us', 'guestOrderScreen.notAvailableServiceConfirmation.title');
			const subTitle=await await trans('en-us', 'guestOrderScreen.notAvailableServiceConfirmation.subTitle');
			
			this.setState({title,subTitle});
		})()
	}

	render() {
		const { resetForm, setTouched, handleResetWizard } = this.props;

		return (
			<div className="form-section fx-fadein" id="">

				<div className="message">

					<div className="message-left">
						<i className="fas fa-check-circle" />
					</div>

					<div className="message-right">
						<h4>{this.state.title}</h4>
						<p>{this.state.subTitle}</p>
					</div>

				</div>

				<div className="form-group form-group-button">
					<button
						onClick={() => handleResetWizard(resetForm, setTouched)}
						type="button"
						id="sendlogin"
						className="btn btn-primary btn-block"
						data-target="fs1"
					>
						Check Another Address
					</button>
				</div>

			</div>
		);
	}
}

export default NotAvailableConfirmation;

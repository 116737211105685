import { ACCOUNT_AUTOPAY_STATUS, AUTOPAY_WARNING_MESSAGE } from '../actions/autoPayActions';

const initialState = {
    loading: false,
    error: null,
    accountAutoPayStatus: null,
    autoPayWarningMessage: null
};

export const accountAutoPayReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACCOUNT_AUTOPAY_STATUS.REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case ACCOUNT_AUTOPAY_STATUS.SUCCESS:
            return {
                ...state,
                loading: true,
                accountAutoPayStatus: action.payload,
                error: null
            };
        case ACCOUNT_AUTOPAY_STATUS.FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case AUTOPAY_WARNING_MESSAGE.REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case AUTOPAY_WARNING_MESSAGE.SUCCESS:
            return {
                ...state,
                loading: false,
                autoPayWarningMessage: action.payload,
                error: null
            };
        case AUTOPAY_WARNING_MESSAGE.FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
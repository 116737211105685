// Use this component for handle methods from formik

import React, { Component } from 'react'

export class HandleChangeFormik extends Component {

    componentDidUpdate(prevProps) {
        //console.log(this.props)
        const {resetForm} = this.props;
        if(prevProps.failure !== this.props.failure && this.props.failure.length > 0) {
            resetForm()
        }
    }
    render() {
        return (
            <div>
                
            </div>
        )
    }
}

export default HandleChangeFormik

import axiosInstance from '../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../utils/helpers';


export const CHANGE_LANGUAGE = createRequestTypes('CHANGE_LANGUAGE');

export const changeLanguage = (language) => async (dispatch) => {
	dispatch(actionCreator.success(CHANGE_LANGUAGE, language));
};

export const emptyChangeLanguage = (language) => async (dispatch) => {
	dispatch(actionCreator.failure(CHANGE_LANGUAGE, language));
};

import React, { Component } from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import ReactHtmlParser from 'react-html-parser';
import {trans} from "../../../../utils/helpers";

class Five extends Component {

	state={
		signUpShareMessage:'',
		signUpConfirmation:'',
		signUpFacebookUrl:'',
		signUpTwitterUrl:'',
		signUpConfirmationLine2:'',
		signUpConfirmationLine3:'',
		surveyShareMessage:'',
		surveyConfirmation:'',
		surveyFacebookUrl:'',
		surveyConfirmationLine2:'',
		surveyConfirmationLine3:'',
		activeShareMessage:'',
		activeConfirmation:'',
		activeFacebookUrl:'',
		activeTwitterUrl:'',
		activeConfirmationLine2:'',
		activeConfirmationLine3:''
	}

	componentDidMount(){
		(async() => {
			const signUpShareMessage=await trans('en-us', 'guestOrderScreen.confirmation.signUpShareMessage');
			const signUpConfirmation=await trans('en-us', 'guestOrderScreen.confirmation.signUpConfirmation');
			const signUpFacebookUrl=await trans('en-us', 'guestOrderScreen.confirmation.signUpFacebookUrl');
			const signUpTwitterUrl=await trans('en-us', 'guestOrderScreen.confirmation.signUpTwitterUrl');
			const signUpConfirmationLine2=await trans('en-us', 'guestOrderScreen.confirmation.signUpConfirmationLine2');
			const signUpConfirmationLine3=await trans('en-us', 'guestOrderScreen.confirmation.signUpConfirmationLine3');
			const surveyShareMessage=await trans('en-us', 'guestOrderScreen.confirmation.surveyShareMessage');
			const surveyConfirmation=await trans('en-us', 'guestOrderScreen.confirmation.surveyConfirmation');
			const surveyFacebookUrl=await trans('en-us', 'guestOrderScreen.confirmation.surveyFacebookUrl');
			const surveyTwitterUrl=await trans('en-us', 'guestOrderScreen.confirmation.surveyTwitterUrl');
			const surveyConfirmationLine2=await trans('en-us', 'guestOrderScreen.confirmation.surveyConfirmationLine2');
			const surveyConfirmationLine3=await trans('en-us', 'guestOrderScreen.confirmation.surveyConfirmationLine3');
			const activeShareMessage=await trans('en-us', 'guestOrderScreen.confirmation.activeShareMessage');
			const activeConfirmation=await trans('en-us', 'guestOrderScreen.confirmation.activeConfirmation');
			const activeFacebookUrl=await trans('en-us', 'guestOrderScreen.confirmation.activeFacebookUrl');
			const activeTwitterUrl=await trans('en-us', 'guestOrderScreen.confirmation.activeTwitterUrl');
			const activeConfirmationLine2=await trans('en-us', 'guestOrderScreen.confirmation.activeConfirmationLine2');
			const activeConfirmationLine3=await trans('en-us', 'guestOrderScreen.confirmation.activeConfirmationLine3');

			this.setState({signUpShareMessage,signUpConfirmation,signUpFacebookUrl,signUpTwitterUrl,signUpConfirmationLine2,signUpConfirmationLine3,surveyShareMessage,surveyConfirmation,surveyFacebookUrl,surveyTwitterUrl,surveyConfirmationLine2,surveyConfirmationLine3,activeShareMessage,activeConfirmation,activeFacebookUrl,activeTwitterUrl,activeConfirmationLine2,activeConfirmationLine3});
		})()
	}

	renderTexts = () => {
		const { newOrderInfoData } = this.props;
		let shareMessage = '';
		let confirmation = '';
		let confirmationLine2 = '';
		let confirmationLine3 = '';
		let shareFacebookUrl = '';
		let shareTwitterUrl = '';

		if (newOrderInfoData.orderType === 'SIGNUP') {
			shareMessage =this.state.signUpShareMessage;
			confirmation =this.state.signUpConfirmation; 
			shareFacebookUrl =this.state.signUpFacebookUrl;  
			shareTwitterUrl =this.state.signUpTwitterUrl;
			confirmationLine2 =this.state.signUpConfirmationLine2;
			confirmationLine3 =this.state.signUpConfirmationLine3;
		} else if (newOrderInfoData.orderType === 'SURVEY') {
			shareMessage =this.state.surveyShareMessage;
			confirmation =this.state.surveyConfirmation; 
			shareFacebookUrl =this.state.surveyFacebookUrl; 
			shareTwitterUrl =this.state.surveyTwitterUrl; 
			confirmationLine2 =this.state.surveyConfirmationLine2;  
			confirmationLine3 =this.state.surveyConfirmationLine3;  
		} else if (newOrderInfoData.orderType === 'ACTIVE') {
			shareMessage =this.state.activeShareMessage;  
			confirmation =this.state.activeConfirmation;   
			shareFacebookUrl =this.state.activeFacebookUrl;   
			shareTwitterUrl =this.state.activeTwitterUrl;   
			confirmationLine2 =this.state.activeConfirmationLine2;
			confirmationLine3 =this.state.activeConfirmationLine3; 
		}

		let texts = {
			shareMessage,
			confirmation,
			shareFacebookUrl,
			shareTwitterUrl,
			confirmationLine2,
			confirmationLine3
		};

		return texts;
	};
	render() {
		const { newOrderInfoData, handleResetWizard, resetForm, setTouched, values } = this.props;

		let texts = this.renderTexts();

		return (
			<div className="form-section" id="fs6">

				<div className="message">

					<div className="message-left">
						<i className="fas fa-check-circle" />
					</div>

					<div className="message-right">

						<h4>{texts.confirmation}</h4>

						<p className="message-code">Your reference # is {newOrderInfoData.id}</p>

						<p>
							{/*We will contact you with futher updates. >Spread the word, let's build a better internet for
							Medina County.*/}
							{ReactHtmlParser(texts.confirmationLine2)} <b>{values.account.accountHolder.personalEmail}</b>
							{ReactHtmlParser(texts.confirmationLine3)}
						</p>

						<div className="message-footer">

							<FacebookShareButton url={texts.shareFacebookUrl} quote={texts.shareMessage}>
								<button type="button" className="btn btn-primary btn-facebook">
									<i className="fab fa-facebook"/>&nbsp;Share It
								</button>
							</FacebookShareButton>

							<TwitterShareButton url={texts.shareTwitterUrl} title={texts.shareMessage}>
								<button type="button" className="btn btn-primary btn-twitter">
									<i className="fab fa-twitter"/>&nbsp;Tweet It
								</button>
							</TwitterShareButton>

							{/* <button type="button" formaction="cp-dashboard.html" id="" className="btn btn-primary btn-mail">
								<i className="fas fa-at" />&nbsp;Mail It
							</button>
							<button type="button" className="btn btn-primary btn-link">
								<i className="fas fa-link" />&nbsp;Link
							</button> */}

						</div>

					</div>

				</div>

				<div className="form-group form-group-button">
					<button
						type="button"
						className="btn btn-primary btn-block"
						onClick={() => handleResetWizard(resetForm, setTouched)}
					>
						Check Another Address
					</button>
				</div>

			</div>
		);
	}
}

export default Five;

import React, { Component, Fragment } from 'react';
import OptionalPackage from './Options/OptionalPackage';

class OptionalPackages extends Component {
	render() {
		const { planFeature, accountInitialStatus } = this.props;

		return (
			<Fragment>
				{planFeature.map((featurePackage, index) => (
					<Fragment key={featurePackage.featurePackageId}>
						<hr/>
						<h5>{featurePackage.description}</h5>

						{featurePackage.featurePackageOptions.map((packageOption) => (
							<OptionalPackage
								featurePackage={featurePackage}
								packageOption={packageOption}
								key={packageOption.featurePackageOptionId}
								accountInitialStatus={accountInitialStatus}
								{...this.props}
							/>
						))}
					</Fragment>
				))}
			</Fragment>
		);
	}
}

export default OptionalPackages;

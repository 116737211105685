import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import isEmpty from "../../../../utils/helpers";

class Plan extends Component {
	renderButtonText = () => {
		const { initialStatus } = this.props;

		if (initialStatus === 'SIGNUP') {
			return 'Select';
		} else if (initialStatus === 'SURVEY') {
			return 'Interested';
		} else if (initialStatus === 'ACTIVE') {
			return 'Select';
		}
	};

	render() {
		const { plan, setSelectedItem, initialStatus } = this.props;

		return (
			<div className={"product-item product-item-border" + (plan.hideCustomerPortal ? " d-none" : "")}>
				<div className="header">
					<div className="header-title">
						<div className="title">{plan.description}</div>
						<div className="description">{ReactHtmlParser(plan.note)}</div>
					</div>
				</div>
				<div className="container-service-providers">
					{!isEmpty(plan.providers) ? (plan.providers.map((provider) => (
						provider.logo &&
						<img className="cp-icon cp-icon-service-provider-r" src={`${process.env.PUBLIC_URL}/media/service-providers/${provider.logo}`} />
					))) : null}
				</div>
				<div className="basic">
					<div className="left">
						<i className="fas fa-fw fa-wifi" />
					</div>
					<div className="right">
						from ${plan.fromPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo
					</div>
				</div>
				<p className="text-xs text-muted">Internet</p>
				<div className="details collapse">
					<div className="description">
						<div className="mb-1">
							<p className="lead">
								<i className="fas fa-fw fa-wifi" /> {plan.description}
							</p>
							<ul className="list-unstyled list-product-item-details">
								<li>{plan.note}</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="footer">
					<div className="footer-toolbar">
						<button
							type="button"
							onClick={() => setSelectedItem(plan, 'plan')}
							className="btn btn-sm btn-primary btn-fs-action"
							data-target="fs5"
						>
							{this.renderButtonText()}
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default Plan;

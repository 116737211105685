import { combineReducers } from 'redux';

import { authReducer } from './authReducer';
import { accountInfoReducer } from './accountInfoReducer';
import { errorReducer } from './errorReducer';
import { loadingReducer } from './loadingReducer';
import { guestOrderReducer } from './guestOrderReducer';
import { languageReducer } from './languageReducer';
import { homeInfoReducer } from './homeInfoReducer';
import { signUpReducer } from './signUpReducer';
import { userReducer } from './userReducer';
import { paymentReducer } from './paymentReducer';
import { signupToServiceReducer } from './signupToServiceReducer';
import { paymentProviderReducer } from "./paymentProviderReducer";
import {notificationReducer } from "./notificationReducer";
import { reducer as toastrReducer } from 'react-redux-toastr';
import {accountAutoPayReducer} from "./autoPayReducer";

import { AUTH_LOGOUT } from '../actions/authActions';

const appReducer = combineReducers({
	auth: authReducer,
	accountInfo: accountInfoReducer,
	errors: errorReducer,
	loading: loadingReducer,
	guestOrder: guestOrderReducer,
	language: languageReducer,
	homeInfo: homeInfoReducer,
	signUp: signUpReducer,
	user: userReducer,
	payment: paymentReducer,
	signupToService: signupToServiceReducer,
	toastr: toastrReducer,
	paymentProviders: paymentProviderReducer,
	notifications: notificationReducer,
	accountAutopay: accountAutoPayReducer
});

export const rootReducer = (state, action) => {
	if (action.type === AUTH_LOGOUT.SUCCESS) {
		state = undefined;
	}

	return appReducer(state, action);
};

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logout } from '../../../actions/authActions';
import FlyInRightPanel from '../../../components/FlyInRightPanel'
import isEmpty, {trans} from "../../../utils/helpers";
import Profile from "../../Home/Profile";
import Notifications from "../../Notifications/index";


export class UserNavbar extends Component {
	static propTypes = {
		accountInfo: PropTypes.object.isRequired
	};

	state = {
		companyName:'',
		dropdown: '',
		isOpen: false,
		isMobileMenuOpen: false,
		isNotificationsOpen: false,
		isChangePasswordMenuOpen: false
	};

	componentDidMount(){
		(async() => {
			const companyName= await trans('en-us', 'companyName');

			this.setState({companyName})
		})()
	}
	onFocuschangeActiveDropDown = (e, name) => {
		this.setState((prevProps) => ({
			dropdown: name,
			isOpen: !prevProps.isOpen
		}));
	};

	onBlurchangeActiveDropDown = (e, name) => {
		this.setState((prevProps) => ({
			dropdown: '',
			isOpen: false
		}));
	};

	handleShowHideMenuDropdown = () => {
		this.setState((prevState) => ({
			isMobileMenuOpen: !prevState.isMobileMenuOpen
		}))
	};

	handleShowHideChangePassword = (resetForm) => {
		if(resetForm) resetForm()
		this.setState((prevState) => ({
			isChangePasswordMenuOpen: !prevState.isChangePasswordMenuOpen
		}))
	}

	handleShowHideNotifications = () => {
		this.setState((prevState) => ({
			isNotificationsOpen: !prevState.isNotificationsOpen
		}));
	};

	logout = () => {
		this.props.logout(this.props.history);
	}

	render() {
		const { dropdown, isOpen, isMobileMenuOpen, isNotificationsOpen, isChangePasswordMenuOpen } = this.state;
		const { accountInfo } = this.props;
		const menuContent = (window.navbarLinks && window.navbarLinks.map(item => (
			<li className="nav-item" key={item.name}>
				<a className="nav-link" href={item.link} target={item.newWindow ? '_blank' : '_self'}>
					<i className={'fas fa-fw' + (item.newWindow ? ' fa-external-link-alt' : '')}/>
					{item.name}
				</a>
			</li>
		)));

		return (
			<>

				<FlyInRightPanel title="Menu" onClose={this.handleShowHideMenuDropdown} isVissible={isMobileMenuOpen}>
					<ul className="navbar-nav">
						{menuContent}
					</ul>
				</FlyInRightPanel>

				<FlyInRightPanel title="Profile" onClose={this.handleShowHideChangePassword} isVissible={isChangePasswordMenuOpen}>
					<ul className="navbar-nav">
						<Profile handleShowHideChangePassword={this.handleShowHideChangePassword} />
					</ul>
				</FlyInRightPanel>

				<FlyInRightPanel title="Communication Preferences" onClose={this.handleShowHideNotifications} isVissible={isNotificationsOpen}>
					<ul className='navbar-nav'>
						<Notifications />
					</ul>
				</FlyInRightPanel>

				<header className="cp-header cp-header-user">
					<nav className="navbar navbar-light cp-navbar cp-navbar-user">
						<div className="container">

							{(window.logoLink === '' ? (
								<span className="navbar-left navbar-img">
									<img src={`${process.env.PUBLIC_URL}/themes/active/img/app-banner.svg`} />
								</span>
							) : (
								<a className="navbar-left navbar-img" href={window.logoLink} target="_blank" rel="noopener noreferrer">
									<img src={`${process.env.PUBLIC_URL}/themes/active/img/app-banner.svg`} />
								</a>
							))}

							<span className="navbar-brand">
                            	{window.translations['en-us'].companyName !== '' &&this.state.companyName}
							</span>

							<div className="navbar-right">

								<ul className="navbar-nav">
									{menuContent}
								</ul>

								<div
									className="dropdown"
									onClick={(e) => this.onFocuschangeActiveDropDown(e, 'user')}
									onBlur={(e) => this.onBlurchangeActiveDropDown(e, 'user')}
								>

									<a
										className="nav-link nav-link-user dropdown-toggle"
										id="navbarDropdownMenuLink"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										<i className="far fa-user" />
										<span className="nav-link-user-username">
											{accountInfo && accountInfo.primaryContact && !isEmpty(accountInfo.primaryContact.company)
												? accountInfo.primaryContact.company
												: accountInfo.primaryContact.firstName + ' ' + accountInfo.primaryContact.lastName
											}
										</span>
									</a>

									<div className={'dropdown-menu dropdown-menu-right' + (dropdown === 'user' && isOpen ? ' show' : '')}
										aria-labelledby="navbarDropdownMenuLink"
									>
										<h6 className="dropdown-header">
											{accountInfo && accountInfo.primaryContact && accountInfo.primaryContact.firstName}{' '}
											{accountInfo && accountInfo.primaryContact && accountInfo.primaryContact.lastName}
										</h6>
										<div className="dropdown-divider"></div>
										<button onClick={() => this.setState({isChangePasswordMenuOpen: true})} className="dropdown-item">
											Profile
										</button>
										<button onClick={() => this.setState({isNotificationsOpen: true})} className="dropdown-item">
											Communication Preferences
										</button>
										<button onClick={this.logout} className="dropdown-item">
											Sign Out
										</button>
									</div>
								</div>

								{(window.navbarLinks && (
									<button
										className='navbar-toggler'
										type="button"
										data-toggle="collapse"
										data-target="#navbarNavDropdown"
										aria-controls="navbarNavDropdown"
										aria-expanded={isMobileMenuOpen ? 'true' : 'false'}
										aria-label="Toggle navigation"
										onClick={this.handleShowHideMenuDropdown}
									>
										<span className="navbar-toggler-icon"/>
									</button>
								))}

							</div>

						</div>
					</nav>
				</header>
		</>
		);
	}
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
	logout
};

export default connect(mapStateToProps, mapDispatchToProps)(UserNavbar);

import React, {Component} from 'react';
import {connect} from "react-redux";
import {getMyPayments} from "../../../actions/homeInfoActions";
import {createLoadingSelector, selectHomeInfo} from "../../../selectors";
import isEmpty, {formatAmount} from "../../../utils/helpers";
import Loader from "../../../components/common/Spinners/Loader";
import moment from "moment";

export class Payments extends Component {

    componentDidMount() {
        this.props.getMyPayments(this.props.accountInfo.id);
    }

    render() {

        const {
            myPaymentsLoader,
            myPayments,
            postPaymentAmount
        } = this.props;

        return (
            <div className="cp-form">
                <div className="card-product">
                    <div className="card-body">

                        <div className="form-section">
                            <h4>My Payments</h4>
                            <p style={{borderBottom: '1px solid #DBE8EA', marginBottom:'2rem'}}>Historical payments posted to your account.</p>
                            {postPaymentAmount &&
                                <p style={{display:'flex', fontWeight:'600', color:'#259F52'}}>Payment of ${postPaymentAmount.toFixed(2)} is pending</p>
                            }
                        </div>

                        {myPaymentsLoader &&
                        <Loader />
                        }

                        {!myPaymentsLoader && isEmpty(myPayments) &&
                        <p>No data found.</p>
                        }

                        {!myPaymentsLoader && !isEmpty(myPayments) &&
                        <div className="table-responsive">
                            <table className="table cp-table cp-table-primary table-customer-payments">

                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Date</th>
                                        <th className="h-align-r">Amount</th>
                                        <th>Reference</th>
                                    </tr>
                                </thead>

                                {myPayments.map((myPayment) => (
                                <tbody key={myPayment.id}>
                                    <tr>

                                        <td className="v-align-m">

                                            {myPayment.type === 'CHECK'
                                            ? <i className="fas fa-money-check-alt" title='Check' />
                                            : myPayment.type === 'CASH'
                                            ? <i className="fas fa-money-bill" title='Cash' />
                                            : myPayment.type === 'CREDIT_CARD'
                                            ? <i className="fas fa-credit-card" title='Credit Card' />
                                            : myPayment.type === 'ACH'
                                            ? <i className="fas fa-money-check" title='ACH' />
                                            : myPayment.type === 'WIRE_TRANSFER'
                                            ? <i style={{color: '#8bc34a'}} className="fas fa-dollar-sign" title='Wire Transfer' />
                                            : myPayment.type === 'EXTERNAL'
                                            ? <i className="fas fa-external-link-square-alt" title='External' />
                                            : myPayment.type === 'ACCOUNT_CREDIT'
                                            ? <i style={{color: '#f48fb1'}} className="fas fa-piggy-bank" title='Account Credit' />
                                            : <i className="fas fa-question" title='Unknown' />
                                            }

                                        </td>

                                        <td className="v-align-m">
                                            {moment(myPayment.date).format("MM/DD/YYYY")}
                                        </td>

                                        <td className="v-align-m h-align-r">
                                            <strong>
                                                {'$' + formatAmount(myPayment.amount)}
                                            </strong>
                                        </td>

                                        <td className="v-align-m">
                                            <div className="nowrap text-success">
                                                {myPayment.transactionId} {myPayment.authCode != null ? <span className="td-subtitle text-success">#{myPayment.authCode}</span> : ""}
                                            </div>
                                        </td>

                                    </tr>
                                </tbody>
                                ))}

                            </table>
                        </div>
                        }

                    </div>
                </div>
            </div>
        )
    }
}

const getMyPaymentsLoader = createLoadingSelector(['MY_PAYMENTS']);

const mapStateToProps = (state) => {

    const myPaymentsLoader = getMyPaymentsLoader(state);
    const myPayments = selectHomeInfo(state).myPayments;

    return {
        myPaymentsLoader,
        myPayments,
    };
};

const mapDispatchToProps = {
    getMyPayments,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
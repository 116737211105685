import React, { Fragment } from 'react';
 import PropTypes from 'prop-types';
 import { get } from 'lodash';
 
 const RoundedInputIcon = ({ type, label, name, errors, touched, placeholder, value, onChange, onBlur, isDisabled }) => {
 	return (
 		<Fragment>
 			<label htmlFor="">{label}</label>
 			<div className="input-group">
 				<input
 					className={
 						get(errors, `${name}`) && get(touched, `${name}`) ? 'form-control is-invalid' : 'form-control'
 					}
 					name={name}
 					placeholder={placeholder}
 					type={type}
 					value={value}
 					onChange={onChange}
 					onBlur={onBlur}
 					disabled={isDisabled}
 					autoComplete="off"
 				/>
 				<div className="input-group-append">
 					<button
 						type="button"
 						className="btn btn-outline-secondary"
 						data-toggle="tooltip"
 						title="<img className='img-fluid img-thumbnail' src='src/resources/img/default/sample-service-invoice-zip.png'/>"
 					>
 						<i className="fas fa-question" />
 					</button>
 				</div>
 				{get(errors, `${name}`) &&
 				get(touched, `${name}`) && <div className="invalid-feedback">{get(errors, `${name}`)}</div>}{' '}
 			</div>
 		</Fragment>
 	);
 };
 
 RoundedInputIcon.propTypes = {
 	type: PropTypes.string.isRequired,
 	label: PropTypes.string.isRequired,
 	name: PropTypes.string.isRequired,
 	errors: PropTypes.object.isRequired,
 	touched: PropTypes.object.isRequired,
 	value: PropTypes.string.isRequired,
 	onChange: PropTypes.func.isRequired,
 	onBlur: PropTypes.func.isRequired,
 	placeholder: PropTypes.string,
 	isDisabled: PropTypes.bool
 };
 
	 export default RoundedInputIcon;
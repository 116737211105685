import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import isEmpty from '../../../../utils/helpers';

class IncludedFeature extends Component {
	state = {
		isDescriptionToggled: false
	};

	isPriceRendered = () => {
		const { accountInitialStatus, feature } = this.props;
	
		if (accountInitialStatus === 'SIGNUP') {
			if (feature.hideSignupPrice) {
				return false;
			}
		} else if (accountInitialStatus === 'SURVEY') {
			if (feature.hideSurveyPrice) {
				return false;
			}
		}
		return true;
	};

	toggleDescription = (e) => {
		e.stopPropagation();
		this.setState((prevState) => ({
			isDescriptionToggled: !prevState.isDescriptionToggled
		}));
	};

	render() {
		const { isDescriptionToggled } = this.state;
		const { feature,  } = this.props;

		return (
			<div className={"package" + (feature.hideCustomerPortal ? " d-none" : "")}>
				<div className="package-header custom-switch-with-button">
					<div className="custom-control custom-switch">
						<input
							type="checkbox"
							className="custom-control-input"
							// id="b1in1"
							checked={true}
							disabled={true}
						/>
						
						<label className="custom-control-label" htmlFor="">
							<span>{feature.description}</span>
							
							<span>
								{this.isPriceRendered() === true ? feature.featureType === 'ACTIVATION' ? (
										`$${feature.oneTimePrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
									) : (
										`$${(feature.recurringMonthlyPrice == 0 && feature.price > 0 ? feature.price : feature.recurringMonthlyPrice)
											.toFixed(2)
											.replace(/\d(?=(\d{3})+\.)/g, '$&,')}` + '/mo'
									)
									: null
								}
							</span>
							
						</label>
						
					</div>

					{!isEmpty(feature.note) &&
					<button
						type="button"
						onClick={this.toggleDescription}
						className={
							isDescriptionToggled ? (
								'btn btn-link btn-link-text btn-toggle-package-desc toggled'
							) : (
								'btn btn-link btn-link-text btn-toggle-package-desc'
							)
						}
					>
						<i className="fas fa-info-circle" />
					</button>
					}

				</div>
				
				{!isEmpty(feature.provider) && <div className='container-service-providers'><img className="cp-icon cp-icon-service-provider-r" src={`${process.env.PUBLIC_URL}/media/service-providers/${feature.provider.logo}`} /></div>}
				{isDescriptionToggled && <div className="package-description text-sm">{ReactHtmlParser(feature.note)}</div>}
			</div>
			
		);
	}
}

export default IncludedFeature;

import React, { Component, Fragment } from 'react';
import ProductsServiceline from './ProductsServiceline';
import ReactHtmlParser from "react-html-parser";

export class ProductsPlan extends Component {
	state = {
		toggle: false
	}

	toggleClicked = () => {
		this.setState({
			toggle: !this.state.toggle
		});
	}

	render() {
		const { planName, planInfo, values } = this.props;
		const { toggle } = this.state;

		return (
			<div className="form-section">

				{planInfo.description ?
					<h4>{planInfo.description}</h4> :
					''
				}
				{values.note ?
					ReactHtmlParser(values.note):
					''
				}

				<p className="form-divider">{/*<i className={planInfo.serviceModel.icon} />*/}</p>

				<ProductsServiceline {...this.props} />

			</div>
		);
	}
}

export default ProductsPlan;

import React, { Component } from 'react';
import RoundedInput from '../../../components/common/Formik/RoundedInput';
import RoundedInputIcon from '../../../components/common/Formik/RounterInputIcon';
import ReactTextRotator from 'react-text-rotator';
import {trans} from "../../../utils/helpers";


class ConfirmAccount extends Component {
	state={
		enrtyText1:'',
		entryText2:'',
		entryText3:'',
		entryText4:'',
		entryText5:'',
		title:'',
		confirmAccountInitSteps:'',
		contactInfoText:'',
		accountInfoText:'',
		clickQuestionButton:''
	}

	componentDidMount(){
		(async() => {
		const enrtyText1=await trans('en-us', 'registerScreen.confirmAccountSection.enrtyText1');
		const entryText2=await trans('en-us', 'registerScreen.confirmAccountSection.entryText2');
		const entryText3=await trans('en-us', 'registerScreen.confirmAccountSection.entryText3');
		const entryText4=await trans('en-us', 'registerScreen.confirmAccountSection.entryText4');
		const entryText5=await trans('en-us', 'registerScreen.confirmAccountSection.entryText5');
		const title=await trans('en-us', 'registerScreen.confirmAccountSection.title');
		const confirmAccountInitSteps=await trans('en-us', 'registerScreen.confirmAccountSection.confirmAccountInitSteps');
		const contactInfoText=await trans('en-us', 'registerScreen.confirmAccountSection.contactInfoText');
		const accountInfoText=await trans('en-us', 'registerScreen.confirmAccountSection.accountInfoText');
		const clickQuestionButton=await trans('en-us', 'registerScreen.confirmAccountSection.clickQuestionButton');

		this.setState({enrtyText1,entryText2,entryText3,entryText4,entryText5,title,confirmAccountInitSteps,contactInfoText,accountInfoText,clickQuestionButton})
		})()
	}
	handleChangeType = (type) => {
		const { setFieldValue, values } = this.props;
		if (type !== values.type) {
			if (type === 'contactInfo') {
				setFieldValue('type', 'contactInfo');
				setFieldValue('numberOrEmail', '');
			} else if (type === 'accountInfo') {
				setFieldValue('type', 'accountInfo');
				setFieldValue('accountNumber', '');
				setFieldValue('zipcode', '');
			}
		}
	};

	render() {
		const content = [
			{
				text: this.state.enrtyText1,
				animation: 'fade-exiting',
			},
			{
				text: this.state.entryText2,
				animation: 'fade-exiting',
			},
			{
				text: this.state.entryText3,
				animation: 'fade-exiting',
			},
			{
				text: this.state.entryText4,
				animation: 'fade-exiting',
			},
			{
				text: this.state.entryText5,
				animation: 'fade-exiting',
			},
		];
		const {
			values,
			errors,
			touched,
			handleBlur,
			handleChange,
			setFieldValue,
			checkedAccountLoader,
			checkedAccountError,
			isSubmitting,
		} = this.props;

		return (
			<div className="form-section fx-from-right" id="fs1">
				<p className="text-center lead">
					<span id="js-rotating" className="no-break text-orange font-weight-bold">
						<ReactTextRotator content={content} time={1000} />
					</span>
				</p>

				<h3 className="text-center">{this.state.title}</h3>
				<p className="text-center">{this.state.confirmAccountInitSteps}</p>
				<div className="form-check checkbox-slider checkbox-slider--b-flat">
					<label className="w-100">
						<input
							type="radio"
							name="type"
							checked={values.type === 'contactInfo'}
							onChange={() => this.handleChangeType('contactInfo')}
						/>
						<span className="w-100">
							Contact Info
 							<p className="text-muted text-xs mb-1">
								{this.state.contactInfoText}
							</p>
							<div
								className={
									values.type === 'contactInfo' ? (
										'cp-customer-reg-input mb-2'
									) : (
											'cp-customer-reg-input mb-2 d-none'
										)
								}
							>
								<RoundedInput
									type="text"
									placeholder="Phone Number or Email Address"
									name="numberOrEmail"
									errors={errors}
									value={values.numberOrEmail}
									touched={touched}
									onChange={handleChange}
									onBlur={handleBlur}
									isDisabled={isSubmitting}
								/>
							</div>
						</span>
					</label>
				</div>
				<div className="form-check checkbox-slider checkbox-slider--b-flat">
					<label className="w-100">
						<input
							type="radio"
							name="type"
							checked={values.type === 'accountInfo'}
							onChange={() => this.handleChangeType('accountInfo')}
						/>
						<span className="w-100">
							Account Info
 							<p className="text-muted text-xs mb-1">
								{this.state.accountInfoText}
							</p>
							<div
								className={
									values.type === 'accountInfo' ? (
										'cp-customer-reg-input mb-2'
									) : (
											'cp-customer-reg-input mb-2 d-none'
										)
								}
							>
								<p className="text-muted text-xs mb-1">
									{this.state.clickQuestionButton}
								</p>
								<div className="form-group">
									{/* <label htmlFor="">Account Number</label>
 									<div className="input-group"> */}
									<RoundedInputIcon
										type="text"
										label="Account Number"
										placeholder="Account Number"
										name="accountNumber"
										errors={errors}
										value={values.accountNumber}
										touched={touched}
										onChange={handleChange}
										onBlur={handleBlur}
										isDisabled={isSubmitting}
									/>
									{/* <RoundedInput
 											type="text"
 											placeholder="Account Number"
 											name="accountNumber"
 											errors={errors}
 											value={values.accountNumber}
 											touched={touched}
 											onChange={handleChange}
 											onBlur={handleBlur}
 										/>
 										<div className="input-group-append">
 											<button
 												type="button"
 												className="btn btn-outline-secondary"
 												data-toggle="tooltip"
 												title="<img className='img-fluid img-thumbnail' src='img/default/sample-service-invoice-acc.png'/>"
 											>
 												<i className="fas fa-question" />
 											</button>
 										</div> */}
									{/* </div>
 									<div className="invalid-feedback">Invalid account number.</div> */}
								</div>
								<div className="form-group">
									<RoundedInputIcon
										type="text"
										label="Billing zipcode"
										placeholder="Billing zipcode"
										name="zipcode"
										errors={errors}
										value={values.zipcode}
										touched={touched}
										onChange={handleChange}
										onBlur={handleBlur}
										isDisabled={isSubmitting}
									/>

									{/* <div className="input-group">
 										<RoundedInput
 											type="text"
 											placeholder="Billing zipcode"
 											name="zipcode"
 											errors={errors}
 											value={values.zipcode}
 											touched={touched}
 											onChange={handleChange}
 											onBlur={handleBlur}
 										/>
 
 										<div className="input-group-append">
 											<button
 												type="button"
 												className="btn btn-outline-secondary"
 												data-toggle="tooltip"
 												title="<img className='img-fluid img-thumbnail' src='img/default/sample-service-invoice-zip.png'/>"
 											>
 												<i className="fas fa-question" />
 											</button>
 										</div>
 									</div>
 									<div className="invalid-feedback">Invalid billing zipcode.</div> */}
								</div>
							</div>
						</span>
					</label>
				</div>
				{checkedAccountError && (
					<p style={{ color: 'red' }}>
						<i className="fa fa-exclamation-triangle" aria-hidden="true" /> {checkedAccountError}
					</p>
				)}
				<div className="form-group form-group-footer">
					<button
						type="submit"
						className="btn btn-primary btn-fs-action"
						disabled={isSubmitting}
					>
						Continue {checkedAccountLoader && <i className="fas fa-fw fa-spin fa-spinner" />}
					</button>
				</div>
			</div>
		);
	}
}

export default ConfirmAccount;
import React, {Component, Fragment} from 'react';
import {createLoadingSelector} from "../../../selectors";
import {

} from "../../../actions/paymentMethod.actions";
import {connect} from "react-redux";
import Loader from "../../../components/common/Spinners/Loader";
import PropTypes from 'prop-types';
import {getPayment} from "../../../selectors";
import isEmpty from "../../../utils/helpers";
import {createPaymentMethodForm} from "../../../actions/paymentMethod.actions";

class PaymentMethodForm extends Component {

    state = {
        paymentMethodFormSubmitted: false,
        submitFormData: false,
    }

    componentDidMount() {
        this.referenceId = '';
        this.xBtnTimeoutHandle = setTimeout(() => {
            // Unlock panel closing
            //this.props.togglePreventPanelClosing(false);
            // Remove tab close event listener
            window.removeEventListener('beforeunload', this.preventTabClose);
        }, 40000);
    }

    componentWillUnmount() {
        if (this.xBtnTimeoutHandle) {
            clearTimeout(this.xBtnTimeoutHandle);
        }
    }

    componentDidUpdate(prevProps) {
        const {
            getPaymentStatus,
            paymentStatus
        } = this.props;

        if (this.state.submitFormData && !isEmpty(this.props.formData)) {
            if (!this.state.paymentMethodFormSubmitted) {
                this.paymentMethodForm.submit();
                this.setState({paymentMethodFormSubmitted: true, submitFormData: false});
            }
        }

        if (!isEmpty(this.props.formData) && prevProps.formData !== this.props.formData) {
            this.referenceId = this.props.formData.fields['ReferenceId'];
        }

        if (prevProps.paymentStatusCount !== this.props.paymentStatusCount) {
            if (paymentStatus.inProgress === true) {
                getPaymentStatus(this.referenceId);
            } else {
                this.setState({paymentMethodFormSubmitted: false});

                // Unlock panel closing
                this.props.togglePreventPanelClosing(false);

                // Remove tab close event listener
                window.removeEventListener('beforeunload', this.preventTabClose);
            }
        }
    }

    createPaymentMethod = (data) => {
        const {accountInfo, createPaymentMethodForm, getMyPaymentMethods, isOneTimePayment} = this.props;

        // Add tab close event listener
        window.addEventListener('beforeunload', this.preventTabClose);

        if (isOneTimePayment) {
            data.submitType = "oneTimePayment"
        }

        createPaymentMethodForm(accountInfo.id, data).then(resp => {
            if (resp.data && resp.data.submitType === 'deletePaymentMethod' && resp.data.success === true) {
                this.setState({submitFormData: false});
                getMyPaymentMethods(this.props.accountInfo.id);
                return;
            } else if (resp.data && resp.data.fields) {
                this.setState({submitFormData: true});
                return;
            }
        });
    }

    preventTabClose = () => {
        if (this.props.paymentStatus === null) {

            let e = window.event;
            if (e) e.returnValue = true;
            return true;
        }
    }

    render() {
        const {loading, formData} = this.props;

        const formTarget = this.props.formTarget || (formData && formData.target);

        if (loading === true || this.state.paymentMethodFormSubmitted === true) {
            return (
                <>
                    {/*<Loader />*/}
                    {/*<br/>*/}
                </>
            );
        }

        if (isEmpty(formData)) {
            return null;
        }

        return (
            <Fragment>
                <div className="cmv-container-customer-pmnt-card">
                    <form method="post" ref={(ref) => {this.paymentMethodForm = ref}} action={formData.action} target={formTarget}>
                        {Object.keys(formData.fields).map((key) => (
                            <input key={key} type="hidden" name={key} value={formData.fields[key]} />
                        ))}
                    </form>
                </div>
            </Fragment>
        );
    }
}

PaymentMethodForm.propTypes = {
    accountInfo: PropTypes.object.isRequired,
    formTarget: PropTypes.string,
};
const getFormDataSelector = createLoadingSelector(['GET_PAYMENT_FORM']);

const mapStateToProps = (state) => {
    const loading = getFormDataSelector(state),
        formData = getPayment(state).formData,
        paymentStatus = getPayment(state).paymentStatus,
        paymentStatusCount = getPayment(state).paymentStatusCount;

    return {
        loading,
        formData,
        paymentStatus,
        paymentStatusCount
    };
};

const mapDispatchToProps = {
    createPaymentMethodForm,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PaymentMethodForm);

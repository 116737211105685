export const defaultTranslations = {
    'en-us': {
        siteTitle: 'My Neta',
        footerMessage: 'Copyright © 2021 Neta Customer Portal. All rights reserved.',
        companyName: 'Neta Communications',
        networkErrorMessage: 'There is a network outage that may affect you!',
        termsAndConditionsLink: 'I agree with <a target="_blank">Terms &amp; Conditions</a>',
        paymentMessage: 'Thank you for making a payment. Follow the directions below. If you have any questions, please contact us and we can assist you.',
        HomeScreen: {
        },
        registerScreen: {
            choosePasswordSection: {
                title: 'Choose password'
            },
            confirmAccountSection: {
                title: 'Confirm Your Account',
                confirmAccountInitSteps: 'First, provide one of the following details associated with your account.',
                contactInfoText: 'Please provide your phone number or email address.',
                accountInfoText: 'Please provide your Acount number and Billing zipcode.',
                clickQuestionButton: "Click the &amp for information where you can find these",
                enrtyText1: 'View your latest payments...',
                entryText2: 'View historical invoices...',
                entryText3: 'Make a payment...',
                entryText4: 'AutoPay...',
                entryText5: 'Open a support ticket...'
            },
            pinOptionsSection: {
                title: 'Pin Options',
                accountPin: 'I have my Account PIN',
                obtainPin: 'To obtain a PIN to register your account, simply call customer service at (800) 555-6666',
                doNotHavePinMobile: "I don't have any PIN. Send me a temporary PIN to my mobile.",
                temporaryPinMobile: 'We will send a temporary PIN to your pre-verified mobile number on your account.',
                doNotHavePinEmail: "I don't have any PIN. Email me a temporary PIN.",
                temporaryPinEmail: "We will send a temporary PIN to your pre-verified email address on your account."
            },
            signUpCompletedSection: {
                title: 'Sign Up Completed!',
                signInOPtion: 'You can now sign in with your email and password'
            }
        },
        guestOrderScreen: {
            checkAvailability : {
                title: 'Let\'s see what\'s available at your address'
            },
            notAvailableService: {
                title: 'You are not in the current service area, we will notify you when we are planning to build to your neighborhood'
            },
            notAvailableServiceConfirmation: {
                title: 'Thank you for your interest',
                subTitle: 'We will send you an update when we are planning to build to your neighborhood.'
            },
            signUpSelectedService: {
                title: 'Coming soon to your neighborhood, sign up for your services now!'
            },
            surveySelectedService: {
                title: 'Your address is in survey phase, show your interest below'
            },
            serviceSelectedService: {
                title: 'Here is what is available.'
            },
            serviceDetails : {
                recurringDescription: 'Ongoing charges',
                oneTimeDescription: 'Installation, setup and/or activation charges'
            },
            contactInfo : {
                title: 'Tell us about you',
                termsAndCondition: 'By checking the box below I confirm that I am at least 18 years old. Check to agree to the Text above and the Terms For Survey Submissions',
            },
            confirmation: {
                //SIGNUP ACCOUNT
                signUpShareMessage: 'I just signed up for fastest internet in Media county. You can too!',
                signUpConfirmation: 'Thank you for signing up for Medina Fiber service.',
                signUpConfirmationLine2: 'Thank you, you’ll receive a confirmation email at ',
                signUpConfirmationLine3: ' shortly. We will contact you with futher updates. Spread the word, let\'s build a better internet for Medina County.',
                signUpFacebookUrl: 'https://mydev.camvio.cloud/guest-order',
                signUpTwitterUrl: 'https://mydev.camvio.cloud/guest-order',
                // SURVEY ACCOUNT
                surveyShareMessage: 'I just took my survey to bring Fiber to my home in Media county. Would you be next?',
                surveyConfirmation: 'Thanks for submitting your survey.',
                surveyConfirmationLine2: 'Thank you, you’ll receive a confirmation email at ',
                surveyConfirmationLine3: ' shortly. We will contact you with futher updates. Spread the word, let\'s build a better internet for Medina County.',
                surveyFacebookUrl: 'https://mydev.camvio.cloud/guest-order',
                surveyTwitterUrl: 'https://mydev.camvio.cloud/guest-order',
                // ACTIVE ACCOUNT
                activeShareMessage: 'I just activated my fast internet in Media county. You can too!',
                activeConfirmation: 'Thanks for choosing our services.',
                activeConfirmationLine2: 'Thank you, you’ll receive a confirmation email at ',
                activeConfirmationLine3: ' shortly. We will contact you with futher updates. Spread the word, let\'s build a better internet for Medina County.',
                activeFacebookUrl: 'https://mydev.camvio.cloud/guest-order',
                activeTwitterUrl: 'https://mydev.camvio.cloud/guest-order',
            }
        },
        confirmOrderScreen: {
            selectService: {
                title: 'Service is available. Select your bundle or plan.',
                tokenErrorTitle: 'Please contact us for service.',
                tokenErrorDescription: 'Sorry but your token expired. For security reasons please contact us at 555-555-555 to get service.',
                tokenErrorNote: 'Looking forward to providing you broadband service!',
            },
            notAvailableService: {
                title: 'Service Unavailable',
                description: 'Please click Check Availability to indicate you want service when available in your area.',
            },
            serviceDetails: {
                title: 'Configure your Services',
                recurringDescription: 'Ongoing charges',
                oneTimeDescription: 'Installation, setup and/or activation charges',
            },
            scheduleAppointment: {
                title: 'Schedule your Install',
                description: 'Select an Available Date and Timeslot',
                error: 'Choose appointment date and time.',
            },
            confirmOrder: {
                title: 'Confirm Order Details',
                termsAndConditions: 'By checking the box below I confirm that I am at least 18 years old. Check to agree to the Text above and the Terms For Survey Submissions',
                termsAndConditionsLink: 'I agree with <a href="#" target="_blank">Terms &amp; Conditions</a>'
            },
            successfulOrder: {
                title: 'Congratulations!',
                signUpConfirmation: 'Thank you for your service request.',
                signUpConfirmationLine2: 'You will receive a confirmation email with appointment information and a link to signup for our customer portal. The customer portal allows you to manage your account and pay your bill.', // We can add html tags in this
                signUpShareMessage: 'I just signed up for fastest internet. You can too!',
                signUpFacebookUrl: 'https://mydev.camvio.cloud/guest-order',
                signUpTwitterUrl: 'https://mydev.camvio.cloud/guest-order',
            }
        },
        verifyEmailScreen: {
            titleInProgress: "Email Verification",
            titleCompleted: "Email Verified",
            messageInProgress: "Email vefirication in progress.",
            messageCompleted: "Your email address has been verified.",
            messageError: "Error verifying your email."
        }
    },
    'sp-es': {
        siteTitle: 'My Neta',
        footerMessage: 'Copyright © 2021 Neta Customer Portal. All rights reserved.',
        companyName: 'Neta Communications',
        networkErrorMessage: 'There is a network outage that may affect you!',
        termsAndConditionsLink: 'I agree with <a target="_blank">Terms &amp; Conditions</a>',
        paymentMessage: 'Thank you for making a payment. Follow the directions below. If you have any questions, please contact us and we can assist you.',
        registerScreen: {
            choosePasswordSection: {
                title: 'Choose password'
            },
            confirmAccountSection: {
                title: 'Confirm Account',
                confirmAccountInitSteps: 'First, provide one of the following details associated with your account.',
                contactInfoText: 'Please provide your phone number or email address.',
                accountInfoText: 'Please provide your Acount number and Billing zipcode.',
            },
            pinOptionsSection: {
                title: 'Pin Options',
                accountPin: 'I have my Account PIN',
                obtainPin: 'To obtain a PIN to register your account, simply call customer service at (800) 555-6666',
                doNotHavePinMobile: "I don't have any PIN. Send me a temporary PIN to my mobile.",
                temporaryPinMobile: 'We will send a temporary PIN to your pre-verified mobile number on your account.',
                doNotHavePinEmail: "I don't have any PIN. Email me a temporary PIN.",
                temporaryPinEmail: "We will send a temporary PIN to your pre-verified email address on your account."
            },
            signUpCompletedSection: {
                title: 'Sign Up Completed!',
                signInOPtion: 'You can now sign in with your email and password'
            }
        },
        guestOrderScreen: {
            checkAvailability : {
                title: 'Let\'s see what\'s available at your address'
            },
            notAvailableService: {
                title: 'You are not in the current service area, we will notify you when we are planning to build to your neighborhood'
            },
            notAvailableServiceConfirmation: {
                title: 'Thank you for your interest',
                subTitle: 'We will send you an update when we are planning to build to your neighborhood.'
            },
            signUpSelectedService: {
                title: 'Coming soon to your neighborhood, sign up for your services now!'
            },
            surveySelectedService: {
                title: 'Your address is in survey phase, show your interest below'
            },
            serviceSelectedService: {
                title: 'Here is what is available.'
            },
            contactInfo : {
                title: 'Tell us about you',
                termsAndCondition: 'By checking the box below I confirm that I am at least 18 years old. Check to agree to the Text above and the Terms For Survey Submissions'
            },
            confirmation: {
                //SIGNUP ACCOUNT
                signUpShareMessage: 'I just signed up for fastest internet in Media county. You can too!',
                signUpConfirmation: 'Thank you for signing up for Medina Fiber service.',
                signUpConfirmationLine2: 'We will contact you with futher updates.<br />Spread the word, let\'s build a better internet for Medina County.', // We can add html tags in this
                signUpFacebookUrl: 'https://mydev.camvio.cloud/guest-order',
                signUpTwitterUrl: 'https://mydev.camvio.cloud/guest-order',
                // SURVEY ACCOUNT
                surveyShareMessage: 'I just took my survey to bring Fiber to my home in Media county. Would you be next?',
                surveyConfirmation: 'Thanks for submitting your survey.',
                surveyConfirmationLine2: 'We will contact you with futher updates.<br />Spread the word, let\'s build a better internet for Medina County.', // We can add html tags in this
                surveyFacebookUrl: 'https://mydev.camvio.cloud/guest-order',
                surveyTwitterUrl: 'https://mydev.camvio.cloud/guest-order',
                // ACTIVE ACCOUNT
                activeShareMessage: 'I just activated my fast internet in Media county. You can too!',
                activeConfirmation: 'Thanks for choosing our services.',
                activeConfirmationLine2: 'We will contact you with futher updates.<br />Spread the word, let\'s build a better internet for Medina County.', // We can add html tags in this
                activeFacebookUrl: 'https://mydev.camvio.cloud/guest-order',
                activeTwitterUrl: 'https://mydev.camvio.cloud/guest-order',
            }
        },
        confirmOrderScreen: {
            selectService: {
                title: 'Service is available. Select your bundle or plan.',
                tokenErrorTitle: 'Please contact us for service.',
                tokenErrorDescription: 'Sorry but your token expired. For security reasons please contact us at 555-555-555 to get service.',
                tokenErrorNote: 'Looking forward to providing you broadband service!',
            },
            notAvailableService: {
                title: 'Service Unavailable',
                description: 'Please click Check Availability to indicate you want service when available in your area.',
            },
            serviceDetails: {
                title: 'Configure your Services',
                recurringDescription: 'Ongoing charges',
                oneTimeDescription: 'Installation, setup and/or activation charges',
            },
            scheduleAppointment: {
                title: 'Schedule your Install',
                description: 'Select an Available Date and Timeslot',
                error: 'Choose appointment date and time.',
            },
            confirmOrder: {
                title: 'Confirm Order Details',
                termsAndConditions: 'By checking the box below I confirm that I am at least 18 years old. Check to agree to the Text above and the Terms For Survey Submissions',
                termsAndConditionsLink: 'I agree with <a href="#" target="_blank">Terms &amp; Conditions</a>'
            },
            successfulOrder: {
                title: 'Congratulations!',
                signUpConfirmation: 'Thank you for your service request.',
                signUpConfirmationLine2: 'You will receive a confirmation email with appointment information and a link to signup for our customer portal. The customer portal allows you to manage your account and pay your bill.', // We can add html tags in this
                signUpShareMessage: 'I just signed up for fastest internet. You can too!',
                signUpFacebookUrl: 'https://mydev.camvio.cloud/guest-order',
                signUpTwitterUrl: 'https://mydev.camvio.cloud/guest-order',
            }
        },
        verifyEmailScreen: {
            titleInProgress: "Email Verification",
            titleCompleted: "Email Verified",
            messageInProgress: "Email vefirication in progress.",
            messageCompleted: "Your email address has been verified.",
            messageError: "Error verifying your email."
        }
    },
}
import React, {Component} from 'react';
import ReactHtmlParser from "react-html-parser";
import PackageTooltip from "../../../../../components/PackageTooltip";
import isEmpty from "../../../../../utils/helpers";

class OptionFeature extends Component {

    state = {
        isTooltipOpen: false
    };

    handleTooltip = () => {
        this.setState((prevProps) => ({
            isTooltipOpen: !prevProps.isTooltipOpen
        }));
    };

    render() {
        const { isTooltipOpen } = this.state;
        const { features, lineIndex, handleOptionalFeature, feature } = this.props;

        return (
            <div className={"package" + (feature.hideCustomerPortal ? " d-none" : "")}>
                <div className="package-header custom-switch-with-button">

                    <div className="custom-control custom-switch" onClick={() => handleOptionalFeature(feature.id, lineIndex, feature)}>
                        <input type="checkbox"
                               className="custom-control-input"
                               checked={features.findIndex((x) => x.id === feature.id) !== -1}/>
                        <label className="custom-control-label" htmlFor="">
                            <span>{feature.description}</span>
                            <span>
								${feature.featureType === 'ACTIVATION' ? (
                                feature.oneTimePrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                            ) : (
                                feature.recurringMonthlyPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + '/mo'
                            )}
							</span>
                        </label>
                    </div>

                    {!isEmpty(feature.note) &&
                    <button type="button" className="btn btn-toggle-package-desc" onClick={() => this.handleTooltip()}>
                        <i className="fas fa-info-circle"/>
                    </button>
                    }

                </div>

                <PackageTooltip isVisible={isTooltipOpen}>{ReactHtmlParser(feature.note)}</PackageTooltip>

            </div>
        );
    }
}

export default OptionFeature;

import React, { Component, Fragment } from 'react'
import PlanServicelines from './PlanServicelines';

class PlanList extends Component {

    state = {
        planId: '',
        isPlanCollapsed: true
    };

    collapsePlan = (id) => {
        this.setState((prevProps) => ({
            planId: id,
            isPlanCollapsed: (prevProps.planId === id && prevProps.isPlanCollapsed === true ? false : true)
        }));
    };

    render() {
        const { planId, isPlanCollapsed } = this.state;
        const { planInfo, planIndex, values } = this.props;

        return (
            <div className={'product-item' + (planId === planIndex && isPlanCollapsed ? ' product-item-collapsed' : '')}>
                <div className="header">
                    <div className="header-title">
                        {planInfo.description}
                    </div>
                    <button className={'btn header-collapse'} type="button" onClick={() => this.collapsePlan(planIndex)}>
                        <i className="fas fa-angle-up"></i>
                    </button>
                </div>
                <div className="details">
                    <div className="description">
                        {values.order.plans[planIndex] &&
                            values.order.plans[planIndex].servicelines.map((line, index) => (
                                <PlanServicelines
                                    lineInfo={line}
                                    key={index}
                                    lineIndex={index}
                                    planIndex={planIndex}
                                    {...this.props}
                                />
                            ))}
                    </div>
                </div>
            </div>
        )
    }
}

export default PlanList

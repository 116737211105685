import React, {Component, Fragment} from 'react'
import {connect} from "react-redux";
import PaymentMethodForm from "../PaymentMethods/PaymentMethodForm";
import {getPayment, selectAccountInfo} from "../../../selectors";
import {getMyPaymentMethods, getMyAutoPayInfo} from "../../../actions/homeInfoActions";

export class PaymentMethods extends Component {

    state = {
        paymentFormDisplayed: false,

    }

    componentDidMount() {
        if (!this.state.paymentFormDisplayed) {

            const { accountInfo, } = this.props;
            const data = { iframe: true, };
            const { guestOrderConfig } = window;

            data.submitType = "managePaymentMethods";
            if (guestOrderConfig && guestOrderConfig.defaultLocationId) {
                data.locationId = guestOrderConfig.defaultLocationId;
            } else {
                console.warn("No default location ID");
            }

            data.accountId = accountInfo.id
            this.paymentMethodFormRef.current.createPaymentMethod(data);
            this.setState({paymentFormDisplayed: true});
        }
    }

    componentWillUnmount() {
        this.props.getMyPaymentMethods(this.props.accountInfo.id);
        this.props.getMyAutoPayInfo(this.props.accountInfo.id);
    }

    constructor(props) {
        super(props);
        this.paymentMethodFormRef = React.createRef();
    }

    render() {
        const { accountInfo, } = this.props;

        return (
            <div className="cp-form">
                <div className="card-product">
                    <div className="card-body">
                        <iframe
                            name="payment_method_frame"
                            src={this.props.formData && this.props.formData.action}
                            allow="payment; web-share"
                            frameBorder="0"
                            style={
                                {
                                    position: 'relative',
                                    zIndex: 0,
                                    display: 'block',
                                    width: '100%',
                                    minHeight:'1030px',
                                    marginTop: '-111px',
                                    borderRadius: '0.5rem'
                                }
                            }>
                        </iframe>

                        <PaymentMethodForm
                            ref={this.paymentMethodFormRef}
                            accountInfo={accountInfo}
                            formTarget="payment_method_frame"
                            togglePreventPanelClosing={this.props.togglePreventPanelClosing}
                            preventPanelClosing={this.props.preventPanelClosing}
                            getMyPaymentMethods={this.props.getMyPaymentMethods}
                            paymentFormSubmitted={false}
                        />

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {accountInfo} = selectAccountInfo(state);
    const formData = getPayment(state).formData

    return {
        accountInfo,
        formData
    };
};

const mapDispatchToProps = {
    getMyPaymentMethods,
    getMyAutoPayInfo
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PaymentMethods);

import React, { Fragment } from 'react';
 import PropTypes from 'prop-types';
 import { get } from 'lodash';
 
 const RoundedInput = ({
 	type,
 	name,
 	errors,
 	touched,
 	placeholder,
 	value,
 	onChange,
 	onBlur,
 	isDisabled
 }) => {
 	return (
 		<Fragment>
 			<input
 				className={
 					get(errors, `${name}`) && get(touched, `${name}`) ? 'form-control is-invalid' : 'form-control'
 				}
 				name={name}
 				placeholder={placeholder}
 				type={type}
 				value={value}
 				onChange={onChange}
 				onBlur={onBlur}
 				disabled={isDisabled}
 				autoComplete="off"
 			/>
 			{get(errors, `${name}`) &&
 			get(touched, `${name}`) && <div className="invalid-feedback">{get(errors, `${name}`)}</div>}
 		</Fragment>
 	);
 };
 
 RoundedInput.propTypes = {
 	type: PropTypes.string.isRequired,
 	name: PropTypes.string.isRequired,
 	errors: PropTypes.object.isRequired,
 	touched: PropTypes.object.isRequired,
 	value: PropTypes.string.isRequired,
 	onChange: PropTypes.func.isRequired,
 	onBlur: PropTypes.func.isRequired,
 	placeholder: PropTypes.string,
 	isDisabled: PropTypes.bool
 };
	 
 export default RoundedInput;
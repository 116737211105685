import React, {Component, Fragment} from 'react';
import {createLoadingSelector} from "../../../selectors";
import {
    clearPaymentFormData,
    getPaymentFormData,
    getPaymentStatus,
    clearPaymentStatus,
    paymentByToken
} from "../../../actions/payment.actions";
import {
    getAutoPayAvailableCC,
} from '../../../actions/homeInfoActions'
import {connect} from "react-redux";
import Loader from "../../../components/common/Spinners/Loader";
import PropTypes from 'prop-types';
import {getPayment} from "../../../selectors";
import isEmpty from "../../../utils/helpers";

class PaymentForm extends Component {

    state = {
        paymentFormSubmitted: false,
        submitFormData: false,
    }

    componentDidMount() {
        this.referenceId = '';
        this.xBtnTimeoutHandle = setTimeout(() => {
            // Unlock panel closing
            this.props.togglePreventPanelClosing(false);
            // Remove tab close event listener
            window.removeEventListener('beforeunload', this.preventTabClose);
        }, 40000);
    }

    componentWillUnmount() {
        this.props.clearPaymentFormData();
        this.props.clearPaymentStatus();

        if (this.xBtnTimeoutHandle) {
            clearTimeout(this.xBtnTimeoutHandle);
        }
    }

    componentDidUpdate(prevProps) {
        const {
            getPaymentStatus,
            paymentStatus,
            handlePaymentSuccess,
            handlePaymentFail,
            handlePaymentGeneric,
            getAutoPayAvailableCC
        } = this.props;

        if (this.state.submitFormData && !isEmpty(this.props.formData)) {
            this.paymentForm.submit();
            this.setState({paymentFormSubmitted: true, submitFormData: false});
            getPaymentStatus(this.referenceId);
        }

        if (!isEmpty(this.props.formData) && prevProps.formData !== this.props.formData) {
            this.referenceId = this.props.formData.fields['ReferenceId'];
        }

        if (prevProps.paymentStatusCount !== this.props.paymentStatusCount) {
            if (paymentStatus.inProgress === true) {
                getPaymentStatus(this.referenceId);
            } else {
                this.setState({paymentFormSubmitted: false});

                // Unlock panel closing
                this.props.togglePreventPanelClosing(false);

                // Remove tab close event listener
                window.removeEventListener('beforeunload', this.preventTabClose);

                if (paymentStatus.status === 'FAIL') {
                    handlePaymentFail(paymentStatus);
                }
                else if (paymentStatus.status === 'SUCCESS') {
                    handlePaymentSuccess(paymentStatus);
                    getAutoPayAvailableCC(this.props.accountInfo.id);
                }
                else if (paymentStatus.status === 'USED') {
                    handlePaymentSuccess(paymentStatus);
                    getAutoPayAvailableCC(this.props.accountInfo.id);
                }
                else {
                    handlePaymentGeneric(paymentStatus);
                    getAutoPayAvailableCC(this.props.accountInfo.id);
                }
            }
        }
    }


    submitPaymentForm = (data) => {
        const {accountInfo, getPaymentFormData, handlePaymentFail} = this.props;

        // Prevent panel closing
        this.props.togglePreventPanelClosing(true);

        // Add tab close event listener
        window.addEventListener('beforeunload', this.preventTabClose);

        getPaymentFormData(accountInfo.id, data).then(resp => {

            if (resp.data && resp.data.fields) {
                this.setState({submitFormData: true});
                return;
            }

            handlePaymentFail({status: 'FAILED', responseText: "Invalid data"});
        });
    }

    submitPaymentWithToken = (data) => {
        const {accountInfo, handlePaymentSuccess, handlePaymentFail, paymentByToken, handlePaymentGeneric} = this.props;
        
        // Prevent panel closing
        this.props.togglePreventPanelClosing(true);

        // Add tab close event listener
        window.addEventListener('beforeunload', this.preventTabClose);

        paymentByToken(accountInfo.id, data).then(resp => {

            if (resp.success) {
                this.setState({submitFormData: false, paymentFormSubmitted: false});
                if (resp.inProgress === true) {
                    //getPaymentStatus(this.referenceId);
                } else if (resp.status === 'SUCCESS') {
                    this.props.togglePreventPanelClosing(false);
                    handlePaymentSuccess({status: resp.status, result:resp});
                } else if (resp.status === 'USED') {
                    this.props.togglePreventPanelClosing(false);
                    handlePaymentSuccess({status: resp.status, result:resp});
                } else if (resp.status === 'FAIL') {
                    this.props.togglePreventPanelClosing(false);
                    handlePaymentFail({status: 'FAILED', responseText: "Transaction Failed", result:resp});
                } else {
                    this.props.togglePreventPanelClosing(false);
                    handlePaymentGeneric(resp.status);
                }
                return;
            }

            this.props.togglePreventPanelClosing(false);
            handlePaymentFail({status: 'FAILED', responseText: "Invalid data"});
        });
    }


    preventTabClose = () => {
        if (this.props.paymentStatus === null) {

            let e = window.event;
            if (e) e.returnValue = true;
            return true;
        }
    }

    render() {
        const {loading, formData} = this.props;

        const formTarget = this.props.formTarget || (formData && formData.target);

        if (loading === true || this.state.paymentFormSubmitted === true) {
            return (
                <>
                    <Loader />
                    <br/>
                </>
            );
        }

        if (isEmpty(formData)) {
            return null;
        }

        return (
            <Fragment>
                <div className="cmv-container-customer-pmnt-card">
                    <form method="post" ref={(ref) => {this.paymentForm = ref}} action={formData.action} target={formTarget}>
                        {Object.keys(formData.fields).map((key) => (
                            <input key={key} type="hidden" name={key} value={formData.fields[key]} />
                        ))}
                    </form>
                </div>
            </Fragment>
        );
    }
}

PaymentForm.propTypes = {
    accountInfo: PropTypes.object.isRequired,
    formTarget: PropTypes.string,
    handlePaymentSuccess: PropTypes.func,
    handlePaymentFail: PropTypes.func,
    handlePaymentGeneric: PropTypes.func,
};
const getFormDataSelector = createLoadingSelector(['GET_PAYMENT_FORM']);

const mapStateToProps = (state) => {
    const loading = getFormDataSelector(state),
        formData = getPayment(state).formData,
        paymentStatus = getPayment(state).paymentStatus,
        paymentStatusCount = getPayment(state).paymentStatusCount;

    return {
        loading,
        formData,
        paymentStatus,
        paymentStatusCount
    };
};

const mapDispatchToProps = {
    getPaymentFormData,
    clearPaymentFormData,
    getPaymentStatus,
    clearPaymentStatus,
    getAutoPayAvailableCC,
    paymentByToken,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PaymentForm);

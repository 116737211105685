import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import PackageTooltip from "../../../../components/PackageTooltip";
import isEmpty from '../../../../utils/helpers';

class OptionalFeature extends Component {

	state = {
		isTooltipOpen: false
	};

	handleTooltip = () => {
		this.setState((prevProps) => ({
			isTooltipOpen: !prevProps.isTooltipOpen
		}));
	};

	isPriceRendered = () => {
		const { accountInitialStatus, feature } = this.props;

		if (accountInitialStatus === 'SIGNUP') {
			if (feature.hideSignupPrice) {
				return false;
			}
		} else if (accountInitialStatus === 'SURVEY') {
			if (feature.hideSurveyPrice) {
				return false;
			}
		}
		return true;
	};

	render() {
		const { isTooltipOpen } = this.state;
		const { feature, lineInfo, lineIndex, handleOptionalFeature } = this.props;

		return (
			<div className={"package" + (feature.hideCustomerPortal ? " d-none" : "")}>
				<div className="package-header custom-switch-with-button">
					<div
						className="custom-control custom-switch"
						onClick={() => handleOptionalFeature(feature.id, lineIndex, feature)}
					>
						<input type="checkbox" className="custom-control-input"
							checked={lineInfo.features.findIndex((x) => x.id === feature.id) !== -1}
							disabled=""
							readOnly
						/>

						<label className="custom-control-label" htmlFor="">
							<span>{!isEmpty(feature.provider) && <img className="cp-icon cp-icon-service-provider-r" src={`${process.env.PUBLIC_URL}/media/service-providers/${feature.provider.logo}`} />} {feature.description} </span>
							<span>
								{this.isPriceRendered() === true ? feature.featureType === 'ACTIVATION' ? (
										`$${feature.oneTimePrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
									) : (
										`$${(feature.recurringMonthlyPrice == 0 && feature.price > 0 ? feature.price : feature.recurringMonthlyPrice)
											.toFixed(2)
											.replace(/\d(?=(\d{3})+\.)/g, '$&,')}` + '/mo'
									)
									: null
								}
							</span>
						</label>
					</div>

					{!isEmpty(feature.note) &&
					<button type="button" className="btn btn-toggle-package-desc" onClick={() => this.handleTooltip()}>
						<i className="fas fa-info-circle"/>
					</button>
					}

				</div>

				<PackageTooltip isVisible={isTooltipOpen}>{ReactHtmlParser(feature.note)}</PackageTooltip>

			</div>
		);
	}
}

export default OptionalFeature;
import axios from 'axios';
import axiosInstance from '../utils/axios-instance';
import {actionCreator, createRequestTypes, generateLocalStorageItem, getErrorMessage} from '../utils/helpers';
import {API_ROOT} from "../utils/api-config";

export const REGISTER_EMAIL_AND_ACCOUNT_NUMBER = createRequestTypes('REGISTER_EMAIL_AND_ACCOUNT_NUMBER');

export const registerByEmailAndNumber = (accountNumber, email) => async (dispatch, getState) => {
    dispatch(actionCreator.request(REGISTER_EMAIL_AND_ACCOUNT_NUMBER));

    const data = {
        accountNumber: accountNumber,
        email: email,
    };

    const headers = {
        headers: {
            'Content-Type': 'application/json'
        },
        method: 'GET',
        url: `${API_ROOT}/public/account/email/registration`,
        params: data
    };

    try {
        const response = await axios(headers);
        generateLocalStorageItem(response.data);
        dispatch(actionCreator.success(REGISTER_EMAIL_AND_ACCOUNT_NUMBER, response.data));
        return true;
    } catch (err) {
        let language = getState().language.language;
        let mockError = getErrorMessage(err.response, language);
        dispatch(actionCreator.failure(REGISTER_EMAIL_AND_ACCOUNT_NUMBER, mockError));
        return false;
    }
};

export const clearRegisterError = () => (dispatch) => {
    dispatch(actionCreator.clear(REGISTER_EMAIL_AND_ACCOUNT_NUMBER));
};
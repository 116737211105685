import React, { Component, Fragment } from 'react';
import ReactHtmlParser from 'react-html-parser';
import isEmpty from '../../../../utils/helpers';

export class Bundle extends Component {
	state = {
		isDetailsOpen: false
	};

	renderButtonText = () => {
		const { initialStatus } = this.props;

		if (initialStatus === 'SIGNUP') {
			return 'Select';
		} else if (initialStatus === 'SURVEY') {
			return 'Interested';
		} else if (initialStatus === 'ACTIVE') {
			return 'Select';
		}
	};

	showDetailsHandler = () => {
		this.setState((prevState) => ({
			isDetailsOpen: !prevState.isDetailsOpen
		}));
	};

	render() {
		const { isDetailsOpen } = this.state;
		const { bundle, setSelectedItem, initialStatus } = this.props;

		return (
			<div className={isDetailsOpen ? 'product-item product-item-with-details' : 'product-item'}>
				<div className="header">

					<div className="header-title">
						<div className="title">{bundle.description}</div>
						<div className="description">{ReactHtmlParser(bundle.note)}</div>
					</div>
				</div>
				<div className="container-service-providers">
					{!isEmpty(bundle.providers) ? (bundle.providers.map((provider) => (
						provider.logo &&
						<img className="cp-icon cp-icon-service-provider-r" src={`${process.env.PUBLIC_URL}/media/service-providers/${provider.logo}`} />
					))) : null}
				</div>
				<div className="basic">
					<div className="left">
						{bundle.plans.map((plan, index) => (
							<Fragment><i className={plan.serviceModel.icon} key={plan.id} />{' '}</Fragment>
						))}
					</div>


					<div className="right">
						from ${bundle.fromPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo
					</div>
				</div>

				<p className="text-xs text-muted">
					{bundle.plans.map((plan, index) => {
						return index === 0 ? plan.serviceModel.description : ' + ' + plan.serviceModel.description;
					})}
				</p>
				<div className={isDetailsOpen ? 'details collapse show' : 'details collapse'}>
					<div className="description">
						{bundle.plans.map((plan, index) => (
							<div className="form-group" key={plan.id}>
								<p className="lead">
									<i className={plan.serviceModel.icon} /> {plan.description}
								</p>
								<ul className="list-unstyled list-product-item-details">
									<li>{ReactHtmlParser(plan.note)}</li>
								</ul>
							</div>
						))}
					</div>
				</div>
				<div className="footer">
					<div className="footer-toolbar">
						<button
							type="button"
							onClick={this.showDetailsHandler}
							className="btn btn-sm btn-outline-secondary btn-product-details"
						>
							Details
						</button>
						<button
							type="button"
							onClick={() => setSelectedItem(bundle, 'bundle')}
							className="btn btn-sm btn-primary btn-fs-action"
							data-target="fs5"
						>
							{this.renderButtonText()}
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default Bundle;

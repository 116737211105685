import React, { Component } from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import ReactHtmlParser from 'react-html-parser';
import {trans} from "../../../../utils/helpers";
import {Link} from "react-router-dom";

class SuccessStep extends Component {

	state={
		title:'',
		signUpConfirmation:'',
		signUpConfirmationLine2:'',
		signUpFacebookUrl:'',
		signUpShareMessage:'',
		signUpTwitterUrl:''
	}

	componentDidMount(){
		(async() => {
			const title= await trans('en-us', 'confirmOrderScreen.successfulOrder.title');
			const signUpConfirmation= await trans('en-us', 'confirmOrderScreen.successfulOrder.signUpConfirmation');
			const signUpConfirmationLine2= await trans('en-us', 'confirmOrderScreen.successfulOrder.signUpConfirmationLine2');
			const signUpFacebookUrl= await trans('en-us', 'confirmOrderScreen.successfulOrder.signUpFacebookUrl');
			const signUpShareMessage= await trans('en-us', 'confirmOrderScreen.successfulOrder.signUpShareMessage');
			const signUpTwitterUrl= await trans('en-us', 'confirmOrderScreen.successfulOrder.signUpTwitterUrl');
			
				
			this.setState({title,signUpConfirmation,signUpConfirmationLine2,signUpFacebookUrl,signUpShareMessage,signUpTwitterUrl});
		})()
	}

	render() {

		const {
			values,
		} = this.props;

		return (
			<div className="form-section">

				<div className="message">

					<div className="message-left">
						<i className="fas fa-check-circle" />
					</div>

					<div className="message-right">

						<h4>{this.state.title}</h4>
						<p className="message-code">{this.state.signUpConfirmation}</p>
						<p>{ReactHtmlParser(this.state.signUpConfirmationLine2)}</p>

						{/*<Link
							to={{
								pathname: '/emailregister',
								state: {email: values.account.accountHolder.personalEmail,}
							}}
							className="btn btn-primary btn-block btn-a"
						>
							Register
						</Link>

						<p></p>*/}

						<div className="message-footer">

							<FacebookShareButton
								url={this.state.signUpFacebookUrl}
								quote={this.state.signUpShareMessage}
							>
								<button type="button" className="btn btn-primary btn-facebook">
									<i className="fab fa-facebook"/>&nbsp;Share It
								</button>
							</FacebookShareButton>

							<TwitterShareButton
								url={this.state.signUpTwitterUrl}
								title={this.state.signUpShareMessage}
							>
								<button type="button" className="btn btn-primary btn-twitter">
									<i className="fab fa-twitter"/>&nbsp;Tweet It
								</button>
							</TwitterShareButton>

							{/*<button type="button" formaction="cp-dashboard.html" id="" className="btn btn-primary btn-mail">
								<i className="fas fa-at" />&nbsp;Mail It
							</button>*/}

							{/*<button type="button" className="btn btn-primary btn-link">
								<i className="fas fa-link" />&nbsp;Link
							</button>*/}

						</div>

					</div>

				</div>

			</div>
		);
	}
}

export default SuccessStep;

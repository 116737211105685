import React, { Component } from 'react'
import Loader from "../../components/common/Spinners/Loader";

class IframeAddress extends Component {

    render() {

        const {iframeAddressUrl, iframeLoader, handleHideIframeLoader, handleIframeError} = this.props;

        return (
            <>

            {iframeAddressUrl.length > 0 &&
            <iframe
                className={iframeLoader === true ? 'map-canvas cp-with-loader' : 'map-canvas'}
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
                src={iframeAddressUrl}
                onLoad={handleHideIframeLoader}
                onError={handleIframeError}
            />
            }

            {iframeLoader &&
            <div className="map-canvas map-canvas-loader">
                <Loader />
                <p>Loading Map</p>
            </div>
            }

            </>
        )
    }
}

export default IframeAddress

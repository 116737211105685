import React, {Component, Fragment} from 'react';
import ReactHtmlParser from 'react-html-parser';
import isEmpty from '../../../../utils/helpers';
import FlyInRightPanel from '../../../../components/FlyInRightPanel'

export class Bundle extends Component {
	state = {
		isDetailsOpen: false
	};

	renderButtonText = () => {
		const { initialStatus } = this.props;

		if (initialStatus === 'SIGNUP') {
			return 'Choose';
		} else if (initialStatus === 'SURVEY') {
			return 'Interested';
		} else if (initialStatus === 'ACTIVE') {
			return 'Select';
		}
	};

	showDetailsHandler = () => {
		this.setState((prevState) => ({
			isDetailsOpen: !prevState.isDetailsOpen
		}));
	};

	render() {
		const { isDetailsOpen } = this.state;
		const { bundle, setSelectedItem, initialStatus } = this.props;

		return (
			<div className={'product-item product-item-border' + (isDetailsOpen ? ' product-item-with-details' : '')
			+ (bundle.hideCustomerPortal ? " d-none" : "")}>
				<div className="header">
					<div className="header-title">
						<div className="title">
							<h5>{bundle.description}</h5>
						</div>
					</div>
				</div>
				<div className="details">
					{ReactHtmlParser(bundle.note)}
				</div>

				<div className="basic">
					<div className="left">
						{bundle.plans.map((plan) => (
							<Fragment key={plan.id}><i className={plan.serviceModel.icon} key={plan.id} />{' '}</Fragment>
						))}

						<p>
							{bundle.plans.map((plan, index) => {
								return index === 0 ? plan.serviceModel.description : ' + ' + plan.serviceModel.description;
							})}
						</p>
					</div>
					{!bundle.hideFromPrice&&<div className="right text-success">
						<small>from </small>${bundle.fromPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}<small> / mo</small>
					</div>}
				</div>

				{!isEmpty(bundle.providers) && (
					<div className="container-service-providers">
						{bundle.providers.map((provider) => (
							provider.logo &&
							<img className="cp-icon cp-icon-service-provider-r" src={`${process.env.PUBLIC_URL}/media/service-providers/${provider.logo}`} />
						))}
					</div>
				)}

				<FlyInRightPanel
					title="Details"
					onClose={this.showDetailsHandler}
					isVissible={isDetailsOpen}
					price={!bundle.hideFromPrice ?
						<span>
							from <span className="price">${bundle.fromPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span> / mo
						</span>
						:
						null
					}
				>

					<h4>{bundle.description}</h4>

					<div className="form-divider">
						{ReactHtmlParser(bundle.note)}
					</div>

					<div className="description">
						{bundle.plans.map((plan) => (
							<div key={plan.id}>
								<div className="basic plan" key={plan.id}>
									<div className="left">
										<i className={plan.serviceModel.icon} />
									</div>
									<div className="right">
										<h5>{plan.description}</h5>
										<div>{ReactHtmlParser(plan.note)}</div>
									</div>
								</div>
								<div className="form-divider" />
							</div>
						))}
					</div>

					<div className="container-service-providers">
						{!isEmpty(bundle.providers) ? (bundle.providers.map((provider) => (
							provider.logo &&
							<img className="cp-icon cp-icon-service-provider-r" src={`${process.env.PUBLIC_URL}/media/service-providers/${provider.logo}`} />
						))) : null}
					</div>

					<button
						type="button"
						onClick={() => setSelectedItem(bundle, 'bundle')}
						className="btn btn-primary btn-block"
						data-target="fs5"
					>
						{this.renderButtonText()}
					</button>

				</FlyInRightPanel>

				<div className="footer">
					<div className="footer-toolbar">
						<button
							type="button"
							onClick={this.showDetailsHandler}
							className="btn btn-product-details btn-quick"
						>
							Details
						</button>
						<button
							type="button"
							onClick={() => setSelectedItem(bundle, 'bundle')}
							className="btn btn-primary btn-fs-action"
							data-target="fs5"
						>
							{this.renderButtonText()}
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default Bundle;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    getNotificationCategories,
    subscribeForNotification,
    unsubscribeFromNotification
} from "../../actions/notifications";
import {selectAccountInfo} from "../../selectors";
import NotificationCategories from './NotificationCategories';

class Notifications extends Component {
    state = {
        isNotificationCategoriesLoaded: false
    };

    componentDidMount() {
        const { accountInfo } = this.props;
        this.props.getNotificationCategories(accountInfo.id);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.notificationCategoriesData !== this.props.notificationCategoriesData &&
            this.state.isNotificationCategoriesLoaded === false
        ) {
            this.setState({
                isNotificationCategoriesLoaded: true
            });
        }
    }

    handleNotificationSubscribe = (data) => {
        const { accountInfo } = this.props;
        this.props.subscribeForNotification(accountInfo.id, data);
    };

    handleNotificationUnsubscribe = (data) => {
        const { accountInfo } = this.props;
        this.props.unsubscribeFromNotification(accountInfo.id, data);
    };

    render() {
        const { isNotificationCategoriesLoaded } = this.state;
        const { notificationCategoriesData, notificationCategoriesLoader, accountInfo } = this.props;

        return (
            <div
                className="tab-pane fade show active"
                id="account-notifications"
                role="tabpanel"
                aria-labelledby="account-notifications-tab"
            >
                <div className="container-client-notifications">
                    <NotificationCategories
                        notificationCategoriesData={notificationCategoriesData}
                        handleNotificationSubscribe={this.handleNotificationSubscribe}
                        handleNotificationUnsubscribe={this.handleNotificationUnsubscribe}
                        isNotificationCategoriesLoaded={isNotificationCategoriesLoaded}
                        notificationCategoriesLoader={notificationCategoriesLoader}
                        accountId={accountInfo.id}
                    />
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    const accountInfo = selectAccountInfo(state).accountInfo,
        notificationCategoriesData = state.notifications.notificationCategories;

    return {
        accountInfo,
        notificationCategoriesData
    }
};

const mapDispatchToProps = {
    getNotificationCategories,
    subscribeForNotification,
    unsubscribeFromNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);

import React, {Component, Fragment} from 'react';
import PackageTooltip from "../../../components/PackageTooltip";
import {trans} from "../../../utils/helpers";

class SummaryContainer extends Component {

    state = {
        recurringDescription:'',
        isDescriptionVisible: false
    };

    componentDidMount(){
        (async() => {
            const recurringDescription=await trans('en-us', 'guestOrderScreen.serviceDetails.recurringDescription');

            this.setState({recurringDescription});
        })()
    }
    toggleDescription = () => {
        this.setState((prevProps) => ({
            isDescriptionVisible: !prevProps.isDescriptionVisible
        }));
    };

    render() {
        const { isDescriptionVisible } = this.state;
        const { values } = this.props;

        return (
            <Fragment>
                <div className="product-price">

                    <div className="package">
                        <div className="package-header">

                            <span className="package-left">Recurring:</span>

                            <span className="package-right">

						        ${values.features
                                .reduce((acc, currentValue, index) => {
                                    if (currentValue.featureType !== 'ACTIVATION') {
                                        acc += currentValue.recurringMonthlyPrice;
                                    }

                                    return acc;
                                }, 0)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo

                                <button
                                    type="button"
                                    className="btn btn-toggle-package-desc"
                                    onClick={() => this.toggleDescription()}
                                >
                                    <i className="fas fa-info-circle" />
                                </button>

                            </span>

                        </div>

                        <PackageTooltip color={'white'} isVisible={isDescriptionVisible}>
                        {this.state.recurringDescription}
                        </PackageTooltip>

                    </div>

                </div>
            </Fragment>
        );
    }
}

export default SummaryContainer;

import {
    MY_SERVICE,
    MY_ORDERS,
    SET_UPDATE_REQUEST_PLAN_DETAILS,
    SET_UPDATE_REQUEST_FEATURES,
    SET_UPDATE_CHANGE_SERVICE,
    MY_INVOICES,
    MY_PAYMENTS,
    MY_PAYMENT_METHODS,
    MY_AMOUNTS,
    AUTOPAY,
    AUTOPAY_AVAILABLE_CC,
    GET_ACCOUNT_TYPE_DISTRIBUTION_METHODS,
    GET_ACCOUNT_DISTRIBUTION_METHOD, UPDATE_ACCOUNT_DISTRIBUTION_METHOD
} from '../actions/homeInfoActions';

let initialState = {
    myServiceInfo: [],
    myOrders: [],
    planDetails: {},
    updatedServiceInfo: {},
    features: {}

}

export const homeInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case MY_SERVICE.SUCCESS:
            return {
                ...state,
                myServiceInfo: action.payload
            };
        case MY_ORDERS.SUCCESS:
            return {
                ...state,
                myOrders: action.payload
            };
        case MY_INVOICES.SUCCESS:
            return {
                ...state,
                myInvoices: action.payload
            };
        case MY_PAYMENTS.SUCCESS:
            return {
                ...state,
                myPayments: action.payload
            };
        case MY_PAYMENT_METHODS.SUCCESS:
            return {
                ...state,
                myPaymentMethods: action.payload
            };
        case MY_AMOUNTS.SUCCESS:
            return {
                ...state,
                myAmounts: action.payload
            };
        case SET_UPDATE_REQUEST_PLAN_DETAILS.SUCCESS:
            return {
                ...state,
                planDetails: action.payload
            };
        case SET_UPDATE_REQUEST_PLAN_DETAILS.EMPTY:
            return {
                ...state,
                planDetails: {}
            };
        case SET_UPDATE_REQUEST_FEATURES.SUCCESS:
            return {
                ...state,
                features: action.payload
            };
        case SET_UPDATE_REQUEST_FEATURES.EMPTY:
            return {
                ...state,
                features: {}
            };
        case SET_UPDATE_CHANGE_SERVICE.SUCCESS:
            return {
                ...state,
                updatedServiceInfo: action.payload
            }
        case SET_UPDATE_CHANGE_SERVICE.EMPTY:
            return {
                ...state,
                updatedServiceInfo: {}
            }
        case AUTOPAY.EMPTY:
            return {
                ...state,
                myAutoPayInfo: {}
            };
        case AUTOPAY.SUCCESS:
            return {
                ...state,
                myAutoPayInfo: action.payload
            };
        case AUTOPAY_AVAILABLE_CC.EMPTY:
            return {
                ...state,
                myAutoPayAvailableCC: {}
            };
        case AUTOPAY_AVAILABLE_CC.SUCCESS:
            return {
                ...state,
                myAutoPayAvailableCC: action.payload
            };
        case GET_ACCOUNT_TYPE_DISTRIBUTION_METHODS.SUCCESS:
            return {
                ...state,
                myDistributionMethods: action.payload
            }
        case GET_ACCOUNT_DISTRIBUTION_METHOD.SUCCESS:
        // case UPDATE_ACCOUNT_DISTRIBUTION_METHOD.SUCCESS:
            return {
                ...state,
                myCurrentDistributionMethod: action.payload
            }
        default:
            return state
    }
}
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, Formik} from 'formik';
import {
	clearNoServiceAccountError,
	createNewOrder,
	createNoServiceAccount,
	emptyGuestOrder,
	emptyPlanDetails,
	getCheckAvailability,
	getPlanDetails
} from '../../actions/guestOrderActions';
import {createLoadingSelector, selectErrorMessage, selectGuestOrder, selectLanguage} from '../../selectors';
import {validationSchema} from './validation';
import {generateId, isEmpty} from '../../utils/helpers';

import GuestLayout from '../Layout/GuestLayout';
import IframeAddress from './IframeAddress';
import StepContent from './StepContent';

let defaultFormValues = {
    street: '',
    unit: '',
    zipcode: '',
    formattedAddress: '',
    baseOrderId: null,
    account: {
        accountHolder: {
            accountType: window.guestOrderConfig.residentialAccountTypeId,
            firstName: '',
            lastName: '',
            personalEmail: '',
            serviceAddress: '',
            mobileNumber: {
                number: '',
                verified: false
            }
        },
        accountTermsInfo: {
            accountTerm: '',
            billCycle: window.guestOrderConfig.residentialDefaultBillcycle
        },
        initialStatus: '',
        locationId: window.guestOrderConfig.defaultLocationId,
        termsAndCondition: false
    },
    order: {
        name: 'NEW_ORDER_' + generateId(),
        source: 'PORTAL',
        note: '',
        accountId: '',
        address: '',
        plans: []
    },
};

export class index extends Component {
    static propTypes = {};

    state = {
        activeStep: 'check-availability',
        planDetailsError: '',
        iframeAddressUrl: '',
        iframeLoader: false,
        initialFormValues: defaultFormValues
    };


    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
        const {emptyGuestOrder} = this.props;
        emptyGuestOrder();
    }

    populateServiceAndLines = (values, setFieldValue) => {
        const {getPlanDetails} = this.props;

        values.order.plans.forEach(async (element) => {
            let response = await getPlanDetails(values.account.locationId, element.planId);
            // This is hack - the response error format need to be changed
            if (response.success !== false) {
                let findIndex = values.order.plans.findIndex((x) => x.planId === response.id);
                let servicelines = [];

                for (let index = 0; index < 1; index++) {
                    let currentServiceLine = {};
                    if (
                        response.serviceModel.directorySystemNumType &&
                        response.serviceModel.directorySystemNumType.defaultSource === 'number_inventory'
                    ) {
                        currentServiceLine = {
                            features: [],

                            number: '',
                            serviceLineRelation: index === 0 ? 'Main' : 'Required',
                            index: index
                        };
                    } else {
                        currentServiceLine = {
                            features: [],

                            serviceLineRelation: index === 0 ? 'Main' : 'Required',
                            index: index
                        };
                    }

                    // ADD FEATURES TO THE LINES
                    if (currentServiceLine.serviceLineRelation === 'Main') {
                        if (!isEmpty(response.mainIncludedFeatures)) {
                            response.mainIncludedFeatures.forEach((element) => {
                                let feature = {
                                    ...element
                                };
                                currentServiceLine.features.push(feature);
                            });
                        }
                        if (!isEmpty(response.mainIncludedFeaturePackages)) {
                            response.mainIncludedFeaturePackages.forEach((element) => {
                                let feature = {
                                    featurePackageId: element.featurePackageId,
                                    featurePackageDescription: element.description,
                                    ...element.featurePackageOptions[0]
                                };

                                currentServiceLine.features.push(feature);
                            });
                        }
                        if (!isEmpty(response.mainMandatoryFeatures)) {
                            response.mainMandatoryFeatures.forEach((element) => {
                                let feature = {
                                    ...element
                                };
                                currentServiceLine.features.push(feature);
                            });
                        }
                        if (!isEmpty(response.mainMandatoryFeaturePackages)) {
                            response.mainMandatoryFeaturePackages.forEach((element) => {
                                let feature = {
                                    featurePackageId: element.featurePackageId,
                                    featurePackageDescription: element.description,
                                    ...element.featurePackageOptions[0]
                                };

                                currentServiceLine.features.push(feature);
                            });
                        }
                    }

                    servicelines.push(currentServiceLine);
                }

                setFieldValue(`order.plans.${findIndex}.index`, findIndex);
                setFieldValue(`order.plans.${findIndex}.servicelines`, servicelines);
            } else {
                this.setState({
                    planDetailsError: response.error.message
                });
            }
        });

        this.handleChangeStep('packages-and-products');
    };

    handleChangeStep = (step) => {
        this.setState({
            activeStep: step
        });
    };

    handleBackStep = (step, setFieldValue) => {
        this.props.emptyPlanDetails();
        setFieldValue('order.plans', []);
        this.setState({
            activeStep: step,
            planDetailsError: ''
        });
    };

    handleResetWizard = (resetForm, setTouched) => {
        const {initialFormValues} = this.state;
        //Reset initial form values, and wizard step

        this.props.emptyGuestOrder();
        resetForm({initialFormValues});
        setTouched({});

        this.setState({
            activeStep: 'check-availability',
            iframeAddressUrl: '',
            iframeLoader: false,
            planDetailsError: ''
        });
    };

    handleHideIframeLoader = () => {
        this.setState({
            iframeLoader: false
        });
    };

    handleCheckAvailabilityStep = async (formattedAddress, setFieldValue) => {
        let data = {
            address: formattedAddress
        };

        const result = await this.props.getCheckAvailability(data, window.guestOrderConfig.defaultLocationId);
        if (result) {
            setFieldValue('formattedAddress', result.validatedAddress.fullAddress);
            if (result.validatedAddress.allowedServiceOrderType) {
                setFieldValue('order.address', result.validatedAddress.fullAddress);
                if (result.validatedAddress.allowedServiceOrderType === 'NEW_SIGNUP') {
                    this.setState({
                        iframeAddressUrl: result.validatedAddress.embedUrl,
                        iframeLoader: true
                    });

                    setFieldValue('account.initialStatus', 'SIGNUP');
                    this.handleChangeStep('new-signup');
                } else if (result.validatedAddress.allowedServiceOrderType === 'NEW_SURVEY') {
                    this.setState({
                        iframeAddressUrl: result.validatedAddress.embedUrl,
                        iframeLoader: true
                    });

                    setFieldValue('account.initialStatus', 'SURVEY');
                    this.handleChangeStep('new-signup');
                }
            } else {
                setFieldValue(
                    'account.accountHolder.serviceAddress',
                    result.validatedAddress.formattedAddress
                );
                setFieldValue('account.initialStatus', 'PROSPECT');
                this.handleChangeStep('not-available-service');
            }
        }
    };

    handleNotAvailableServiceStep = async (values, actions) => {
        const result = await this.props.createNoServiceAccount(values.account);

        if (result) {
            this.handleChangeStep('no-service-confirmation');
        }
    };

    handleCreateOrder = async (values, actions) => {
        const {createNewOrder} = this.props;

        let orderData = {
            ...values.order,
			baseOrderId: values.baseOrderId
        };
        let orderType = values.account.initialStatus;

        const createOrder = await createNewOrder(orderData, orderType);

        if (createOrder) {
            this.handleChangeStep('confirmation');
        }
    };

    handleOnSubmit = (values, actions) => {
        const {activeStep} = this.state;

        if (activeStep === 'check-availability') {
            this.handleCheckAvailabilityStep(values, actions);
        } else if (activeStep === 'not-available-service') {
            this.handleNotAvailableServiceStep(values, actions);
        } else if (activeStep === 'packages-and-products') {
            this.handleCreateOrder(values, actions);
        }
    };

    render() {
        const {
            initialFormValues,
            isFormReseting,
            activeStep,
            iframeAddressUrl,
            iframeLoader,
            planDetailsError,
        } = this.state;
        const {
            availablePlansData,
            availablePlansLoader,
            availablePlansError,
            noServiceAccountLoader,
            noServiceAccountError,
            newOrderInfoData,
            plansDetailsData,
            createAccountAndOrderLoader,
            createAccountAndOrderError
        } = this.props;

        return (
            <GuestLayout>
                <div className="cp-page cp-page-guest">
                    <div className="cp-container-maps">
                        <IframeAddress
                            iframeAddressUrl={iframeAddressUrl}
                            iframeLoader={iframeLoader}
                            handleHideIframeLoader={this.handleHideIframeLoader}
                        />

                        <div className="cp-container-maps-overlay">
                            <div className="container">
                                <div className="card-guest">
                                    <div className="card-guest-body">
                                        <Formik
                                            initialValues={initialFormValues}
                                            validationSchema={validationSchema(activeStep)}
                                            onSubmit={this.handleOnSubmit}
                                            enableReinitialize={true}
                                        >
                                            {(formProps) => (
                                                <Form
                                                    onSubmit={formProps.handleSubmit}
                                                    className="cp-form"
                                                    autoComplete="off"
                                                    noValidate
                                                >
                                                    <div className="form-sections-wrapper">
                                                        <StepContent
                                                            formProps={formProps}
                                                            activeStep={activeStep}
                                                            planDetailsError={planDetailsError}
                                                            handleResetWizard={this.handleResetWizard}
                                                            handleChangeStep={this.handleChangeStep}
                                                            populateServiceAndLines={this.populateServiceAndLines}
                                                            handleBackStep={this.handleBackStep}
                                                            handleCheckAvailabilityStep={this.handleCheckAvailabilityStep}
                                                            {...this.props}
                                                        />
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </GuestLayout>
        );
    }
}

const getAvailablePlansLoader = createLoadingSelector(['CHECK_AVAILABLE_PLANS']);
const getAvailablePlansError = selectErrorMessage(['CHECK_AVAILABLE_PLANS']);
const getCreateNoServiceAccountLoader = createLoadingSelector(['CREATE_NO_SERVICE_ACCOUNT']);
const getCreateNoServiceAccoountError = selectErrorMessage(['CREATE_NO_SERVICE_ACCOUNT']);
const getCreateAccountAndOrderLoader = createLoadingSelector(['CREATE_ACCOUNT', 'CREATE_ORDER']);
const getCreateAccountAndOrderError = selectErrorMessage(['CREATE_ACCOUNT', 'CREATE_ORDER']);

const mapStateToProps = (state) => {
    const lang = selectLanguage(state),
        availablePlansData = selectGuestOrder(state).availablePlans,
        noServiceAccountInfoData = selectGuestOrder(state).noServiceAccountInfo,
        newAccountInfoData = selectGuestOrder(state).newAccountInfo,
        newOrderInfoData = selectGuestOrder(state).newOrderInfo,
        plansDetailsData = selectGuestOrder(state).plansDetails,
        availablePlansLoader = getAvailablePlansLoader(state),
        noServiceAccountLoader = getCreateNoServiceAccountLoader(state),
        createAccountAndOrderLoader = getCreateAccountAndOrderLoader(state),
        createAccountAndOrderError = getCreateAccountAndOrderError(state),
        noServiceAccountError = getCreateNoServiceAccoountError(state),
        availablePlansError = getAvailablePlansError(state);

    return {
        availablePlansData,
        availablePlansLoader,
        availablePlansError,
        noServiceAccountInfoData,
        noServiceAccountLoader,
        noServiceAccountError,
        newAccountInfoData,
        plansDetailsData,
        newOrderInfoData,
        createAccountAndOrderLoader,
        createAccountAndOrderError
    };
};

const mapDispatchToProps = {
    getCheckAvailability,
    createNoServiceAccount,
    emptyGuestOrder,
    getPlanDetails,
    createNewOrder,
    emptyPlanDetails,
    clearNoServiceAccountError
};

export default connect(mapStateToProps, mapDispatchToProps)(index);

import React, {Component} from 'react';
import {Formik, Form} from 'formik';
import {connect} from 'react-redux';
import {verifyEmail} from '../../actions/authActions';
import GuestLayout from "../Layout/GuestLayout";
import {getErrorMessage, trans} from "../../utils/helpers";

class VerifyEmail extends Component {
    state = {
        error: '',
        title: '',
        message: '',
        titleCompleted:'',
        messageCompleted:'',
        requestStarted: false
    };

    componentDidMount(){
        (async() => {
			const title= await trans('en-us', 'verifyEmailScreen.titleInProgress');
			const message= await trans('en-us', 'verifyEmailScreen.messageInProgress');
			const titleCompleted= await trans('en-us', 'verifyEmailScreen.titleCompleted');
			const messageCompleted= await trans('en-us', 'verifyEmailScreen.messageCompleted');
				
			this.setState({title,message,titleCompleted,messageCompleted});
		})()
    }
    startVerification = () => {
        const verificationToken = this.props.location.search.substring(7);
        this.props.verifyEmail(verificationToken).then((response) => {
            if (response.success === true) {
                this.setState({
                    error: false,
                    title: this.state.titleCompleted,
                    message: this.state.messageCompleted,
                });
            } else {
                const errorMessage = getErrorMessage({data: response}, 'en-us');
                this.setState({
                    error: true,
                    message: errorMessage.message,
                });
            }
        });
    }

    render() {
        const {error, requestStarted} = this.state;

        if (!requestStarted) {
            this.setState({requestStarted: true}, (state) => {
                this.startVerification();
            });
        }

        return (
            <GuestLayout>
                <div className="cp-page cp-page-login justify-content-start">
                    <div className="card-guest card-full-height">

                        <div className="message">

                            <div className={"message-left" + (error === true ? ' text-danger' : (error === false ? '' : ' text-muted'))}>
                                <i className={"fas fa-" + (error === true ? 'times' : (error === false ? 'check' : 'spinner fa-spin'))}></i>
                            </div>

                            <div className="message-right">
                                <h4 className={(error === true ? ' text-danger' : (error === false ? '' : ' text-muted'))}>{this.state.title}</h4>
                                <p>{this.state.message}</p>
                            </div>

                        </div>

                    </div>
                </div>
            </GuestLayout>
        );
    }
}

VerifyEmail.propTypes = {};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
    verifyEmail
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);

import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {selectAuth, selectErrorMessage} from "../../selectors";
import {impersonateUser, logout} from "../../actions/authActions";
import {toastr} from "react-redux-toastr";

class ImpersonateUser extends Component {
    static propTypes = {
        isLoggedIn: PropTypes.bool.isRequired,
        logout: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired
    };

    componentDidMount() {
        const {history, impersonateUser, location} = this.props;
        const userId = this.props.match.params.userId;
        const apiToken = decodeURIComponent(new URLSearchParams(location.search).get("apiToken"));

        impersonateUser(userId, apiToken, history).then(response => {

            if (response && response.status && response.status !== 200) {
                const {impersonateError} = this.props;

                toastr.error(impersonateError, {
                    disableTimeOut: true,
                    timeOut: 0,
                    extendedTimeOut: 0,
                    position: 'top-center',
                    onCloseButtonClick: () => this.handleImpersonateError()
                });
            }
        }).catch(err => {

            toastr.error('There is an issue, try again in a few min', {
                disableTimeOut: true,
                timeOut: 0,
                extendedTimeOut: 0,
                position: 'top-center',
                onCloseButtonClick: () => this.handleImpersonateError()
            });
        });
    }

    handleImpersonateError = () => {
        this.props.history.push('/login');
    }

    render() {
        return false;
    }

}

const getImpersonateError = selectErrorMessage(['IMPERSONATE_USER']);

const mapStateToProps = (state) => {
    const isLoggedIn = selectAuth(state).isLoggedIn,
    impersonateError = getImpersonateError(state)

    return {
        isLoggedIn,
        impersonateError
    };
};

const mapDispatchToProps = {
    impersonateUser,
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(ImpersonateUser);
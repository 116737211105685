import React, {useEffect, useRef, useState} from 'react'
import { Form, Formik, Field } from "formik";
import { number, object, string, ref } from 'yup';
import Button from "../../../components/common/Buttons/Button";
import {isEmpty} from "lodash";
import axiosInstance from "../../../utils/axios-instance";


const Profile = ({handleShowHideChangePassword}) => {

    const [changePasswordAPIMessage, setChangePasswordAPIMessage] = useState({message: '', color: ''})
    const {password_requirement_reg_expr, password_requirement_desc} = window


    let changePasswordSchema = object().shape({
        currentPassword: string()
            .required('Required'),
        newPassword: string()
            .required('Required')
            .matches(password_requirement_reg_expr,
            password_requirement_desc),
        confirmedNewPassword: string()
            .oneOf([ ref('newPassword') ], 'Passwords do not match')
            .required('Required')
    });



    const handleOnSubmit = async (values, event ) => {

        if(changePasswordSchema.isValid){
            const response =  await axiosInstance.post(`/user/changepassword`,
                {currentPassword: values.currentPassword, newPassword: values.newPassword});

            if(response.data.context){
                setChangePasswordAPIMessage({message: response.data.context, color: 'green'})
                event.resetForm()
            } else {
                setChangePasswordAPIMessage({message: response.data.error.message, color: 'red'})
            }
        }
    }


    return (

        <div className="form-section">
            <div className='needs-validation cp-form'>
            <h4>Change Password</h4>
                <div className='form-divider' />
                <Formik
                    initialValues={{
                        currentPassword: '',
                        newPassword: '',
                        confirmedNewPassword: ''
                    }}
                    validationSchema={changePasswordSchema}
                    onSubmit={handleOnSubmit}
                    enableReinitialize={true}
                >
                    {({
                          handleChange,
                          handleSubmit,
                          handleBlur,
                          values,
                          errors,
                          touched,
                          resetForm,
                      }) => (
                        <Form onSubmit={(event) => handleSubmit(event)}>
                            <div className="form-section fx-fadein">
                            <div className='form-group'>
                            <label>Current Password</label>
                                <div className='input-group'>
                                    <input
                                        name="currentPassword"
                                        type="password"
                                        value={values.currentPassword}
                                        className='form-control'
                                        errors={errors}
                                        touched={touched}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </div>
                                {errors.currentPassword  && touched.currentPassword &&
                                    <p style={{color:'red'}}>{errors.currentPassword}</p>
                                }
                                {}
                            </div>
                            <div className='form-group'>
                            <label>New Password</label>
                                <div className='input-group'>
                                    <input
                                        name="newPassword"
                                        type="password"
                                        value={values.newPassword}
                                        className='form-control'
                                        errors={errors}
                                        touched={touched}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </div>
                                {errors.newPassword && touched.newPassword &&
                                    <p style={{color:'red'}}>{errors.newPassword}</p>
                                }
                            </div>
                            <div className='form-group'>
                            <label>Confirm Password</label>
                                <div className='input-group'>
                                    <input
                                        name="confirmedNewPassword"
                                        type="password"
                                        value={values.confirmedNewPassword}
                                        className='form-control'
                                        errors={errors}
                                        touched={touched}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </div>
                                {errors.confirmedNewPassword  && touched.confirmedNewPassword &&
                                    <p style={{color:'red'}}>{errors.confirmedNewPassword}</p>
                                }
                                {!isEmpty(changePasswordAPIMessage) &&
                                    <p style={{color:changePasswordAPIMessage.color}}>{changePasswordAPIMessage.message}</p>
                                }
                            </div>
                            <button className='btn btn-quick' type="button"
                                    onClick={() => {
                                handleShowHideChangePassword(resetForm);
                                setChangePasswordAPIMessage({...changePasswordAPIMessage, message: '', color: ''})
                                    }}>CANCEL</button>
                                <Button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={!isEmpty(errors)}
                                >CHANGE PASSWORD
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )

}

export default Profile
import React, { Component } from 'react';
import {getAccountAutoPayStatus, getPaymentProviders, selectHomeInfo} from "../../../selectors";
import {getMyAutoPayInfo, enableAutoPayACH, enableAutoPayCC, disableAutoPay, getAutoPayAvailableCC, enableAutoPayTokenPaymentMethod, getMyPaymentMethods} from "../../../actions/homeInfoActions";
import {getCurrentPaymentProviderId, getCurrentPaymentProviderSupportsPaymentMethodStorage} from "../../../actions/paymentProviderActions";
import { connect } from "react-redux";
import { Form, Formik, Field } from "formik";
import {toastr} from "react-redux-toastr";
import Loader from "react-spinners/BeatLoader";
import isEmpty from "../../../utils/helpers";
import * as Yup from "yup";
import {getUserPermission} from "../../../selectors";
import {getAccountAutoPay} from "../../../actions/autoPayActions";

function noEmptyString() {
    return this.transform((value, originalValue) => originalValue.trim().length === 0 ? NaN : value);
}

function noSpaces() {
    return this.transform((value, originalValue) => /\s/.test(originalValue) ? NaN : value);
}

Yup.addMethod(Yup.string, 'noEmptyString', noEmptyString);
Yup.addMethod(Yup.number, 'noSpaces', noSpaces);

const ccSchema = Yup.object().shape({
    cc: Yup.object().required('Required')
});

const achSchema = Yup.object().shape({
    cardHolderName: Yup.string().noEmptyString().typeError('Required').required('Required'),
    routingNumber: Yup.number().noSpaces().typeError('Please enter correct number.').positive('Please enter correct number.').required('Required'),
    accountNumber: Yup.number().noSpaces().typeError('Please enter correct number.').positive('Please enter correct number.').required('Required'),
    accountType: Yup.string().required('Required')
});

const tokenPayMethodSchema = Yup.object().shape({
    token: Yup.string().required('Required')
});

const ACCOUNT_TYPE = {
    CHECKING: 'CHECKING',
    SAVINGS: 'SAVINGS',
    BUSINESS_CHECK: 'BUSINESS_CHECK'
}

export class AutoPay extends Component {

    state = {
        myAutoPayInfoLoading: false,
        autopayUpdated: false,
        autopayActive: !isEmpty(this.props.myAutoPayInfo) || this.props.myAutoPayWarning,
        ccActive: (this.props.myAutoPayInfo && this.props.myAutoPayInfo.apiAccountAutoPayCC) || false,
        achActive: (this.props.myAutoPayInfo && this.props.myAutoPayInfo.apiAccountAutoPayACH) || false,
        tokenPayMethodActive: (this.props.myAutoPayInfo && this.props.myAutoPayInfo.apiAccountAutoPayToken) || false,
        payProviderSupportsPaymentMethodStorage: false,
    }

    componentDidMount() {
        this.props.getCurrentPaymentProviderId();
        this.props.getCurrentPaymentProviderSupportsPaymentMethodStorage().then(resp => {
            this.setState({payProviderSupportsPaymentMethodStorage: resp.data.result});
        });
        this.props.getAutoPayAvailableCC(this.props.accountInfo.id);
    }

    componentWillUnmount() {
        if (this.state.payProviderSupportsPaymentMethodStorage) {
            this.props.getMyPaymentMethods(this.props.accountInfo.id);
        }
    }

    handleEnableAutoPay = (props) => {
        if ((this.props.myAutoPayInfo && Object.keys(this.props.myAutoPayInfo).length !== 0) || this.props.myAutoPayWarning ) {

            toastr.confirm("Are you sure you want to disable Autopay?", {
                cancelText: "Cancel",
                okText: "Confirm",
                onOk: () => {
                    this.setState({myAutoPayInfoLoading: 'enable'});

                    this.props.disableAutoPay(props.values).then(response => {
                        this.props.getAccountAutoPay(this.props.accountInfo.id)
                        this.setState({
                            autopayUpdated: false,
                            myAutoPayInfoLoading: false,
                            autopayActive: false,
                            ccActive: false,
                            achActive: false,
                            tokenPayMethodActive: false,
                        });
                    });
                },
                onCancel: () => {
                }
            });
        }
        else {
            if (this.state.payProviderSupportsPaymentMethodStorage) {
                this.setState((prevState) => ({
                    autopayActive: !prevState.autopayActive,
                    ccActive: false,
                    achActive: false,
                    tokenPayMethodActive: prevState.autopayActive === true ? false : true
                }));
                this.props.getAccountAutoPay(this.props.accountInfo.id)
            } else {
                this.setState((prevState) => ({
                    autopayActive: !prevState.autopayActive,
                    ccActive: prevState.autopayActive === true ? false : true,
                    achActive: false,
                    tokenPayMethodActive: false
                }));
                this.props.getAccountAutoPay(this.props.accountInfo.id)
            }
        }
    }

    handleCCSubmit = (values) => {
        this.setState({myAutoPayInfoLoading: 'card'});

        this.props.enableAutoPayCC(values).then(response => {
            this.setState({autopayUpdated: true, myAutoPayInfoLoading: 'info'}, () => {

                this.props.getMyAutoPayInfo(this.props.accountInfo.id).then(response => {
                    this.setState({myAutoPayInfoLoading: false});
                });
            });
        });
    }

    handleACHSubmit = (values) => {
        this.setState({myAutoPayInfoLoading: 'ach'});
        this.props.enableAutoPayACH(values).then(response => {
            this.setState({autopayUpdated: true, myAutoPayInfoLoading: 'info'}, () => {

                this.props.getMyAutoPayInfo(this.props.accountInfo.id).then(response => {
                    this.setState({myAutoPayInfoLoading: false});
                });
            });
        });
    }

    handleTokenPayMethodSubmit = (values) => {
        if (values.token !== "") {
            this.setState({myAutoPayInfoLoading: 'paymentMethod'});
            this.props.enableAutoPayTokenPaymentMethod(values).then(response =>{
                this.setState({autopayUpdated: true, myAutoPayInfoLoading: 'info'}, () => {
                    this.props.getAccountAutoPay(this.props.accountInfo.id);
                    this.props.getMyAutoPayInfo(this.props.accountInfo.id).then(response => {
                        this.setState({myAutoPayInfoLoading: false});
                    });
                });
            });
        } else {
            alert("Select payment method first!")
        }
    }

    handlePaymentMethodChange = (e, setFieldValue, myAutoPayAvailableCC) => {
        if (e.target.value === "MANAGE_PAYMENT_METHODS") {
            this.props.togglePanel(4)
            // Wait for animation to finish
            setFieldValue('token', "");
            setTimeout(() => {
                // Open Payment Methods Panel
                this.props.togglePanel(8)
            }, 300);
        } else {
            setFieldValue('token', myAutoPayAvailableCC[parseInt(e.target.value)].cardToken)
        }
    }

    dateconverter =(date)=>{
        const datearr=date.split('-');
        const monthlist={
            '01':'Jan',
            '02':'Feb',
            '03':'Mar',
            '04':'Apr',
            '05':'May',
            '06':'Jun',
            '07':'Jul',
            '08':'Aug',
            '09':'Sep',
            '10':'Act',
            '11':'Nov',
            '12':'Dec'
        }

        return `${monthlist[datearr[1]]} ${datearr[2]}, ${datearr[0]}`
    }

    render() {
        const {
            autopayActive,
            myAutoPayInfoLoading,
            autopayUpdated,
            achActive,
            ccActive,
            tokenPayMethodActive,
        } = this.state;

        const {
            accountInfo,
            myAutoPayInfo,
            myAutoPayAvailableCC,
            currentProviderId,
            canManagePaymentMethods,
            myAutoPayWarning
        } = this.props;       
        if (!currentProviderId) {
            return <div className="form-section">
                <h4>Autopay</h4>
                <p className="form-divider">
                    Autopay is Inactive.
                </p>
            </div>
        }

        console.log(myAutoPayInfo && myAutoPayInfo && Object.keys(myAutoPayInfo).length !== 0)

        return (
            <div className="cp-form cp-form-autopay">

                <div className="form-section">
                    <h4>Autopay</h4>
                    <p className="form-divider">
                        {myAutoPayInfoLoading
                            ? <span>&nbsp;</span>
                            : myAutoPayWarning ? 'Autopay is enabled.' : myAutoPayInfo && myAutoPayInfo && Object.keys(myAutoPayInfo).length !== 0
                                ?`Autopay is enabled${this.props.accountBalace>0?`, and scheduled for ${this.dateconverter(myAutoPayInfo.appliesOn)}`:''}`
                                : "Autopay is disabled."
                        }
                    </p>
                </div>

                <div className="form-group">
                    <Formik
                        initialValues={{
                            accountId: accountInfo.id
                        }}
                        onSubmit={this.handleSubmit}
                    >
                        {props => (
                            <Form>
                                <div className="custom-switch-with-button">
                                    <div className="custom-control custom-switch">
                                        <input
                                            className="custom-control-input"
                                            disabled={myAutoPayInfoLoading ? 'disabled' : false}
                                            checked={autopayActive}
                                            name="autopayActive"
                                            id="autopayActive"
                                            type="checkbox"
                                            onClick={() => this.handleEnableAutoPay(props)}
                                        />
                                        <label className="custom-control-label custom-control-label-opaque d-block" htmlFor="autopayActive">
                                            Enable Autopay
                                            {myAutoPayInfoLoading === 'enable' && <Loader css={'float: right'} size={5} color={'#258C9F'}/>}
                                        </label>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>

                {myAutoPayWarning && <span className='text-danger'>There is an issue with your AutoPay payment method. Please select a valid payment method.</span>}

                {autopayActive &&
                <hr/>
                }

                {autopayActive && this.state.payProviderSupportsPaymentMethodStorage &&

                <div className="form-group">
                    Payment Methods
                    <p className="text-muted text-xs mb-0">
                        Choose from existing payment methods.
                    </p>
                </div>
                }

                {autopayActive && !this.state.payProviderSupportsPaymentMethodStorage &&
                <div className="form-group">
                    <div className="custom-switch-with-button">
                        <div className="custom-control custom-switch">
                            <input
                                className="custom-control-input"
                                disabled={myAutoPayInfoLoading || ccActive ? 'disabled' : false}
                                value={ccActive}
                                checked={ccActive}
                                name="card"
                                id="card"
                                type="checkbox"
                                onChange={(event) => {
                                    this.setState({ccActive: event.target.checked});

                                    if (event.target.checked === true) {
                                        this.setState({
                                            autopayActive: true,
                                            achActive: false,
                                            autopayUpdated: false,
                                            tokenPayMethodActive: false
                                        });
                                    }
                                }}
                            />
                            <label className="custom-control-label custom-control-label-opaque d-block" htmlFor="card">
                                Debit / Credit Card
                                {myAutoPayInfoLoading === 'card' &&
                                <Loader css={'float: right'} size={5} color={'#258C9F'}/>}
                                <p className="text-muted text-xs mb-0">
                                    {isEmpty(myAutoPayAvailableCC)
                                    ? "Please make a payment to enable Autopay."
                                    : "Choose from existing debit / credit cards."
                                    }
                                </p>
                            </label>
                        </div>
                    </div>
                </div>
                }

                {autopayActive && this.state.payProviderSupportsPaymentMethodStorage &&
                <div className="form-group">
                    <Formik
                        initialValues={{
                            accountId: accountInfo.id,
                            tokenPaymentMethod: '',
                            token: ''
                        }}
                        validationSchema={tokenPayMethodSchema}
                        validateOnChange={true}
                        onSubmit={(values) => {
                            this.handleTokenPayMethodSubmit(values);
                        }}
                        render={(props) => {
                            const {values, touched, errors, setFieldValue} = props;

                            return (
                                <Form className="mb-5">
                                    <div className="cp-table-primary form-group">
                                        <div className="row-expand">
                                            <div>
                                                <div className="cp-datarow-container mr-3 ml-3">
                                                    <div className="cp-datarow-wrapper">

                                                        {myAutoPayInfoLoading === 'info' && <Loader size={5} color={'#258C9F'}/>}

                                                        {myAutoPayInfo && myAutoPayInfo.apiAccountAutoPayToken && myAutoPayInfo.apiAccountAutoPayToken.paymentInformation && <>
                                                            <div className="mb-1 clearfix">
                                                                <h6 className="float-left">Current Payment Method</h6>
                                                            </div>
                                                            {myAutoPayInfo.apiAccountAutoPayToken.paymentInformation && !myAutoPayInfo.apiAccountAutoPayToken.paymentInformation.includes("null") &&
                                                            <div className="mb-1 clearfix">
                                                                <h6 className="float-left">{myAutoPayInfo.apiAccountAutoPayToken.paymentInformation}</h6>
                                                            </div>
                                                            }
                                                            {!autopayUpdated &&
                                                            <hr/>
                                                            }
                                                        </>}

                                                        {(!autopayUpdated || (myAutoPayInfo && !myAutoPayInfo.apiAccountAutoPayToken)) && <>
                                                            <label>Select Payment Method</label>
                                                            <div className="form-group">
                                                                <select
                                                                    id="token"
                                                                    name="token"
                                                                    className={"form-control" + (errors.cc ? " is-invalid" : "")}
                                                                    disabled={myAutoPayInfoLoading && 'disabled'}
                                                                    onChange={(e) =>
                                                                        this.handlePaymentMethodChange(
                                                                            e,
                                                                            setFieldValue,
                                                                            myAutoPayAvailableCC
                                                                        )}
                                                                >
                                                                    <option selected disabled>Select payment method...</option>
                                                                    {myAutoPayAvailableCC.map((payMethod, index) => (
                                                                        <option value={index}>{payMethod.paymentInformation}{payMethod.autoPay ? ' (Autopay)' : ''}</option>
                                                                    ))};
                                                                    {canManagePaymentMethods &&
                                                                        <option value="MANAGE_PAYMENT_METHODS" key="MANAGE_PAYMENT_METHODS">Manage Payment Methods</option>
                                                                    }
                                                                </select>
                                                                {touched.token && errors.token &&
                                                                <div className="invalid-feedback">{errors.token}</div>
                                                                }
                                                            </div>
                                                        </> }


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {(!autopayUpdated || (myAutoPayInfo && !myAutoPayInfo.apiAccountAutoPayCC)) &&
                                    <div className="form-group text-right">
                                        <div className="btn-group-sm">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                disabled={myAutoPayInfoLoading && 'disabled'}
                                            >
                                                Save Changes
                                            </button>
                                        </div>

                                    </div>
                                    }

                                </Form>
                            )
                        }}
                    />
                </div>
                }

                {ccActive && !isEmpty(myAutoPayAvailableCC) && !this.state.payProviderSupportsPaymentMethodStorage &&
                <div className="form-group">
                    <Formik
                        initialValues={{
                            accountId: accountInfo.id,
                            cc: '',
                            type: '',
                            cardToken: '',
                            cardExpirationDate: '',
                            displayCardNumber: ''
                        }}
                        validationSchema={ccSchema}
                        validateOnChange={true}
                        onSubmit={(values) => {
                            this.handleCCSubmit(values);
                        }}
                        render={(props) => {
                            const {values, touched, errors, setFieldValue} = props;

                            return (
                                <Form className="mb-5">
                                    <div className="cp-table-primary form-group">
                                        <div className="row-expand">
                                            <div>
                                                <div className="cp-datarow-container mr-3 ml-3">
                                                    <div className="cp-datarow-wrapper">

                                                        {myAutoPayInfoLoading === 'info' && <Loader size={5} color={'#258C9F'}/>}

                                                        {myAutoPayInfo && myAutoPayInfo.apiAccountAutoPayCC && myAutoPayInfo.apiAccountAutoPayCC.displayCardNumber  && <>
                                                        <div className="order-label">Details</div>
                                                        <div className="cp-datarow-row">
                                                            <div className="cp-datarow-label">Current Card</div>
                                                            <div className="cp-datarow-value">{myAutoPayInfo.apiAccountAutoPayCC.displayCardNumber}</div>
                                                        </div>
                                                            {myAutoPayInfo.apiAccountAutoPayCC.cardExpirationDate && !myAutoPayInfo.apiAccountAutoPayCC.cardExpirationDate.includes("null") &&
                                                                <div className="mb-1 clearfix">
                                                                    <h6 className="float-left">Expiration Date</h6>
                                                                    <div
                                                                        className="float-right">{myAutoPayInfo.apiAccountAutoPayCC.cardExpirationDate}</div>
                                                                </div>
                                                            }
                                                        </> }

                                                        {(!autopayUpdated || (myAutoPayInfo && !myAutoPayInfo.apiAccountAutoPayCC)) && <>
                                                        <div className="order-label">New Card</div>

                                                        <div className="form-group">
                                                            <select
                                                                id="cc"
                                                                name="cc"
                                                                className={"form-control form-control-sm" + (touched.cc && errors.cc ? " is-invalid" : "")}
                                                                disabled={myAutoPayInfoLoading && 'disabled'}
                                                                onChange={(e) => {
                                                                    setFieldValue('cc', myAutoPayAvailableCC[parseInt(e.target.value)]);
                                                                    setFieldValue('type', myAutoPayAvailableCC[parseInt(e.target.value)].type);
                                                                    setFieldValue('cardToken', myAutoPayAvailableCC[parseInt(e.target.value)].cardToken)
                                                                    setFieldValue('cardExpirationDate', myAutoPayAvailableCC[parseInt(e.target.value)].cardExpirationDate);
                                                                    setFieldValue('displayCardNumber', myAutoPayAvailableCC[parseInt(e.target.value)].displayCardNumber);
                                                                }}
                                                            >
                                                                <option selected disabled>Select card...</option>
                                                                {myAutoPayAvailableCC.map((cc, index) => (cc.cardExpirationDate && (!cc.cardExpirationDate.includes("null")) ?
                                                                    <option value={index}>{cc.displayCardNumber}  - expires {cc.cardExpirationDate}</option>
                                                                    : <option value={index}>{cc.displayCardNumber}</option>)
                                                                )};
                                                            </select>
                                                            {touched.cc && errors.cc &&
                                                                <div className="invalid-feedback">{errors.cc}</div>
                                                            }
                                                        </div>
                                                        </> }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {(!autopayUpdated || (myAutoPayInfo && !myAutoPayInfo.apiAccountAutoPayCC)) &&
                                    <div className="form-group text-right">
                                        <div className="btn-group-sm">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                disabled={myAutoPayInfoLoading && 'disabled'}
                                            >
                                                Save Changes
                                            </button>
                                        </div>

                                    </div>
                                    }

                                </Form>
                            )
                        }}
                    />
                </div>
                }

                {ccActive && isEmpty(myAutoPayAvailableCC) && !this.state.payProviderSupportsPaymentMethodStorage &&

                    <div className="form-group text-right">
                        <button
                            className="btn btn-primary"
                            onClick={() => {

                                // Close AutoPay Panel
                                this.props.togglePanel(4)

                                // Wait for animation to finish
                                setTimeout(() => {

                                    // Open Payment Panel
                                    this.props.togglePanel(3)
                                }, 300)
                            }}
                        >
                            Make a Payment
                        </button>
                    </div>
                }

                {autopayActive && !this.state.payProviderSupportsPaymentMethodStorage &&
                <div className="form-group">
                    <div className="custom-switch-with-button">
                        <div className="custom-control custom-switch">
                            <input
                                className="custom-control-input"
                                disabled={myAutoPayInfoLoading || achActive ? 'disabled' : false}
                                value={achActive}
                                checked={achActive}
                                name="ach"
                                id="ach"
                                type="checkbox"
                                onChange={(event) => {
                                    this.setState({achActive: event.target.checked});

                                    if (event.target.checked === true) {
                                        this.setState({
                                            autopayActive: true,
                                            ccActive: false,
                                            autopayUpdated: false,
                                            tokenPayMethodActive: false
                                        });
                                    }
                                }}
                            />
                            <label className="custom-control-label custom-control-label-opaque d-block" htmlFor="ach">
                                New Checking / Saving Account
                                {myAutoPayInfoLoading === 'ach' &&
                                <Loader css={'float: right'} size={5} color={'#258C9F'}/>}
                                <p className="text-muted text-xs mb-0">Add new checking / saving account to use.</p>
                            </label>
                        </div>
                    </div>
                </div>
                }

                {achActive && !this.state.payProviderSupportsPaymentMethodStorage &&
                <div className="form-group">
                    <Formik
                        initialValues={{
                            routingNumber: '',
                            accountNumber: '',
                            accountId: accountInfo.id,
                            cardHolderName: '',
                            accountType: ''
                        }}
                        validationSchema={achSchema}
                        onSubmit={(values) => {
                            this.handleACHSubmit(values);
                        }}
                        render={(props) => {
                            const {values, touched, errors, setFieldValue, handleChange, handleBlur} = props;

                            return (
                                <Form className="mb-5">
                                    <div className="cp-table-primary form-group">
                                        <div className="row-expand">
                                            <div>
                                                <div className="cp-datarow-container mr-3 ml-3">
                                                    <div className="cp-datarow-wrapper">

                                                        {myAutoPayInfoLoading === 'info' && <Loader size={5} color={'#258C9F'}/>}

                                                        {myAutoPayInfo && myAutoPayInfo.apiAccountAutoPayACH && <>
                                                        <div className="order-label">Details</div>
                                                            <div className="cp-datarow-row">
                                                                <div className="cp-datarow-label">Account Holder Name</div>
                                                                <div className="cp-datarow-value">{myAutoPayInfo.apiAccountAutoPayACH.holder_name}</div>
                                                            </div>
                                                            <div className="cp-datarow-row">
                                                                <div className="cp-datarow-label">Current Routing #</div>
                                                                <div className="cp-datarow-value">{myAutoPayInfo.apiAccountAutoPayACH.routing_number}</div>
                                                            </div>
                                                            <div className="cp-datarow-row">
                                                                <div className="cp-datarow-label">Current Bank Account #</div>
                                                                <div className="cp-datarow-value">{myAutoPayInfo.apiAccountAutoPayACH.account_number}</div>
                                                            </div>
                                                            <div className="cp-datarow-row">
                                                                <div className="cp-datarow-label">Account Type</div>
                                                                <div className="cp-datarow-value">{myAutoPayInfo.apiAccountAutoPayACH.account_type}</div>
                                                            </div>
                                                        </> }

                                                        {(!autopayUpdated || (myAutoPayInfo && !myAutoPayInfo.apiAccountAutoPayACH)) && <>
                                                        <div className="order-label">New Account</div>

                                                        <div className="form-group">
                                                            <label>Account Holder Name</label>
                                                            <Field
                                                                type="text"
                                                                name="cardHolderName"
                                                                placeholder="Enter holder name"
                                                                value={values.cardHolderName}
                                                                className={"form-control form-control-sm" + (touched.cardHolderName && errors.cardHolderName ? " is-invalid" : "")}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                disabled={myAutoPayInfoLoading && 'disabled'}
                                                            />
                                                            {touched.cardHolderName && errors.cardHolderName &&
                                                                <div className="invalid-feedback">{errors.cardHolderName}</div>
                                                            }
                                                        </div>

                                                        <div className="form-group">
                                                            <label>Routing Number</label>
                                                            <Field
                                                                type="text"
                                                                name="routingNumber"
                                                                placeholder="Enter routing number"
                                                                value={values.routingNumber}
                                                                className={"form-control form-control-sm" + (touched.routingNumber && errors.routingNumber ? " is-invalid" : "")}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                disabled={myAutoPayInfoLoading && 'disabled'}
                                                            />
                                                            {touched.routingNumber && errors.routingNumber &&
                                                                <div className="invalid-feedback">{errors.routingNumber}</div>
                                                            }
                                                        </div>

                                                        <div className="form-group">
                                                            <label>Account Number</label>
                                                            <Field
                                                                type="text"
                                                                name="accountNumber"
                                                                placeholder="Enter account number"
                                                                value={values.accountNumber}
                                                                className={"form-control form-control-sm" + (touched.accountNumber && errors.accountNumber ? " is-invalid" : "")}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                disabled={myAutoPayInfoLoading && 'disabled'}
                                                            />
                                                            {touched.accountNumber && errors.accountNumber &&
                                                                <div className="invalid-feedback">{errors.accountNumber}</div>
                                                            }
                                                        </div>

                                                        <div className="form-group">
                                                            <label>Account Type</label>
                                                            <select
                                                                id="AchAccountType"
                                                                name="accountType"
                                                                className={"form-control form-control-sm" + (touched.accountType && errors.accountType ? " is-invalid" : "")}
                                                                onChange={(e) => setFieldValue('accountType', e.target.value)}
                                                                onBlur={handleBlur}
                                                                disabled={myAutoPayInfoLoading && 'disabled'}
                                                            >
                                                                <option value="" selected disabled>Select account type...</option>
                                                                <option value={ACCOUNT_TYPE.CHECKING}>Checking</option>
                                                                <option value={ACCOUNT_TYPE.SAVINGS}>Savings</option>
                                                                <option value={ACCOUNT_TYPE.BUSINESS_CHECK}>Business Check</option>
                                                            </select>
                                                            {touched.accountType && errors.accountType &&
                                                                <div className="invalid-feedback">{errors.accountType}</div>
                                                            }
                                                        </div>
                                                        </> }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {(!autopayUpdated || (myAutoPayInfo && !myAutoPayInfo.apiAccountAutoPayACH)) &&
                                    <div className="form-group text-right">
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            disabled={myAutoPayInfoLoading && 'disabled'}
                                        >
                                            Save Changes
                                        </button>
                                    </div>
                                    }

                                </Form>
                            )
                        }}
                    />
                </div>
                }

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const myAutoPayInfo = selectHomeInfo(state).myAutoPayInfo;
    const myAutoPayAvailableCC = selectHomeInfo(state).myAutoPayAvailableCC;
    const currentProviderId = getPaymentProviders(state).currentProviderId;
    const canManagePaymentMethods = getUserPermission(state, "API", "API_MANAGE_PAYMENT_METHODS");
    const myAutoPayStatus = getAccountAutoPayStatus(state);
    const myAutoPayWarning = getAccountAutoPayStatus(state).autoPayWarningMessage;

    return {
        myAutoPayInfo,
        myAutoPayAvailableCC,
        currentProviderId,
        canManagePaymentMethods,
        myAutoPayWarning,
        myAutoPayStatus
    };
};

const mapDispatchToProps = {
    getCurrentPaymentProviderId,
    getMyAutoPayInfo,
    enableAutoPayACH,
    enableAutoPayCC,
    disableAutoPay,
    getAutoPayAvailableCC: getAutoPayAvailableCC,
    enableAutoPayTokenPaymentMethod,
    getCurrentPaymentProviderSupportsPaymentMethodStorage: getCurrentPaymentProviderSupportsPaymentMethodStorage,
    getMyPaymentMethods,
    getAccountAutoPay
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AutoPay);
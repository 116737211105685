import React, { Component } from 'react';
import RoundedInput from '../../../components/common/Formik/RoundedInput';
import {get} from "lodash";
import {validateRegisterEmail} from "../../../actions/signUpActions";
import {connect} from "react-redux";
import {isEmpty, trans} from "../../../utils/helpers";
import ReactHtmlParser from "react-html-parser";

export class ChoosePasswordEmailSignUp extends Component {
	state = {
		invalidToken: false,
		invalidTokenMessage: '',
		choosepasswordtitle:'',
		termsAndCondition:''
	}

	componentDidMount() {
		(async() => {
			const choosepasswordtitle= await trans('en-us', 'registerScreen.choosePasswordSection.title');
			const termsAndCondition= await trans('en-us', 'registerScreen.choosePasswordSection.termsAndConditionsLink');
			this.setState({choosepasswordtitle,termsAndCondition});
		})()
		const {setFieldValue} = this.props.formProps;
		const registerToken = this.props.location.search.substring(7);

		this.props.validateRegisterEmail(registerToken).then((response) => {
			if (response.data.success === false) {
				this.setState({
					invalidToken: true,
					invalidTokenMessage: response.data.error.message
				});
			} else {
				setFieldValue('email', response.data.message);
			}
		});
	}

	render() {
		const { values, handleChange, handleBlur, errors, touched, isSubmitting } = this.props.formProps;
		const {invalidToken, invalidTokenMessage} = this.state;
		const {registrationErrorMessgage} = this.props;

		if (invalidToken === true) {
			return (
				<div className="form-section fx-from-right" id="fs4">
					<div className="message">
						<div className="message-left text-danger">
							<i className="fas fa-times"/>
						</div>
						<div className="message-right">
							<h4 className="text-danger">Error</h4>
							<p>{invalidTokenMessage}</p>
						</div>
					</div>
				</div>
			);
		}

		if (!isEmpty(registrationErrorMessgage)) {
			return (
				<div className="form-section fx-from-right" id="fs4">
					<div className="message">
						<div className="message-left text-danger">
							<i className="fas fa-times"/>
						</div>
						<div className="message-right">
							<h4 className="text-danger">Error</h4>
							<p>{registrationErrorMessgage}</p>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div class="form-section" id="fs4">
				<h3 class="text-center">{this.state.choosepasswordtitle}</h3>
				<div class="form-group">
					<label for="">Email</label>
					<RoundedInput name="email" value={values["email"]} type="text" isDisabled={true} />
				</div>
				<div class="form-group">
					<label for="">Password</label>
					<RoundedInput type="password" name="newPassword" value={values['newPassword']} onChange={handleChange} onBlur={handleBlur} errors={errors} touched={touched}/>
				</div>
				<div class="form-group">
					<label for="">Confirm Password</label>
					<RoundedInput type="password" name="repeatPassword" value={values['repeatPassword']} onChange={handleChange} onBlur={handleBlur} errors={errors} touched={touched}/>
				</div>
				<div className="form-group custom-checkbox">
					<input
						type="checkbox"
						className="custom-control-input"
						name="termsAndConditions"
						id="agree-tc"
						onChange={handleChange}
					/>
					<label className="custom-control-label" htmlFor="agree-tc">
						{ReactHtmlParser(
							this.state.termsAndCondition
						)}
					</label>
					{get(errors, 'termsAndConditions') &&
					get(touched, 'termsAndConditions') && (<div style={{display: 'block'}} className="invalid-feedback">{get(errors, 'termsAndConditions')}</div>)}
				</div>
				<div className="form-group form-group-footer">
					<button type="submit" disabled={isSubmitting} class="btn btn-primary btn-fs-action" data-target="fs5">
						Register
 					</button>
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = {
	validateRegisterEmail
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoosePasswordEmailSignUp);

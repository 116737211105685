import React, {Component, Fragment} from 'react'
import Loader from "../../../components/common/Spinners/Loader";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {connect} from "react-redux";
import {getMyPaymentMethods} from "../../../actions/homeInfoActions";
import PaymentMethodForm from "./PaymentMethodForm";
import {createLoadingSelector, getPaymentProviders, selectAccountInfo, selectHomeInfo} from "../../../selectors";
import isEmpty, {asNoneNegative, formatAmount, trans} from "../../../utils/helpers";
import {toastr} from "react-redux-toastr";
import moment from "moment";
import {getCurrentPaymentProviderId} from "../../../actions/paymentProviderActions";

const PAYMENT_METHOD = {
    NONE: 'NONE',
    CREDIT_CARD: 'CC',
    ACH: 'ACH',
}

const PAYMENT_RESULT = {
    NONE: 0,
    FAIL: 1,
    SUCCESS: 2,
    GENERIC: 3,
};

const PayMethodSchema = Yup.object().shape({
    paymentMethod: Yup.string()
        .required('Required'),

});

export class PaymentMethods extends Component {

    state = {
        paymentResult: PAYMENT_RESULT.NONE,
        transactionResult: null,
    }

    componentDidMount() {
        this.props.getCurrentPaymentProviderId();
        this.props.getMyPaymentMethods(this.props.accountInfo.id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.resetFormOnClose === true && this.state.paymentResult === PAYMENT_RESULT.SUCCESS) {
            setTimeout(() => {this.setState({paymentResult: PAYMENT_RESULT.NONE})}, 500);
        }
    }

    constructor(props) {
        super(props);

        this.paymentMethodFormRef = React.createRef();
    }

    handlePaymentMethodChange = (e, values, setFieldValue, setValues) => {
        this.setState(prevState => (prevState.paymentResult !== PAYMENT_RESULT.NONE)? {paymentResult: PAYMENT_RESULT.NONE} : null);
        setFieldValue('paymentMethod', e.target.value);
    }

    handlePaymentSuccess = (setFieldValue, setSubmitting, transactionResult) => {
        this.setState({paymentResult: PAYMENT_RESULT.SUCCESS, transactionResult});
        this.props.getMyPaymentMethods(this.props.accountInfo.id);
        setSubmitting(false);
    }

    handlePaymentFail = (setFieldValue, setSubmitting, transactionResult) => {
        this.setState({paymentResult: PAYMENT_RESULT.FAIL, transactionResult});
        setSubmitting(false);
    }

    handlePaymentGeneric = (setFieldValue, setSubmitting, transactionResult) => {
        this.setState({paymentResult: PAYMENT_RESULT.GENERIC, transactionResult});
        this.props.getMyPaymentMethods(this.props.accountInfo.id);
        setSubmitting(false);
    }

    getTransactionResultMessages = () => {
        const { transactionResult } = this.state;
        const sentences = [];

        if (transactionResult == null) {
            return sentences;
        }

        if (!isEmpty(transactionResult.responseText)) {
            sentences.push("Response: " + transactionResult.responseText + ".");
        }

        if (!isEmpty(transactionResult.authCode)) {
            sentences.push("Auth code: " + transactionResult.authCode + ".");
        }

        if (!isEmpty(transactionResult.transactionId)) {
            let transactionId = transactionResult.transactionId;
            if (transactionId.length > 10) {
                transactionId = "..." + transactionId.substring(transactionId.length - 10);
            }

            sentences.push("Transaction ID: " + transactionId + ".");
        }

        return sentences;
    }

    clearPaymentResult = () => {
        this.setState({paymentResult: PAYMENT_RESULT.NONE});
    }

    renderPaymentMethod = (values, errors, touched, setFieldValue, handleChange, handleBlur, setSubmitting, isSubmitting) => {
        const { accountInfo, myAmounts, currentProviderId } = this.props;
        const { paymentResult } = this.state;

        const visible = paymentResult === PAYMENT_RESULT.NONE;

        switch (values.paymentMethod) {

            case PAYMENT_METHOD.CREDIT_CARD:
            case PAYMENT_METHOD.NEW_PAYMENT_METHOD:
                return (visible &&
                    <PaymentMethodForm
                        ref={this.paymentMethodFormRef}
                        accountInfo={accountInfo}
                        formTarget="payment_method_frame"
                        togglePreventPanelClosing={this.props.togglePreventPanelClosing}
                        preventPanelClosing={this.props.preventPanelClosing}
                        getMyPaymentMethods = {this.props.getMyPaymentMethods}
                    />
                )

            default:
                return null;
        }
    };

    handleSubmit = (values, { setSubmitting, setFieldValue, setFieldError}) => {
        const { paymentMethod, submitType, paymentMethodToken} = values;
        const { guestOrderConfig } = window;
        const { accountInfo, creditBalance } = this.props;

        if (values.submitType === "managePaymentMethods") {
            this.props.togglePanel(8)
            setTimeout(() => {
                // Open Payment Panel
                this.props.togglePanel(9)
            }, 300)
        } else {
            this.setState({paymentResult: PAYMENT_RESULT.NONE, transactionResult: null});

            const data = {
                iframe: true,
            };
            data.submitType = submitType

            if (guestOrderConfig && guestOrderConfig.defaultLocationId) {
                data.locationId = guestOrderConfig.defaultLocationId;
            } else {
                console.warn("No default location ID");
            }

            data.accountId = accountInfo.id

            if (data.submitType === 'deletePaymentMethod') {
                data.paymentMethodToken = paymentMethodToken
                toastr.confirm("Are you sure you want to delete the Payment Method?", {
                    cancelText: "Cancel",
                    okText: "Proceed",
                    onOk: () => {
                        this.paymentMethodFormRef.current.createPaymentMethod(data);
                        setSubmitting(false);
                        setFieldValue('submitType', '');
                    },
                    onCancel: () => {
                        setSubmitting(false);
                    }
                });
            } else {
                switch (paymentMethod) {
                    case PAYMENT_METHOD.CREDIT_CARD:
                        data.paymentFormType = paymentMethod;
                        this.paymentMethodFormRef.current.createPaymentMethod(data);
                        break;
                    case PAYMENT_METHOD.NEW_PAYMENT_METHOD:
                        data.paymentFormType = paymentMethod;
                        this.paymentMethodFormRef.current.createPaymentMethod(data);
                        break;
                    default:
                        setSubmitting(false);
                }
            }
        }
    };

    render() {

        const {
            paymentResult,
        } = this.state;

        const {
            myPaymentMethodsLoader,
            myPaymentMethods,
            myAmounts,
            currentProviderId,
        } = this.props;

        return (
            <div className="cp-form">
                <div className="card-product">
                    <div className="card-body">
                        <h4>Payment Methods</h4>
                        <p className="form-divider">Payment Methods associated with your account.</p>

                        {myPaymentMethodsLoader &&
                        <Loader />
                        }

                        {!myPaymentMethodsLoader && isEmpty(myPaymentMethods) &&
                        <div className="form-divider">No payment methods found.</div>
                        }

                        <Formik
                            initialValues={{
                                paymentMethod: currentProviderId && PAYMENT_METHOD.CREDIT_CARD,
                            }}
                            enableReinitialize
                            validationSchema={PayMethodSchema}
                            onSubmit={this.handleSubmit}

                            render={(props) => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue,
                                    setValues,
                                    setSubmitting,
                                    setIn
                                } = props;

                                const { paymentMethod } = values;

                                return (
                                    <Fragment>

                                        <Form
                                            onSubmit={handleSubmit}
                                            id="mainFormPaymentMethod"
                                            autoComplete="off"
                                            className={"cmv-form"}
                                        >
                                            {!myPaymentMethodsLoader && !isEmpty(myPaymentMethods) &&
                                                <div className="table-responsive">
                                                    <table className="table cp-table cp-table-primary table-customer-payments">

                                                        <thead>
                                                        <tr>
                                                            <th>Type</th>
                                                            <th colSpan={"2"}>Payment Information</th>
                                                        </tr>
                                                        </thead>

                                                        {myPaymentMethods.map((myPaymentMethod) => (
                                                            <tbody key={myPaymentMethod.id}>
                                                            <tr>

                                                                <td className="v-align-m">

                                                                    {myPaymentMethod.type === 'CHECK'
                                                                        ? <i className="fas fa-money-check-alt" title='Check' />
                                                                        : myPaymentMethod.type === 'CASH'
                                                                            ? <i className="fas fa-money-bill" title='Cash' />
                                                                            : myPaymentMethod.type === 'CREDIT_CARD'
                                                                                ? <i className="fas fa-credit-card" title='Credit Card' />
                                                                                : myPaymentMethod.type === 'ACH'
                                                                                    ? <i className="fas fa-money-check" title='ACH' />
                                                                                    : myPaymentMethod.type === 'WIRE_TRANSFER'
                                                                                        ? <i style={{color: '#8bc34a'}} className="fas fa-dollar-sign" title='Wire Transfer' />
                                                                                        : myPaymentMethod.type === 'EXTERNAL'
                                                                                            ? <i className="fas fa-external-link-square-alt" title='External' />
                                                                                            : myPaymentMethod.type === 'ACCOUNT_CREDIT'
                                                                                                ? <i style={{color: '#f48fb1'}} className="fas fa-piggy-bank" title='Account Credit' />
                                                                                                : <i className="fas fa-question" title='Unknown' />
                                                                    }

                                                                </td>

                                                                <td className="v-align-m  h-align-r">
                                                                    {myPaymentMethod.type === 'ACH'
                                                                        ? <span>{myPaymentMethod.paymentInformation}{myPaymentMethod.autoPay === true ? <span style={{color: '#8bc34a'}}><br/>Enabled for AutoPay</span> : ""}</span>
                                                                        : myPaymentMethod.type === 'CREDIT_CARD'
                                                                            ? <span>{myPaymentMethod.paymentInformation}{myPaymentMethod.autoPay === true ? <span style={{color: '#8bc34a'}}><br/>Enabled for AutoPay</span> : ""}</span>
                                                                            : <i className="fas fa-question" title='Unknown' />
                                                                    }
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        ))}

                                                    </table>
                                                </div>
                                            }

                                            <div className="form-group col-12 text-right">
                                                <div className="btn-group-sm">
                                                    <button
                                                        type="submit" form="mainFormPaymentMethod" className="btn btn-outline-secondary"
                                                        id="btn-edit-pmnt-mthds" name="submitType" value="managePaymentMethods"
                                                        onClick={() => setFieldValue('submitType', "managePaymentMethods")}
                                                    >
                                                        Manage Payment Methods
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-group col-12 text-right">
                                                <div className="btn-group-sm">
                                                    <button id="btn-manage-autopay" name="submitType" value="manageAutoPay"
                                                            className="btn btn-primary"
                                                            onClick={() => {
                                                                // Close AutoPay Panel
                                                                this.props.togglePanel(8)
                                                                // Wait for animation to finish
                                                                setTimeout(() => {
                                                                    // Open Payment Panel
                                                                    this.props.togglePanel(4)
                                                                }, 300)
                                                            }}
                                                    >
                                                        Manage AutoPay
                                                    </button>
                                                    &nbsp;
                                                    <button id="btn-make-pmnt" name="submitType" value="makePayment"
                                                            className="btn btn-primary"
                                                            onClick={() => {
                                                                // Close AutoPay Panel
                                                                this.props.togglePanel(8)
                                                                // Wait for animation to finish
                                                                setTimeout(() => {
                                                                    // Open Payment Panel
                                                                    this.props.togglePanel(3)
                                                                }, 300)
                                                            }}
                                                    >
                                                        Make a Payment
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-group col-12 text-right">
                                            </div>

                                        </Form>
                                        <div className={"form-section form-section-customer-payment-management" + (paymentResult === PAYMENT_RESULT.SUCCESS ? " form-section-customer-payment-management-success" : "")}>

                                            {this.renderPaymentMethod(
                                                values,
                                                errors,
                                                touched,
                                                setFieldValue,
                                                handleChange,
                                                handleBlur,
                                                setSubmitting,
                                                isSubmitting,
                                            )
                                            }

                                            {(paymentMethod !== PAYMENT_METHOD.CREDIT_CARD
                                                && paymentMethod !== PAYMENT_METHOD.ACH
                                                && paymentMethod !== PAYMENT_METHOD.NEW_PAYMENT_METHOD
                                                && paymentMethod !== PAYMENT_METHOD.NONE)
                                            && isSubmitting && (
                                                <Loader/>
                                            )}

                                            {paymentResult === PAYMENT_RESULT.SUCCESS &&
                                            <div className="message">
                                                <div className="message-left">
                                                    <i className="fas fa-check-circle" />
                                                </div>
                                                <div className="message-right">
                                                    <h4>Payment successful</h4>
                                                    {this.getTransactionResultMessages().map(msg => <Fragment><span>{msg}</span><br/></Fragment>)}
                                                    <p>You'll receive details about it soon.</p>

                                                </div>
                                            </div>
                                            }

                                            {paymentResult === PAYMENT_RESULT.FAIL &&
                                            <>
                                                <br/>
                                                <div className="message">
                                                    <div className="message-left text-danger">
                                                        <i className="fas fa-times" onClick={this.clearPaymentResult} style={{cursor: 'pointer'}}/>
                                                    </div>
                                                    <div className="message-right">
                                                        <h4 className="text-danger">Transaction failed</h4>
                                                        {this.getTransactionResultMessages().map(msg => <Fragment><span>{msg}</span><br/></Fragment>)}
                                                    </div>
                                                </div>
                                            </>
                                            }

                                            {paymentResult === PAYMENT_RESULT.GENERIC &&
                                            <>
                                                <br/>
                                                <div className="message">
                                                    <div className="message-left text-muted">
                                                        <i className="fas fa-info-circle" onClick={this.clearPaymentResult} style={{cursor: 'pointer'}}/>
                                                    </div>
                                                    <div className="message-right">
                                                        <h4 className="text-muted">Transaction details</h4>
                                                        {this.getTransactionResultMessages().map(msg => <Fragment><span>{msg}</span><br/></Fragment>)}
                                                    </div>
                                                </div>
                                            </>
                                            }

                                        </div>
                                        {/*TODO What should this if statment be if anything??*/}
                                        {paymentMethod === paymentMethod &&
                                        <iframe
                                            name="payment_method_frame"
                                            frameBorder="0"
                                            allow="payment; camera; microphone; web-share https://secure1.paymentus.com"
                                            style={
                                                {
                                                    position: 'relative',
                                                    zIndex: 0,
                                                    display: isSubmitting ? 'block' : 'none',
                                                    width: '100%',
                                                    minHeight: values.paymentMethod === PAYMENT_METHOD.CREDIT_CARD ? '1530px' : '1250px',
                                                    marginTop: '-111px',
                                                    borderRadius: '0.5rem'
                                                }
                                            }>
                                        </iframe>
                                        }

                                    </Fragment>

                                )}} />
                    </div>
                </div>
            </div>
        )
    }
}

const getMyPaymentMethodsLoader = createLoadingSelector(['MY_PAYMENT_METHODS']);

const mapStateToProps = (state) => {
    const myPaymentMethodsLoader = getMyPaymentMethodsLoader(state);
    const myPaymentMethods = selectHomeInfo(state).myPaymentMethods;
    const { accountInfo, creditBalance } = selectAccountInfo(state);
    const currentProviderId = getPaymentProviders(state).currentProviderId;

    return {
        myPaymentMethodsLoader,
        myPaymentMethods,
        accountInfo,
        creditBalance,
        currentProviderId
    };
};

const mapDispatchToProps = {
    getCurrentPaymentProviderId,
    getMyPaymentMethods
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PaymentMethods);

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import {
	getPublicMapLinkLocation,
	getMapLinkLocation,
	emptyMapLinkLocation,
	getCheckAvailability,
	createNoServiceAccount,
	emptyGuestOrder,
	createAccount,
	createNewOrder,
	getPlanDetails,
	emptyPlanDetails,
	clearNoServiceAccountError,
	updateAccountEmail,
	billingAddressValidation
} from '../../actions/guestOrderActions';
import { selectLanguage, selectGuestOrder, createLoadingSelector, selectErrorMessage } from '../../selectors';
import { validationSchema } from './validation';
import { isEmpty, generateId } from '../../utils/helpers';

import GuestLayout from '../Layout/GuestLayout';
import IframeAddress from './IframeAddress';
import StepContent from './StepContent';
import {validateRequiredOrOptionalFields} from "../../actions/signUpToServiceActions";
import {checkForServiceLine, checkForServiceOrder} from "../../actions/signUpActions";

let defaultFormValues = {
	street: '',
	unit: '',
	// zipcode: '',
	formattedAddress: '',
	validatedAddress: {},
	account: {
		accountHolder: {
			accountType: window.guestOrderConfig.residentialAccountTypeId,
			firstName: '',
			lastName: '',
			personalEmail: '',
			confirmEmail: '',
			serviceAddress: '',
			mobileNumber: {
				number: '',
				verified: false
			},
			company: '',
			businessEmail: '',
			confirmBusinessEmail: '',
			businessNumber: {
				number: '',
				verified: false
			},
		},
		accountTermsInfo: {
			accountTerm: '',
			billCycle: window.guestOrderConfig.residentialDefaultBillcycle
		},
		initialStatus: '',
		locationId: window.guestOrderConfig.defaultLocationId,
		termsAndCondition: false
	},
	order: {
		name: 'NEW_ORDER_' + generateId(),
		source: 'PORTAL',
		note: '',
		accountId: '',
		address: '',
		plans: []
	},
	locationId: window.guestOrderConfig.defaultLocationId,
};

export class index extends Component {

	static propTypes = {};

	constructor(props) {
		super(props);
		this.formikRef = React.createRef();
	}

	state = {
		activeStep: 'check-availability',
		planDetailsError: '',
		iframeAddressUrl: '',
		iframeLoader: false,
		errorMessage: null,
		fieldValidationKey: [],
		initialFormValues: defaultFormValues,
		formSubmitting: false
	};

	componentDidMount() {
		const {location} = this.props;

		if (location && location.state && location.state.street) {
			// if (location && location.state && location.state.street && location.state.zipcode) {

			setTimeout(() => {
				this.formikRef.current.setFieldValue('street', location.state.street);
				// this.formikRef.current.setFieldValue('zipcode', location.state.zipcode);
				this.formikRef.current.submitForm();
			}, 0)
		}

	}


	componentWillUnmount() {
		const { emptyGuestOrder } = this.props;
		emptyGuestOrder();
	}

	fetchRequiredOrOptionalFields = (accountTypeId) => {
		//call fetch function to declare required/optional fields
		this.props.validateRequiredOrOptionalFields().then((response) => {
			let planCategoryFields = response.find((element) => element.id === parseInt(accountTypeId))
			this.setState({fieldValidationKey: planCategoryFields.fields})
		})
	}

	populateServiceAndLines = (values, setFieldValue) => {
		const { getPlanDetails } = this.props;
		let locId = values.account.locationId
		if ((values['validatedAddress'] != null) && (values['validatedAddress']['locationId'] != null)) {
			locId = values['validatedAddress']['locationId']
		}
		values.order.plans.forEach(async (element) => {
			let response = await getPlanDetails(locId, element.planId);
			// This is hack - the response error format need to be changed
			if (response.success !== false) {
				let findIndex = values.order.plans.findIndex((x) => x.planId === response.id);
				let servicelines = [];

				for (let index = 0; index < 1; index++) {
					let currentServiceLine = {};
					if (
						response.serviceModel.directorySystemNumType &&
						response.serviceModel.directorySystemNumType.defaultSource === 'number_inventory'
					) {
						currentServiceLine = {
							features: [],

							number: '',
							serviceLineRelation: index === 0 ? 'Main' : 'Required',
							index: index
						};
					} else {
						currentServiceLine = {
							features: [],

							serviceLineRelation: index === 0 ? 'Main' : 'Required',
							index: index
						};
					}

					// ADD FEATURES TO THE LINES
					if (currentServiceLine.serviceLineRelation === 'Main') {
						if (!isEmpty(response.mainIncludedFeatures)) {
							response.mainIncludedFeatures.forEach((element) => {
								let feature = {
									...element
								};
								currentServiceLine.features.push(feature);
							});
						}
						if (!isEmpty(response.mainIncludedFeaturePackages)) {
							response.mainIncludedFeaturePackages.forEach((element) => {
								let feature = {
									...element
								};

								currentServiceLine.features.push(feature);
							});
						}
						if (!isEmpty(response.mainMandatoryFeatures)) {
							response.mainMandatoryFeatures.forEach((element) => {
								let feature = {
									...element
								};
								currentServiceLine.features.push(feature);
							});
						}
						if (!isEmpty(response.mainMandatoryFeaturePackages)) {
							response.mainMandatoryFeaturePackages.forEach((element) => {
								let feature = {
									featurePackageId: element.featurePackageId,
									featurePackageDescription: element.description,
									...element.featurePackageOptions[0]
								};

								currentServiceLine.features.push(feature);
							});
						}
					}

					servicelines.push(currentServiceLine);
				}

				setFieldValue(`order.plans.${findIndex}.index`, findIndex);
				setFieldValue(`order.plans.${findIndex}.servicelines`, servicelines);
			} else {
				this.setState({
					planDetailsError: response.error.message
				});
			}
		});

		this.handleChangeStep('packages-and-products');
	};

	handleChangeStep = (step) => {
		this.setState({
			activeStep: step
		});
	};

	handleBackStep = (step, setFieldValue) => {
		this.props.emptyPlanDetails();
		setFieldValue('order.plans', []);
		this.setState({
			activeStep: step,
			planDetailsError: ''
		});
	};

	handleResetWizard = (resetForm, setTouched) => {
		const { initialFormValues } = this.state;
		//Reset initial form values, and wizard step

		this.props.emptyGuestOrder();
		resetForm({ initialFormValues });
		setTouched({});

		this.setState({
			activeStep: 'check-availability',
			iframeAddressUrl: '',
			iframeLoader: false,
			planDetailsError: ''
		});
	};

	handleHideIframeLoader = () => {
		this.setState({
			iframeLoader: false
		});
	};

	onAddressSelect = async (address, unit) => {
		//fires when address is selected on autosuggest dropdown
		//we set errorMessage to true until calls fully resolve to disable button
		this.setState({errorMessage: true}, () => {
			if (address === 'error') {
				this.props.emptyMapLinkLocation();
			}
			else {
				if (!isEmpty(unit)) {
					address.address2 = unit;
				}
				const addressRequest = {
					addressWrapper: address
				};

				this.formikRef.current.setFieldValue('addressWrapper', address);

				if (this.state.addressType !== "manualAddress") {
					this.setState({
						iframeLoader: true
					});
					//fetches validated address
					this.props.getPublicMapLinkLocation(this.formikRef.current.values.account.locationId, addressRequest).then(response => {
						if (response && response.validatedAddress && response.validatedAddress.embedUrl) {
							this.formikRef.current.setFieldValue('validatedAddress', response.validatedAddress);
							this.setState({
								iframeAddressUrl: response.validatedAddress.embedUrl,
								iframeLoader: false,
							});

							(async() => {

								const addressData = response.validatedAddress
								let activeServiceLines = false
								let params = {address1: addressData.address1, address2: addressData.address2,
									city: addressData.city,
									zip: address.zip}
								//removes params that contain null values and trims extra space in string
								for(let key in params) {
									if(!params[key]){
										delete params[key]
									} else {
										params[key].trim()
									}
								}
								//fetch calls check if address has an active or pending service line and if serviceline count = 0
								// then checks service order count
								//displays error message from endpoint
								const serviceLineResponse = await this.props.checkForServiceLine(params)
								if(serviceLineResponse.errorMessage) {
									this.setState({errorMessage: serviceLineResponse.errorMessage})
									activeServiceLines = true
								}
								if (activeServiceLines === false) {
									const serviceOrderResponse = await this.props.checkForServiceOrder(params)
									if(serviceOrderResponse.errorMessage) {
										this.setState({errorMessage: serviceOrderResponse.errorMessage})
									} else {
										this.setState({errorMessage: null})
									}
								}

							})()
						}
					});
				}
			}
		})
	};

	setSelectedItem = async (values,item, type,setFieldValue) => {
		let allPlans = [];
		if (type === 'bundle') {
			item.plans.forEach((plan) => {
				let currentPlan = {
					planBundleId: item.id,
					planBundleDescription: item.description,
					planBundleName: item.name,
					planDescription: plan.description,
					planId: plan.id,
					planName: plan.name,
					servicelines: []
				};

				allPlans.push(currentPlan);
			});
		} else if (type === 'plan') {
			let currentPlan = {
				planDescription: item.description,
				planId: item.id,
				planName: item.name,
				servicelines: []
			};

			allPlans.push(currentPlan);
		}

		await setFieldValue('order.plans', allPlans);
		values.order.plans=allPlans;
		this.populateServiceAndLines(values, setFieldValue);
	};



	handleCheckAvailabilityStep = async (values, actions) => {
		let locId = values.account.locationId
		if ((values['validatedAddress']) && (values['validatedAddress']['locationId'])) {
			locId = values['validatedAddress']['locationId']
		}

		const result = await this.props.getCheckAvailability({addressWrapper: values.addressWrapper, apiValidatedAddressWrapper: values.validatedAddress}, locId, values.planCategoryId);
		if (result) {
			if (result.validatedAddress) {
				actions.setFieldValue('formattedAddress', result.validatedAddress.fullAddress);
				values.validatedAddress = result.validatedAddress;
				let accountInitialStatus = null;
				if (values.validatedAddress.allowedServiceOrderType === 'NEW_SIGNUP') {
					accountInitialStatus = 'SIGNUP';
				} else if (values.validatedAddress.allowedServiceOrderType === 'NEW_SURVEY') {
					accountInitialStatus = 'SURVEY';
				} else if (values.validatedAddress.allowedServiceOrderType === 'NEW_SERVICE') {
					accountInitialStatus = 'ACTIVE';
				}
				if (!isEmpty(accountInitialStatus)) {
					actions.setFieldValue('account.initialStatus', accountInitialStatus);

					if(result.plans&&result.plans.length===1){
						this.populateServiceAndLines(values,actions.setFieldValue);
						this.setSelectedItem(values,this.props.availablePlansData.plans[0],'plan',actions.setFieldValue);
						this.handleChangeStep('packages-and-products')
					}else{
						this.handleChangeStep('new-signup');
					}
				}
			} else {
				actions.setFieldValue('account.accountHolder.serviceAddress', values.addressWrapper.fullAddress);
				actions.setFieldValue('account.initialStatus', 'PROSPECT');
				this.handleChangeStep('not-available-service');
			}
		}
	};

	handleNotAvailableServiceStep = async (values, actions) => {
		const result = await this.props.createNoServiceAccount(values.account);

		if (result) {
			this.handleChangeStep('no-service-confirmation');
		}
	};

	handleValidateAddress = async(billingAddress) => {
		const { billingAddressValidation } = this.props;
		const address = {
			address1: billingAddress.address1,
			address2: billingAddress.address2,
			address3: billingAddress.address3,
			city: billingAddress.city,
			state: billingAddress.state,
			zip: billingAddress.zip,
		}
		return await billingAddressValidation(address);
	}

	handleCustomerInfoStep = async (values, actions) => {
		const { createAccount, createNewOrder, newAccountInfoData, updateAccountEmail } = this.props;
		this.setState({formSubmitting: true}, async() => {
			if (!isEmpty(newAccountInfoData)) {
				const updateEmailResponse = await updateAccountEmail(newAccountInfoData.id, newAccountInfoData.number, values.account.accountHolder.personalEmail);
				if (updateEmailResponse) {
					this.handleChangeStep('confirmation');
				}

				return;
			}

			let billingAddress = {
				address1: values.validatedAddress.address1,
				address2: values.validatedAddress.address2,
				address3: values.validatedAddress.address3,
				city: values.validatedAddress.city,
				state: values.validatedAddress.state && values.validatedAddress.state.abbreviation,
				zip: values.validatedAddress.zipcode,
				zip4: values.validatedAddress.zip4,
				latitude: values.validatedAddress.latitude,
				longitude: values.validatedAddress.longitude,
				verified: false
			}

			this.handleValidateAddress(billingAddress).then((async response => {
				if(response != undefined && response.data.status != "ERROR"){
					billingAddress["address1"] = response.data.address1;
					billingAddress["city"] = response.data.city;
					billingAddress["state"] = response.data.state;
					billingAddress["zip"] = response.data.zip;
					billingAddress["verified"] = true;
				}
				let updatedAccountData = {
					...values.account,
					accountHolder: {
						...values.account.accountHolder,
						accountType: values.accountTypeId,
						firstName: values.account.accountHolder.firstName.trim(),
						lastName: values.account.accountHolder.lastName.trim(),
						personalEmail: values.account.accountHolder.personalEmail.trim(),
						confirmEmail: values.account.accountHolder.confirmEmail.trim(),
						company: values.account.accountHolder.company.trim(),
						businessEmail: values.account.accountHolder.businessEmail.trim(),
						confirmBusinessEmail: values.account.accountHolder.confirmBusinessEmail.trim()
					},
					billingDetails: {
						billingAddress: billingAddress
					},
					planCategoryId: values.planCategoryId
				};

				const createAccountResponse = await createAccount(updatedAccountData);

				if (createAccountResponse && createAccount) {
					let orderData = {
						...values.order,
						accountId: createAccountResponse.id,
						addressZoneValuesToSave: this.props.validatedAddress.savedAddressZoneValues,
						validatedAddressWrapper: this.props.validatedAddress
					};
					let orderType = values.account.initialStatus;

					const createOrder = await createNewOrder(orderData, orderType);

					if (createOrder) {
						this.handleChangeStep('confirmation');
						this.setState({formSubmitting:false})
					}
				} else {
					this.setState({formSubmitting:false})
				}
			}));
		})
	};

	handleOnSubmit = (values, actions) => {
		const { activeStep } = this.state;

		if (activeStep === 'check-availability') {
			this.handleCheckAvailabilityStep(values, actions);
		} else if (activeStep === 'not-available-service') {
			this.handleNotAvailableServiceStep(values, actions);
		} else if (activeStep === 'customer-info') {
			this.handleCustomerInfoStep(values, actions);
		}
	};


	render() {

		const {
			initialFormValues,
			activeStep,
			iframeAddressUrl,
			iframeLoader,
			planDetailsError
		} = this.state;

		const {
			availablePlansData,
			availablePlansLoader,
			availablePlansError,
			noServiceAccountLoader,
			noServiceAccountError,
			newOrderInfoData,
			plansDetailsData,
			createAccountAndOrderLoader,
			createAccountAndOrderError
		} = this.props;

		return (
			<GuestLayout>
				<div className="cp-page cp-page-guest">
					<div className="cp-container-maps partial-width">

						<IframeAddress
							iframeAddressUrl={iframeAddressUrl}
							iframeLoader={iframeLoader}
							handleHideIframeLoader={this.handleHideIframeLoader}
						/>

						<div className="cp-container-maps-overlay">
							<div className="card-guest card-full-height">
								<div className="card-guest-body">
									<Formik
										innerRef={this.formikRef}
										initialValues={initialFormValues}
										validationSchema={validationSchema(activeStep, this.state.fieldValidationKey)}
										onSubmit={this.handleOnSubmit}
										enableReinitialize={true}
									>
										{(formProps) => (
											<Form
												onSubmit={formProps.handleSubmit}
												className="cp-form"
												autoComplete="off"
												noValidate
											>
												<div className="form-sections-wrapper">
													<StepContent
														formProps={formProps}
														activeStep={activeStep}
														planDetailsError={planDetailsError}
														fields={this.state.fieldValidationKey}
														fetchRequiredOrOptionalFields={this.fetchRequiredOrOptionalFields}
														// availablePlansData={availablePlansData}
														// availablePlansLoader={availablePlansLoader}
														// availablePlansError={availablePlansError}
														// noServiceAccountLoader={noServiceAccountLoader}
														// noServiceAccountError={noServiceAccountError}
														// newOrderInfoData={newOrderInfoData}
														// plansDetailsData={plansDetailsData}
														createAccountAndOrderLoader={createAccountAndOrderLoader}
														createAccountAndOrderError={createAccountAndOrderError}
														handleResetWizard={this.handleResetWizard}
														handleChangeStep={this.handleChangeStep}
														onAddressSelect={this.onAddressSelect}
														populateServiceAndLines={this.populateServiceAndLines}
														handleBackStep={this.handleBackStep}
														errorMessage={this.state.errorMessage}
														formSubmitting={this.state.formSubmitting}
														{...this.props}
													/>
												</div>
											</Form>
										)}
									</Formik>
								</div>
							</div>
						</div>

					</div>
				</div>
			</GuestLayout>
		);
	}
}

const getAvailablePlansLoader = createLoadingSelector([ 'CHECK_AVAILABLE_PLANS' ]);
const getAvailablePlansError = selectErrorMessage([ 'CHECK_AVAILABLE_PLANS' ]);
const getCreateNoServiceAccountLoader = createLoadingSelector([ 'CREATE_NO_SERVICE_ACCOUNT' ]);
const getCreateNoServiceAccoountError = selectErrorMessage([ 'CREATE_NO_SERVICE_ACCOUNT' ]);
const getCreateAccountAndOrderLoader = createLoadingSelector([ 'CREATE_ACCOUNT', 'CREATE_ORDER' ]);
const getCreateAccountAndOrderError = selectErrorMessage([ 'CREATE_ACCOUNT', 'CREATE_ORDER', 'UPDATE_ACCOUNT_EMAIL' ]);

const mapStateToProps = (state) => {
	const lang = selectLanguage(state),
		availablePlansData = selectGuestOrder(state).availablePlans,
		noServiceAccountInfoData = selectGuestOrder(state).noServiceAccountInfo,
		newAccountInfoData = selectGuestOrder(state).newAccountInfo,
		newOrderInfoData = selectGuestOrder(state).newOrderInfo,
		plansDetailsData = selectGuestOrder(state).plansDetails,
		availablePlansLoader = getAvailablePlansLoader(state),
		noServiceAccountLoader = getCreateNoServiceAccountLoader(state),
		createAccountAndOrderLoader = getCreateAccountAndOrderLoader(state),
		createAccountAndOrderError = getCreateAccountAndOrderError(state),
		noServiceAccountError = getCreateNoServiceAccoountError(state),
		availablePlansError = getAvailablePlansError(state),
		validatedAddress = selectGuestOrder(state).validatedAddress;

	return {
		availablePlansData,
		availablePlansLoader,
		availablePlansError,
		noServiceAccountInfoData,
		noServiceAccountLoader,
		noServiceAccountError,
		newAccountInfoData,
		plansDetailsData,
		newOrderInfoData,
		createAccountAndOrderLoader,
		createAccountAndOrderError,
		validatedAddress,
	};
};

const mapDispatchToProps = {
	getMapLinkLocation,
	getPublicMapLinkLocation,
	emptyMapLinkLocation,
	getCheckAvailability,
	createNoServiceAccount,
	emptyGuestOrder,
	createAccount,
	getPlanDetails,
	createNewOrder,
	emptyPlanDetails,
	clearNoServiceAccountError,
	updateAccountEmail,
	billingAddressValidation,
	validateRequiredOrOptionalFields,
	checkForServiceLine,
	checkForServiceOrder
};

export default connect(mapStateToProps, mapDispatchToProps)(index);

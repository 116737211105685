import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    getCheckAvailability,
    emptyGuestOrder,
    createNewOrder,
    getPlanDetails,
    emptyPlanDetails,
} from '../../actions/guestOrderActions';
import {
    validateSignupForServiceToken,
    getOrderAvailability,
    createOrderAppointment,
    completeSignupToService,
    patchServiceOrder,
    patchOrder
} from '../../actions/signUpToServiceActions';
import {
    selectGuestOrder,
    createLoadingSelector,
    selectErrorMessage, getSignupToService
} from '../../selectors';
import {toastr} from "react-redux-toastr";
import moment from 'moment';
import {isEmpty, generateId, trans} from '../../utils/helpers';
import {validationSchema} from "./validation";
import GuestLayout from '../Layout/GuestLayout';
import {Formik, Form} from 'formik';
import StepContent from './StepContent';
import IframeAddress from './IframeAddress';
import Loader from "../../components/common/Spinners/Loader";

export class SignupToService extends Component {

    state = {
        tokenErrorTitle:'',
        tokenErrorDescription:'',
        tokenErrorNote:'',


        activeStep: 'select-service',
        isTokenLoading: true,
        validationToken: '',
        invalidToken: false,
        planDetailsError: '',
        iframeAddressUrl: '',
        iframeLoader: false,
        fullAddress: {},
        initialFormValues: {
            appointmentDate: '',
            accountId: '',
            formattedAddress: '',
            serviceOrderId: '',
            serviceModelServiceOrderList: [],
            account: {
                accountHolder: {
                    accountType: window.guestOrderConfig.residentialAccountTypeId,
                    firstName: '',
                    lastName: '',
                    personalEmail: '',
                    serviceAddress: '',
                    mobileNumber: {
                        number: '',
                        verified: false,
                    },
                },
                accountTermsInfo: {
                    accountTerm: '',
                    billCycle: window.guestOrderConfig.residentialDefaultBillcycle,
                },
                initialStatus: '',
                locationId: window.guestOrderConfig.defaultLocationId,
                termsAndCondition: false,
            },
            order: {
                accountId: '',
                address: '',
                name: 'NEW_ORDER_' + generateId(),
                note: '',
                plans: [],
                type: 'NEW_SERVICE',
                source: 'PORTAL',
            },
        },
    };

    componentDidMount() {
        (async() => {
			const tokenErrorTitle=await trans('en-us', 'confirmOrderScreen.selectService.tokenErrorTitle');
			const tokenErrorDescription=await trans('en-us', 'confirmOrderScreen.selectService.tokenErrorDescription');
			const tokenErrorNote=await trans('en-us', 'confirmOrderScreen.selectService.tokenErrorNote');
				
			this.setState({tokenErrorTitle,tokenErrorDescription,tokenErrorNote});
		})()
        const validationToken = this.props.location.search.substring(7);

        this.props.validateSignupForServiceToken(validationToken).then((response) => {
            if (response && response.data && response.data.success === false) {
                // Display token error
                this.setState({
                    isTokenLoading: false,
                    invalidToken: true,
                });
            } else {
                const signUpForServiceTokenResponse = this.props.signUpForServiceTokenResponse;
                let updatedInitialFormValues = this.updateInitialFormValues(signUpForServiceTokenResponse);

                let addressWrapper = this.updateAddressWrapper(signUpForServiceTokenResponse, signUpForServiceTokenResponse.addressWrapper);
                let locId = window.guestOrderConfig.defaultLocationId;

                if (signUpForServiceTokenResponse.location && signUpForServiceTokenResponse.location.id) {
                    locId = signUpForServiceTokenResponse.location.id;
                    updatedInitialFormValues.account.accountHolder.locationId = locId;
                }

                this.setState({
                    isTokenLoading: false,
                    validationToken: validationToken,
                    initialFormValues: updatedInitialFormValues,
                    fullAddress: signUpForServiceTokenResponse.addressWrapper.fullAddress
                });

                // Load plans
                this.props.getCheckAvailability(addressWrapper, locId, addressWrapper.planCategoryId).then((response) => {
                    // Load map
                    if (response && response.availablePlans && response.availablePlans.validatedAddress.embedUrl) {
                        this.setState({
                            iframeAddressUrl: response.availablePlans.validatedAddress.embedUrl,
                            iframeLoader: true
                        });
                    }

                    if (response && response.availablePlans &&
                        (response.availablePlans.validatedAddress.allowedServiceOrderType === "NEW_SIGNUP" ||
                            response.availablePlans.validatedAddress.allowedServiceOrderType === "NEW_SURVEY")) {
                        this.handleChangeStep('not-available');
                    }
                });
            }
        });
    }

    updateAddressWrapper(signUpForServiceTokenResponse, addressWrapper) {
        if (signUpForServiceTokenResponse.planCategoryId) {
            addressWrapper.planCategoryId = signUpForServiceTokenResponse.planCategoryId;
        }else if (signUpForServiceTokenResponse.type && signUpForServiceTokenResponse.type.defaultPlanCategoryId) {
            addressWrapper.planCategoryId = signUpForServiceTokenResponse.type.defaultPlanCategoryId;
        }
        addressWrapper.serviceModelServiceOrderList = signUpForServiceTokenResponse.apiServiceModelServiceOrders;

        return addressWrapper;
    }

    // Validate token
    updateInitialFormValues(signUpForServiceTokenResponse) {
        let updatedInitialFormValues = this.state.initialFormValues;

        if (signUpForServiceTokenResponse.accountStatus) {
            updatedInitialFormValues.account.initialStatus = signUpForServiceTokenResponse.accountStatus;
        }
        if (signUpForServiceTokenResponse.serviceOrderId) {
            updatedInitialFormValues.serviceOrderId = signUpForServiceTokenResponse.serviceOrderId;
        }
        if (signUpForServiceTokenResponse.formattedAddress) {
            updatedInitialFormValues.formattedAddress = signUpForServiceTokenResponse.formattedAddress;
            updatedInitialFormValues.order.address = signUpForServiceTokenResponse.formattedAddress;
        }
        if (signUpForServiceTokenResponse.apiServiceModelServiceOrders) {
            updatedInitialFormValues.serviceModelServiceOrderList = signUpForServiceTokenResponse.apiServiceModelServiceOrders;
        }
        if (signUpForServiceTokenResponse.accountId) {
            updatedInitialFormValues.accountId = signUpForServiceTokenResponse.accountId;
            updatedInitialFormValues.order.accountId = signUpForServiceTokenResponse.accountId;
        }
        if (signUpForServiceTokenResponse.contact) {
            if (signUpForServiceTokenResponse.contact.firstName) {
                updatedInitialFormValues.account.accountHolder.firstName = signUpForServiceTokenResponse.contact.firstName;
            }
            if (signUpForServiceTokenResponse.contact.lastName) {
                updatedInitialFormValues.account.accountHolder.lastName = signUpForServiceTokenResponse.contact.lastName;
            }
            if (!isEmpty(signUpForServiceTokenResponse.contact.contactEmails)) {
                const personalEmail = signUpForServiceTokenResponse.contact.contactEmails.find(email => email.type === "PERSONAL");
                if (personalEmail) {
                    updatedInitialFormValues.account.accountHolder.personalEmail = personalEmail.email;
                }
            }
            if (!isEmpty(signUpForServiceTokenResponse.contact.contactAddresses)) {
                const serviceAddress = signUpForServiceTokenResponse.contact.contactAddresses.find(address => address.type === "SERVICE");
                if (serviceAddress) {
                    updatedInitialFormValues.account.accountHolder.serviceAddress = serviceAddress.address1;
                }
            }
            if (!isEmpty(signUpForServiceTokenResponse.contact.contactNumbers)) {
                const personalNumbers = signUpForServiceTokenResponse.contact.contactNumbers.filter(number => number.type === "PERSONAL");
                personalNumbers.forEach(number => {
                    updatedInitialFormValues.account.accountHolder.mobileNumber.number = number.number;
                    updatedInitialFormValues.account.accountHolder.mobileNumber.verified = number.verified;
                });
            }
        }
        if (signUpForServiceTokenResponse.terms && signUpForServiceTokenResponse.terms.id) {
            updatedInitialFormValues.account.accountTermsInfo.accountTerm = signUpForServiceTokenResponse.terms.id;
        }
        if (signUpForServiceTokenResponse.billCycle && signUpForServiceTokenResponse.billCycle.billCycle) {
            updatedInitialFormValues.account.accountTermsInfo.billCycle = signUpForServiceTokenResponse.billCycle.billCycle;
        }

        return updatedInitialFormValues;
    }

    componentWillUnmount() {
        this.props.emptyGuestOrder();
    }

    populateServiceAndLines = (values, setFieldValue) => {
        const { getPlanDetails } = this.props;

        values.order.plans.forEach(async (element) => {
            let locId = values.account.locationId
            if (values.account.accountHolder.locationId) {
                locId = values.account.accountHolder.locationId
            }
            let response = await getPlanDetails(locId, element.planId);


            // This is hack - the response error format need to be changed

            if (response.success !== false) {

                let findIndex = values.order.plans.findIndex((x) => x.planId === response.id);

                let servicelines = [];

                for (let index = 0; index < 1; index++) {
                    let currentServiceLine = {};
                    if (
                        response.serviceModel.directorySystemNumType &&
                        response.serviceModel.directorySystemNumType.defaultSource === 'number_inventory'
                    ) {
                        currentServiceLine = {
                            features: [],

                            number: '',
                            serviceLineRelation: index === 0 ? 'Main' : 'Required',
                            index: index
                        };
                    } else {
                        currentServiceLine = {
                            features: [],

                            serviceLineRelation: index === 0 ? 'Main' : 'Required',
                            index: index
                        };
                    }

                    // ADD FEATURES TO THE LINES
                    if (currentServiceLine.serviceLineRelation === 'Main') {
                        if (!isEmpty(response.mainIncludedFeatures)) {
                            response.mainIncludedFeatures.forEach((element) => {
                                let feature = {
                                    ...element
                                };
                                currentServiceLine.features.push(feature);
                            });
                        }
                        if (!isEmpty(response.mainIncludedFeaturePackages)) {
                            response.mainIncludedFeaturePackages.forEach((element) => {
                                let feature = {
                                    featurePackageId: element.featurePackageId,
                                    featurePackageDescription: element.description,
                                    ...element.featurePackageOptions[0]
                                };

                                currentServiceLine.features.push(feature);
                            });
                        }
                        if (!isEmpty(response.mainMandatoryFeatures)) {
                            response.mainMandatoryFeatures.forEach((element) => {
                                let feature = {
                                    ...element
                                };
                                currentServiceLine.features.push(feature);
                            });
                        }
                        if (!isEmpty(response.mainMandatoryFeaturePackages)) {
                            response.mainMandatoryFeaturePackages.forEach((element) => {
                                let feature = {
                                    featurePackageId: element.featurePackageId,
                                    featurePackageDescription: element.description,
                                    ...element.featurePackageOptions[0]
                                };

                                currentServiceLine.features.push(feature);
                            });
                        }
                    }

                    servicelines.push(currentServiceLine);
                }

                setFieldValue(`order.plans.${findIndex}.index`, findIndex);
                setFieldValue(`order.plans.${findIndex}.servicelines`, servicelines);
            }
            else {

                this.setState({
                    planDetailsError: response.error.message
                });
            }
        });
        this.handleChangeStep('service-details');
    };

    handleOnSubmit = (values, actions) => {
        this.handlePatchServiceOrder(values)
    };

    handlePatchServiceOrder = (values) => {
        let data = {
            accountId: values.accountId  || '',
            serviceOrderId: values.serviceOrderId  || '',
            plans: values.order && values.order.plans ? values.order.plans : '',
            type: values.order && values.order.type ? values.order.plans.type : '',
            name: values.order && values.order.name ? values.order.name : '',
            note: values.order && values.order.note ? values.order.note : '',
            serviceModelServiceOrderList: values.serviceModelServiceOrderList || '',
            fullAddress: this.state.fullAddress
        }
        this.props.patchOrder(data).then((patchOrderresponse) => {
           if(patchOrderresponse.data.success){
               this.props.completeSignupToService(this.state.validationToken).then(completeSignupToServiceResponse => {
                   if(completeSignupToServiceResponse.data.success){
                       this.handleChangeStep('success');
                   }else{
                       this.handleErrorPatchingServiceOrder();
                   }
               })
           }else{
               this.handleErrorPatchingServiceOrder();
           }
        });
    }

    handleErrorPatchingServiceOrder = () => {
        toastr.error('We couldn\'t confirm your service. Please contact us.',
            {disableTimeOut : true, timeOut: 0, extendedTimeOut: 0, position: 'top-center'});
    }

    handleChangeStep = (step) => {
        this.setState({
            activeStep: step
        });
    };

    handleBackStep = (step, setFieldValue) => {
        this.props.emptyPlanDetails();
        setFieldValue('order.plans', []);
        this.setState({
            activeStep: step,
            planDetailsError: ''
        });
    };

    handleResetWizard = (resetForm, setTouched) => {
        const { initialFormValues } = this.state;

        this.props.emptyGuestOrder();

        resetForm({ initialFormValues });
        setTouched({});

        this.setState({
            activeStep: 'select-service',
            iframeAddressUrl: '',
            iframeLoader: false,
            planDetailsError: ''
        });
    };

    handleHideIframeLoader = () => {
        this.setState({
            iframeLoader: false
        });
    };

    render() {

        const {
            isTokenLoading,
            invalidToken,
            initialFormValues,
            iframeAddressUrl,
            iframeLoader,
            activeStep,
            planDetailsError,
        } = this.state;

        return (
            <GuestLayout>
                <div className="cp-page cp-page-guest">
                    <div className="cp-container-maps partial-width">

                        <IframeAddress
                            iframeAddressUrl={iframeAddressUrl}
                            iframeLoader={iframeLoader}
                            handleHideIframeLoader={this.handleHideIframeLoader}
                        />

                        <div className="cp-container-maps-overlay">
                            <div className="card-guest card-full-height">
                                <div className="card-guest-body">

                                    {isTokenLoading
                                        ? <Loader />
                                        : invalidToken
                                            ?
                                            <div className="form-section">
                                                <div className="message">
                                                    <div className="message-left text-danger">
                                                        <i className="fas fa-times"/>
                                                    </div>
                                                    <div className="message-right">
                                                        <h4 className="text-danger">{this.state.tokenErrorTitle}</h4>
                                                        <p className="message-code">{this.state.tokenErrorDescription}</p>
                                                        <p>{this.state.tokenErrorNote}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <Formik
                                                initialValues={initialFormValues}
                                                validationSchema={validationSchema(activeStep)}
                                                onSubmit={this.handleOnSubmit}
                                                enableReinitialize={true}
                                            >
                                                {(formProps) => (
                                                    <Form
                                                        onSubmit={formProps.handleSubmit}
                                                        className="cp-form"
                                                        autoComplete="off"
                                                        noValidate
                                                    >
                                                        <div className="form-sections-wrapper">
                                                            <StepContent
                                                                activeStep={activeStep}
                                                                planDetailsError={planDetailsError}
                                                                handleResetWizard={this.handleResetWizard}
                                                                handleChangeStep={this.handleChangeStep}
                                                                handleBackStep={this.handleBackStep}
                                                                populateServiceAndLines={this.populateServiceAndLines}
                                                                formProps={formProps}
                                                                {...this.props}
                                                            />
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </GuestLayout>
        );
    }
}

const getAvailabilityLoadingSelector = createLoadingSelector(['GET_ORDER_AVAILABILITY']);
const getCreateOrderLoader = createLoadingSelector(['CREATE_ORDER']);
const getCreateOrderError = selectErrorMessage(['CREATE_ORDER']);

const mapStateToProps = (state) => {

    const availabilityLoading = getAvailabilityLoadingSelector(state);
    const createOrderLoader = getCreateOrderLoader(state);
    const createOrderError = getCreateOrderError(state);

    const availablePlans = selectGuestOrder(state).availablePlans;
    const newOrderInfo = selectGuestOrder(state).newOrderInfo;
    const plansDetails = selectGuestOrder(state).plansDetails;
    const technicianAvailability = getSignupToService(state).orderAvailability;
    const signUpForServiceTokenResponse = getSignupToService(state).signUpForServiceTokenResponse;

    return {
        availablePlans,
        plansDetails,
        newOrderInfo,
        createOrderLoader,
        createOrderError,
        availabilityLoading,
        technicianAvailability,
        signUpForServiceTokenResponse
    };
};

const mapDispatchToProps = {
    validateSignupForServiceToken,
    getCheckAvailability,
    emptyGuestOrder,
    getPlanDetails,
    createNewOrder,
    emptyPlanDetails,
    getOrderAvailability,
    createOrderAppointment,
    completeSignupToService,
    patchServiceOrder,
    patchOrder
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupToService);

import {
	PAYMENT_PROVIDER_FIND_CURRENT_PROVIDER_ID

} from "../actions/paymentProviderActions";

const initialState = {
	currentProviderId: undefined,
};

export const paymentProviderReducer = (state = initialState, action) => {
	switch (action.type) {
		case PAYMENT_PROVIDER_FIND_CURRENT_PROVIDER_ID.SUCCESS:
			return {
				...state,
				currentProviderId: action.payload
			};
		case PAYMENT_PROVIDER_FIND_CURRENT_PROVIDER_ID.EMPTY:
			return {
				...state,
				currentProviderId: undefined
			};
		default:
			return state;
	}
};

import React, {Component, Fragment} from 'react'
import Loader from "../../../components/common/Spinners/Loader";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {connect} from "react-redux";
import PaymentForm from "./PaymentForm";
import {
    getMyAutoPayInfo,
    enableAutoPayACH,
    enableAutoPayCC,
    enableAutoPayTokenPaymentMethod,
    getAutoPayAvailableCC,
    getMyPaymentMethods
} from "../../../actions/homeInfoActions";
import {getAccountCreditBalance} from "../../../actions/accountInfoActions";
import {getMyAmounts, getMyInvoices} from "../../../actions/homeInfoActions";
import {
    createLoadingSelector,
    getPaymentProviders,
    getUserPermission,
    selectAccountInfo,
    selectHomeInfo
} from "../../../selectors";
import isEmpty, {asNoneNegative, formatAmount, trans} from "../../../utils/helpers";
import ACHForm from "./ACHForm";
import {toastr} from "react-redux-toastr";
import moment from "moment";
import {getCurrentPaymentProviderId} from "../../../actions/paymentProviderActions";
import {getCurrentPaymentProviderSupportsPaymentMethodStorage, getCurrentPaymentProviderSupportsOneTimePayment} from "../../../actions/paymentProviderActions";

const PAYMENT_METHOD = {
    NONE: 'NONE',
    CREDIT_CARD: 'CC',
    ACH: 'ACH',
}

const PAYMENT_RESULT = {
    NONE: 0,
    FAIL: 1,
    SUCCESS: 2,
    GENERIC: 3,
};

const PayNowSchema = Yup.object().shape({
    paymentMethod: Yup.string()
        .required('Required'),
    paymentAmount: Yup.number()
        .required('Required')
        .transform((value, originalValue) => Number(transformAmount(originalValue)))
        .typeError('Invalid Payment Amount')
        .positive('Invalid Payment Amount')
        .test(
            'is-valid-amount',
            'Invalid Payment Amount',
            value => (value + '').match(/^(\d+\.\d{0,2}|\d+\.?|\.\d{1,2})$/gm) !== null),

});

const transformAmount = (amount) => {
    return amount.replace(/,/g, '');
}

export class Payment extends Component {

    state = {
        paymentMessage:'',
        paymentResult: PAYMENT_RESULT.NONE,
        transactionResult: null,
        achDetails: null,
        autoPayLoading: false,
        autoPayEnabled: false,
        payProviderSupportsPaymentMethodStorage: false,
        payProviderSupportsOneTimePayment: false,
        defaultPaymentMethod: null
    }

    componentDidMount() {
        (async() => {
            const paymentMessage= await trans('en-us', 'paymentMessage');

            this.setState({paymentMessage})
        })()

        this.props.getAccountCreditBalance(this.props.accountInfo.id);
        this.props.getCurrentPaymentProviderSupportsPaymentMethodStorage().then(resp => {
            this.state.payProviderSupportsPaymentMethodStorage = resp.data.result;
            this.props.getMyPaymentMethods(this.props.accountInfo.id);
        });
        this.props.getCurrentPaymentProviderSupportsOneTimePayment().then(resp => {
            this.state.payProviderSupportsOneTimePayment = resp.data.result;
        });
        this.props.getCurrentPaymentProviderId();

        }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.resetFormOnClose === true && this.state.paymentResult === PAYMENT_RESULT.SUCCESS) {
            setTimeout(() => {this.setState({paymentResult: PAYMENT_RESULT.NONE})}, 500);
        }
    }

    constructor(props) {
        super(props);

        this.paymentFormRef = React.createRef();
    }

    handlePaymentMethodChange = (e, values, setFieldValue, setValues, resetForm) => {
        this.setState(prevState => (prevState.paymentResult !== PAYMENT_RESULT.NONE)? {paymentResult: PAYMENT_RESULT.NONE} : null);
        setFieldValue('paymentMethod', e.target.value);
        if (e.target.value === "MANAGE_PAYMENT_METHODS") {
            this.props.togglePanel(3)
            resetForm()
            // Wait for animation to finish
            setFieldValue('paymentMethod', "");
            setTimeout(() => {
                // Open Payment Methods Panel
                this.props.togglePanel(8)
            }, 300);
        }
    }

    /* Disable closing of ACH form like we do with Card payments */
    /*
    handlePaymentMethodChange = (e, values, setFieldValue, setValues) => {
        let newMethod = e.target.value;

        if (newMethod === "ACH") {

            toastr.confirm("To ensure a successful payment, please do not close this page until you see a confirmation message.", {
                cancelText: "Cancel",
                okText: "Proceed",
                onOk: () => {
                    this.setState(prevState => (prevState.paymentResult !== PAYMENT_RESULT.NONE) ? {paymentResult: PAYMENT_RESULT.NONE} : null);
                    setFieldValue('paymentMethod', newMethod);
                },
                onCancel: () => {}
            });
        }
        else {
            this.setState(prevState => (prevState.paymentResult !== PAYMENT_RESULT.NONE) ? {paymentResult: PAYMENT_RESULT.NONE} : null);
            setFieldValue('paymentMethod', newMethod);
        }
    }
    */

    handlePaymentSuccess = (setFieldValue, setSubmitting, transactionResult, resetForm) => {
        if (!isEmpty(transactionResult.result)) {
            this.setState({paymentResult: PAYMENT_RESULT.SUCCESS, transactionResult: transactionResult.result});
        } else {
            this.setState({paymentResult: PAYMENT_RESULT.SUCCESS, transactionResult: transactionResult});
        }
        this.props.getMyAmounts(this.props.accountInfo.id);
        this.props.getMyInvoices(this.props.accountInfo.id);
        setSubmitting(false);
        resetForm();
    }

    handlePaymentFail = (setFieldValue, setSubmitting, transactionResult) => {
        this.setState({paymentResult: PAYMENT_RESULT.FAIL, transactionResult:transactionResult});
        setSubmitting(false);
    }

    handlePaymentGeneric = (setFieldValue, setSubmitting, transactionResult) => {
        this.setState({paymentResult: PAYMENT_RESULT.GENERIC, transactionResult});
        this.props.getMyAmounts(this.props.accountInfo.id);
        this.props.getMyInvoices(this.props.accountInfo.id);
        setSubmitting(false);
    }

    getTransactionResultMessages = () => {
        const { transactionResult } = this.state;
        const sentences = [];

        if (transactionResult == null) {
            return sentences;
        }

        if (!isEmpty(transactionResult.responseText)) {
            sentences.push("Response: " + transactionResult.responseText + ".");
        }

        if ((!isEmpty(transactionResult.result)) && (!isEmpty(transactionResult.result.statusText))) {
            if (sentences.length > 0) {
                sentences.push(transactionResult.result.statusText);
            } else {
                sentences.push("Response: " + transactionResult.result.statusText);
            }
        }

        if (!isEmpty(transactionResult.authCode)) {
            sentences.push("Auth code: " + transactionResult.authCode + ".");
        }

        if (!isEmpty(transactionResult.transactionId)) {
            let transactionId = transactionResult.transactionId;
            if (transactionId.length > 10) {
                transactionId = "..." + transactionId.substring(transactionId.length - 10);
            }

            sentences.push("Transaction ID: " + transactionId + ".");
        }

        return sentences;
    }

    clearPaymentResult = () => {
        this.setState({paymentResult: PAYMENT_RESULT.NONE});
    }

    renderPaymentMethod = (values, errors, touched, setFieldValue, handleChange, handleBlur, setSubmitting, isSubmitting, resetForm) => {
        const { accountInfo, myAmounts, currentProviderId } = this.props;
        const { paymentResult } = this.state;

        const amountDue = myAmounts && myAmounts.amountDue || 0;
        const accountBalance = myAmounts && myAmounts.accountBalace || 0;

        const visible = paymentResult === PAYMENT_RESULT.NONE;

        if (this.state.payProviderSupportsPaymentMethodStorage) {
            return (visible &&
                <PaymentForm
                    ref={this.paymentFormRef}
                    accountInfo={accountInfo}
                    formTarget="payment_frame"
                    handlePaymentSuccess={(transactionResult) => this.handlePaymentSuccess(setFieldValue, setSubmitting, transactionResult, resetForm)}
                    handlePaymentFail={(transactionResult) => this.handlePaymentFail(setFieldValue, setSubmitting, transactionResult)}
                    handlePaymentGeneric={(transactionResult) => this.handlePaymentGeneric(setFieldValue, setSubmitting, transactionResult)}
                    togglePreventPanelClosing={this.props.togglePreventPanelClosing}
                    preventPanelClosing={this.props.preventPanelClosing}
                />
            )
        } else {
            switch (values.paymentMethod) {

                case PAYMENT_METHOD.ACH:
                    return (
                        <ACHForm
                            visible={visible}
                            accountBalance={accountBalance}
                            amountDue={amountDue}
                            currentProviderId={currentProviderId}
                            storeACHDetails={(achDetails) => this.storeACHDetails(achDetails)}
                            handlePaymentSuccess={(transactionResult) => this.handlePaymentSuccess(setFieldValue, setSubmitting, transactionResult)}
                            handlePaymentFail={(transactionResult) => this.handlePaymentFail(setFieldValue, setSubmitting, transactionResult)}
                            handlePaymentGeneric={(transactionResult) => this.handlePaymentGeneric(setFieldValue, setSubmitting, transactionResult)}
                            togglePreventPanelClosing={this.props.togglePreventPanelClosing}
                            preventPanelClosing={this.props.preventPanelClosing}
                            onSubmit={() => setSubmitting(true)}
                        />
                    )

                case PAYMENT_METHOD.CREDIT_CARD:
                    return (visible &&
                        <PaymentForm
                            ref={this.paymentFormRef}
                            accountInfo={accountInfo}
                            formTarget="payment_frame"
                            handlePaymentSuccess={(transactionResult) => this.handlePaymentSuccess(setFieldValue, setSubmitting, transactionResult)}
                            handlePaymentFail={(transactionResult) => this.handlePaymentFail(setFieldValue, setSubmitting, transactionResult)}
                            handlePaymentGeneric={(transactionResult) => this.handlePaymentGeneric(setFieldValue, setSubmitting, transactionResult)}
                            togglePreventPanelClosing={this.props.togglePreventPanelClosing}
                            preventPanelClosing={this.props.preventPanelClosing}
                        />
                    )

                default:
                    return null;
            }
        }
    };

    handleSubmit = (values, { setSubmitting, setFieldValue, setFieldError, resetForm}) => {
        const { paymentMethod, paymentAmount } = values;
        const { guestOrderConfig } = window;
        const { accountInfo, creditBalance, myPaymentMethods } = this.props;

        if (paymentMethod === "") {
            alert("Select payment method first!")
        }
        if (paymentMethod === "ONE_TIME_PAYMENT") {
            this.props.togglePanel(3)
            // Wait for animation to finish
            setFieldValue('oneTimePayment', "");
            setTimeout(() => {
            // Open One Time Payment Panel
                this.props.togglePanel(10, {paymentAmount: values.paymentAmount})}
                , 300
            );
            resetForm()
        } else {
            toastr.confirm("To ensure a successful payment, please do not close this page until you see a confirmation message.", {
                cancelText: "Cancel",
                okText: "Proceed",
                onOk: () => {

                    this.props.getMyPaymentMethods(this.props.accountInfo.id);
                    this.setState({paymentResult: PAYMENT_RESULT.NONE, transactionResult: null});

                    const data = {
                        paymentOption: 'ALL_UNPAID_INVOICES',
                        paymentAmount: transformAmount(paymentAmount),
                        iframe: true,
                    };

                    if (guestOrderConfig && guestOrderConfig.defaultLocationId) {
                        data.locationId = guestOrderConfig.defaultLocationId;
                    } else {
                        console.warn("No default location ID");
                    }

                    let usablePaymentMethod = paymentMethod
                    if ((this.state.payProviderSupportsPaymentMethodStorage) && (Object.values(PAYMENT_METHOD).includes(usablePaymentMethod)) && (!isEmpty(this.state.defaultPaymentMethod))) {
                        // This means that paymentMethod is still the initial value and the payment method has not been selected in the drop down. use the default payment method
                        usablePaymentMethod = this.state.defaultPaymentMethod.token
                    }
                    if (!isEmpty(usablePaymentMethod)) {
                        switch (usablePaymentMethod) {
                            case PAYMENT_METHOD.CREDIT_CARD:
                                data.paymentFormType = usablePaymentMethod;
                                this.paymentFormRef.current.submitPaymentForm(data);
                                break;
                            default:
                                // If this is not a valid paymentMethod, assume it is a token and try to submit using it
                                data.token = usablePaymentMethod
                                this.paymentFormRef.current.submitPaymentWithToken(data);
                        }
                    }
                },
                onCancel: () => {
                    setSubmitting(false);
                }
            });
        }
    };

    storeACHDetails = (achDetails) => {
        this.setState({achDetails: {
            accountId: this.props.accountInfo.id,
            routingNumber: achDetails.routingNumber,
            accountNumber: achDetails.accountNumber,
            accountType: achDetails.accountType,
            cardHolderName: achDetails.holderName,
        }});
    };

    handleEnableAutoPay = () => {
        const {achDetails, transactionResult} = this.state;
        const {accountInfo, enableAutoPayACH, enableAutoPayCC, getMyAutoPayInfo, enableAutoPayTokenPaymentMethod} = this.props;

        // Enable AutoPay by ACH
        if (!isEmpty(achDetails)) {
            this.setState({autoPayLoading: true});

            enableAutoPayACH(achDetails).then(response => {
                this.setState({autoPayLoading: false, autoPayEnabled: true});
                getMyAutoPayInfo(accountInfo.id);
                toastr.success('AutoPay is now active!', { timeOut: 2000, position: 'top-center' });
            });
        }

        // Enable AutoPay by Card
        else if (!isEmpty(transactionResult)) {
            this.setState({autoPayLoading: true});

            if (!isEmpty(transactionResult.token)) {
                const info = {token: transactionResult.token, accountId:accountInfo.id};
                enableAutoPayTokenPaymentMethod(info).then(response =>{
                    this.setState({autopayUpdated: true, myAutoPayInfoLoading: 'info'}, () => {
                        this.props.getMyAutoPayInfo(this.props.accountInfo.id).then(response => {
                            this.setState({myAutoPayInfoLoading: false});
                        });
                    });
                });

            } else {
                enableAutoPayCC({
                    accountId: accountInfo.id,
                    cardExpirationDate: moment(transactionResult.cardExpirationDate).format("MM/YY"),
                    cardToken: transactionResult.cardToken,
                    displayCardNumber: transactionResult.displayCardNumber,
                    type: transactionResult.paymentType,
                    cc: {
                        cardExpirationDate: moment(transactionResult.cardExpirationDate).format("MM/YY"),
                        cardToken: transactionResult.cardToken,
                        displayCardNumber: transactionResult.displayCardNumber,
                        type: transactionResult.paymentType,
                    },
                }).then(response => {
                    this.setState({autoPayLoading: false, autoPayEnabled: true});
                    getMyAutoPayInfo(accountInfo.id);
                    toastr.success('AutoPay is now active!', { timeOut: 2000, position: 'top-center' });
                });
            }
        }
    }

    render() {

        const {
            paymentResult,
            autoPayLoading,
            autoPayEnabled,
            payProviderSupportsPaymentMethodStorage,
            payProviderSupportsOneTimePayment,
        } = this.state;

        const {
            myAmounts,
            myAmountsLoader,
            creditBalance,
            creditBalanceLoading,
            currentProviderId,
            myPaymentMethodsLoader,
            myPaymentMethods,
            canManagePaymentMethods,
            canMakeOneTimePayment
        } = this.props;

        const amountDue = myAmounts && myAmounts.amountDue;
        const accountBalance = myAmounts && myAmounts.accountBalace;

        if ((!isEmpty(myPaymentMethods)) && (isEmpty(this.state.defaultPaymentMethod))) {
            this.state.defaultPaymentMethod = myPaymentMethods[0];
        }

        return (
            <div className="cp-form">
                <div className="card-product">
                    <div className="card-body">

                        <p>{this.state.paymentMessage}</p>

                        {paymentResult !== PAYMENT_RESULT.SUCCESS &&
                        <div className="product-price">
                            <div className="package">
                                <div className="package-header">

                                    <span className="package-left">Total Amount Due</span>

                                    <span className="package-right">
                                        {myAmountsLoader && <i className="fas fa-spinner fa-spin"/>}
                                        {!myAmountsLoader && '$' + formatAmount(amountDue)}
                                    </span>

                                </div>
                            </div>
                            <div className="package">
                                <div className="package-header">
                                    <span className="package-left">Total Balance</span>
                                    <span className="package-right">
                                        {myAmountsLoader && <i className="fas fa-spinner fa-spin"/>}
                                        {!myAmountsLoader && '$' + formatAmount(accountBalance)}
                                    </span>
                                </div>
                            </div>
                        </div>
                        }
                        {myPaymentMethodsLoader &&
                        <Loader />
                        }
                       
                        <Formik
                            initialValues={{
                                paymentMethod: currentProviderId && PAYMENT_METHOD.CREDIT_CARD,
                                paymentAmount: formatAmount(asNoneNegative(accountBalance)),
                            }}
                            enableReinitialize
                            validationSchema={PayNowSchema}
                            onSubmit={this.handleSubmit}
                            render={(props) => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue,
                                    setValues,
                                    setSubmitting,
                                    setIn,
                                    resetForm
                                } = props;

                                const { paymentMethod } = values;

                                return (
                                    <Fragment>
                                    {!myAmountsLoader && (paymentResult !== PAYMENT_RESULT.SUCCESS && !isSubmitting) &&

                                    <Form
                                        onSubmit={handleSubmit}
                                        id="mainForm"
                                        autoComplete="off"
                                        className={"cmv-form" + (paymentResult === PAYMENT_RESULT.SUCCESS ? " d-none" : "")}
                                    >
                                        <div className="form-section form-section-customer-payment-content">
                                            <div className="form-section form-section-customer-payment-options">

                                                <div className="form-row">
                                                    <div className="form-group col-sm-7">

                                                        <label>Payment Method</label>

                                                        {!payProviderSupportsPaymentMethodStorage &&
                                                            <select
                                                                name="paymentMethod"
                                                                value={paymentMethod}
                                                                disabled={isSubmitting || !currentProviderId}
                                                                onChange={(e) =>
                                                                    this.handlePaymentMethodChange(
                                                                        e,
                                                                        values,
                                                                        setFieldValue,
                                                                        setValues,
                                                                        resetForm
                                                                    )}
                                                                id="sel-customer-pmnt-method"
                                                                className={'form-control form-control-sm' + (errors.paymentMethod && touched.paymentMethod ? ' is-invalid' : '')}
                                                            >
                                                                <option value={PAYMENT_METHOD.CREDIT_CARD}>Debit / Credit Card</option>
                                                                <option value={PAYMENT_METHOD.ACH}>Checking / Savings Account</option>
                                                            </select>
                                                        }
                                                        {payProviderSupportsPaymentMethodStorage && !myPaymentMethodsLoader &&
                                                            <select
                                                                name="paymentMethod"
                                                                value={paymentMethod}
                                                                disabled={isSubmitting || !currentProviderId}
                                                                onChange={(e) =>
                                                                    this.handlePaymentMethodChange(
                                                                        e,
                                                                        values,
                                                                        setFieldValue,
                                                                        setValues,
                                                                        resetForm
                                                                    )}
                                                                id="sel-customer-pmnt-method"
                                                                className={'form-control form-control-sm' + (errors.paymentMethod && touched.paymentMethod ? ' is-invalid' : '')}
                                                            >
                                                                <option value="" key="PLEASE_CHOOSE">Select payment method</option>
                                                                {myPaymentMethods &&
                                                                <>
                                                                    {myPaymentMethods.map((myPaymentMethod) => (
                                                                        <option value={myPaymentMethod.token} key={myPaymentMethod.token}>{myPaymentMethod.paymentInformation}{myPaymentMethod.autoPay ? ' (AutoPay)' : ''}</option>
                                                                    ))}
                                                                </>
                                                                }
                                                                {canManagePaymentMethods &&
                                                                    <option value="MANAGE_PAYMENT_METHODS" key="MANAGE_PAYMENT_METHODS">Manage Payment Methods</option>
                                                                }
                                                                {payProviderSupportsOneTimePayment && canMakeOneTimePayment &&
                                                                    <option value="ONE_TIME_PAYMENT" key="ONE_TIME_PAYMENT">One Time Payment</option>
                                                                }
                                                            </select>
                                                        }

                                                        {errors.paymentMethod && touched.paymentMethod && (
                                                            <div className="invalid-feedback">{errors.paymentMethod}</div>
                                                        )}

                                                    </div>

                                                    {paymentMethod !== PAYMENT_METHOD.ACH &&
                                                    <Fragment>
                                                    <div className="form-group col-sm-5">
                                                        <label>Payment Amount</label>

                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">$</span>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                data-type="currency"
                                                                name="paymentAmount"
                                                                disabled={isSubmitting}
                                                                className={'form-control' + (errors.paymentAmount && touched.paymentAmount ? ' is-invalid' : '')}
                                                                value={values.paymentAmount}
                                                                data-amount={''}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                            />
                                                            {errors.paymentAmount &&
                                                            touched.paymentAmount && (
                                                                <div className="invalid-feedback">
                                                                    {errors.paymentAmount}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="form-group col-12 text-right">
                                                        <span className="package-right" style={{fontSize:'0.875rem', color:'#259F52'}}>
                                                            {myAmountsLoader && <i className="fas fa-spinner fa-spin"/>}
                                                            {!myAmountsLoader && myAmounts && myAmounts.postPaymentAmount && `Payment of $${myAmounts.postPaymentAmount.toFixed(2)} is pending`}
                                                        </span>
                                                        {paymentResult !== PAYMENT_RESULT.SUCCESS &&
                                                            <div className="btn-group-sm">
                                                                <button
                                                                    disabled={isSubmitting}
                                                                    type="submit" form="mainForm" className="btn btn-primary"
                                                                    id="btn-pmnt-submit"
                                                                >
                                                                    {this.state.payProviderSupportsPaymentMethodStorage ? 'Submit Payment' : 'Continue to Pay'}
                                                                </button>
                                                            </div>
                                                        }
                                                    </div>
                                                    </Fragment>}
                                                </div>

                                            </div>
                                        </div>
                                    </Form>}

                                    <div className={"form-section form-section-customer-payment-management" + (paymentResult === PAYMENT_RESULT.SUCCESS ? " form-section-customer-payment-management-success" : "")}>

                                        {this.renderPaymentMethod(
                                                values,
                                                errors,
                                                touched,
                                                setFieldValue,
                                                handleChange,
                                                handleBlur,
                                                setSubmitting,
                                                isSubmitting,
                                                resetForm
                                            )
                                        }

                                        {(paymentMethod !== PAYMENT_METHOD.CREDIT_CARD
                                            && paymentMethod !== PAYMENT_METHOD.ACH
                                            && paymentMethod !== PAYMENT_METHOD.NONE)
                                            && isSubmitting && (
                                            <Loader/>
                                        )}

                                        {paymentResult === PAYMENT_RESULT.SUCCESS &&
                                            <div className="message">
                                                <div className="message-left">
                                                    <i className="fas fa-check-circle" />
                                                </div>
                                                <div className="message-right">
                                                    <h4>Payment successful</h4>
                                                    {this.getTransactionResultMessages().map(msg => <Fragment><span>{msg}</span><br/></Fragment>)}
                                                    <p>You'll receive details about it soon.</p>

                                                    {isEmpty(this.props.myAutoPayInfo) &&
                                                    <>
                                                        <p>Enable AutoPay with the payment details you just used by pressing the button below!</p>
                                                        <br/>
                                                        <button
                                                            className="btn btn-primary"
                                                            onClick={this.handleEnableAutoPay}
                                                            disabled={autoPayLoading}
                                                        >
                                                            Enable AutoPay {autoPayLoading && <i className="fas fa-fw fa-spin fa-spinner" />}
                                                        </button>
                                                    </>
                                                    }

                                                    {!isEmpty(this.props.myAutoPayInfo) &&
                                                    <>
                                                        <p>AutoPay is now active!</p>
                                                    </>
                                                    }

                                                </div>
                                            </div>
                                        }

                                        {paymentResult === PAYMENT_RESULT.FAIL &&
                                            <>
                                            <br/>
                                            <div className="message">
                                                <div className="message-left text-danger">
                                                    <i className="fas fa-times" onClick={this.clearPaymentResult} style={{cursor: 'pointer'}}/>
                                                </div>
                                                <div className="message-right">
                                                    <h4 className="text-danger">Transaction failed!</h4>
                                                    {this.getTransactionResultMessages().map(msg => <Fragment><span>{msg}</span><br/></Fragment>)}
                                                </div>
                                            </div>
                                            </>
                                        }

                                        {paymentResult === PAYMENT_RESULT.GENERIC &&
                                            <>
                                            <br/>
                                            <div className="message">
                                                <div className="message-left text-muted">
                                                    <i className="fas fa-info-circle" onClick={this.clearPaymentResult} style={{cursor: 'pointer'}}/>
                                                </div>
                                                <div className="message-right">
                                                    <h4 className="text-muted">Transaction details</h4>
                                                    {this.getTransactionResultMessages().map(msg => <Fragment><span>{msg}</span><br/></Fragment>)}
                                                </div>
                                            </div>
                                            </>
                                        }

                                    </div>
                                    {paymentMethod === PAYMENT_METHOD.CREDIT_CARD &&
                                        <iframe
                                            name="payment_frame"
                                            frameBorder="0"
                                            style={
                                                {
                                                    position: 'relative',
                                                    zIndex: 0,
                                                    display: isSubmitting ? 'block' : 'none',
                                                    width: '100%',
                                                    minHeight: values.paymentMethod === PAYMENT_METHOD.CREDIT_CARD ? '1530px' : '1250px',
                                                    marginTop: '-111px',
                                                    borderRadius: '0.5rem'
                                                }
                                            }>
                                        </iframe>
                                     }

                                </Fragment>
                            )}} />
                    </div>
                </div>
            </div>
        )
    }
}

const accountCreditBalanceLoadingSelector = createLoadingSelector(['GET_ACCOUNT_CREDIT_BALANCE']);
const getMyPaymentMethodsLoader = createLoadingSelector(['MY_PAYMENT_METHODS']);

const mapStateToProps = (state) => {
    const myPaymentMethodsLoader = getMyPaymentMethodsLoader(state);
    const myPaymentMethods = selectHomeInfo(state).myPaymentMethods;
    const { accountInfo, creditBalance } = selectAccountInfo(state);
    const creditBalanceLoading = accountCreditBalanceLoadingSelector(state);
    const currentProviderId = getPaymentProviders(state).currentProviderId;
    const canManagePaymentMethods = getUserPermission(state, "API", "API_MANAGE_PAYMENT_METHODS");
    const canMakeOneTimePayment = getUserPermission(state, "BILLING", "BILLING_PAYMENT_ONE_TIME_PAYMENT");


    return {
        accountInfo,
        creditBalance,
        creditBalanceLoading,
        currentProviderId,
        myPaymentMethodsLoader,
        myPaymentMethods,
        canManagePaymentMethods,
        canMakeOneTimePayment
    };
};

const mapDispatchToProps = {
    getCurrentPaymentProviderId,
    getCurrentPaymentProviderSupportsPaymentMethodStorage: getCurrentPaymentProviderSupportsPaymentMethodStorage,
    getCurrentPaymentProviderSupportsOneTimePayment: getCurrentPaymentProviderSupportsOneTimePayment,
    getMyAmounts,
    getMyInvoices,
    getAccountCreditBalance,
    getMyAutoPayInfo,
    enableAutoPayACH,
    enableAutoPayCC,
    enableAutoPayTokenPaymentMethod,
    getAutoPayAvailableCC,
    getMyPaymentMethods
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Payment);

import React, {Component, Fragment} from 'react'
import {connect} from "react-redux";
import PaymentMethodForm from "../PaymentMethods/PaymentMethodForm";
import {selectAccountInfo} from "../../../selectors";
import Loader from "../../../components/common/Spinners/Loader";

export class OneTimePayment extends Component {

    state = {
        paymentFormDisplayed: false,
    }

    componentDidMount() {
        if (!this.state.paymentFormDisplayed) {

            const { accountInfo, paymentAmount} = this.props;
            const data = { iframe: true, paymentAmount: paymentAmount};
            const { guestOrderConfig } = window;

            data.submitType = "managePaymentMethods";
            if (guestOrderConfig && guestOrderConfig.defaultLocationId) {
                data.locationId = guestOrderConfig.defaultLocationId;
            } else {
                console.warn("No default location ID");
            }

            data.accountId = accountInfo.id
            this.paymentMethodFormRef.current.createPaymentMethod(data);
            this.setState({paymentFormDisplayed: true});
        }
    }

    constructor(props) {
        super(props);
        this.paymentMethodFormRef = React.createRef();
    }

    render() {
        const { accountInfo, paymentAmount } = this.props;

        return (
            <div className="cp-form">
                <div className="card-product">
                    <div className="card-body">
                        <Loader/>
                        <iframe
                            name="payment_method_frame"
                            frameBorder="0"
                            style={
                                {
                                    position: 'relative',
                                    zIndex: 0,
                                    display: 'block',
                                    width: '100%',
                                    minHeight:'1030px',
                                    marginTop: '-111px',
                                    borderRadius: '0.5rem'
                                }
                            }>
                        </iframe>

                        <PaymentMethodForm
                            ref={this.paymentMethodFormRef}
                            accountInfo={accountInfo}
                            formTarget="payment_method_frame"
                            togglePreventPanelClosing={this.props.togglePreventPanelClosing}
                            preventPanelClosing={this.props.preventPanelClosing}
                            paymentFormSubmitted = {false}
                            isOneTimePayment = {true}
                        />

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { accountInfo } = selectAccountInfo(state);

    return {
        accountInfo,
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(OneTimePayment);

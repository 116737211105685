import {
	GET_PAYMENT_FORM,
	GET_PAYMENT_STATUS
} from '../actions/payment.actions';
import {CREATE_PAYMENT_METHOD_FORM} from "../actions/paymentMethod.actions";

let initialState = {
	formData: null,
	paymentStatus: null,
	paymentStatusCount: 0
};

export const paymentReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_PAYMENT_FORM.SUCCESS:
			return {
				...state,
				formData: action.payload
			}
		case GET_PAYMENT_FORM.EMPTY:
			return {
				...state,
				formData: {}
			}
		case CREATE_PAYMENT_METHOD_FORM.SUCCESS:
			return {
				...state,
				formData: action.payload
			}
		case CREATE_PAYMENT_METHOD_FORM.EMPTY:
			return {
				...state,
				formData: {}
			}
		case GET_PAYMENT_STATUS.SUCCESS:
			return {
				...state,
				paymentStatus: action.payload,
				paymentStatusCount: state.paymentStatusCount + 1
			}
		case GET_PAYMENT_STATUS.EMPTY:
			return {
				...state,
				paymentStatus: {},
				paymentStatusCount: 0
			}
		default:
			return state;
	}
};

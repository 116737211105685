import React, {Component, Fragment} from 'react';
import {get} from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import GuestOrderInput from '../../../../components/common/Formik/GuestOrderInput';
import NumberFormatInput from '../../../../components/common/Formik/NumberFormatInput';
import isEmpty from "../../../../utils/helpers";
import {trans} from "../../../../utils/helpers";

class CustomerInfoStep extends Component {
	state={
		title:'',
		termsAndCondition:'',
		termsAndConditionsLink:'',
		fieldLabels: {mobileNumber: 'Mobile Phone', company: 'Company',
			businessEmail: 'Business Email', businessNumber: 'Business Phone',
			firstName: 'First Name', lastName: 'Last Name', personalEmail: 'Email'}
	}
	componentDidMount() {
		(async() => {
			const title=await trans('en-us', 'guestOrderScreen.contactInfo.title');
			const termsAndCondition=await trans('en-us', 'guestOrderScreen.contactInfo.termsAndCondition');
			const termsAndConditionsLink=await trans('en-us', 'termsAndConditionsLink');

			this.setState({title,termsAndCondition,termsAndConditionsLink});
			this.props.fetchRequiredOrOptionalFields(this.props.values.accountTypeId)
			this.props.setSubmitting(false)
		})()

		const {setTouched} = this.props;
		setTouched({});
	}

	dynamicallyRenderFormFields = (field) => {
		let optional
		field.required !== true ? optional = "(optional)" : optional = ''
		const {touched, handleChange, handleBlur, errors, values, setFieldValue, editMode} = this.props
		if(field.name.toUpperCase().includes('NUMBER')) {
			return (
				<NumberFormatInput
					key={field.index}
					type="text"
					label={this.state.fieldLabels[field.name] + ' ' + optional}
					name={"account.accountHolder." + `${field.name}.number`}
					size=""
					errors={errors}
					value={`${values.account.accountHolder[field.name].number}`}
					touched={touched}
					onChange={(e) =>
						setFieldValue(
							`account.accountHolder.${field.name}.number`,
							e.target.value.replace(/[()+,' '-]/g, '').trim()
						)}
					onBlur={handleBlur}
					isDisabled={editMode}
				/>
			)
		} else if(field.name.toUpperCase().includes('EMAIL')) {
			let confirmEmailValue
			let confirmEmailName

			if(field.name.toUpperCase() === "PERSONALEMAIL") {
				confirmEmailValue = values.account.accountHolder.confirmEmail
				confirmEmailName = `account.accountHolder.confirmEmail`
			} else {
				confirmEmailValue = values.account.accountHolder.confirmBusinessEmail
				confirmEmailName = `account.accountHolder.confirmBusinessEmail`
			}

			return (
				<>
					<GuestOrderInput
						key={field.index}
						type="text"
						label={this.state.fieldLabels[field.name] + ' ' + optional}
						name={`account.accountHolder.${field.name}`}
						size=""
						errors={errors}
						value={`${values.account.accountHolder[field.name]}`}
						touched={touched}
						onChange={handleChange}
						onBlur={handleBlur}
						isDisabled={editMode}
					/>
					<GuestOrderInput
						key={field.index + 1}
						type="text"
						label={`Confirm ` + this.state.fieldLabels[field.name] + ' ' + optional}
						name={confirmEmailName}
						size=""
						errors={errors}
						value={confirmEmailValue}
						touched={touched}
						onChange={handleChange}
						onBlur={handleBlur}
						isDisabled={editMode}
					/>
				</>
			)
		} else {
			return (
				<GuestOrderInput
					key={field.index}
					type="text"
					label={this.state.fieldLabels[field.name] + ' ' + optional}
					name={`account.accountHolder.${field.name}`}
					size=""
					errors={errors}
					value={`${values.account.accountHolder[field.name]}`}
					touched={touched}
					onChange={(e) => {setFieldValue(`account.accountHolder.${field.name}`, e.target.value.replace(/[^a-z\-A-Z\s']/g, '').trimStart())}}
					onBlur={handleBlur}
					isDisabled={editMode}
				/>
			)
		}
	}

	render() {
		const {
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			setFieldValue,
			createAccountAndOrderLoader,
			createAccountAndOrderError,
			handleChangeStep,
			newAccountInfoData
		} = this.props;

		const editMode = !isEmpty(newAccountInfoData);

		return (
			<div className="form-section" id="fs5">

				<h4>{this.state.title}</h4>
				<p><i className="fas fa-map-marker-alt" /> {values.formattedAddress}</p>
				<p className="text-center text-xs text-muted d-block d-sm-none">Scroll down to see map</p>


				{this.props.fields.length && this.props.fields.map((field, index) => (
					this.dynamicallyRenderFormFields(field)
				))
				}

				{!editMode && (
					<Fragment>
						<p className="text-xs">{this.state.termsAndCondition}</p>
						<div className="form-group custom-checkbox">
							<input
								type="checkbox"
								className="custom-control-input"
								id="agree-tc"
								name="account.termsAndCondition"
								checked={editMode? true: values.account.termsAndCondition}
								onChange={(e) => setFieldValue('account.termsAndCondition', e.target.checked)}
								onBlur={handleBlur}
								disabled={editMode}
							/>
							<label className="custom-control-label" htmlFor="agree-tc">
								{ReactHtmlParser(
									this.state.termsAndConditionsLink
								)}
							</label>
							{get(errors, `account.termsAndCondition`) &&
								get(touched, `account.termsAndCondition`) && (
									<div style={{display: 'block'}} className="invalid-feedback">
										{get(errors, `account.termsAndCondition`)}
									</div>
								)}
						</div>
					</Fragment>
				)}

				{createAccountAndOrderError && <p style={{ color: 'red' }}>{createAccountAndOrderError}</p>}

				<div className="form-group form-group-footer">
					<button
						onClick={() => handleChangeStep(editMode? 'confirmation': 'packages-and-products')}
						type="button"
						id=""
						className="btn btn-quick"
						data-target="fs5"
					>
						Back
					</button>
					<button
						disabled={createAccountAndOrderLoader || !isEmpty(errors) || this.props.formSubmitting}
						type="submit"
						id=""
						className="btn btn-primary"
						data-target="fs7"
					>
						Submit {createAccountAndOrderLoader && <i className="fas fa-fw fa-spin fa-spinner" />}
					</button>
				</div>

			</div>
		);
	}
}





export default CustomerInfoStep
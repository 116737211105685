import React, { Fragment } from 'react';
import GuestNavbar from './NavBar/GuestNavbar';
import Footer from './Footer';

const GuestLayout = (props) => {
	return (
		<Fragment>
			<GuestNavbar {...props} />
			<main>
				{(window.backgroundVideo !== '' &&
				<video className="cp-video-bg" playsInline autoPlay muted loop>
					<source src={`${process.env.PUBLIC_URL}/themes/active/img/background.mp4`} type="video/mp4"/>
				</video>
				)}
				{props.children}
			</main>
			<Footer />
		</Fragment>
	);
};

export default GuestLayout;

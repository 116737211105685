import * as Yup from 'yup';

export const validationSchema = (activeStep) => {
	
	const messages = window.validationMessages; // This is from external js file

	if (activeStep === 'confirmation') {
		return Yup.object().shape({
			account: Yup.object().shape({
				termsAndCondition: Yup.bool().oneOf([true], messages.termsAndCondition)
			})
		});
	}
	else {
		return Yup.object().shape({
		})
	}
};

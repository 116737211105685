import React, { Component, Fragment } from 'react';

class Confirmation extends Component {
	state = {
		startOrderStatus: null,
		isOrderStartedLoading: false
	};

	render() {
		const { updatedServiceInfoData, showHideManagePlan } = this.props;

		return (
			<div className="form-section">

				<div className="message">

					<div className="message-left">
						<i className="fas fa-check-circle" />
					</div>

					<div className="message-right">
						<h4>Changes have been submitted and will get processed momentarily.</h4>
						<p className="message-code">Reference # is {updatedServiceInfoData.id}</p>
					</div>

				</div>

				<div className="form-group form-group-button">

					<button type="button" className="btn btn-primary btn-block" onClick={showHideManagePlan}>Close &amp; Continue</button>

				</div>

			</div>
		);
	}
}

export default Confirmation;

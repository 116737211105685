import axiosInstance from '../utils/axios-instance';
import {actionCreator, createRequestTypes} from '../utils/helpers';




export const ACCOUNT_AUTOPAY_STATUS = createRequestTypes('ACCOUNT_AUTOPAY_STATUS');
export const AUTOPAY_WARNING_MESSAGE = createRequestTypes('AUTOPAY_WARNING_MESSAGE');

export const getAccountAutoPay = (accountId) => async (dispatch) => {
    dispatch(actionCreator.request(ACCOUNT_AUTOPAY_STATUS))
    dispatch(actionCreator.request(AUTOPAY_WARNING_MESSAGE));
    try {
        const params = {
            searchType: 'ACCOUNTID',
            term: accountId,
            limit: 20
        }

        const response = await axiosInstance.get(`/accounts`, {params});
        dispatch(actionCreator.success(ACCOUNT_AUTOPAY_STATUS, response.data[0].autoPay));

        const res = await axiosInstance.get(`/autopay/${accountId}`);
        if(!(res.data.apiAccountAutoPayACH != null || res.data.apiAccountAutoPayCC != null || res.data.apiAccountAutoPayToken != null) && response.data[0].autoPay) {
            dispatch(actionCreator.success(AUTOPAY_WARNING_MESSAGE, true));
        } else {
            dispatch(actionCreator.success(AUTOPAY_WARNING_MESSAGE, false));
        }
        return response.data[0].autoPay;
    } catch (err) {
        dispatch(actionCreator.failure(ACCOUNT_AUTOPAY_STATUS))
    }
};


import React, { Component } from 'react';
import {trans} from "../../../utils/helpers";

class PinOptions extends Component {
	state={
		title:'',
		accountPin:'',
		obtainPin:'',
		doNotHavePinMobile:'',
		doNotHavePinEmail:'',
		temporaryPinEmail:'',
		temporaryPinMobile:''
	}

	componentDidMount(){
		(async() => {
		const title=await trans('en-us', 'registerScreen.pinOptionsSection.title');
		const accountPin=await trans('en-us', 'registerScreen.pinOptionsSection.accountPin');
		const obtainPin=await trans('en-us', 'registerScreen.pinOptionsSection.obtainPin');
		const doNotHavePinMobile=await trans('en-us', 'registerScreen.pinOptionsSection.doNotHavePinMobile');
		const doNotHavePinEmail=await trans('en-us', 'registerScreen.pinOptionsSection.doNotHavePinEmail');
		const temporaryPinEmail=await trans('en-us', 'registerScreen.pinOptionsSection.temporaryPinEmail');
		const temporaryPinMobile=await trans('en-us', 'registerScreen.pinOptionsSection.temporaryPinMobile');
			
		this.setState({title,accountPin,obtainPin,doNotHavePinMobile,doNotHavePinEmail,temporaryPinEmail,temporaryPinMobile});
		})()
	}
	handleChangeType = (type) => {
		const { setFieldValue, values, checkedAccountData } = this.props;

		if (type !== values.pinType) {
			if (type === 'accountPin') {
				setFieldValue('pinType', 'accountPin');
				setFieldValue('securityPhrase', '');
			} else if (type === 'mobilePin') {
				setFieldValue('pinType', 'mobilePin');
				setFieldValue('pinValue', checkedAccountData.mobileNumber);
			} else if (type === 'emailPin') {
				setFieldValue('pinType', 'emailPin');
				setFieldValue('pinValue', checkedAccountData.email);
			}
		}
	};

	// resetAuthCodeState = (setFieldValue) => {
	// 	this.setState({
	// 		showVerificationMobileNumber: false,
	// 		isAuthCodeValid: null,
	// 		authCode: ''
	// 	});
	// 	setFieldValue('accountHolder.mobileNumber.verified', false);
	// };

	onSubmitAuthCode = (number) => {
		const { checkedAccountData, values } = this.props;
		let value = '';

		// if (values.pinType === 'accountPin') {
		// } else if (values.pinType === 'mobilePin') {
		// } else if (values.pinType === 'emailPin') {
		// }

		let data = {
			contactNumberId: checkedAccountData.contactNumberId,
			requester: values.pinValue
		};
		// if there is no error - show field for verification
		this.props.sendAuthCode(data).then((response) => {
			// To Do - need to catch exeption - error
		});
	};

	// onChangeAuthCodeHandler = (e) => {
	// 	this.setState({
	// 		authCode: e.target.value
	// 	});
	// };

	// onSubmitVerifyAuthCode = (number, setFieldValue) => {
	// 	let data = {
	// 		authcode: this.state.authCode,
	// 		phoneNumber: number,
	// 		requester: 'alex'
	// 	};
	// 	this.props.verifyAuthCode(data).then((response) => {
	// 		if (response.data.code === 200) {
	// 			this.setState({ isAuthCodeValid: true });
	// 			setFieldValue('accountHolder.mobileNumber.verified', true);
	// 		}
	// 		if (response.data.error) {
	// 			this.setState({ isAuthCodeValid: false });
	// 			setFieldValue('accountHolder.mobileNumber.verified', false);
	// 		}
	// 	});
	// };

	render() {
		const { values, checkedAccountData, handleChange, handleGoBack } = this.props;

		return (
			<div class="form-section fx-from-right" id="fs3">
				<h3 class="text-center">{this.state.title}</h3>
				<div class="form-check checkbox-slider checkbox-slider--b-flat">
					<label class="w-100">
						<input
							type="radio"
							name="cp-customer-reg-pin-options"
							checked={values.pinType === 'accountPin'}
							onChange={() => this.handleChangeType('accountPin')}
						/>
						<span class="w-100">
							{this.state.accountPin}
							<p class="text-muted text-xs mb-1">
								{this.state.obtainPin}{' '}
								<span class="nowrap">(800) 555-6666</span>.
 							</p>
							<div
								class="cp-customer-reg-input mb-2"
								className={
									values.pinType === 'accountPin' ? (
										'cp-customer-reg-input mb-2'
									) : (
											'cp-customer-reg-input mb-2 d-none'
										)
								}
							>
								<input
									type="text"
									class="form-control"
									name="pinValue"
									placeholder="Please type Account PIN here"
									onChange={handleChange}
								/>
							</div>
						</span>
					</label>
				</div>

				<div class="form-check checkbox-slider checkbox-slider--b-flat">
					<label class="w-100">
						<input
							type="radio"
							name="cp-customer-reg-pin-options"
							checked={values.pinType === 'mobilePin'}
							onChange={() => this.handleChangeType('mobilePin')}
						/>
						<span class="w-100">
							{this.state.doNotHavePinMobile}
							<p class="text-muted text-xs mb-1">
								{this.state.temporaryPinMobile}{' '}
							</p>
							<div
								className={
									values.pinType === 'mobilePin' ? (
										'cp-customer-reg-input mb-2 input-group'
									) : (
											'cp-customer-reg-input mb-2 input-group d-none'
										)
								}
							>
								<select class="selectize-generic form-control" name="pinValue" onChange={handleChange}>
									<option value="" >Choose</option>
									<option value={checkedAccountData.mobileNumber}>
										{checkedAccountData.mobileNumber}
									</option>
								</select>
								<div class="input-group-append">
									<button
										type="button"
										class="btn btn-outline-secondary btn-reg-pin-send"
										onClick={this.onSubmitAuthCode}
									>
										Send
 									</button>
								</div>
							</div>
						</span>
					</label>
				</div>

				<div class="form-check checkbox-slider checkbox-slider--b-flat">
					<label class="w-100">
						<input
							type="radio"
							name="cp-customer-reg-pin-options"
							checked={values.pinType === 'emailPin'}
							onChange={() => this.handleChangeType('emailPin')}
						/>
						<span class="w-100">
							{this.state.doNotHavePinEmail}
							<p class="text-muted text-xs mb-1">
								{this.state.temporaryPinEmail}{' '}
							</p>
							<div
								className={
									values.pinType === 'emailPin' ? (
										'cp-customer-reg-input mb-2 input-group'
									) : (
											'cp-customer-reg-input mb-2 input-group d-none'
										)
								}
							>
								<select class="selectize-generic form-control" disabled={true}>
									<option value={checkedAccountData.email}>{checkedAccountData.email}</option>
								</select>
								<div class="input-group-append">
									<button onClick={this.onSubmitAuthCode} type="button" class="btn btn-outline-secondary btn-reg-pin-send">
										Send
 									</button>
								</div>
							</div>
						</span>
					</label>
				</div>
				<div class="form-group form-group-footer">
					<button type="button" class="btn btn-outline-secondary btn-fs-action" onClick={handleGoBack}>
						Go Back
 					</button>
					<button type="submit" class="btn btn-primary btn-fs-action">
						Continue
 					</button>
				</div>
			</div>
		);
	}
}

export default PinOptions;
import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import PackageTooltip from "../../../../components/PackageTooltip";
import isEmpty from "../../../../utils/helpers";

class MandatoryPackage extends Component {

	state = {
		isTooltipOpen: false
	};

	handleTooltip = () => {
		this.setState((prevProps) => ({
			isTooltipOpen: !prevProps.isTooltipOpen
		}));
	};

	isPriceRendered = () => {
		const { accountInitialStatus, packageOption } = this.props;

		if (accountInitialStatus === 'SIGNUP') {
			if (packageOption.features.some((x) => x.hideSignupPrice === true)) {
				return false;
			}
		} else if (accountInitialStatus === 'SURVEY') {
			if (packageOption.features.some((x) => x.hideSurveyPrice === true)) {
				return false;
			}
		}
		return true;
	};

	render() {
		const { isTooltipOpen } = this.state;
		const { featurePackage, packageOption, lineInfo, lineIndex, handleMandatoryPackages } = this.props;
		return (
			<div className="package">
				<div className="package-header custom-switch-with-button">

					<div className="custom-control custom-switch"
						 onClick={() =>
							handleMandatoryPackages(packageOption.featurePackageOptionId, lineIndex, {
								featurePackageId: featurePackage.featurePackageId,
								featurePackageDescription: featurePackage.description,
								...packageOption
							})}
					>
						<input type="checkbox" className="custom-control-input"
							checked={
								lineInfo.features.findIndex(
									(x) => x.featurePackageOptionId === packageOption.featurePackageOptionId
								) !== -1
							}
							readOnly
						/>
						<label className="custom-control-label" htmlFor="">
							<span>{packageOption.description}</span>
							<span>
								{packageOption.features && this.isPriceRendered() &&
									`$${packageOption.recurringMonthlyPrice
										.toFixed(2)
										.replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo`}
							</span>
						</label>
					</div>

					{!isEmpty(packageOption.note) &&
					<button type="button" className="btn btn-toggle-package-desc" onClick={() => this.handleTooltip()}>
						<i className="fas fa-info-circle"/>
					</button>
					}

				</div>

				<PackageTooltip isVisible={isTooltipOpen}>{ReactHtmlParser(packageOption.note)}</PackageTooltip>

			</div>
		);
	}
}

export default MandatoryPackage;
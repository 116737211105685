import { CHANGE_LANGUAGE } from '../actions/languageActions';

let initialState = {
	language: 'en-us'
};

export const languageReducer = (state = initialState, action) => {
	switch (action.type) {
		case CHANGE_LANGUAGE.SUCCESS:
			return {
				...state,
				language: action.payload
            };
        case CHANGE_LANGUAGE.EMPTY:
            return {
                ...state,
                language: 'en-us'
            }
		default:
			return state;
	}
};

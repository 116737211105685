import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, Formik} from 'formik';
import {registerUserByToken} from '../../actions/signUpActions';
import {createLoadingSelector, selectErrorMessage, selectSignUp} from '../../selectors';
import GuestLayout from '../Layout/GuestLayout';
import ChoosePasswordEmailSignUp from "./Steps/ChoosePasswordEmailSignUp";
import * as Yup from "yup";
import RoundedInput from "../../components/common/Formik/RoundedInput";

const registerSchema = (window.password_requirement_reg_expr && window.password_requirement_reg_expr !== '')
    ?
    Yup.object().shape({
        newPassword: Yup.string().matches(
            window.password_requirement_reg_expr,
            window.password_requirement_desc,
        ),
        repeatPassword: Yup.string().required('Required').test('passwords-match', 'Passwords must match', function (value) {
            return this.parent.newPassword === value;
        }),
        termsAndConditions: Yup.bool().oneOf([true], window.validationMessages.termsAndCondition),
    })
    :
    Yup.object().shape({
        newPassword: Yup.string().test('newPassword',
            'Use a minimum of 10 symbols, including numbers, both uppercase and lowercase letters, and special symbols.',
            (value) => !(/^(.{0,9}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/).test(value)
        ),
        repeatPassword: Yup.string().required('Required').test('passwords-match', 'Passwords must match', function (value) {
            return this.parent.newPassword === value;
        }),
        termsAndConditions: Yup.bool().oneOf([true], window.validationMessages.termsAndCondition),
    })
;

class EmailSignUp extends Component {
    state = {
        initialFormValues: {
            email: '',
            newPassword: '',
            repeatPassword: '',
            termsAndConditions: false
        },
        successfullRegistration: false,
        registrationErrorMessgage: null
    };

    componentDidUpdate(prevProps) {
    }


    handleOnSubmit = (values, actions) => {
        actions.setSubmitting(true);
        const registerToken = this.props.location.search.substring(7);
        this.props.registerUserByToken(values, registerToken).then((response) => {
            if (response.data.success === true) {
                this.setState({successfullRegistration: true});
            } else {
                this.setState({registrationErrorMessgage: response.data.error.message});
            }
        }).finally(() => {
            actions.setSubmitting(false);
        });
    };

    render() {
        const {initialFormValues, successfullRegistration, registrationErrorMessgage} = this.state;

        return (
            <GuestLayout>
                <div className="cp-page cp-page-login justify-content-start">
                    <div className="card-guest card-full-height">
                        {successfullRegistration === false ? (
                            <Formik
                                initialValues={initialFormValues}
                                /* validationSchema={validationSchema(activeStep)} */
                                onSubmit={this.handleOnSubmit}
                                enableReinitialize={true}
                                validationSchema={registerSchema}
                            >
                                {(formProps) => (
                                    <Form
                                        onSubmit={formProps.handleSubmit}
                                        className="cp-form"
                                        autoComplete="off"
                                    >
                                        <div className="form-sections-wrapper">
                                            <ChoosePasswordEmailSignUp
                                                formProps={formProps}
                                                registrationErrorMessgage={registrationErrorMessgage}
                                                {...this.props}
                                            />
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        ) : (
                            <div className="form-sections-wrapper">
                                <div className="form-section fx-from-right" id="fs4">

                                    <div className="message">
                                        <div className="message-left">
                                            <i className="fas fa-check"></i>
                                        </div>
                                        <div className="message-right">
                                            <h4>Registration Completed!</h4>
                                            <p>Your password has been updated and you can now sign-in.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </GuestLayout>
        );
    }
}


const mapStateToProps = (state) => {
    const checkedAccountData = selectSignUp(state).checkedAccount;

    return {
        checkedAccountData
    };
};

const mapDispatchToProps = {
    registerUserByToken
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailSignUp);
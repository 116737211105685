import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import Loader from '../../components/common/Spinners/Loader'
import ServiceCard from './ServiceCard';
import MyOrdersAndSupportCenter from "./MyOrdersAndSupportCenter";
import moment from 'moment';
import ManagePlan from "./ManagePlan";
import isEmpty, { formatAmount } from "../../utils/helpers";
import FlyInRightPanel from '../../components/FlyInRightPanel'
import Invoices from './Invoices'
import Payment from './Payment'
import PaymentMethods from './PaymentMethods'
import AddEditPaymentMethods from "./AddEditPaymentMethods";
import ProgressBar from "../../components/ProgressBar";
import AutoPay from './AutoPay';
import Paperless from "./Paperless";
import Payments from "./Payments";
import {getMyAmounts} from "../../actions/homeInfoActions";
import axiosInstance from '../../utils/axios-instance';
import ReactHtmlParser from "react-html-parser";
import {getAccountAutoPayStatus, getUserPermission} from "../../selectors";
import {OneTimePayment} from "./OneTimePayment";
import {getAccountAutoPay} from "../../actions/autoPayActions";
import '../../customeCSS.css'

const PANEL = {
    NONE: 0,
    INVOICES: 1,
    MANAGE_PLANS: 2,
    PAYMENT: 3,
    AUTOPAY: 4,
    PAPERLESS: 5,
    ORDERS: 6,
    PAYMENTS: 7,
    MANAGE_PAYMENTS: 8,
    ADD_EDIT_PAYMENT_METHODS: 9,
    ONE_TIME_PAYMENT: 10,
}

export const DISTRIBUTION_METHOD = {
    EMAIL: 'EMAIL',
    PAPERLESS: 'PAPERLESS',
    PAPER: 'PAPER',
}

export const DISTRIBUTION_METHOD_DESCRIPTION = {
    EMAIL: 'Paperless is enabled, you will receive an email with invoice attached.',
    PAPER: 'Paperless is disabled, you will receive a paper invoice.',
    PAPERLESS: 'Paperless is enabled, you will receive an email notifying you that your invoice is available in the customer portal.'
}

export const DISTRIBUTION_METHOD_DESCRIPTION_SHORT = {
    EMAIL: 'Receive an email with invoice attached.',
    PAPER: 'Receive a paper invoice.',
    PAPERLESS: 'Receive an email notifying you that your invoice is available in the customer portal.'
}

const callFunc = (func, ...args) => {
    if (typeof func === 'function') {
        func(...args);
    }
}

export class CustomerServices extends Component {

    state = {
        showManagePlanIndex: -1,
        activePanel: PANEL.NONE,
        visiblePanel: PANEL.NONE,
        resetFormOnClose: false,
        preventPanelClosing: false,
        payProviderSupportsPaymentMethodStorage: false,
        installmentPlans:[],
        accountBalanceFetchAttemptCount: 0,
        fetchBalanceTimer: null,
        paymentAmount: 1.00,
        accountBalance: 0,
        fetchAttempts: 0,
    }

    static propTypes = {
        accountInfo: PropTypes.object.isRequired
    };

    componentDidMount() {
        this.props.getCurrentPaymentProviderSupportsPaymentMethodStorage().then(resp => {
            this.state.payProviderSupportsPaymentMethodStorage = resp.data.result;
        });
        axiosInstance.get(`/account/${this.props.accountInfo.id}/installmentPlan`).then((res)=>this.setState({installmentPlans:res.data}));
        const fetchBalanceTimer = setInterval(this.fetchUpdatedBalance, 6000)
        this.setState({fetchBalanceTimer: fetchBalanceTimer})
        this.fetchBalanceInterval = setInterval(this.fetchUpdatedBalanceWithCheck, 6000);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Lock / unlock body scrolling
        setTimeout(() => {
            if (this.state.activePanel !== PANEL.NONE)
                document.body.classList.add('sidebar-open')
            else
                document.body.classList.remove('sidebar-open')
        }, 0);

        if(this.props.myAmounts?.postPaymentAmount !== prevProps.myAmounts?.postPaymentAmount && !this.state.fetchBalanceTimer) {
            const fetchBalanceTimer = setInterval(this.fetchUpdatedBalance, 6000)
            this.setState({fetchBalanceTimer: fetchBalanceTimer, accountBalanceFetchAttemptCount: 0})
        }
    }


    componentWillUnmount() {
        clearTimeout(this.panelTimeoutHandler);
        clearInterval(this.state.fetchBalanceTimer)
        // Unlock body scrolling
        document.body.classList.remove('sidebar-open')
        clearInterval(this.fetchBalanceInterval);
    }

    fetchUpdatedBalance = () => {
        if(!this.props.myAmounts.postPaymentAmount || this.state.accountBalanceFetchAttemptCount >= 3) {
            clearInterval(this.state.fetchBalanceTimer)
            this.setState({fetchBalanceTimer: null})
            return
        }
        if(this.props.myAmounts.postPaymentAmount) {
            this.props.getMyAmounts(this.props.accountId);
            this.setState({accountBalanceFetchAttemptCount: this.state.accountBalanceFetchAttemptCount + 1})
        }
    }

    fetchUpdatedBalanceWithCheck = () => {
        const { accountInfo, getMyAmounts, myAmounts } = this.props;
        const oldAccountbalance = myAmounts.accountBalace;
        if (this.state.fetchAttempts < 7) {
            getMyAmounts(accountInfo.id).then(() => {
                const newBalance = this.props.myAmounts.accountBalace;
                if (newBalance !== oldAccountbalance) {
                    this.setState({ accountBalance: newBalance });
                    clearInterval(this.fetchBalanceInterval);
                    this.setState({ fetchAttempts: 0 });
                } else {
                    this.setState(prevState => ({
                        fetchAttempts: prevState.fetchAttempts + 1
                    }));
                }
            }).catch(error => {

                this.setState(prevState => ({
                    fetchAttempts: prevState.fetchAttempts + 1
                }));
            });
        } else {
            clearInterval(this.fetchBalanceInterval);
            this.setState({fetchAttempts: 0})

        }
    }

    showPanel = (panel, getInitialSate) => {
        this.setState(prevState => {
            const additionalState = callFunc(getInitialSate, prevState);
            return { activePanel: panel, ...additionalState };
        },
            () => {
                clearTimeout(this.panelTimeoutHandler);
                this.panelTimeoutHandler = setTimeout(() => this.setState({ visiblePanel: panel }), 0)
            }
        );
    }

    hidePanel = (hideTimeoutMs, callback) => {
        this.setState({ visiblePanel: PANEL.NONE, fetchAttempts: 0 }, () => {
            callFunc(callback);
            clearTimeout(this.panelTimeoutHandler);
            this.panelTimeoutHandler = setTimeout(() => this.setState({ activePanel: PANEL.NONE }), hideTimeoutMs || 500);
        });
    }


    togglePanel = (panel, newStateValueMap, hideTimeoutMs, getInitialSate, onHideCallback) => {
        // this.setState({ fetchAttempts: 0 });
        if (newStateValueMap !== undefined) {
            this.setState(newStateValueMap);
        }
        if (this.state.activePanel === panel) {
            this.hidePanel(hideTimeoutMs, onHideCallback);
        } else {
            this.showPanel(panel, getInitialSate);

            if (this.fetchBalanceInterval) {
                clearInterval(this.fetchBalanceInterval);
            }
            //this.fetchBalanceInterval = setInterval(this.fetchUpdatedBalanceWithCheck, 6000);
        }
        // Reset form
        this.setState({ resetFormOnClose: true }, () => {
            this.setState({ resetFormOnClose: false });
            this.setState({ fetchAttempts: 0 });
        });
    };

    showHideManagePlan = (index) => {
        this.togglePanel(
            PANEL.MANAGE_PLANS,
            {},
            500,
            () => this.setState({ showManagePlanIndex: index }),
            () => this.setState({ showManagePlanIndex: -1 })
        );
    }

    togglePreventPanelClosing = (state) => {
        this.setState({preventPanelClosing: state});
    }

    presentServiceCards = () => {
        const { visiblePanel, showManagePlanIndex } = this.state;
        const { myServiceInfo, accountInfo } = this.props;

        if (isEmpty(myServiceInfo)) {
            return null;
        }

        const elements = [];

        for (let index = 0; index < myServiceInfo.length; index++) {

                const service = myServiceInfo[index];

                const curElement = (
                    <ServiceCard showHideManagePlan={this.showHideManagePlan}
                                 myServiceInfoItem={service} myServiceInfoItemIndex={index} {...this.props} />
                );

                const nextService = (index + 1) < myServiceInfo.length ? myServiceInfo[index + 1] : null;

                const nextElement = !isEmpty(nextService)
                    ?
                    <ServiceCard
                        showHideManagePlan={this.showHideManagePlan}
                        myServiceInfoItem={nextService}
                        myServiceInfoItemIndex={index + 1}
                        {...this.props}
                    />
                    :
                    null;

                elements.push(
                    <Fragment key={index}>
                        {curElement}
                        {nextElement}
                    </Fragment>
                );

                index++;

                if (showManagePlanIndex >= 0 && (showManagePlanIndex === index || showManagePlanIndex === index - 1)) {

                    elements.push(
                        <FlyInRightPanel
                            title={"Manage"}
                            isVissible={visiblePanel === PANEL.MANAGE_PLANS}
                            onClose={() => this.showHideManagePlan(showManagePlanIndex)}
                        >
                            <ManagePlan myServiceInfoItemIndex={(showManagePlanIndex)}
                                        planName={myServiceInfo[showManagePlanIndex].planName}
                                        showHideManagePlan={() => this.showHideManagePlan(showManagePlanIndex)}
                                        myServiceInfoItem={myServiceInfo[showManagePlanIndex]} {...this.props}
                                        {...this.props}
                            />
                        </FlyInRightPanel>
                    );

                    // Lock body scrolling
                    document.body.classList.add('sidebar-open');

                } else {

                    // Unlock body scrolling
                    document.body.classList.remove('sidebar-open');
                }
        }

        return (
            <Fragment>
                {elements}
            </Fragment>
        );
    }
    dateconverter =(date)=>{
        const datearr=date.split('-');
        const monthlist={
            '01':'Jan',
            '02':'Feb',
            '03':'Mar',
            '04':'Apr',
            '05':'May',
            '06':'Jun',
            '07':'Jul',
            '08':'Aug',
            '09':'Sep',
            '10':'Oct',
            '11':'Nov',
            '12':'Dec'
        }

        return `${monthlist[datearr[1]]} ${datearr[2]}, ${datearr[0]}`
    }
    render() {

        const {
            activePanel,
            visiblePanel,
            resetFormOnClose,
            preventPanelClosing,
        } = this.state;

        const {
            accountInfo,
            myServiceInfoLoader,
            myOrdersInfoLoader,
            myInvoicesInfoLoader,
            myOrders,
            myInvoices,
            myAmounts,
            myAmountsLoader,
            myAutoPayInfo,
            myAutoPayAvailableCC,
            myDistributionMethods,
            canPayNow,
            canViewPayments,
            canViewInvoices,
            canManagePaymentMethods,
            myAutoPayStatus,
            myAutoPayWarning
        } = this.props;

        const myCurrentDistributionMethod = this.props.myCurrentDistributionMethod && this.props.myCurrentDistributionMethod.length
            ? this.props.myCurrentDistributionMethod[0]
            : {};

        if (myServiceInfoLoader) {
            return <Loader />
        }

        let accountVisibleStatuses = ['Active', 'Deactivated', 'Suspended']

        /* Bill Cycle Title & Percentage */
        let billCycleTitle = 'Bill Cycle';
        let billCycleTitleSuffix = '';
        let billCyclePercents = 0;
        let billCycleDaysLeft = 0;
        let billCycleStart;
        let billCycleEnd;
        let billCycleLength;

        if (accountInfo.billCycle) {
            let now = moment();

            // If this month's bill cycle date is upcoming
            if (accountInfo.billCycle > parseInt(moment().format('D'))) {
                billCycleStart = moment().date(accountInfo.billCycle).subtract(1, 'month');
                billCycleEnd = moment().date(accountInfo.billCycle).subtract(1, 'day');
                billCycleTitleSuffix = " " + billCycleStart.format("M/D") + "-" + billCycleEnd.format("M/D");
            }

            // If we're past this month's bill cycle date
            else {
                billCycleStart = moment().date(accountInfo.billCycle);
                billCycleEnd = moment().date(accountInfo.billCycle).add(1, 'month').subtract(1, 'day');
                billCycleTitleSuffix = " " + billCycleStart.format("M/D") + "-" + billCycleEnd.format("M/D");
            }

            // Bill Cycle Period length, percentage elapsed, days left
            billCycleLength = billCycleEnd.diff(billCycleStart, 'days');
            billCyclePercents = ((billCycleLength - billCycleEnd.diff(now, 'days')) / billCycleLength) * 100;
            billCycleDaysLeft = billCycleEnd.diff(now, 'days');

            // Default percentage
            if (billCyclePercents === 0) billCyclePercents = 1;
        }

        return (
            <>
                <div className="container-customer-services">
                    <div className="container">

                        <div className="container-customer">
                            <div className="row">
                                <div className="cp-col col-sm-7">
                                    <h3>
                                        <span className="font-weight-light">Welcome,</span>{' '}
                                        {accountInfo && accountInfo.primaryContact && !isEmpty(accountInfo.primaryContact.company)
                                            ? accountInfo.primaryContact.company
                                            : accountInfo.primaryContact.firstName
                                        }
                                    </h3>
                                    <p>
                                        Account #: {accountInfo.number}
                                        <span
                                            className={"badge " + (
                                                accountInfo.status === "Survey" ? "badge-outline-white" :
                                                accountInfo.status === "Prospect" ? "badge-outline-white" :
                                                accountInfo.status === "Signup" ? "badge-white text-success" :
                                                accountInfo.status === "Suspended" ? "badge-warning" :
                                                accountInfo.status === "Deactivated" ? "badge-danger" :
                                                accountInfo.status === "Written-Off" ? "badge-white text-danger" :
                                                "badge-customer-container-bg"
                                            )}
                                        >
                                            {accountInfo.status}
                                        </span>
                                    </p>
                                </div>
                                {canPayNow &&
                                    <div className="cp-col col-sm-5 d-flex align-items-center justify-content-start justify-content-sm-end">
                                        <button
                                            className="btn btn-secondary" data-target="default"
                                            onClick={() => this.togglePanel(PANEL.PAYMENT)}
                                        >
                                            Make a Payment
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="row">

                                <div className="cp-col col-12 col-lg-6 d-flex">
                                    <div className="card card-service">

                                        <div className="card-caption">
                                            <div className="card-left">

                                                <div className="card-icon">
                                                    <i className="fas fa-wallet" />
                                                </div>

                                            </div>
                                            <div className="card-right">

                                                <div className="card-header">
                                                    Total Balance
                                                    <div style={{display:'flex', flexDirection:'column', alignItems:'flex-end'}}>
                                                    <span className="font-weight-bold float-right">
                                                        {myAmountsLoader &&
                                                            <i className="fas fa-spinner fa-spin" />}
                                                        {!myAmountsLoader && myAmounts &&
                                                            '$' + formatAmount(myAmounts.accountBalace)}
                                                    </span>
                                                        {myAmounts && myAmounts.postPaymentAmount &&
                                                            <div style={{fontSize:'13px', color:'#00000080'}}>
                                                                Payment of ${myAmounts.postPaymentAmount.toFixed(2)} is pending
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            {accountInfo.billCycle && accountVisibleStatuses.includes(accountInfo.status) && (
                                                <ProgressBar
                                                    icon={'far fa-fw fa-clock'}
                                                    title={billCycleTitle}
                                                    titleSuffix={billCycleTitleSuffix}
                                                    value={billCycleDaysLeft + (billCycleDaysLeft === 1 ? ' Day' : ' Days') + ' left'}
                                                    color={'red'}
                                                    percentage={billCyclePercents}
                                                    note={''}
                                                />
                                            )}
                                            {
                                                this.state.installmentPlans&&this.state.installmentPlans.map((plan)=>plan.balance>0&&<ProgressBar
                                                icon={'far fa-fw fa-clock'}
                                                title={plan.invoicedItem.description+", "}
                                                titleSuffix={plan.installmentPlanItem.description}
                                                value={`$${plan.balance} remaining`}
                                                color={'green'}
                                                percentage={(1-plan.balance/plan.amount)*100}
                                                note={''}
                                            />)
                                            }

                                            <div className="list">

                                                {((myDistributionMethods && myDistributionMethods.length > 0) || myCurrentDistributionMethod) &&
                                                <p className="border-bottom">
                                                    <span>
                                                        {myCurrentDistributionMethod &&(myCurrentDistributionMethod.name==='email'||myCurrentDistributionMethod.name==='email')?
                                                        <i className='fab fa-fw fa-envira text-success'/>:
                                                        <i className='fab fa-fw fa-envira'/>
                                                        }
                                                        {myCurrentDistributionMethod && (
                                                            ReactHtmlParser(myCurrentDistributionMethod.note) ||
                                                            DISTRIBUTION_METHOD_DESCRIPTION[myCurrentDistributionMethod.name && myCurrentDistributionMethod.name.toUpperCase()]
                                                        )}
                                                    </span>
                                                    {(myDistributionMethods && myDistributionMethods.length > 0) &&
                                                    <button
                                                        className="btn btn-quick float-right" type="button"
                                                        onClick={() => this.togglePanel(PANEL.PAPERLESS)}
                                                    >
                                                        <i className="fas fa-cog"/>
                                                    </button>
                                                    }
                                                </p>
                                                }

                                                <p>
                                                    <span>
                                                        {myAutoPayWarning
                                                            ? <>
                                                                <i className="fas fa-fw fa-dollar-sign text-success"/>
                                                                <span className='p-1'>AutoPay is enabled. <a
                                                                    className='actionable-text text-danger p-1 font-weight-bold border-bottom border-danger'
                                                                    onClick={() => this.togglePanel(PANEL.AUTOPAY)}>Missing payment method</a></span>
                                                            </>
                                                            : myAutoPayInfo && (myAutoPayInfo.apiAccountAutoPayACH || myAutoPayInfo.apiAccountAutoPayCC || myAutoPayInfo.apiAccountAutoPayToken)
                                                                ? <>
                                                                    <i className="fas fa-fw fa-dollar-sign text-success" />
                                                                    {myAmounts && myAmounts.accountBalace > 0 ? `Autopay is enabled, and scheduled for ${this.dateconverter(myAutoPayInfo.appliesOn)}`:`Autopay is enabled`}
                                                                </>
                                                                : <>
                                                                    <i className="fas fa-fw fa-dollar-sign" />
                                                                    Autopay is disabled.
                                                                </>
                                                        }
                                                    {/*{myAutoPayInfo && (myAutoPayInfo.apiAccountAutoPayACH || myAutoPayInfo.apiAccountAutoPayCC || myAutoPayInfo.apiAccountAutoPayToken ) || myAutoPayWarning*/}
                                                    {/*    ? <>*/}
                                                    {/*        <i className="fas fa-fw fa-dollar-sign text-success" />*/}
                                                    {/*        {myAmounts && myAmounts.accountBalace > 0 && !myAutoPayWarning ? `Autopay is enabled, and scheduled for ${this.dateconverter(myAutoPayInfo.appliesOn)}`:`Autopay is enabled`}*/}
                                                    {/*    </> : <>*/}
                                                    {/*        <i className="fas fa-fw fa-dollar-sign" />*/}
                                                    {/*        Autopay is disabled.*/}
                                                    {/*    </>*/}
                                                    {/*}*/}
                                                    </span>
                                                    <button
                                                        className="btn btn-quick float-right" type="button"
                                                        onClick={() => this.togglePanel(PANEL.AUTOPAY)}
                                                    >
                                                        <i className="fas fa-cog" />
                                                    </button>
                                                </p>

                                            </div>

                                            {myAutoPayInfo && myAutoPayInfo.apiAccountAutoPayCC && <>
                                                <ProgressBar
                                                    icon="far fa-fw fa-credit-card mr-1"
                                                    title="Card Number"
                                                    value={myAutoPayInfo.apiAccountAutoPayCC.displayCardNumber}
                                                />
                                                {myAutoPayInfo.apiAccountAutoPayCC.cardExpirationDate
                                                    && !myAutoPayInfo.apiAccountAutoPayCC.cardExpirationDate.includes("null") &&
                                                <ProgressBar
                                                    icon="far fa-fw fa-hourglass mr-1"
                                                    title="Expiration Date"
                                                    value={myAutoPayInfo.apiAccountAutoPayCC.cardExpirationDate}
                                                />
                                                }
                                            </>}

                                            {myAutoPayInfo && myAutoPayInfo.apiAccountAutoPayACH && <>
                                                <ProgressBar
                                                    icon="fas fa-fw fa-hashtag mr-1"
                                                    title="Routing Number"
                                                    value={myAutoPayInfo.apiAccountAutoPayACH.routing_number}
                                                />
                                                <ProgressBar
                                                    icon="fas fa-fw fa-hashtag mr-1"
                                                    title="Bank Account"
                                                    value={myAutoPayInfo.apiAccountAutoPayACH.account_number}
                                                />
                                            </>}

                                            {myAutoPayInfo && myAutoPayInfo.apiAccountAutoPayToken && <>
                                                <ProgressBar
                                                    icon="fas fa-fw fa-hashtag mr-1"
                                                    title="Payment Information"
                                                    value={myAutoPayInfo.apiAccountAutoPayToken.paymentInformation}
                                                />
                                            </>}

                                        </div>

                                        <div className="card-footer">
                                            <div className="card-footer-toolbar">
                                                {canViewPayments &&
                                                    <button className="btn btn-sm btn-fw-enable btn-light"
                                                            onClick={() => this.togglePanel(PANEL.PAYMENTS)}>
                                                        View Payments
                                                    </button>
                                                }
                                                {canViewInvoices &&
                                                    <button className="btn btn-sm btn-fw-enable btn-light"
                                                            onClick={() => this.togglePanel(PANEL.INVOICES)}>
                                                        View Invoices
                                                    </button>
                                                }

                                                {this.state.payProviderSupportsPaymentMethodStorage && canManagePaymentMethods && <>
                                                    <button className="btn btn-sm btn-fw-enable btn-light"
                                                            onClick={() => this.togglePanel(PANEL.MANAGE_PAYMENTS)}>
                                                        Payment Methods
                                                    </button>
                                                </>}

                                                {canPayNow &&
                                                    <button className="btn btn-sm btn-fw-enable btn-primary"
                                                            onClick={() => this.togglePanel(PANEL.PAYMENT)}>
                                                        Make a Payment
                                                    </button>
                                                }

                                            </div>
                                        </div>

                                    </div>

                                </div>


                            {this.presentServiceCards()}

                            {!myOrdersInfoLoader && <MyOrdersAndSupportCenter myOrders={myOrders} myOrdersInfoLoader={myOrdersInfoLoader} hasActivePanel={activePanel !== PANEL.NONE} />}
                            {myOrdersInfoLoader && <MyOrdersAndSupportCenter myOrders={myOrders} myOrdersInfoLoader={myOrdersInfoLoader} hasActivePanel={activePanel !== PANEL.NONE} />}

                        </div>


                            <>
                                {/* Payment Panel needs to be kept in the DOM, so IPPay requests can complete normally */}
                                <FlyInRightPanel
                                    title="Payment"
                                    isVissible={visiblePanel === PANEL.PAYMENT}
                                    preventPanelClosing={preventPanelClosing}
                                    onClose={() => {
                                        if (!preventPanelClosing) {
                                            this.togglePanel(PANEL.PAYMENT);
                                            this.setState({resetFormOnClose: true}, () => {
                                                this.setState({resetFormOnClose: false});
                                            })
                                        }
                                    }}
                                >
                                    <Payment
                                        myAutoPayInfo={myAutoPayInfo}
                                        myAmountsLoader={myAmountsLoader}
                                        myAmounts={myAmounts}
                                        resetFormOnClose={resetFormOnClose}
                                        togglePreventPanelClosing={this.togglePreventPanelClosing}
                                        preventPanelClosing={preventPanelClosing}
                                        togglePanel={this.togglePanel}

                                    />
                                </FlyInRightPanel>

                                {activePanel === PANEL.INVOICES &&
                                <FlyInRightPanel
                                    title="My Invoices"
                                    isVissible={visiblePanel === PANEL.INVOICES}
                                    onClose={() => this.togglePanel(PANEL.INVOICES)}
                                >
                                    <Invoices
                                        myInvoicesInfoLoader={myInvoicesInfoLoader}
                                        myInvoices={myInvoices}
                                        myAmountsLoader={myAmountsLoader}
                                        myAmounts={myAmounts}
                                    />
                                </FlyInRightPanel>
                                }

                                {activePanel === PANEL.AUTOPAY &&
                                <FlyInRightPanel
                                    title="Autopay"
                                    isVissible={visiblePanel === PANEL.AUTOPAY}
                                    onClose={() => {
                                        this.togglePanel(PANEL.AUTOPAY);
                                        this.setState({ resetFormOnClose: true }, () => {
                                            this.setState({ resetFormOnClose: false });
                                        })
                                    }}
                                >
                                    <AutoPay
                                        accountInfo={accountInfo}
                                        myAutoPayInfo={myAutoPayInfo}
                                        myAutoPayAvailableCC={myAutoPayAvailableCC}
                                        togglePanel={this.togglePanel}
                                        accountBalace={myAmounts.accountBalace}
                                    />
                                </FlyInRightPanel>
                                }

                                {activePanel === PANEL.PAPERLESS &&
                                <FlyInRightPanel
                                    title="Paperless Billing"
                                    isVissible={visiblePanel === PANEL.PAPERLESS}
                                    onClose={() => {
                                        this.togglePanel(PANEL.PAPERLESS);
                                        this.setState({ resetFormOnClose: true }, () => {
                                            this.setState({ resetFormOnClose: false });
                                        })
                                    }}
                                >
                                    <Paperless
                                        myDistributionMethods={myDistributionMethods}
                                        myCurrentDistributionMethod={myCurrentDistributionMethod}
                                    />
                                </FlyInRightPanel>
                                }

                                {activePanel === PANEL.PAYMENTS &&
                                <FlyInRightPanel
                                    title="My Payments"
                                    isVissible={visiblePanel === PANEL.PAYMENTS}
                                    onClose={() => {
                                        this.togglePanel(PANEL.PAYMENTS);
                                        this.setState({ resetFormOnClose: true }, () => {
                                            this.setState({ resetFormOnClose: false });
                                        })
                                    }}
                                >
                                    <Payments
                                        accountInfo={accountInfo}
                                        postPaymentAmount={this.props.myAmounts.postPaymentAmount}
                                    />
                                </FlyInRightPanel>
                                }

                                {activePanel === PANEL.MANAGE_PAYMENTS &&
                                <FlyInRightPanel
                                    title="Payment Methods"
                                    isVissible={visiblePanel === PANEL.MANAGE_PAYMENTS}
                                    onClose={() => {
                                        this.togglePanel(PANEL.MANAGE_PAYMENTS);
                                        this.setState({ resetFormOnClose: true }, () => {
                                            this.setState({ resetFormOnClose: false });
                                        })
                                    }}
                                >
                                    <PaymentMethods
                                        accountInfo={accountInfo}
                                        togglePanel={this.togglePanel}
                                    />
                                </FlyInRightPanel>
                                }

                                {activePanel === PANEL.ONE_TIME_PAYMENT &&
                                    <FlyInRightPanel
                                        title="One Time Payment"
                                        isVissible={visiblePanel === PANEL.ONE_TIME_PAYMENT}
                                        onClose={() => {

                                            //this.fetchUpdatedBalanceWithCheck();
                                            this.togglePanel(PANEL.ONE_TIME_PAYMENT);
                                            this.setState({ resetFormOnClose: true }, () => {
                                                this.setState({ resetFormOnClose: false, fetchAttempts: 0  }, () => {
                                                    //this.fetchUpdatedBalanceWithCheck();
                                                    if (this.fetchBalanceInterval) {
                                                        clearInterval(this.fetchBalanceInterval);
                                                    }
                                                    this.fetchBalanceInterval = setInterval(this.fetchUpdatedBalanceWithCheck, 6000);
                                                });
                                            })
                                        }}
                                    >
                                        <OneTimePayment
                                            accountInfo={accountInfo}
                                            togglePanel={this.togglePanel}
                                            paymentAmount={this.state.paymentAmount}
                                        />
                                    </FlyInRightPanel>
                                }

                                {activePanel === PANEL.ADD_EDIT_PAYMENT_METHODS &&
                                <FlyInRightPanel
                                    title="Manage Payment Methods"
                                    isVissible={visiblePanel === PANEL.ADD_EDIT_PAYMENT_METHODS}
                                    onClose={() => {
                                        this.togglePanel(PANEL.ADD_EDIT_PAYMENT_METHODS);
                                        this.setState({ resetFormOnClose: true }, () => {
                                            this.setState({ resetFormOnClose: false });
                                        })
                                    }}
                                >
                                    <AddEditPaymentMethods
                                        accountInfo={accountInfo}
                                        togglePanel={this.togglePanel}
                                    />
                                </FlyInRightPanel>
                                }

                            </>


                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const canPayNow = getUserPermission(state, "BILLING", "BILLING_PAY_NOW"),
        canViewPayments = getUserPermission(state, "BILLING", "BILLING_PAYMENTS_VIEW"),
        canViewInvoices = getUserPermission(state, "BILLING", "BILLING_INVOICES_VIEW"),
        canManagePaymentMethods = getUserPermission(state, "API", "API_MANAGE_PAYMENT_METHODS"),
        myAutoPayStatus = getAccountAutoPayStatus(state),
        myAutoPayWarning = getAccountAutoPayStatus(state).autoPayWarningMessage;

    return {
        canPayNow,
        canViewPayments,
        canViewInvoices,
        canManagePaymentMethods,
        myAutoPayWarning,
        myAutoPayStatus
    }
}

const mapDispatchToProps = {
    getMyAmounts,
    getAccountAutoPay
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerServices);

import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Route, Switch, Redirect} from 'react-router-dom';
import {logout} from '../../actions/authActions';
import {getAccountInfoById} from '../../actions/accountInfoActions';
import {getUserConfiguration} from "../../actions/userActions";

import {selectAuth, selectAccountInfo, selectErrorMessage, getUser} from '../../selectors';

import IdleTimer from 'react-idle-timer';
import Home from '../Home';
import UserNavbar from './NavBar/UserNavbar';
import isEmpty from '../../utils/helpers';
import Loader from '../../components/common/Spinners/Loader';
import Footer from './Footer';

class MainLayout extends Component {
    constructor(props) {
        super(props);
        this.timer = null;
        this.idleTimer = null;
        this.onAction = this._onAction.bind(this);
        this.onActive = this._onActive.bind(this);
        this.onIdle = this._onIdle.bind(this);
    }

    componentDidMount() {
        const {isLoggedIn, accountId, getAccountInfoById, history, location, getUserConfiguration} = this.props;

        if (isLoggedIn) {
            getUserConfiguration();
            getAccountInfoById(accountId);
        } else {
            history.push({
                pathname: '/login',
                state: {from: location}
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLoggedIn !== this.props.isLoggedIn && this.props.isLoggedIn === false) {
            this.props.logout(this.props.history);
        }
    }

    _onAction(e) {
    }

    _onActive(e) {
    }

    _onIdle(e) {
        this.props.logout(this.props.history);
    }

    render() {
        const {accountInfo, accountInfoError, logout, userConfiguration, history} = this.props;
        let iddleTime;
        if (!isEmpty(userConfiguration)) {
            let getIddleTime = userConfiguration.find((x) => x.key === 'portal.idle.timeout');
            iddleTime = 1000 * 60 * Number(getIddleTime.value);
        }

        if (!isEmpty(accountInfoError)) {
            logout(history);
        }

        if (isEmpty(accountInfo)) {
            return <Loader/>;
        }


        return (
            <Fragment>
                <IdleTimer
                    ref={(ref) => {
                        this.idleTimer = ref;
                    }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={250}
                    timeout={iddleTime}
                />

                <UserNavbar {...this.props} />
                <main>
                    {(window.backgroundVideo !== '' &&
                    <video className="cp-video-bg" playsinline autoplay muted loop>
                        <source src={`${process.env.PUBLIC_URL}/themes/active/img/background.mp4`} type="video/mp4"/>
                    </video>
                    )}
                    <Switch>
                        <Route exact path={`/`} render={() => <Redirect to="/home"/>}/>
                        <Route path={'/home'} render={() => <Home {...this.props} />}/>
                    </Switch>
                </main>
                <Footer/>
            </Fragment>
        );
    }
}

const getAccountInfoError = selectErrorMessage(['ACCOUNT_INFO']);

const mapStateToProps = (state) => {
    const isLoggedIn = selectAuth(state).isLoggedIn,
        accountId = selectAuth(state).accountId,
        accountInfo = selectAccountInfo(state).accountInfo,
        accountInfoError = getAccountInfoError(state),
        userConfiguration = getUser(state).configuration;

    return {
        isLoggedIn,
        accountId,
        accountInfo,
        accountInfoError,
        userConfiguration
    };
};

const mapDispatchToProps = {
    logout,
    getAccountInfoById,
    getUserConfiguration
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import IncludedFeature from './Options/IncludedFeature';

class IncludedFeatures extends Component {
	render() {
		const { planFeature, accountInitialStatus } = this.props;

		return (
			<Fragment>
				{planFeature.map((feature) => (
					<IncludedFeature feature={feature} key={feature.id} accountInitialStatus={accountInitialStatus} />
				))}
			</Fragment>
		);
	}
}

IncludedFeatures.propTypes = {};

export default IncludedFeatures;

import React, {Component} from 'react';
import Feature from './Options/Feature';
import IncPackage from "./Options/IncludedPackage";

class МandatoryFeatures extends Component {

	state = {
		isPlanCollapsed: false
	};

	collapsePlan = () => {
		this.setState((prevProps) => ({
			isPlanCollapsed: !prevProps.isPlanCollapsed
		}));
	};

	hideSection = (planFeature) => {
		let showSection = false;
		planFeature.map(feature => {
			if(typeof feature.hideCustomerPortal == 'undefined' ||
				!feature.hideCustomerPortal){
				showSection = true;
			}
		})
		if(!showSection){
			return  "d-none";
		}
	}

	render() {
		const { isPlanCollapsed } = this.state;
		const { planFeature } = this.props;

		return (
			<div className={(this.hideSection(planFeature))}>
				<div className={'product-item' + (isPlanCollapsed ? ' product-item-collapsed' : '')}>

					<div className="header">

						<div className="header-title">Mandatory Features</div>

						<button className="btn header-collapse" type="button" onClick={() => this.collapsePlan()}>
							<i className="fas fa-angle-up"></i>
						</button>

					</div>

					<div className="details">

						<div className="description">
							{planFeature.map((feature, index) => <Feature feature={feature} key={index} />)}
						</div>

					</div>

				</div>
			</div>
		);
	}
}

export default МandatoryFeatures;

import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

const GuestOrderInput = ({ type, name, size, label, errors, touched, placeholder, value, onChange, onBlur, isDisabled }) => {
	return (
		<div className={`form-group ${size}`}>

			<label htmlFor={name}>{label}</label>

			<input
				className={get(errors, `${name}`) && get(touched, `${name}`) ? 'form-control is-invalid' : 'form-control'}
				id={name}
				name={name}
				placeholder={placeholder}
				type={type}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				disabled={isDisabled}
				autoComplete="off"
			/>

			{get(errors, `${name}`) && get(touched, `${name}`) &&
			<div className="invalid-feedback">{get(errors, `${name}`)}</div>
			}

		</div>
	);
};

GuestOrderInput.propTypes = {
	type: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	errors: PropTypes.object.isRequired,
	touched: PropTypes.object.isRequired,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	isDisabled: PropTypes.bool
};

export default GuestOrderInput;

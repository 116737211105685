import React, {Component} from 'react'
import {Form, Formik} from 'formik';
import {emptyPlanDetails, getFeatures, getPlanDetails, updateChangeService} from "../../../actions/homeInfoActions";
import {connect} from "react-redux";
import {generateId} from "../../../utils/helpers";
import {mapInitialState, transformPlanDetails} from "../../../utils/orderUtils";
import {createLoadingSelector, selectHomeInfo} from "../../../selectors";
import Products from "./Products";
import Confirmation from "./Confirmation";

export class ManagePlan extends Component {
    state = {
        initialValues: {
            name: 'SERVICE_CHANGE_ORDER_' + generateId(),
            source: 'PORTAL',
            note: this.props.myServiceInfoItem.planNote,
            accountId: this.props.accountInfo.id,
            planId: this.props.myServiceInfoItem.planId,
            existingServiceLineId: this.props.myServiceInfoItem.serviceLines[0].serviceLineId,
            applyOn: 'ORDER_COMPLETION',
            features: []
        },
        activeFeaturesSnapshot: [],
        plansDetails: {},
        isSummaryFullWith: false,
        isSummaryCollapsed: false,
        activeTab: 0
    };

    componentDidMount() {
        const {
            accountInfo,
            getPlanDetails,
            myServiceInfoItem
        } = this.props;

        getPlanDetails(accountInfo.id, '', myServiceInfoItem.planId);
    }

    componentWillUnmount() {
        this.props.emptyPlanDetails();
    }

    componentDidUpdate(prevState) {
        if (prevState.myServiceInfoItemIndex !== this.props.myServiceInfoItemIndex) {
            this.setState({
                initialValues: {
                    name: 'SERVICE_CHANGE_ORDER_' + generateId(),
                    source: 'PORTAL',
                    note: this.props.myServiceInfoItem.planNote,
                    accountId: this.props.accountInfo.id,
                    planId: this.props.myServiceInfoItem.planId,
                    existingServiceLineId: this.props.myServiceInfoItem.serviceLines[0].serviceLineId,
                    applyOn: 'ORDER_COMPLETION',
                    features: []
                }
            }, () => {
                const {
                    accountInfo,
                    getPlanDetails,
                    myServiceInfoItem
                } = this.props;

                getPlanDetails(accountInfo.id, window.guestOrderConfig.defaultLocationId, myServiceInfoItem.planId);
            })
        }

        if (prevState.planDetails !== this.props.planDetails) {
            const { accountInfo, planDetails, myServiceInfoItem} = this.props;

            const serviceLineId = myServiceInfoItem.serviceLines[0].serviceLineId;
            const planRelation = myServiceInfoItem.serviceLines[0].planRelation;

            let transformedData = transformPlanDetails(planRelation, planDetails);

            this.props.getFeatures(accountInfo.id, serviceLineId).then((response) => {
                const activeFeatures = response;

                const transformedInitialState = mapInitialState(
                    planRelation,
                    transformedData,
                    activeFeatures
                );

                const transformedActiveFeatures = Object.keys(activeFeatures).reduce((acc, currentValue) => {
                    acc.push(...activeFeatures[currentValue]);
                    return acc;
                }, []);

                const copyInitialValues = { ...this.state.initialValues };
                copyInitialValues['features'] = transformedInitialState;

                this.setState({
                    plansDetails: transformedData,
                    initialValues: copyInitialValues,
                    activeFeaturesSnapshot: transformedActiveFeatures
                });
            });
        }
    }

    applyChanges = (values, actions) => {
        const { showHideManagePlan } = this.props;

        let updateRequestValues = {
            ...values,
            features: [ ...values.features ],
            autoExecuteOrder: true
        };

        this.state.activeFeaturesSnapshot.forEach((feature) => {
            if (feature.featurePackageId) {
                let findIndex = updateRequestValues.features.findIndex(
                    (x) =>
                        x.featurePackageId === feature.featurePackageId &&
                        x.featurePackageOptionId === feature.featurePackageOptions[0].featurePackageOptionId
                );

                const findPackage = updateRequestValues.features.find(
                    (x) =>
                        x.featurePackageId === feature.featurePackageId &&
                        x.featurePackageOptionId === feature.featurePackageOptions[0].featurePackageOptionId
                );
                if (findPackage === undefined) {
                    let featurePackage = {
                        featurePackageId: feature.featurePackageId,
                        featurePackageDescription: feature.description,
                        featurePackageNote: feature.note,
                        ...feature.featurePackageOptions[0],
                        action: 'DISABLE'
                    };
                    updateRequestValues.features.push(featurePackage);
                } else {
                    updateRequestValues.features = updateRequestValues.features.filter(
                        (x) =>
                            x.featurePackageId !== feature.featurePackageId &&
                            x.featurePackageOptionId !== feature.featurePackageOptions[0].featurePackageOptionId
                    );
                }
            } else if (feature.featureId) {
                const findFeature = updateRequestValues.features.find((x) => x.id === feature.featureId);
                if (findFeature === undefined) {
                    let featurePackage = {
                        id: feature.featureId,
                        action: 'DISABLE'
                    };
                    updateRequestValues.features.push(featurePackage);
                } else {
                    updateRequestValues.features = updateRequestValues.features.filter(
                        (x) => x.id !== feature.featureId
                    );
                }
            }
        });

        //Transform values in acceptable api post format
        updateRequestValues.features = updateRequestValues.features.map((feature) => {
            if (feature.featurePackageId) {
                return {
                    // id: feature.featurePackageId,
                    featurePackageOptionId: feature.featurePackageOptionId,
                    action: feature.action
                };
            } else if (feature.id) {
                return {
                    id: feature.id,
                    action: feature.action
                };
            }
            return feature;
        });

        this.props.updateChangeService(updateRequestValues).then((response) => {

            if (response.success) {
                this.setState({
                    activeTab: 1
                });
            }else {
                console.log('Error')
            }
        });
    };

    renderTabContent = (formProps) => {
        const { activeTab, plansDetails } = this.state;
        const { planName, updatedServiceInfoData, showHideManagePlan } = this.props;
        let tabContent;
        switch (activeTab) {
            case 0:
                tabContent = (
                    <Products
                        plansDetails={plansDetails}
                        planName={planName}
                        showHideManagePlan={showHideManagePlan}
                        {...formProps}
                        {...this.props}
                        valueUpdated={this.valueUpdated}
                    />
                );
                break;
            case 1:
                tabContent = (
                    <Confirmation
                        showHideManagePlan={showHideManagePlan}
                        updatedServiceInfoData={updatedServiceInfoData}
                        handleViewOrder={this.handleViewOrder}
                    />
                );
                break;
            default:
                tabContent = null;
                break;
        }

        return tabContent;
    };

    render() {
        const { initialValues, plansDetails } = this.state;
        const { myServiceInfoItemIndex, planName, showHideManagePlan, myServiceInfoItem } = this.props;

        return (
            <Formik
                initialValues={initialValues}
                onSubmit={this.applyChanges}
                enableReinitialize
                render={(props) => (
                    <Form onSubmit={props.handleSubmit} className="cp-form" autoComplete="off">
                        {this.renderTabContent(props)}
                    </Form>
                )}/>
        )
    }
}

const updateChangeServiceLoader = createLoadingSelector(['SET_UPDATE_CHANGE_SERVICE']);

const mapStateToProps = (state) => {

    const
        planDetails = selectHomeInfo(state).planDetails,
        updatedServiceInfoData = selectHomeInfo(state).updatedServiceInfo,
        updateChangeServiceLoading = updateChangeServiceLoader(state);

    return {
        planDetails,
        updatedServiceInfoData,
        updateChangeServiceLoading,
    };
};

const mapDispatchToProps = {
    getPlanDetails,
    emptyPlanDetails,
    getFeatures,
    updateChangeService
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagePlan);

import React, { Component } from 'react';

class OfflinePage extends Component {
	render() {
		return (
			<main>
				<div className="cp-page cp-page-maintenance">
					<div className="card-guest">
						<div className="card-guest-header">
							<div className="brand-container" />
							<p className="brand-header">We are currently down for maintenance</p>
						</div>
						<div className="card-guest-body">
							<p className="text-center">We expect to be back in couple of hours.</p>
							<p className="text-center">Thanks for your patience.</p>
						</div>
					</div>
				</div>
			</main>
		);
	}
}

export default OfflinePage;

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Formik, Form, Field} from 'formik';
import {validationSchema} from './validation';
import {checkByEmailOrNumber, checkByAcountAndZipcode, sendAuthCode} from '../../actions/signUpActions';
import {selectSignUp, selectErrorMessage, createLoadingSelector} from '../../selectors';

import StepContent from './StepContent';
import GuestLayout from '../Layout/GuestLayout';
import isEmpty from '../../utils/helpers';

class SignUp extends Component {

    state = {
        activeStep: 0,
        initialFormValues: {
            type: 'contactInfo',
            numberOrEmail: '',
            accountNumber: '',
            zipcode: '',
            pinType: '',
            pinValue: '',
            recaptcha: ''
        },
        authCode: '',
        authCodeRequest: false,
        isAuthCodeValid: null,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.checkedAccountData !== this.props.checkedAccountData && !isEmpty(this.props.checkedAccountData)) {
            this.handleNextStep()
        }
    }

    handleConfirmAccountStep = (values, actions) => {
        const {checkByEmailOrNumber, checkByAcountAndZipcode} = this.props;

        if (values.type === 'contactInfo') {
            checkByEmailOrNumber(values.numberOrEmail).then(() => {
                actions.setSubmitting(false);
            });
        } else if (values.type === 'accountInfo') {
            checkByAcountAndZipcode(values.accountNumber, values.zipcode).then(() => {
                actions.setSubmitting(false);
            });
        }
    };

    handleConfirmNotARobot = (values, actions) => {
        this.handleNextStep()
    }
    handlePinOptions = (values, actions) => {
        this.handleNextStep()
    }

    handleChoosePassword = (values, actions) => {
        this.handleNextStep()
    }

    handleSignUpCompleted = (values, actions) => {
        this.handleNextStep()
    }

    handleNextStep = () => {
        this.setState((prevState) => ({
            activeStep: prevState.activeStep + 1
        }));
    }
    handleGoBack = () => {
        this.setState((prevState) => ({
            activeStep: prevState.activeStep - 1
        }))
    }

    handleOnSubmit = (values, actions) => {
        const {activeStep} = this.state;

        if (activeStep === 0) {
            this.handleConfirmAccountStep(values, actions);
        } else if (activeStep === 1) {
            this.handlePinOptions(values, actions);
        } else if (activeStep === 2) {
            this.handleChoosePassword(values, actions);
        } else if (activeStep === 3) {
            this.handleSignUpCompleted(values, actions);
        }
    };

    render() {
        const {activeStep, initialFormValues} = this.state;

        return (
            <GuestLayout>
                <div className="cp-page cp-page-login justify-content-start">
                    <div className="card-guest card-full-height">
                        <Formik
                            initialValues={initialFormValues}
                            /* validationSchema={validationSchema(activeStep)} */
                            onSubmit={this.handleOnSubmit}
                            enableReinitialize={true}
                        >
                            {(formProps) => (
                                <Form
                                    onSubmit={formProps.handleSubmit}
                                    className="cp-form"
                                    autoComplete="off"
                                    noValidate
                                >
                                    <div className="form-sections-wrapper">
                                        <StepContent
                                            activeStep={activeStep}
                                            formProps={formProps}
                                            handleGoBack={this.handleGoBack}
                                            {...this.props}
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </GuestLayout>
        );
    }
}


const getCheckedAccountLoader = createLoadingSelector(['CHECK_ACCOUNT']);
const getCheckedAccountError = selectErrorMessage(['CHECK_ACCOUNT']);

const mapStateToProps = (state) => {
    const checkedAccountData = selectSignUp(state).checkedAccount,
        checkedAccountLoader = getCheckedAccountLoader(state),
        checkedAccountError = getCheckedAccountError(state)

    return {
        checkedAccountData,
        checkedAccountLoader,
        checkedAccountError
    };
};

const mapDispatchToProps = {
    checkByEmailOrNumber,
    checkByAcountAndZipcode,
    sendAuthCode
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
import {
	EMPTY_GUEST_ORDER,
	CHECK_AVAILABLE_PLANS,
	CREATE_NO_SERVICE_ACCOUNT,
	CREATE_ACCOUNT,
	PLAN_DETAILS,
	CREATE_ORDER,
	CHECK_EMAIL_VERIFIED,
	UPDATE_ACCOUNT_EMAIL,
	MAP_LINK_LOCATION,
	GET_PLAN_CATEGORIES,
} from '../actions/guestOrderActions';

let initialState = {
	availablePlans: {},
	noServiceAccountInfo: {},
	newAccountInfo: {},
	plansDetails: [],
	emailVerified: false,
	verificationCounter: 0,
	validatedAddress: null,
	planCategories: []
};

export const guestOrderReducer = (state = initialState, action) => {

	switch (action.type) {
		case CHECK_AVAILABLE_PLANS.SUCCESS:
			return {
				...state,
				availablePlans: action.payload
			};
		case CHECK_AVAILABLE_PLANS.EMPTY:
			return {
				...state,
				availablePlans: {}
			};
		case CREATE_NO_SERVICE_ACCOUNT.SUCCESS:
			return {
				...state,
				noServiceAccountInfo: action.payload
			};
		case CREATE_ACCOUNT.SUCCESS:
			return {
				...state,
				newAccountInfo: action.payload
			};
		case UPDATE_ACCOUNT_EMAIL.SUCCESS:
			return {
				...state
			};
		case PLAN_DETAILS.SUCCESS:
			//JSON.stringify comparisons to stop duplications
			if(JSON.stringify(state.plansDetails[0]) === JSON.stringify(action.payload)) {
				return {
					...state,
					plansDetails: state.plansDetails
				}
			}
			return {
				...state,
				plansDetails: [ ...state.plansDetails, action.payload ]
			};
		case PLAN_DETAILS.EMPTY:
			return {
				...state,
				plansDetails: []
			};
		case CREATE_ORDER.SUCCESS:
			return {
				...state,
				newOrderInfo: action.payload
			}
		case CHECK_EMAIL_VERIFIED.SUCCESS:
			return {
				...state,
				emailVerified: action.payload.result,
				verificationCounter: state.verificationCounter + 1
			}
		case EMPTY_GUEST_ORDER.SUCCESS:
			return {
				...state,
				...initialState
			};
		case MAP_LINK_LOCATION.SUCCESS:
			return {
				...state,
				validatedAddress: action.payload.validatedAddress
			};
		case MAP_LINK_LOCATION.FAILURE:
			return {
				...state,
				validatedAddress: action.payload
			};
		case MAP_LINK_LOCATION.EMPTY:
			return {
				...state,
				validatedAddress: null
			};
		case GET_PLAN_CATEGORIES.SUCCESS:
			return {
				...state,
				planCategories: action.payload
			};
		default:
			return state;
	}
};

import {
	ACCOUNT_INFO,
	GET_ACCOUNT_CREDIT_BALANCE
} from '../actions/accountInfoActions';

let initialState = {
	accountInfo: {},
	creditBalance: null
};

export const accountInfoReducer = (state = initialState, action) => {
	switch (action.type) {
        case ACCOUNT_INFO.SUCCESS:
			return {
				...state,
				accountInfo: action.payload
			};
		case ACCOUNT_INFO.EMPTY:
			return {
				...state,
				accountInfo: {}
			};
		case GET_ACCOUNT_CREDIT_BALANCE.SUCCESS:
			return {
				...state,
				creditBalance: action.payload
			}
		case GET_ACCOUNT_CREDIT_BALANCE.EMPTY:
			return {
				...state,
				creditBalance: null
			}
		default:
			return state;
	}
};

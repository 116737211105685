import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    getCheckAvailability,
    emptyGuestOrder
} from '../../actions/guestOrderActions';
import {
    validateSignupForServiceToken,
    getOrderAvailability,
    createOrderAppointment,
    completeScheduleInstall,
} from '../../actions/signUpToServiceActions';
import {
    selectGuestOrder,
    createLoadingSelector,
    selectErrorMessage, getSignupToService
} from '../../selectors';
import {isEmpty, generateId, trans} from '../../utils/helpers';
import {validationSchema} from './validation';
import GuestLayout from '../Layout/GuestLayout';
import {Formik, Form} from 'formik';
import StepContent from './StepContent';
import IframeAddress from './IframeAddress';
import Loader from "../../components/common/Spinners/Loader";
import {toastr} from "react-redux-toastr";
import * as moment from "moment";

export class Scheduling extends Component {

    state = {
        tokenErrorTitle:'',
        tokenErrorDescription:'',
        tokenErrorNote:'',
        activeStep: 'schedule-appointment',
        isTokenLoading: true,
        validationToken: '',
        invalidToken: false,
        iframeAddressUrl: '',
        iframeLoader: false,
        initialFormValues: {
            appointmentDate: '',
            accountId: '',
            formattedAddress: '',
            serviceOrderId: '',
            orderId: '',
            account: {
                accountHolder: {
                    accountType: window.guestOrderConfig.residentialAccountTypeId,
                    firstName: '',
                    lastName: '',
                    companyName: '',
                    personalEmail: '',
                    serviceAddress: '',
                    mobileNumber: {
                        number: '',
                        verified: false,
                    },
                },
                accountTermsInfo: {
                    accountTerm: '',
                    billCycle: window.guestOrderConfig.residentialDefaultBillcycle,
                },
                locationId: window.guestOrderConfig.defaultLocationId,
                termsAndCondition: false,
            },
            order: {
                accountId: '',
                address: '',
                name: 'NEW_ORDER_' + generateId(),
                note: '',
                plans: [],
                type: 'NEW_SERVICE',
                source: 'PORTAL',
            },
        },
    };

    componentDidMount() {

        (async() => {
            const tokenErrorTitle=await trans('en-us', 'confirmOrderScreen.selectService.tokenErrorTitle');
            const tokenErrorDescription=await trans('en-us', 'confirmOrderScreen.selectService.tokenErrorDescription');
            const tokenErrorNote=await trans('en-us', 'confirmOrderScreen.selectService.tokenErrorNote');

            this.setState({tokenErrorTitle,tokenErrorDescription,tokenErrorNote});
        })()


        const validationToken = this.props.location.search.substring(7);

        // Validate token
        this.props.validateSignupForServiceToken(validationToken).then((response) => {

            if (response && response.data && response.data.success === false) {

                // Display token error
                this.setState({
                    isTokenLoading: false,
                    invalidToken: true,
                });
            }
            else {

                let updatedInitialFormValues = this.state.initialFormValues;

                if (response.data.orderId) {
                    updatedInitialFormValues.orderId = response.data.orderId;
                }
                if (response.data.serviceOrderId) {
                    updatedInitialFormValues.serviceOrderId = response.data.serviceOrderId;
                }
                if (response.data.addressWrapper) {
                    updatedInitialFormValues.formattedAddress = response.data.addressWrapper.addressWrapper.fullAddress;
                    updatedInitialFormValues.order.address = response.data.addressWrapper.addressWrapper.fullAddress;
                }
                if (response.data.accountId) {
                    updatedInitialFormValues.accountId = response.data.accountId;
                    updatedInitialFormValues.order.accountId = response.data.accountId;
                }
                if (response.data.contact && response.data.contact.firstName) {
                    updatedInitialFormValues.account.accountHolder.firstName = response.data.contact.firstName;
                }
                if (response.data.contact && response.data.contact.lastName) {
                    updatedInitialFormValues.account.accountHolder.lastName = response.data.contact.lastName;
                }
                if (response.data.contact && response.data.contact.company) {
                    updatedInitialFormValues.account.accountHolder.company = response.data.contact.company;
                }
                if (response.data.contact && !isEmpty(response.data.contact.contactEmails) && !isEmpty(response.data.contact.contactEmails.filter(email => email.type === "PERSONAL"))) {
                    updatedInitialFormValues.account.accountHolder.personalEmail = response.data.contact.contactEmails.filter(email => email.type === "PERSONAL")[0].email;
                }
                if (response.data.contact && !isEmpty(response.data.contact.contactEmails) && !isEmpty(response.data.contact.contactEmails.filter(email => email.type === "BUSINESS"))) {
                    updatedInitialFormValues.account.accountHolder.personalEmail = response.data.contact.contactEmails.filter(email => email.type === "BUSINESS")[0].email;
                }
                if (response.data.contact && !isEmpty(response.data.contact.contactAddresses) && !isEmpty(response.data.contact.contactAddresses.filter(address => address.type === "SERVICE"))) {
                    updatedInitialFormValues.account.accountHolder.serviceAddress = response.data.contact.contactAddresses.filter(address => address.type === "SERVICE")[0].address1;
                }
                if (response.data.contact && !isEmpty(response.data.contact.contactNumbers) && !isEmpty(response.data.contact.contactNumbers.filter(number => number.numberType === "MOBILE"))) {
                    response.data.contact.contactNumbers.filter(number => number.numberType === "MOBILE").map(number => {
                        updatedInitialFormValues.account.accountHolder.mobileNumber.number = number.number;
                        updatedInitialFormValues.account.accountHolder.mobileNumber.verified = number.verified;
                    });
                }
                if (response.data.terms && response.data.terms.id) {
                    updatedInitialFormValues.account.accountTermsInfo.accountTerm = response.data.terms.id;
                }
                if (response.data.billCycle && response.data.billCycle.billCycle) {
                    updatedInitialFormValues.account.accountTermsInfo.billCycle = response.data.billCycle.billCycle;
                }

                // Store updated form values
                this.setState({
                    isTokenLoading: false,
                    validationToken: validationToken,
                    initialFormValues: updatedInitialFormValues,
                })

                // Load plans
                this.props.getCheckAvailability(response.data.addressWrapper, window.guestOrderConfig.defaultLocationId).then((response) => {

                    // Load map
                    if (response && response.validatedAddress && response.validatedAddress.embedUrl) {
                        this.setState({
                            iframeAddressUrl: response.validatedAddress.embedUrl,
                            iframeLoader: true
                        });
                    }

                    if (response && response.availablePlans && (response.availablePlans.validatedAddress.allowedServiceOrderType === "NEW_SIGNUP" || response.availablePlans.validatedAddress.allowedServiceOrderType === "NEW_SURVEY")) {
                        this.handleChangeStep('not-available');
                    }
                });
            }
        });
    }

    componentWillUnmount() {

        this.props.emptyGuestOrder();
    }

    handleOnSubmit = (values, actions) => {
        if (this.state.activeStep === 'confirmation') {
            if (values.appointmentDate) {

                const orderId = values.orderId;
                const appointmentData = {
                    overbook: false,
                    dateTime: moment(values.appointmentDate).format("YYYY-MM-DDTHH:mm:ss")
                };

                // Create appointment
                this.props.createOrderAppointment(orderId, appointmentData).then(createOrderAppointmentResponse => {
                    if (createOrderAppointmentResponse.data.success) {
                        this.props.completeScheduleInstall(this.state.validationToken).then(completeScheduleInstallResponse => {
                            if (completeScheduleInstallResponse.data.success) {
                                this.handleChangeStep('success');
                            } else {
                                this.handleErrorSchedulingInstall()
                                this.handleResetWizard();
                            }

                        });
                    } else {
                        this.handleErrorSchedulingInstall()
                        this.handleResetWizard();
                    }
                });
            }
        }
    }

    handleErrorSchedulingInstall = () => {
        toastr.error('We couldn\'t create your appointment. Please contact us.',
            {disableTimeOut : true, timeOut: 0, extendedTimeOut: 0, position: 'top-center'});
    }

    handleChangeStep = (step) => {
        this.setState({
            activeStep: step
        });
    };

    handleBackStep = (step, setFieldValue) => {
        this.props.emptyPlanDetails();
        setFieldValue('schedule-appointment', []);
        this.setState({
            activeStep: step,
            planDetailsError: ''
        });
    };

    handleResetWizard = () => {
        const { initialFormValues } = this.state;

        this.setState({
            activeStep: 'schedule-appointment',
            iframeAddressUrl: '',
            iframeLoader: false,
            planDetailsError: ''
        });
    };

    handleHideIframeLoader = () => {
        this.setState({
            iframeLoader: false
        });
    };

    render() {

        const {
            isTokenLoading,
            invalidToken,
            initialFormValues,
            iframeAddressUrl,
            iframeLoader,
            activeStep,
            planDetailsError,
        } = this.state;

        return (
            <GuestLayout>
                <div className="cp-page cp-page-guest">
                    <div className="cp-container-maps partial-width">

                        <IframeAddress
                            iframeAddressUrl={iframeAddressUrl}
                            iframeLoader={iframeLoader}
                            handleHideIframeLoader={this.handleHideIframeLoader}
                        />

                        <div className="cp-container-maps-overlay">
                            <div className="card-guest card-full-height">
                                <div className="card-guest-body">

                                    {isTokenLoading
                                        ? <Loader />
                                        : invalidToken
                                            ?
                                            <div className="form-section">
                                                <div className="message">
                                                    <div className="message-left text-danger">
                                                        <i className="fas fa-times"/>
                                                    </div>
                                                    <div className="message-right">
                                                        <h4 className="text-danger">{this.state.tokenErrorTitle}</h4>
                                                        <p className="message-code">{this.state.tokenErrorDescription}</p>
                                                        <p>{this.state.tokenErrorNote}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <Formik
                                                initialValues={initialFormValues}
                                                validationSchema={validationSchema(activeStep)}
                                                onSubmit={this.handleOnSubmit}
                                                enableReinitialize={true}
                                            >
                                                {(formProps) => (
                                                    <Form
                                                        onSubmit={formProps.handleSubmit}
                                                        className="cp-form"
                                                        autoComplete="off"
                                                        noValidate
                                                    >
                                                        <div className="form-sections-wrapper">
                                                            <StepContent
                                                                activeStep={activeStep}
                                                                planDetailsError={planDetailsError}
                                                                handleResetWizard={this.handleResetWizard}
                                                                handleChangeStep={this.handleChangeStep}
                                                                handleBackStep={this.handleBackStep}
                                                                populateServiceAndLines={this.populateServiceAndLines}
                                                                formProps={formProps}
                                                                {...this.props}
                                                            />
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </GuestLayout>
        );
    }
}

const getAvailabilityLoadingSelector = createLoadingSelector(['GET_ORDER_AVAILABILITY']);
const getCreateOrderLoader = createLoadingSelector(['CREATE_ORDER']);
const getCreateOrderError = selectErrorMessage(['CREATE_ORDER']);

const mapStateToProps = (state) => {

    const availabilityLoading = getAvailabilityLoadingSelector(state);
    const createOrderLoader = getCreateOrderLoader(state);
    const createOrderError = getCreateOrderError(state);

    const newOrderInfo = selectGuestOrder(state).newOrderInfo;
    const technicianAvailability = getSignupToService(state).orderAvailability;

    return {
        newOrderInfo,
        createOrderLoader,
        createOrderError,
        availabilityLoading,
        technicianAvailability,
    };
};

const mapDispatchToProps = {
    validateSignupForServiceToken,
    getCheckAvailability,
    emptyGuestOrder,
    getOrderAvailability,
    createOrderAppointment,
    completeScheduleInstall,
};

export default connect(mapStateToProps, mapDispatchToProps)(Scheduling);

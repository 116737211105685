import * as Yup from 'yup';

export const validationSchema = (activeStep, fieldValidationKey) => {
	const messages = window.validationMessages; // This is from external js file

	const calculateRequiredOrOptional = (name) => {
		if(fieldValidationKey.find((value) => value.name === name) && fieldValidationKey.find((value) => value.name === name).required === true) {
			return 'required'
		} else {
			return 'not required'
		}
	}

	const phoneRegExp = /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/
	// const zipcodeRegExp = /(^\d{5}$)|(^\d{5}-\d{4}$)/

	if (activeStep === 'check-availability') {
		// return Yup.object().shape({
			// street: Yup.string().min(3, messages.street).required('Required'),
			// zipcode: Yup.string().matches(zipcodeRegExp, 'Invalid zip').required('Required'),
		// });
	}
	else if (activeStep === 'not-available-service') {
		return Yup.object().shape({
			account: Yup.object().shape({
				accountHolder: Yup.object().shape({
					personalEmail: Yup.string().email('Invalid email format').required('Required'),
				})
			})
		});
	}
	else if (activeStep === 'new-signup'){
		return Yup.object().shape({
		})
	}
	else if (activeStep === 'customer-info'){

		return Yup.object().shape({
			account: Yup.object().shape({
				accountHolder: Yup.object().shape({
					firstName: Yup.string().min(1, 'Min 1 character').when(() =>  {
						const result = calculateRequiredOrOptional('firstName')
						if(result === 'required') {
							return Yup.string().min(1, 'Min 1 characters').required('Required')
						}
					}),
					lastName: Yup.string().min(1, 'Min 1 characters').when(() =>  {
						const result = calculateRequiredOrOptional('lastName')
						if(result === 'required') {
							return Yup.string().min(1, 'Min 1 characters').required('Required')
						}
					}),
					company: Yup.string().min(1, 'Min 1 characters').when(() =>  {
						const result = calculateRequiredOrOptional('company')
						if(result === 'required') {
							return Yup.string().min(1, 'Min 1 characters').required('Required')
						}
					}),
					personalEmail: Yup.string().email('Invalid email format').when(() =>  {
						const result = calculateRequiredOrOptional('personalEmail')
						if(result === 'required') {
							return Yup.string().email('Invalid email format').required('Required')
						}
					}),
					confirmEmail: Yup.string().oneOf([Yup.ref('personalEmail'), null], 'Emails must match').when(() =>  {
							const result = calculateRequiredOrOptional('personalEmail')
							if(result === 'required') {
								return Yup.string().oneOf([Yup.ref('personalEmail'), null], 'Emails must match').required('Required')
							}
						}),
					businessEmail: Yup.string().email('Invalid email format').when(() =>  {
						const result = calculateRequiredOrOptional('businessEmail')
						if(result === 'required') {
							return Yup.string().email('Invalid email format').required('Required')
						}
					}),
					confirmBusinessEmail: Yup.string().oneOf([Yup.ref('businessEmail'), null], 'Emails must match').when(() =>  {
						const result = calculateRequiredOrOptional('businessEmail')
						if(result === 'required') {
							return Yup.string().oneOf([Yup.ref('businessEmail'), null], 'Emails must match').required('Required')
						}
					}),
					mobileNumber: Yup.object().shape({
						number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').when(() =>  {
							const result = calculateRequiredOrOptional('mobileNumber')
							if(result === 'required') {
								return Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Required')
							}
						}),
						verified: Yup.boolean()
					}),
					businessNumber: Yup.object().shape({
						number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').when(() =>  {
							const result = calculateRequiredOrOptional('businessNumber')
							if(result === 'required') {
								return Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Required')
							}
						}),
						verified: Yup.boolean()
					})
				}),
				termsAndCondition: Yup.bool().oneOf([true], messages.termsAndCondition)
			})
		})
	}
};

import React, {Component} from 'react';
import ReactHtmlParser from "react-html-parser";
import PackageTooltip from "../../../../../components/PackageTooltip";
import isEmpty from "../../../../../utils/helpers";

class IncludedPackage extends Component {

	state = {
		isTooltipOpen: false
	};

	handleTooltip = () => {
		this.setState((prevProps) => ({
			isTooltipOpen: !prevProps.isTooltipOpen
		}));
	};

	render() {
		const { isTooltipOpen } = this.state;
		const { featurePackage } = this.props;

		return (
			<div className={"package" + (featurePackage.hideCustomerPortal ? " d-none" : "")}>
				<div className="package-header custom-switch-with-button">

					<div className="custom-control custom-switch">
						<input type="checkbox" className="custom-control-input" checked={true} disabled={true}/>
						<label className="custom-control-label" htmlFor="">
							<span>{featurePackage.featurePackageOptions[0].description}</span>
							<span>
								${featurePackage.featurePackageOptions[0].recurringMonthlyPrice
								.toFixed(2)
								.replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo
							</span>
						</label>
					</div>

					{!isEmpty(featurePackage.featurePackageOptions[0].note) &&
					<button type="button" className="btn btn-toggle-package-desc" onClick={() => this.handleTooltip()}>
						<i className="fas fa-info-circle"/>
					</button>
					}

				</div>

				<PackageTooltip isVisible={isTooltipOpen}>{ReactHtmlParser(featurePackage.featurePackageOptions[0].note)}</PackageTooltip>

			</div>
		);
	}
}

export default IncludedPackage;

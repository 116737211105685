import React, { Component } from 'react';
import RoundedInput from '../../../components/common/Formik/RoundedInput';
import {trans} from "../../../utils/helpers";

export class SignUpCompleted extends Component {
	state={
		title:'',
		signInOPtion:''
	}

	componentDidMount(){
		(async() => {
			const title=await trans('en-us', 'registerScreen.signUpCompletedSection.title');
			const signInOPtion=await trans('en-us', 'registerScreen.signUpCompletedSection.signInOPtion');
				
			this.setState({title,signInOPtion});
		})()
	}
	render() {
		return (
			<div className="form-section fx-from-right" id="fs5">
				<h4 className="text-center text-success font-weight-bold">{this.state.title}</h4>
				<div className="form-group">
					<p className="display-4 text-success text-center">
						<i className="fas fa-check" />
					</p>
				</div>
				<p className="text-success text-center font-weight-bold">
					{this.state.signInOPtion}
				</p>
				<div className="form-group">
					<label for="">Email</label>
					<RoundedInput type="text" placeholder="Email" />
				</div>
				<div className="form-group">
					<label for="">Password</label>
					<RoundedInput type="password" placeholder="Password" />
				</div>
				<div className="form-group form-group-footer">
					<button type="submit" formaction="cp-forgotten-password.html" className="btn btn-outline-secondary">
						Reset Password
 					</button>
					<button type="submit" formaction="cp-dashboard.html" id="sendlogin" className="btn btn-primary">
						Sign In
 					</button>
				</div>
			</div>
		);
	}
}

export default SignUpCompleted;
import React, { Component, Fragment } from 'react';
import isEmpty from '../../../../utils/helpers';
import Loader from '../../../../components/common/Spinners/Loader';
import PlanList from './PlanList';
import PackageTooltip from "../../../../components/PackageTooltip";
import {trans} from "../../../../utils/helpers";

class ServiceDetailsStep extends Component {
	state = {
		recurringDescription:'',
		oneTimeDescription:'',
		toggledDescription: [],
		showProductPrice: false,
		showOneTimePrice: false
	};

	componentDidMount(){
		(async() => {
			const recurringDescription= await trans('en-us', 'guestOrderScreen.serviceDetails.recurringDescription');
			const oneTimeDescription= await trans('en-us', 'guestOrderScreen.serviceDetails.oneTimeDescription');

			this.setState({recurringDescription,oneTimeDescription});
		})()
	}

	componentDidUpdate(prevProps) {
		let showProductPrice = false;
		let showOneTimePrice = false;
		if (prevProps.values !== this.props.values) {
			if(this.props.values.order){
				if(this.props.values.order.plans){
					this.props.values.order.plans.map(plan => {
						if(plan.servicelines){
							plan.servicelines.map(serviceline => {
								if(this.props.values.account) {
									showProductPrice = this.checkHidePriceFlag(serviceline, showProductPrice,
										this.props.values.account.initialStatus == "SIGNUP");
								}
								serviceline.features.map(feature => {
									if (feature.featureType === 'ACTIVATION') {
										if(feature.price > 0 || feature.oneTimePrice > 0){
											showOneTimePrice = true;
										}
									}
								})
							})
						}
					})
				}
			}
			this.setState({
				showProductPrice: showProductPrice,
				showOneTimePrice: showOneTimePrice
			});
		}
	}

	checkHidePriceFlag = (serviceline, showProductPrice, isSignup) => {
		if(serviceline.features){
			serviceline.features.map(feature => {
				if(feature.features){
					feature.features.map(subFeature => {
						if(isSignup) {
							if (!subFeature.hideSignupPrice) {
								showProductPrice = true;
							}
						}else{
							if (!subFeature.hideSurveyPrice) {
								showProductPrice = true;
							}
						}
					})
				}else{
					if(isSignup) {
						if (!feature.hideSignupPrice) {
							showProductPrice = true;
						}
					}else{
						if (!feature.hideSurveyPrice) {
							showProductPrice = true;
						}
					}
				}
			})
		}else if(isSignup) {
			if (!serviceline.hideSignupPrice) {
				showProductPrice = true;
			}
		}else{
			if (!serviceline.hideSurveyPrice) {
				showProductPrice = true;
			}
		}
		return showProductPrice;
	}

	handleShowHideDescription = (type) => {
		const { toggledDescription } = this.state;

		if (toggledDescription.includes(type)) {
			this.setState({
				toggledDescription: toggledDescription.filter((x) => x !== type)
			});
		} else {
			this.setState({
				toggledDescription: [...toggledDescription, type]
			});
		}
	};

	checkFeaturePrice = (feature) => {
		let showProductPrice = false;
		if(feature.featurePackageOptions != undefined) {
			if (this.checkHidePriceFlag(feature.featurePackageOptions[0], showProductPrice, this.props.values.account.initialStatus == "SIGNUP")) {
				return feature.featurePackageOptions[0].recurringMonthlyPrice;
			}
		}
		if(this.checkHidePriceFlag(feature, showProductPrice, this.props.values.account.initialStatus == "SIGNUP")) {
			if(feature.features != undefined){
				let price = 0;
				feature.features.map(subFeature => {
					price += subFeature.recurringMonthlyPrice;
				})
				return price;
			}
			else if(feature.oneTimePrice > 0){
				return feature.oneTimePrice;
			}else{
				if(feature.recurringMonthlyPrice == 0 && feature.price > 0){
					return feature.price;
				}
				return feature.recurringMonthlyPrice;
			}
		}
		return 0;
	}

	calculatePrice = (values, billType) => {
		let price = values.order.plans.reduce((acc, currentValue, index) => {
			currentValue.servicelines.map((serviceline) => {
				serviceline.features.map((feature) => {
					if (feature.featureType !== 'ACTIVATION' && billType === "recurring") {
						acc += this.checkFeaturePrice(feature);
					}
					if(feature.featureType === 'ACTIVATION' && billType === "onetime") {
						acc += this.checkFeaturePrice(feature)
					}
				});
			});
			return acc;
		}, 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')

		if(billType === "recurring") price = price + '/mo'
		return price
	}

	render() {
		const { toggledDescription, showProductPrice, showOneTimePrice } = this.state;
		const {
			handleChangeStep,
			handleBackStep,
			plansDetailsData,
			values,
			planDetailsError,
			setFieldValue,
			totalPlans
		} = this.props;

		if (isEmpty(plansDetailsData) && planDetailsError === '') {
			return <Loader />;
		}

		return (
			<div className="form-section fx-fadein" id="fs5">

				<h4>Configure your Services</h4>

				<p className="form-divider">
					<i className="fas fa-map-marker-alt" /> {values.formattedAddress}
				</p>

				<p className="text-center text-xs text-muted d-block d-sm-none">Scroll down to see map</p>

				<div className="card-product">

					<div className="card-body">

						{planDetailsError && (
							<div class="alert alert-info">
								{planDetailsError}. We cannot process your request at this moment. Please try again
								later.
							</div>
						)}

						{!isEmpty(plansDetailsData) &&
							plansDetailsData.map((plan) => (
								<PlanList
									key={plan.id}
									planInfo={plan}
									planIndex={values.order.plans.findIndex((x) => x.planId === plan.id)}
									{...this.props}
								/>
							))}

						{showProductPrice &&
						<div className="product-price">

							{showOneTimePrice &&
							<div className="package">
								<div className="package-header">
									<span className="package-left">One-time:</span>
									<span className="package-right">
										${this.calculatePrice(values, "onetime")}
										<button
											type="button"
											className={'btn btn-toggle-package-desc' + (toggledDescription.includes('oneTime') ? ' toggled' : '')}
											onClick={() => this.handleShowHideDescription('oneTime')}
										>
											<i className="fas fa-info-circle"/>
										</button>
									</span>
								</div>

								<PackageTooltip color={'white'} isVisible={toggledDescription.includes('oneTime')}>
									{this.state.oneTimeDescription}
								</PackageTooltip>

							</div>
							}

							<div className="package">
								<div className="package-header">
									<span className="package-left">Recurring:</span>
									<span className="package-right">
										${this.calculatePrice(values, "recurring")}
										<button
											type="button"
											className={
												toggledDescription.includes('reccuring') ? (
													'btn btn-toggle-package-desc toggled'
												) : (
													'btn btn-toggle-package-desc'
												)
											}
											onClick={() => this.handleShowHideDescription('reccuring')}
										>
											<i className="fas fa-info-circle"/>
										</button>
									</span>
								</div>

								<PackageTooltip color={'white'} isVisible={toggledDescription.includes('reccuring')}>
									{this.state.recurringDescription}
								</PackageTooltip>

							</div>

						</div>
						}
					</div>

					<div className="card-footer">

						<button
							onClick={() => totalPlans>1?handleBackStep('new-signup', setFieldValue):handleBackStep('check-availability', setFieldValue)}
							type="button"
							id=""
							className="btn btn-quick"
						>
							Back
						</button>

						{!planDetailsError && (
							<button
								onClick={() => handleChangeStep('customer-info')}
								type="button"
								id="sendlogin"
								className="btn btn-primary"
							>
								Continue
							</button>
						)}

					</div>

				</div>

			</div>
		);
	}
}

export default ServiceDetailsStep;

import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import moment from "moment";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import {isEmpty, trans} from "../../../../utils/helpers";
import Loader from "../../../../components/common/Spinners/Loader";
import '../../../../resources/components/ui-component-datepicker.scss'

export class ScheduleAppointmentStep extends Component {

	state = {
		title:'',
		description:'',
		error:'',
		appointmentError: false,
		availabilityDates: undefined,
		availabilityTimes: [],
	}

	componentDidMount() {
		(async() => {
			const title=await trans('en-us', 'confirmOrderScreen.scheduleAppointment.title');
			const description=await trans('en-us', 'confirmOrderScreen.scheduleAppointment.description');
			const error=await trans('en-us', 'confirmOrderScreen.scheduleAppointment.error');

			this.setState({title,description,error})
		})()

		this.props.getOrderAvailability(this.props.values.orderId).then((response) => {
			this.getAvailableDates()
		})

		this.handleDateTimeChange(this.props.values.appointmentDate)
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!this.props.availabilityLoading && this.props.technicianAvailability !== undefined && this.state.availabilityDates === undefined) {
			this.getAvailableDates();
		}
	}

	handleDateTimeChange = (newDateTime) => {
		const {values, setFieldValue} = this.props;

		let updatedAppointmentDate;

		// If selecting the same day or there is no selected date
		if (values.appointmentDate === '' || moment(values.appointmentDate).format("YYYY-MM-DD") === moment(newDateTime).format("YYYY-MM-DD")) {
			updatedAppointmentDate = newDateTime;
		}
		// If selecting a different day, reset hours
		else {
			updatedAppointmentDate = setHours(newDateTime, 0);
		}


		// Load available timeslots
		let availabilityTimes = this.getAvailableTimes(updatedAppointmentDate);

		//Sets appointment to first timeslot available if different day selected
		if(moment(values.appointmentDate).dayOfYear() !== moment(updatedAppointmentDate).dayOfYear()) {
			updatedAppointmentDate = availabilityTimes[0]
		}

		// Set new appointment date
		setFieldValue("appointmentDate", updatedAppointmentDate);
	}

	getAvailableDates = () => {
		let availabilityDates = [];
		let filteredAvailabilityDates = [];
		// Get all dates
		Object.keys(this.props.technicianAvailability).map((date) => {
			availabilityDates.push(moment(date).toDate())
		});
		// Include dates with non-zero availability
		availabilityDates.map(availabilityDate => {

			let includeDate = false;
			let scheduleDaysOut = window.guestOrderConfig.scheduleDaysOut || 1;

			// If the date is after the scheduleDaysOut configuration
			if (moment(availabilityDate).toDate() >= (moment().add(scheduleDaysOut,'days').toDate())) {

				// If there is availability data for this date
				if (this.props.technicianAvailability[moment(availabilityDate).format("YYYY-MM-DD")]) {

					// Get all slots
					this.props.technicianAvailability[moment(availabilityDate).format("YYYY-MM-DD")].map(slot => {
						// Include date if it has some availability
						if (slot.availability > 0) {
							includeDate = true;
						}
					})
				}
			}
			// Push date
			if (includeDate) {
				filteredAvailabilityDates.push(availabilityDate);
			}

		});

		this.setState({
			availabilityDates: filteredAvailabilityDates
		});
	}

	getAvailableTimes = (date) => {
		const {values, setFieldValue} = this.props;
		let updatedAppointmentDate = date || values.appointmentDate;

		let availabilityTimes = [];
		let resetTime = true;

		// If there is availability for this date
		if (this.props.technicianAvailability[moment(updatedAppointmentDate).format("YYYY-MM-DD")]) {

			// Get available slots
			this.props.technicianAvailability[moment(updatedAppointmentDate).format("YYYY-MM-DD")].map(slot => {

				// Don't add timeslot if it has no availability
				if (slot.availability <= 0) {
					return;
				}

				// Add timeslot
				availabilityTimes.push(setHours(setMinutes(updatedAppointmentDate, 0), moment.duration(slot.fromTime).asHours()));

				// Don't reset hours if this timeslot is selected
				if (slot.fromTime === moment(updatedAppointmentDate).format("HH:mm:ss")) {
					resetTime = false;
					this.setState({appointmentError: false});
				}
			});

			// If selected time is not available
			if (resetTime) {
				setFieldValue("appointmentDate", setHours(updatedAppointmentDate, 0));
			}
		}

		this.setState({availabilityTimes: availabilityTimes})
		return availabilityTimes
	}

	handleTimeFormat = (time) => {

		if (this.props.technicianAvailability[moment(time).format("YYYY-MM-DD")]) {

			let className = '';
			let timeSlot = this.props.technicianAvailability[moment(time).format("YYYY-MM-DD")].filter(
				slot => slot.fromTime === moment(time).format("HH:mm:00"));

			/** Formats the time to visually have am/pm and a timeslot range **/
			if (timeSlot.length) {
				className += " react-datepicker__time-list-item--timeslot-to-";
				className += timeSlot[0].toTime.substring(0, 2);
			}


			return className;
		}
	}

	handleSubmit = () => {
		const {values, handleChangeStep} = this.props;

		if (values.appointmentDate && values.appointmentDate.getHours() !== 0) {
			// Move to next step
			handleChangeStep('confirmation');
		}
		else {

			// Show error if no date & time selected
			this.setState({appointmentError: true});
		}
	}

	render() {

		const {
			appointmentError,
			availabilityDates,
			availabilityTimes,
		} = this.state;

		const {
			handleChangeStep,
			values,
			availabilityLoading,
		} = this.props;

		return (
			<div className="form-section">

				<h4>{this.state.title}</h4>
				
				<p className="form-divider">{this.state.description}</p>

				{availabilityLoading
				?
				<Loader />
				:
				<div className="card-product">

					<div className="card-body">

						<div className="card-body-datepicker card-body-datepicker--appointment">
							<DatePicker
								inline
								showTimeSelect
								disabledKeyboardNavigation
								dateFormat="MMMM d, yyyy h:mm aa"
								selected={values.appointmentDate}
								placeholderText={"Select Appointment Date and Timeslot"}
								minDate={moment().toDate()}
								includeDates={availabilityDates}
								includeTimes={availabilityTimes}
								timeIntervals={60}
								timeFormat="h"
								timeClassName={time => this.handleTimeFormat(time)}
								onChange={(date) => this.handleDateTimeChange(date)}
							/>
						</div>

					</div>

					{appointmentError &&
					<div style={{display: 'block'}} className="invalid-feedback">
						{this.state.error}
					</div>
					}

					<div className="card-footer">
						<button
							disabled={appointmentError || !values.appointmentDate}
							onClick={this.handleSubmit}
							type="button"
							className="btn btn-primary"
						>
							Continue
						</button>

					</div>

				</div>
				}

			</div>
		);
	}
}

export default ScheduleAppointmentStep;

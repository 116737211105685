import axiosInstance from "../utils/axios-instance";
import { actionCreator, createRequestTypes } from '../utils/helpers';
export const SET_NOTIFICATION_CATEGORIES = createRequestTypes('SET_NOTIFICATION_CATEGORIES');
export const UPDATE_NOTIFICATION_CATEGORIES = createRequestTypes('UPDATE_NOTIFICATION_CATEGORIES');
export const SET_NOTIFICATION = createRequestTypes('SET_NOTIFICATION');
export const GET_NOTIFICATION_ACCOUNT_ALERT = createRequestTypes('GET_NOTIFICATION_ACCOUNT_ALERT');
export const UPDATE_NOTIFICATION_ACCOUNT_ALERT = createRequestTypes('UPDATE_NOTIFICATION_ACCOUNT_ALERT');
export const GET_ACCOUNT_NOTIFICATIONS = createRequestTypes('GET_ACCOUNT_NOTIFICATIONS');


export const getNotificationCategories = (accountId) => async (dispatch) => {
    dispatch(actionCreator.request(SET_NOTIFICATION_CATEGORIES));
    try {
        const response = await axiosInstance.get(`account/${accountId}/notifications/categories`);
        dispatch(actionCreator.success(SET_NOTIFICATION_CATEGORIES, response.data.notificationCategories));
        return response.data.notificationCategories
    } catch (err) {
        dispatch(actionCreator.failure(SET_NOTIFICATION_CATEGORIES));
    }
};

export const subscribeForNotification = (accountId, data) => async (dispatch) => {
    dispatch(actionCreator.request(SET_NOTIFICATION));
    try {
        const response = await axiosInstance.post(`/account/${accountId}/notifications/category/optin`, data);
    } catch (err) {
        dispatch(actionCreator.failure(SET_NOTIFICATION));
    }
};


export const unsubscribeFromNotification = (accountId, data) => async (dispatch) => {
    dispatch(actionCreator.request(SET_NOTIFICATION));
    try {
        const response = await axiosInstance.post(`/account/${accountId}/notifications/category/optout`, data);
    } catch (err) {
        dispatch(actionCreator.failure(SET_NOTIFICATION));
    }
};

export const updateNotificationCategorie = (accountId, notificationCategoryId) => async (dispatch) => {
    dispatch(actionCreator.request(UPDATE_NOTIFICATION_CATEGORIES));
    try {
        const response = await axiosInstance.get(`/account/${accountId}/notifications/category/${notificationCategoryId}`);
        dispatch(actionCreator.success(UPDATE_NOTIFICATION_CATEGORIES, response.data.notificationCategory));
    } catch (err) {
        dispatch(actionCreator.failure(UPDATE_NOTIFICATION_CATEGORIES));
    }
};
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import IncludedPackage from './Options/IncludedPackage';

export class IncludedPackages extends Component {
	render() {
		const { planFeature, accountInitialStatus } = this.props;
		return (
			<Fragment>
				{planFeature.map((featurePackage) => (
					<IncludedPackage
						featurePackage={featurePackage}
						key={featurePackage.featurePackageId}
						accountInitialStatus={accountInitialStatus}
					/>
				))}
			</Fragment>
		);
	}
}

IncludedPackages.propTypes = {

}

export default IncludedPackages;

import React, {Component, Fragment} from 'react';
import ReactHtmlParser from 'react-html-parser';
import isEmpty from "../../../../utils/helpers";

class Plan extends Component {
	renderButtonText = () => {
		const { initialStatus } = this.props;

		if (initialStatus === 'SIGNUP') {
			return 'Choose';
		} else if (initialStatus === 'SURVEY') {
			return 'Interested';
		} else if (initialStatus === 'ACTIVE') {
			return 'Select';
		}
	};

	render() {
		const { plan, setSelectedItem, initialStatus } = this.props;
		return (
			<div className={"product-item product-item-border" + (plan.hideCustomerPortal ? " d-none" : "")}>

				<div className="header">
					<div className="header-title">
						<div className="title">
							<h5>{plan.description}</h5>
						</div>
					</div>
				</div>

				<div className="details">
					{ReactHtmlParser(plan.note)}
				</div>

				<div className="basic">
					<div className="left">
						<Fragment><i className={plan.serviceModel.icon} key={plan.id} /></Fragment>
						<p>{plan.serviceModel.description}</p>
					</div>
					{!plan.hideFromPrice&&<div className="right text-success">
						<small>from </small>${plan.fromPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}<small> / mo</small>
					</div>}
				</div>

				{!isEmpty(plan.providers) && (
					<div className="container-service-providers">
						{plan.providers.map((provider) => (
							provider.logo &&
							<img className="cp-icon cp-icon-service-provider-r" src={`${process.env.PUBLIC_URL}/media/service-providers/${provider.logo}`} />
						))}
					</div>
				)}

				<div className="footer">
					<div className="footer-toolbar">

						<button
							type="button"
							onClick={() => setSelectedItem(plan, 'plan')}
							className="btn btn-primary btn-fs-action"
							data-target="fs5"
						>
							{this.renderButtonText()}
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default Plan;

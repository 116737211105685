import React, { Component } from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import ReactHtmlParser from 'react-html-parser';
import {trans} from '../../../../utils/helpers';

class Five extends Component {

	state={
		signUpShareMessage:'',
		signUpConfirmation:'',
		signUpFacebookUrl:'',
		signUpTwitterUrl:'',
		signUpConfirmationLine2:'',
		signUpConfirmationLine3:'',
		surveyShareMessage:'',
		surveyConfirmation:'',
		surveyFacebookUrl:'',
		surveyConfirmationLine2:'',
		surveyConfirmationLine3:'',
		activeShareMessage:'',
		activeConfirmation:'',
		activeFacebookUrl:'',
		activeTwitterUrl:'',
		activeConfirmationLine2:'',
		activeConfirmationLine3:''
	}

	componentDidMount(){
		(async() => {
			const signUpShareMessage=await trans('en-us', 'guestOrderScreen.confirmation.signUpShareMessage');
			const signUpConfirmation=await trans('en-us', 'guestOrderScreen.confirmation.signUpConfirmation');
			const signUpFacebookUrl=await trans('en-us', 'guestOrderScreen.confirmation.signUpFacebookUrl');
			const signUpTwitterUrl=await trans('en-us', 'guestOrderScreen.confirmation.signUpTwitterUrl');
			const signUpConfirmationLine2=await trans('en-us', 'guestOrderScreen.confirmation.signUpConfirmationLine2');
			const signUpConfirmationLine3=await trans('en-us', 'guestOrderScreen.confirmation.signUpConfirmationLine3');
			const surveyShareMessage=await trans('en-us', 'guestOrderScreen.confirmation.surveyShareMessage');
			const surveyConfirmation=await trans('en-us', 'guestOrderScreen.confirmation.surveyConfirmation');
			const surveyFacebookUrl=await trans('en-us', 'guestOrderScreen.confirmation.surveyFacebookUrl');
			const surveyTwitterUrl=await trans('en-us', 'guestOrderScreen.confirmation.surveyTwitterUrl');
			const surveyConfirmationLine2=await trans('en-us', 'guestOrderScreen.confirmation.surveyConfirmationLine2');

			this.setState({signUpShareMessage,signUpConfirmation,signUpFacebookUrl,signUpTwitterUrl,signUpConfirmationLine2,signUpConfirmationLine3,surveyShareMessage,surveyConfirmation,surveyFacebookUrl,surveyTwitterUrl,surveyConfirmationLine2});
		})()
	}

	renderTexts = () => {
		const { newOrderInfoData } = this.props;
		let shareMessage = '';
		let confirmation = '';
		let confirmationLine2 = '';
		let shareFacebookUrl = '';
		let shareTwitterUrl = '';

		if (newOrderInfoData.orderType === 'SIGNUP') {
			shareMessage = this.state.signUpShareMessage;
			confirmation =this.state.signUpConfirmation;
			shareFacebookUrl =this.state.signUpFacebookUrl;
			shareTwitterUrl =this.state.signUpTwitterUrl;
			confirmationLine2 =this.state.signUpConfirmationLine2;
		} else if (newOrderInfoData.orderType === 'SURVEY') {
			shareMessage =this.state.surveyShareMessage;
			confirmation =this.state.surveyConfirmation;
			shareFacebookUrl =this.state.surveyFacebookUrl;
			shareTwitterUrl =this.state.surveyTwitterUrl;
			confirmationLine2 =this.state.surveyConfirmationLine2;
		}

		let texts = {
			shareMessage,
			confirmation,
			shareFacebookUrl,
			shareTwitterUrl,
			confirmationLine2
		};

		return texts;
	};
	render() {
		const { newOrderInfoData, handleResetWizard, resetForm, setTouched } = this.props;

		let texts = this.renderTexts();

		return (
			<div className="form-section" id="fs6">
				<div className="row">
					<div className="col-md-3">
						<p className="text-center text-success">
							<i className="fas fa-4x fa-check-circle" />
						</p>
					</div>
					<div className="col-md-9">
						<h4 className="text-success">
							{texts.confirmation}
							<br />Your reference # is {newOrderInfoData.id}
						</h4>
					</div>
				</div>
				<p className="text-center text-muted">
					{/* We will contact you with futher updates.<br />Spread the word, let's build a better internet for
					Medina County. */}
					{ReactHtmlParser(texts.confirmationLine2)}
				</p>
				<div className="form-group form-group-footer">
					<FacebookShareButton url={texts.shareFacebookUrl} quote={texts.shareMessage}>
						<button type="button" className="btn btn-primary">
							<i className="fab fa-facebook" />&nbsp;Share It
						</button>
					</FacebookShareButton>
					<TwitterShareButton url={texts.shareTwitterUrl} title={texts.shareMessage}>
						<button type="button" className="btn btn-primary">
							<i className="fab fa-twitter" />&nbsp;Tweet It
						</button>
					</TwitterShareButton>
					{/* <button type="button" formaction="cp-dashboard.html" id="" className="btn btn-primary">
						<i className="fas fa-at" />&nbsp;Mail It
					</button>
					<button type="button" className="btn btn-primary btn-fs-action">
						<i className="fas fa-link" />&nbsp;Link
					</button> */}
				</div>
				<hr />
			</div>
		);
	}
}

export default Five;

import React, { Component } from 'react';
import GuestOrderInput from '../../../../components/common/Formik/GuestOrderInput';
import { get } from 'lodash';
import {trans} from "../../../../utils/helpers";

class NotAvailableServiceStep extends Component {
	state={
		title:''
	}
	componentDidMount() {
		(async() => {
			const title= await trans('en-us', 'guestOrderScreen.notAvailableService.title');

			this.setState({title})
		})()

		const { setTouched } = this.props;

		setTouched({});
	}

	componentWillUnmount() {
		const { clearNoServiceAccountError } = this.props;
		clearNoServiceAccountError();
	}

	handleGoBack = () => {
		const { handleChangeStep, setFieldValue } = this.props;
		setFieldValue('account.accountHolder.serviceAddress', '');
		setFieldValue('formattedAddress', '')
		handleChangeStep('check-availability');
	};

	render() {
		const {
			availablePlansData,
			values,
			touched,
			errors,
			handleChange,
			handleBlur,
			noServiceAccountLoader,
			noServiceAccountError,
			handleResetWizard,
			setTouched,
			resetForm
		} = this.props;

		return (
			<div className="form-section fx-fadein" id="fs2">
				<h3 className="text-center">{this.state.title}</h3>
				<p className="text-center text-sm text-muted">
					<i className="fas fa-map-marker-alt" /> {values.formattedAddress}
				</p>

				<GuestOrderInput
					type="text"
					label="Your email"
					placeholder="Email"
					name="account.accountHolder.personalEmail"
					size=""
					errors={errors}
					value={values.account.accountHolder.personalEmail}
					touched={touched}
					onChange={handleChange}
					onBlur={handleBlur}
				/>
				{noServiceAccountError && <p style={{ color: 'red' }}>{noServiceAccountError}</p>}

				<div className="form-group form-group-footer">
					<button
						type="button"
						className="btn btn-outline-secondary btn-fs-action"
						data-target="fs1"
						onClick={this.handleGoBack}
					>
						Go Back
					</button>
					<button type="submit" className="btn btn-primary btn-fs-action" disabled={noServiceAccountLoader}>
						Submit {noServiceAccountLoader && <i className="fas fa-fw fa-spin fa-spinner" />}
					</button>
				</div>
			</div>
		);
	}
}

export default NotAvailableServiceStep;

import React, {Component} from 'react';
import {validateOrderToken} from "../../../../actions/signUpActions";
import {connect} from "react-redux";
import Loader from "../../../../components/common/Spinners/Loader";


class CheckAvailabilityStep extends Component {
	state = {
		invalidToken: false,
		invalidTokenMessage: null
	}

	componentDidMount() {
		const {setFieldValue, handleCheckAvailabilityStep, values} = this.props;
		const orderToken = this.props.location.search.substring(7);

		this.props.validateOrderToken(orderToken).then((response) => {
			if (response.data.success === false) {
				this.setState({
					invalidToken: true,
					invalidTokenMessage: response.data.error.message
				});
			} else {
				setFieldValue('baseOrderId', response.data.orderId);
				handleCheckAvailabilityStep(response.data.formattedAddress, setFieldValue);
			}
		});

	}

	render() {
		const {
			values,
			errors,
			touched,
			handleBlur,
			handleChange,
			availablePlansError,
			availablePlansLoader
		} = this.props;

		return (
			<Loader />
		);
	}
}


const mapStateToProps = (state) => {
	return {
	};
};

const mapDispatchToProps = {
	validateOrderToken
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckAvailabilityStep);


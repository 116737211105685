import axiosInstance from '../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../utils/helpers';

export const SET_USER_CONFIGURATION = createRequestTypes('SET_USER_CONFIGURATION');

export const getUserConfiguration = () => async (dispatch) => {
	try {
		const response = await axiosInstance.get(`/domain/configuration`);
		dispatch(actionCreator.success(SET_USER_CONFIGURATION, response.data));
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}

		dispatch(actionCreator.failure(SET_USER_CONFIGURATION, mockError));
		return err;
	}
};

import React, { Component, Fragment } from "react";
import ProgressBar from "../../components/ProgressBar";
import IncludedFeature from "../EmailOrder/ProductsItem/Options/IncludedFeature";

export class ServiceCard extends Component {
    state = {};

    showHideManagePlan = () => {
        const { myServiceInfoItemIndex } = this.props;

        this.props.showHideManagePlan(myServiceInfoItemIndex);
    };

    buildAddress = (serviceLine) => {
        let fullAddress = "";
        if (serviceLine) {
            
            fullAddress += serviceLine.address1 ? serviceLine.address1 : "";
            fullAddress += serviceLine.city ? ", " + serviceLine.city : "";
            fullAddress +=
                serviceLine.state && serviceLine.state.name
                    ? ", " + serviceLine.state.name
                    : "";
            fullAddress += serviceLine.zipcode ? ", " + serviceLine.zipcode : "";
        }
        return fullAddress;
    };

    render() {
        const { myServiceInfoItem, myServiceInfoItemIndex, serviceLine } =
            this.props;

        return (
            <Fragment>
                <div className="cp-col col-12 col-lg-6 d-flex">
                    <div className="card card-service">
                        <div className="card-caption">
                            <div className="card-left">
                                <div className="card-icon">
                                    <i className={myServiceInfoItem.serviceModel.icon} />
                                </div>
                            </div>
                            <div className="card-right">
                                <div className="card-header">
                                    {`My ${myServiceInfoItem.planDescription}`}
                                </div>

                                <div className="card-description">
                                    {`You have ${myServiceInfoItem.serviceLines.length} service ${
                                        myServiceInfoItem.serviceLines.length > 1 ? "lines" : "line"
                                    }.`}
                                </div>
                            </div>
                        </div>

                        <div className="card-body">
                            {myServiceInfoItem.serviceLines &&
                            myServiceInfoItem.serviceLines.length > 0 &&
                            myServiceInfoItem.serviceLines.map((serviceLine) => (
                                <>
                                    <div
                                        className="card-header"
                                        style={{ fontSize: "16px", marginTop: "5px" }}
                                    >
                                        {myServiceInfoItem.serviceModel.description == "Voice"
                                            ? serviceLine.number
                                            : this.buildAddress(serviceLine)}
                                    </div>
                                    {serviceLine.servicelineFeatures &&
                                    serviceLine.servicelineFeatures.length > 0 &&
                                    serviceLine.servicelineFeatures.map((feature) => (
                                        <div
                                            className="card-description"
                                            style={{ marginLeft: "5px" }}
                                        >
                                            {feature.featureDescription}
                                        </div>
                                    ))}
                                    {serviceLine.activeFeaturePackages &&
                                    serviceLine.activeFeaturePackages.length > 0 &&
                                    serviceLine.activeFeaturePackages.map(
                                        (featurePackage) => (
                                            <>
                                                {featurePackage.featurePackageOptions &&
                                                featurePackage.featurePackageOptions.length > 0 &&
                                                featurePackage.featurePackageOptions.map(
                                                    (packageOption) => (
                                                        <div
                                                            className="card-description"
                                                            style={{ marginLeft: "5px" }}
                                                        >
                                                            {packageOption.description}
                                                        </div>
                                                    )
                                                )}
                                            </>
                                        )
                                    )}
                                </>
                            ))}
                        </div>

                        <div className="card-footer">
                            <div className="card-footer-toolbar">
                                {/*<button className="btn btn-sm btn-fw-enable btn-light"
                                        data-target="default">
                                    View Usage
                                </button>*/}

                                {/*<button className="btn btn-sm btn-fw-enable btn-light"
                                        data-target="default">
                                    Change Plan
                                </button>*/}

                                {window.managePlanEnable &&
                                myServiceInfoItem.serviceLines &&
                                myServiceInfoItem.serviceLines.length == 1 && (
                                    <button
                                        className="btn btn-sm btn-fw-enable btn-primary"
                                        onClick={this.showHideManagePlan}
                                    >
                                        Manage
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* FOR DEMO PURPOSES - SHOWN ONLY AFTER FIRST CARD
                {myServiceInfoItemIndex === 0 && (
                    <div className="cp-col col-12 d-flex">
                        <div className="card card-service card-service-promo">

                            <div className="card-caption">
                                <div className="card-left">

                                    <div className="card-icon">
                                        <i className="fas fa-ad"/>
                                    </div>

                                </div>
                                <div className="card-right">

                                    <div className="card-header">
                                        Special Offer: Get 20% off an iPad Pro!
                                    </div>

                                    <div className="card-description">
                                        Lorem ipsum dolor ist amet
                                    </div>

                                </div>
                            </div>

                            <div className="card-body">

                                <button className="btn btn-primary">View Details</button>

                            </div>

                        </div>
                    </div>
                )}
                */}

                {/* FOR DEMO PURPOSES - SHOWN ONLY AFTER FOURTH CARD
                {myServiceInfoItemIndex === 3 && (
                    <div className="cp-col col-12 col-lg-6 d-flex">
                        <div className="card card-service card-service-promo card-service-promo-small">

                            <div className="card-caption">

                                <div className="card-right">

                                    <div className="card-header">
                                        Special Offer: Get 20% off an iPad Pro!
                                    </div>

                                    <div className="card-description">
                                        Lorem ipsum dolor ist amet
                                    </div>

                                </div>

                            </div>

                            <div className="card-body">

                                <button className="btn btn-primary">Shop Now</button>

                            </div>

                        </div>
                    </div>
                )}
                */}
            </Fragment>
        );
    }
}

export default ServiceCard;


import React, {Component, Fragment} from 'react';
import ReactHtmlParser from 'react-html-parser';
import {trans} from "../../../../utils/helpers";
import Loader from "../../../../components/common/Spinners/Loader";
import moment from "moment";
import Button from "../../../../components/common/Buttons/Button";
import {Link} from "react-router-dom";

class NotAvailableStep extends Component {

    state={
        title:'',
        description:''
    }

    componentDidMount(){
        (async() => {
			const title= await trans('en-us', 'confirmOrderScreen.notAvailableService.title');
			const description= await trans('en-us', 'confirmOrderScreen.notAvailableService.description');
				
			this.setState({title,description});
		})()
        
    }

    render() {

        const {
            availablePlans,
            values,
        } = this.props;

        return (
            <>

                <div className="form-section">

                    <h4>{this.state.title}</h4>

                    <p className="form-divider">
                        <i className="fas fa-map-marker-alt" /> {values.formattedAddress}
                    </p>

                    <div className="form-group form-group-margin">
                        {this.state.description}
                    </div>

                </div>

                <div className="form-group form-group-button">
                    <Link
                        to={{
                            pathname: '/guest-order',
                            state: {
                                street:
                                    availablePlans.validatedAddress.contactAddress.address1 +
                                    ", " + availablePlans.validatedAddress.contactAddress.city +
                                    ", " + availablePlans.validatedAddress.contactAddress.systemState.abbreviation,
                                zipcode: availablePlans.validatedAddress.contactAddress.zipcode,
                            }
                        }}
                        className="btn btn-primary btn-block btn-a"
                    >
                        Check Availability
                    </Link>
                </div>

            </>
        );
    }
}

export default NotAvailableStep;

import React, { Component, Fragment } from 'react';
import MandatoryPackage from './Options/MandatoryPackage';

class MandatoryPackages extends Component {
	render() {
		const { planFeature, lineInfo, handleMandatoryPackages, accountInitialStatus } = this.props;
		return (
			<Fragment>
				{planFeature.map((featurePackage, index) => (
					featurePackage.featurePackageOptions ?
					<Fragment key={featurePackage.featurePackageId}>
						<hr/>
						<h5>{featurePackage.description}</h5>

						{featurePackage.featurePackageOptions.map((packageOption) => (
							<MandatoryPackage
								featurePackage={featurePackage}
								packageOption={packageOption}
								key={packageOption.featurePackageOptionId}
								lineInfo={lineInfo}
								handleMandatoryPackages={handleMandatoryPackages}
								accountInitialStatus={accountInitialStatus}
								{...this.props}
							/>
						))}
					</Fragment> : ''
				))}
			</Fragment>
		);
	}
}

export default MandatoryPackages;

import axiosInstance from '../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../utils/helpers';
import { getErrorMessage } from '../utils/helpers';

export const MY_SERVICE = createRequestTypes('MY_SERVICE');
export const MY_ORDERS = createRequestTypes('MY_ORDERS');
export const MY_INVOICES = createRequestTypes('MY_INVOICES');
export const MY_PAYMENTS = createRequestTypes('MY_PAYMENTS');
export const MY_PAYMENT_METHODS = createRequestTypes('MY_PAYMENT_METHODS');
export const MY_AMOUNTS = createRequestTypes('MY_AMOUNTS');
export const ORDER_DETAILS = createRequestTypes('ORDER_DETAILS');
export const SET_UPDATE_REQUEST_PLAN_DETAILS = createRequestTypes('SET_UPDATE_REQUEST_PLAN_DETAILS');
export const SET_UPDATE_REQUEST_FEATURES = createRequestTypes('SET_UPDATE_REQUEST_FEATURES');
export const SET_UPDATE_CHANGE_SERVICE = createRequestTypes('SET_UPDATE_CHANGE_SERVICE');
export const AUTOPAY = createRequestTypes('AUTOPAY');
export const AUTOPAY_AVAILABLE_CC = createRequestTypes('AUTOPAY_AVAILABLE_CC');
export const GET_ACCOUNT_TYPE_DISTRIBUTION_METHODS = createRequestTypes('GET_ACCOUNT_TYPE_DISTRIBUTION_METHODS');
export const GET_ACCOUNT_DISTRIBUTION_METHOD = createRequestTypes('GET_ACCOUNT_DISTRIBUTION_METHOD');
export const UPDATE_ACCOUNT_DISTRIBUTION_METHOD = createRequestTypes('UPDATE_ACCOUNT_DISTRIBUTION_METHOD');

export const getMyServiceInfo = (id) => async (dispatch, getState) => {
    dispatch(actionCreator.request(MY_SERVICE));

    try {
        const response = await axiosInstance.get(`/account/${id}/servicelines/`)
        dispatch(actionCreator.success(MY_SERVICE, response.data.services))
    } catch (err) {
        let language = getState().language.language;
        let mockError = getErrorMessage(err.response, language);
        dispatch(actionCreator.failure(MY_SERVICE, mockError));
    }
}

export const getMyOrders = (id) => async (dispatch, getState) => {
    dispatch(actionCreator.request(MY_ORDERS));

    try {
        const response = await axiosInstance.get(`/account/${id}/orders/`)
        dispatch(actionCreator.success(MY_ORDERS, response.data.orders))
    } catch (err) {
        let language = getState().language.language;
        let mockError = getErrorMessage(err.response, language);
        dispatch(actionCreator.failure(MY_ORDERS, mockError));
    }
}

export const getMyInvoices = (id) => async (dispatch, getState) => {
    dispatch(actionCreator.request(MY_INVOICES));

    try {
        const response = await axiosInstance.get(`/account/${id}/invoices/`)
        dispatch(actionCreator.success(MY_INVOICES, response.data.invoices))
    } catch (err) {
        let language = getState().language.language;
        let mockError = getErrorMessage(err.response, language);
        dispatch(actionCreator.failure(MY_INVOICES, mockError));
    }
}

export const getMyPayments = (id) => async (dispatch, getState) => {
    dispatch(actionCreator.request(MY_PAYMENTS));

    try {
        const response = await axiosInstance.get(`/account/${id}/payments/`)
        dispatch(actionCreator.success(MY_PAYMENTS, response.data.payments))
    } catch (err) {
        let language = getState().language.language;
        let mockError = getErrorMessage(err.response, language);
        dispatch(actionCreator.failure(MY_PAYMENTS, mockError));
    }
}

export const getMyPaymentMethods = (id) => async (dispatch, getState) => {
    dispatch(actionCreator.request(MY_PAYMENT_METHODS));

    try {
        const response = await axiosInstance.get(`/account/${id}/paymentMethods/`)
        dispatch(actionCreator.success(MY_PAYMENT_METHODS, response.data.paymentMethods))
    } catch (err) {
        let language = getState().language.language;
        let mockError = getErrorMessage(err.response, language);
        dispatch(actionCreator.failure(MY_PAYMENT_METHODS, mockError));
    }
}


export const getMyAmounts = (id) => async (dispatch, getState) => {
    dispatch(actionCreator.request(MY_AMOUNTS));

    try {
        const response = await axiosInstance.get(`/account/${id}/amounts/`)
        dispatch(actionCreator.success(MY_AMOUNTS, response.data.amounts))
    } catch (err) {
        let language = getState().language.language;
        let mockError = getErrorMessage(err.response, language);
        dispatch(actionCreator.failure(MY_AMOUNTS, mockError));
    }
}

export const getOrderDetails = (id) => async (dispatch, getState) => {
    dispatch(actionCreator.request(ORDER_DETAILS));

    try {
        const response = await axiosInstance.get(`/order/${id}/`)
        dispatch(actionCreator.success(ORDER_DETAILS, response.data.order));
        return response.data.order;
    } catch (err) {
        let language = getState().language.language;
        let mockError = getErrorMessage(err.response, language);
        dispatch(actionCreator.failure(ORDER_DETAILS, mockError));
    }

    return null;
}

export const getPlanDetails = (accountId, locationId, planId) => async (dispatch) => {
    let fromCustomerPortal = true;

    let data = {
        accountId,
        locationId,
        planId,
        fromCustomerPortal
    };

    dispatch(actionCreator.request(SET_UPDATE_REQUEST_PLAN_DETAILS));
    try {
        const response = await axiosInstance.get(`/domain/plan`, { params: data });

        dispatch(actionCreator.success(SET_UPDATE_REQUEST_PLAN_DETAILS, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Not found'
        };
        dispatch(actionCreator.failure(SET_UPDATE_REQUEST_PLAN_DETAILS, mockError));
    }
};

export const emptyPlanDetails = () => {
    return (dispatch) => {
        dispatch(actionCreator.empty(SET_UPDATE_REQUEST_PLAN_DETAILS));
    };
};

export const getFeatures = (accountId, servicelineId) => async (dispatch) => {


    dispatch(actionCreator.request(SET_UPDATE_REQUEST_FEATURES));
    try {
        const response = await axiosInstance.get(`/account/${accountId}/serviceline/${servicelineId}/features/active`);

        dispatch(actionCreator.success(SET_UPDATE_REQUEST_FEATURES, response.data.features));
        return response.data.features;
    } catch (err) {
        let mockError = {
            message: 'Not found'
        };
        dispatch(actionCreator.failure(SET_UPDATE_REQUEST_FEATURES, mockError));
    }
}

export const updateChangeService = (data) => async (dispatch) => {

    dispatch(actionCreator.request(SET_UPDATE_CHANGE_SERVICE));
    try {
        const response = await axiosInstance.post(`/order/changeservice`, data);

        dispatch(actionCreator.success(SET_UPDATE_CHANGE_SERVICE, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Not found'
        };
        dispatch(actionCreator.failure(SET_UPDATE_CHANGE_SERVICE, mockError));
    }
};

export const getMyAutoPayInfo = (accountId) => async (dispatch) => {
    try{
        const response = await axiosInstance.get(`/autopay/${accountId}`);
        if ((response.data.apiAccountAutoPayACH != null) || (response.data.apiAccountAutoPayCC != null) || (response.data.apiAccountAutoPayToken != null)) {
            dispatch(actionCreator.success(AUTOPAY, response.data));
        } else {
            dispatch(actionCreator.failure(AUTOPAY, response.data));
        }
        return response.data;
    } catch (err) { 
        let mockError = {
            message: 'Not found'
        };
        dispatch(actionCreator.failure(AUTOPAY, mockError));
    }
};

export const enableAutoPayACH = (info) => async (dispatch, getState) => {
    try{
        const response = await axiosInstance.post(`/autopay/ach`, info);
        
        dispatch(actionCreator.empty(AUTOPAY)); 

        return response.data;
    } catch (err) {
        let language = getState().language.language;
        let mockError = getErrorMessage(err.response, language);

        dispatch(actionCreator.failure(AUTOPAY, mockError));
    }
};

export const enableAutoPayTokenPaymentMethod = (info) => async (dispatch, getState) => {
    dispatch(actionCreator.request(AUTOPAY));
    try {
        const response = await axiosInstance.post(`/autopay/token-payment-method`, info);
        dispatch(actionCreator.empty(AUTOPAY));
        return response.data;
    } catch (err) {
        let mockError = {message: err.response.data.error.message};
        dispatch(actionCreator.failure(AUTOPAY, mockError));
        return err.response.data;
    }
};

export const enableAutoPayCC = (info) => async (dispatch, getState) => {
    try{
        const response = await axiosInstance.post(`/autopay/cc`, info);

        dispatch(actionCreator.success(AUTOPAY), response.data);
                
        return response.data;
    } catch (err) {
        let language = getState().language.language;
        let mockError = getErrorMessage(err.response, language);

        dispatch(actionCreator.failure(AUTOPAY, mockError));
    }
};

export const disableAutoPay = (info) => async (dispatch, getState) => {
    try{
        const response = await axiosInstance.post(`/autopay/disable`, info);

        dispatch(actionCreator.empty(AUTOPAY), response);
                
        return response.data;
    } catch (err) {
        let language = getState().language.language;
        let mockError = getErrorMessage(err.response, language);

        dispatch(actionCreator.failure(AUTOPAY, mockError));
    }
};

export const getAutoPayAvailableCC = (accountId) => async (dispatch, getState) => {
    try{
        const response = await axiosInstance.get(`/autopay/${accountId}/payment-methods`);
        
        dispatch(actionCreator.success(AUTOPAY_AVAILABLE_CC, response.data.availableCards));

        return response.data.availableCards;
    } catch (err) { 
        let language = getState().language.language;
        let mockError = getErrorMessage(err.response, language);

        dispatch(actionCreator.failure(AUTOPAY_AVAILABLE_CC, mockError));
    }
};

export const getAccountDistributionMethod = (accountId) => async (dispatch) => {
    dispatch(actionCreator.request(GET_ACCOUNT_DISTRIBUTION_METHOD));
    try {
        const response = await axiosInstance.get(`/admin/account/${accountId}/distribution-methods`);
        dispatch(actionCreator.success(GET_ACCOUNT_DISTRIBUTION_METHOD, response.data.distributionMethods));
        return response;
    } catch (err) {
        dispatch(actionCreator.failure(GET_ACCOUNT_DISTRIBUTION_METHOD));
        return err.response;
    }
}

export const updateAccountDistributionMethod = (accountId, distributionDetails) => async (dispatch) => {
    dispatch(actionCreator.request(UPDATE_ACCOUNT_DISTRIBUTION_METHOD));
    try {
        const response = await axiosInstance.patch(`/admin/account/${accountId}/distribution-methods`, distributionDetails);
        dispatch(actionCreator.success(UPDATE_ACCOUNT_DISTRIBUTION_METHOD, response.data.distributionMethods));
        return response;
    } catch (err) {
        dispatch(actionCreator.failure(UPDATE_ACCOUNT_DISTRIBUTION_METHOD));
        return err.response;
    }
}

export const getAccountTypeDistributionMethods = (accountType) => async (dispatch) => {
    dispatch(actionCreator.request(GET_ACCOUNT_TYPE_DISTRIBUTION_METHODS));
    try {
        const response = await axiosInstance.get(`/admin/account-type/distribution-method?accountType=${accountType}`);
        dispatch(actionCreator.success(GET_ACCOUNT_TYPE_DISTRIBUTION_METHODS, response.data.accountTypeDistributionMethods));
        return response;
    } catch (err) {
        dispatch(actionCreator.failure(GET_ACCOUNT_TYPE_DISTRIBUTION_METHODS));
        return err.response;
    }
}



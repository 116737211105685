import axiosInstance from '../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../utils/helpers';
import { getErrorMessage } from '../utils/helpers';

export const GET_INTEGRATION_SEARCH_ADDRESS = createRequestTypes('GET_INTEGRATION_SEARCH_ADDRESS');
export const MAP_LINK_LOCATION = createRequestTypes('MAP_LINK_LOCATION');
export const EMPTY_GUEST_ORDER = createRequestTypes('EMPTY_GUEST_ORDER');
export const CHECK_AVAILABLE_PLANS = createRequestTypes('CHECK_AVAILABLE_PLANS');
export const CREATE_NO_SERVICE_ACCOUNT = createRequestTypes('CREATE_NO_SERVICE_ACCOUNT');
export const CREATE_ACCOUNT = createRequestTypes('CREATE_ACCOUNT');
export const PLAN_DETAILS = createRequestTypes('PLAN_DETAILS');
export const CREATE_ORDER = createRequestTypes('CREATE_ORDER');
export const CHECK_EMAIL_VERIFIED = createRequestTypes('CHECK_EMAIL_VERIFIED');
export const UPDATE_ACCOUNT_EMAIL = createRequestTypes('UPDATE_ACCOUNT_EMAIL');
export const BILLING_ADDRESS_VALIDATION = createRequestTypes('BILLING_ADDRESS_VALIDATION');
export const GET_INTEGRATION_SEARCH_ADDRESS_MIN_CHARS = createRequestTypes('GET_INTEGRATION_SEARCH_ADDRESS_MIN_CHARS');
export const EMPTY_ERROR_REDUCER = 'EMPTY_ERROR_REDUCER';
export const GET_PLAN_CATEGORIES = createRequestTypes('GET_PLAN_CATEGORIES');

export const searchAddress = (q) => async (dispatch) => {
	dispatch(actionCreator.request(GET_INTEGRATION_SEARCH_ADDRESS));
	try {
		const params = {q};
		const response = await axiosInstance.get(`/public/api-integration/address/search`, {params});
		dispatch(actionCreator.success(GET_INTEGRATION_SEARCH_ADDRESS, response.data));
		return response.data;
	} catch (err) {
		let mockError = {message: 'Something goes wrong'}
		dispatch(actionCreator.failure(GET_INTEGRATION_SEARCH_ADDRESS, mockError));
		return err;
	}
};

export const getMinNumAddrSearchChars = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_INTEGRATION_SEARCH_ADDRESS_MIN_CHARS));
	try {
		const response = await axiosInstance.get(`/public/api-integration/address/search/min-chars`);
		dispatch(actionCreator.success(GET_INTEGRATION_SEARCH_ADDRESS_MIN_CHARS, response.data));
		return response.data;
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(GET_INTEGRATION_SEARCH_ADDRESS_MIN_CHARS, mockError));
		return err;
	}
};
export const billingAddressValidation = (address) => async (dispatch) => {
	dispatch(actionCreator.request(BILLING_ADDRESS_VALIDATION))
	try {
		const response = await axiosInstance.post(`public/account/addressvalidation`, address);
		dispatch(actionCreator.success(BILLING_ADDRESS_VALIDATION));
		return response
	} catch (err) {
		dispatch(actionCreator.failure(BILLING_ADDRESS_VALIDATION))
	}
}

export const getPublicMapLinkLocation = (locationId, address) => async (dispatch) => {
	dispatch(actionCreator.request(MAP_LINK_LOCATION));
	try {
		const response = await axiosInstance.post(`/public/order/map/embed/${locationId}`, address);
		dispatch(actionCreator.success(MAP_LINK_LOCATION, response.data));
		return response.data;
	} catch (err) {
		let mockError = {
			error: err.response.data.error,
		};
		dispatch(actionCreator.failure(MAP_LINK_LOCATION, mockError));
	}
};

export const getMapLinkLocation = (locationId, address) => async (dispatch) => {
	dispatch(actionCreator.request(MAP_LINK_LOCATION));
	try {
		const response = await axiosInstance.post(`/order/map/embed/${locationId}`, address);
		dispatch(actionCreator.success(MAP_LINK_LOCATION, response.data));
		return response.data;
	} catch (err) {
		let mockError = {
			error: err.response.data.error,
		};
		dispatch(actionCreator.failure(MAP_LINK_LOCATION, mockError));
	}
};

export const emptyMapLinkLocation = () => {
	return (dispatch) => {
		dispatch(actionCreator.empty(MAP_LINK_LOCATION));
	};
};

export const getCheckAvailability = (address, locationId, planCategoryId) => async (dispatch, getState) => {
	dispatch(actionCreator.request(CHECK_AVAILABLE_PLANS));
	try {
		const response = await axiosInstance.post(`/public/order/plans/${locationId}/${planCategoryId}`, address);
		dispatch(actionCreator.success(CHECK_AVAILABLE_PLANS, response.data));
		return response.data;
	} catch (err) {
		let language = getState().language.language;
		let mockError = getErrorMessage(err.response, language);
		dispatch(actionCreator.failure(CHECK_AVAILABLE_PLANS, mockError));
	}
};

export const emptyCheckAvailability = () => (dispatch) => {
	dispatch(actionCreator.empty(CHECK_AVAILABLE_PLANS));
};

export const createNoServiceAccount = (account) => async (dispatch, getState) => {
	dispatch(actionCreator.request(CREATE_NO_SERVICE_ACCOUNT));
	try {
		const response = await axiosInstance.post(`/public/account`, account);
		dispatch(actionCreator.success(CREATE_NO_SERVICE_ACCOUNT, response.data));
		return response.data;
	} catch (err) {
		let language = getState().language.language;
		let mockError = getErrorMessage(err.response, language);
		dispatch(actionCreator.failure(CREATE_NO_SERVICE_ACCOUNT, mockError));
	}
};

export const createAccount = (account) => async (dispatch, getState) => {
	dispatch(actionCreator.request(CREATE_ACCOUNT));
	try {
		const response = await axiosInstance.post(`/public/account`, account);
		dispatch(actionCreator.success(CREATE_ACCOUNT, response.data));
		return response.data;
	} catch (err) {
		let language = getState().language.language;
		let mockError = getErrorMessage(err.response, language);
		dispatch(actionCreator.failure(CREATE_ACCOUNT, mockError));
	}
};

export const getPlanDetails = (locationId, planId) => async (dispatch, getState) => {
	let params = {
		locationId,
		planId
	};

	dispatch(actionCreator.request(PLAN_DETAILS));
	try {
		const response = await axiosInstance.get(`/public/order/plan/`, { params: params });

		// This is hack - the response error format need to be changed
		if (response.success !== false) {
			dispatch(actionCreator.success(PLAN_DETAILS, response.data));
		}
		return response.data;
	} catch (err) {
		let language = getState().language.language;
		let mockError = getErrorMessage(err.response, language);
		dispatch(actionCreator.failure(PLAN_DETAILS, mockError));
	}
};

export const emptyPlanDetails = () => (dispatch) => {
	dispatch(actionCreator.empty(PLAN_DETAILS));
};

export const createNewOrder = (orderData, orderType) => async (dispatch, getState) => {
	dispatch(actionCreator.request(CREATE_ORDER));
	try {
		let response;
		if (orderType === 'SIGNUP') {
			let signUp = await axiosInstance.post(`public/order/newsignup`, orderData);
			response = { ...signUp.data, orderType: 'SIGNUP' };
		} else if (orderType === 'SURVEY') {
			let survey = await axiosInstance.post(`public/order/newsurvey`, orderData);
			response = { ...survey.data, orderType: 'SURVEY' };
		} else if (orderType === 'ACTIVE') {
			let survey = await axiosInstance.post(`public/order/newservice`, orderData);
			response = { ...survey.data, orderType: 'ACTIVE' };
		} else {
			let survey = await axiosInstance.post(`public/order/newservice`, orderData);
			response = { ...survey.data, orderType: 'ACTIVE' };
		}
		dispatch(actionCreator.success(CREATE_ORDER, response));
		return response;
	} catch (err) {
		let language = getState().language.language;
		let mockError = getErrorMessage(err.response, language);
		dispatch(actionCreator.failure(CREATE_ORDER, mockError));
	}
};

export const checkEmailVerified = (accountId) => async (dispatch, getState) => {
	dispatch(actionCreator.request(CHECK_EMAIL_VERIFIED));
	try {
		let response = await axiosInstance.get(`public/account/${accountId}/email/verified`);
		dispatch(actionCreator.success(CHECK_EMAIL_VERIFIED, response.data));
		return response;
	} catch (err) {
		let language = getState().language.language;
		let mockError = getErrorMessage(err.response, language);
		dispatch(actionCreator.failure(CHECK_EMAIL_VERIFIED, mockError));
	}
};

export const updateAccountEmail = (accountId, accountNumber, email) => async (dispatch, getState) => {
	dispatch(actionCreator.request(UPDATE_ACCOUNT_EMAIL));
	try {
		let params = {
			email
		};

		let response = await axiosInstance.get(`public/account/${accountId}/${accountNumber}/email/update`, { params: params });
		dispatch(actionCreator.success(UPDATE_ACCOUNT_EMAIL, response.data));
		return response;
	} catch (err) {
		let language = getState().language.language;
		let mockError = getErrorMessage(err.response, language);
		dispatch(actionCreator.failure(UPDATE_ACCOUNT_EMAIL, mockError));
	}
};

export const getPlanCategories = (locationId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_PLAN_CATEGORIES));
	try {
		let params = {locationId}
		const response = await axiosInstance.get(`public/domain/planCategories`, {params});

		//following code is to make sure response categories are always of array type
		let responseArray = response.data.categories
		if (!Array.isArray(response.data.categories)) {
			responseArray = []
			responseArray.push(response.data.categories)
		}
		dispatch(actionCreator.success(GET_PLAN_CATEGORIES, responseArray));
		return responseArray;
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(GET_PLAN_CATEGORIES, mockError));
		return err;
	}
}

export const emptyCreateNewOrder = () => (dispatch) => {
	dispatch(actionCreator.empty(CREATE_ORDER));
};

export const emptyGuestOrder = () => (dispatch) => {
	dispatch(actionCreator.success(EMPTY_GUEST_ORDER));
	// dispatch(actionCreator.clear(CREATE_NO_SERVICE_ACCOUNT));
	dispatch({ type: EMPTY_ERROR_REDUCER });
};

export const clearNoServiceAccountError = () => (dispatch) => {
	dispatch(actionCreator.clear(CREATE_NO_SERVICE_ACCOUNT));
};

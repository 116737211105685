import React, { Component, Fragment } from 'react';
import { generateId } from '../../utils/helpers';

import {
	CheckAvailabilityStep,
	NotAvailableServiceStep,
	SelectServiceStep,
	Confirmation,
	CustomerInfoStep,
	NotAvailableConfirmation,
	ServiceDetailsStep,
} from './Steps';
import Bundle from './Steps/SelectServiceStep/Bundle';

class StepContent extends Component {

	componentDidUpdate(prevProps) {
		if (prevProps.location !== this.props.location) {

			//Reset initial form values, and wizard step
			const { resetForm, setTouched } = this.props.formProps;
			this.props.handleResetWizard(resetForm, setTouched);
		}
	}

	setSelectedItem = async (item, type) => {
		let allPlans = [];
		if (type === 'bundle') {
			item.plans.forEach((plan) => {
				let currentPlan = {
					planBundleId: item.id,
					planBundleDescription: item.description,
					planBundleName: item.name,
					planDescription: plan.description,
					planId: plan.id,
					planName: plan.name,
					servicelines: []
				};

				allPlans.push(currentPlan);
			});
		} else if (type === 'plan') {
			let currentPlan = {
				planDescription: item.description,
				planId: item.id,
				planName: item.name,
				servicelines: []
			};

			allPlans.push(currentPlan);
		}
		await this.props.formProps.setFieldValue('order.plans', allPlans);
		this.props.populateServiceAndLines(this.props.formProps.values, this.props.formProps.setFieldValue);
	};

	renderStepContent = () => {

		const {
			activeStep,
			availablePlansData,
			availablePlansLoader,
			availablePlansError,
			noServiceAccountLoader,
			noServiceAccountError,
			newOrderInfoData,
			plansDetailsData,
			createAccountAndOrderLoader,
			createAccountAndOrderError,
			formProps,
			handleResetWizard,
			handleChangeStep,
			populateServiceAndLines,
			handleBackStep,
			clearNoServiceAccountError,
			planDetailsError,
			newAccountInfoData,
			onAddressSelect,
			validatedAddress,
			errorMessage
		} = this.props;
		let stepContent;
		switch (activeStep) {
			case 'check-availability':
				stepContent = (
					<CheckAvailabilityStep
						availablePlansLoader={availablePlansLoader}
						availablePlansError={availablePlansError}
						handleResetWizard={handleResetWizard}
						onAddressSelect={onAddressSelect}
						validatedAddress={validatedAddress}
						errorMessage={errorMessage}
						{...formProps}
						{...this.props}
					/>
				);
				break;
			case 'not-available-service':
				stepContent = (
					<NotAvailableServiceStep
						availablePlansData={availablePlansData}
						handleChangeStep={handleChangeStep}
						noServiceAccountLoader={noServiceAccountLoader}
						noServiceAccountError={noServiceAccountError}
						handleResetWizard={handleResetWizard}
						clearNoServiceAccountError={clearNoServiceAccountError}
						{...formProps}
					/>
				);
				break;
			case 'no-service-confirmation':
				stepContent = (
					<NotAvailableConfirmation
						handleResetWizard={handleResetWizard}
						{...formProps}
					/>
				);
				break;
			case 'new-signup':
				stepContent = (
					<SelectServiceStep
						availablePlansData={availablePlansData}
						handleChangeStep={handleChangeStep}
						populateServiceAndLines={populateServiceAndLines}
						handleResetWizard={handleResetWizard}
						{...formProps}
					/>
				);
				break;
			case 'packages-and-products':
				stepContent = (
					<ServiceDetailsStep
						handleBackStep={handleBackStep}
						handleChangeStep={handleChangeStep}
						plansDetailsData={plansDetailsData}
						handleResetWizard={handleResetWizard}
						planDetailsError={planDetailsError}
						totalPlans={availablePlansData.plans.length}
						{...formProps}
					/>
				);
				break;
			case 'customer-info':
				stepContent = (
					<CustomerInfoStep
						createAccountAndOrderLoader={createAccountAndOrderLoader}
						createAccountAndOrderError={createAccountAndOrderError}
						handleChangeStep={handleChangeStep}
						handleResetWizard={handleResetWizard}
						newAccountInfoData={newAccountInfoData}
						fetchRequiredOrOptionalFields={this.props.fetchRequiredOrOptionalFields}
						fields={this.props.fields}
						formSubmitting={this.props.formSubmitting}
						{...formProps}
					/>
				);
				break;
			case 'confirmation':
				stepContent = (
					<Confirmation
						newOrderInfoData={newOrderInfoData}
						handleResetWizard={handleResetWizard}
						{...formProps}
					/>
				);
				break;

			default:
				stepContent = (
					<CheckAvailabilityStep
						availablePlansLoader={availablePlansLoader}
						availablePlansError={availablePlansError}
						handleResetWizard={handleResetWizard}
						{...formProps}
					/>
				);
				break;
		}

		return stepContent;
	};

	render() {
		return <Fragment>{this.renderStepContent()}</Fragment>;
	}
}

export default StepContent;

import React, { Component, Fragment } from 'react';
import MandatoryPackage from './Options/MandatoryPackage';
import isEmpty from "../../../utils/helpers";
import ReactHtmlParser from "react-html-parser";

class MandatoryPackages extends Component {

	state = {
		isDescriptionToggled: false
	}

	toggleDescription = (e) => {
		e.stopPropagation();
		this.setState((prevState) => ({
			isDescriptionToggled: !prevState.isDescriptionToggled
		}));
	};


	checkNote = (featurePackage) => {
		if(featurePackage && featurePackage.note) {
			let parsedNote = ReactHtmlParser(featurePackage.note.trim())

			if (!isEmpty(parsedNote)) {
				return (
					<button type="button" className="btn btn-toggle-package-desc"
							onClick={this.toggleDescription}>
						<i className="fas fa-info-circle"/>
					</button>
				)
			}
		}
	}


	render() {
		const { planFeature, lineInfo, handleMandatoryPackages, accountInitialStatus } = this.props;
		return (
			<Fragment>
				{planFeature.map((featurePackage, index) => (
					<Fragment key={featurePackage.featurePackageId}>
						<div style={{marginBottom:'1rem'}}>
							<div className="package-header custom-switch-with-button">
								<hr/>
								<h5>{featurePackage.description}</h5>
								{this.checkNote(featurePackage)}
							</div>
							{this.state.isDescriptionToggled &&
								<div className='.card-product.card-body.package-tooltip'>
									<div className="package-tooltip visible package-description text-sm">{ReactHtmlParser(featurePackage.note)}</div>
								</div>
							}
						</div>

						{featurePackage.featurePackageOptions.map((packageOption) => (
							<MandatoryPackage
								featurePackage={featurePackage}
								packageOption={packageOption}
								key={packageOption.featurePackageOptionId}
								lineInfo={lineInfo}
								handleMandatoryPackages={handleMandatoryPackages}
								accountInitialStatus={accountInitialStatus}
								{...this.props}
							/>
						))}
					</Fragment>
				))}
			</Fragment>
		);
	}
}

export default MandatoryPackages;

import React, { Component, Fragment } from 'react';

import {
	NotAvailableServiceStep,
	SelectServiceStep,
	Confirmation,
	CustomerInfoStep,
	NotAvailableConfirmation,
	ServiceDetailsStep,
	CheckAvailabilityStep
} from './Steps';

class StepContent extends Component {
	componentDidUpdate(prevProps) {
		if (prevProps.location !== this.props.location) {
			//Reset initial form values, and wizard step
			const { resetForm, setTouched } = this.props.formProps;
			this.props.handleResetWizard(resetForm, setTouched);
		}
	}

	renderStepContent = () => {
		const {
			activeStep,
			isFormReseting,
			availablePlansData,
			availablePlansLoader,
			availablePlansError,
			noServiceAccountLoader,
			noServiceAccountError,
			newOrderInfoData,
			plansDetailsData,
			createAccountAndOrderLoader,
			createAccountAndOrderError,
			formProps,
			handleResetWizard,
			handleChangeStep,
			populateServiceAndLines,
			handleBackStep,
			clearNoServiceAccountError,
			planDetailsError,
			invalidTokenMessage,
			invalidToken,
			handleCheckAvailabilityStep
		} = this.props;

		if (invalidToken) {
			return (
				<div className="form-section fx-fadein" id="fs2">
					<h3 className="text-center">{invalidTokenMessage}</h3>
				</div>
			);
		}

		let stepContent;
		switch (activeStep) {
			case 'check-availability':
				stepContent = (
					<CheckAvailabilityStep
						availablePlansLoader={availablePlansLoader}
						availablePlansError={availablePlansError}
						isFormReseting={isFormReseting}
						handleResetWizard={handleResetWizard}
						handleCheckAvailabilityStep={handleCheckAvailabilityStep}
						{...formProps}
						{...this.props}
					/>
				);
				break;
			case 'not-available-service':
				stepContent = (
					<NotAvailableServiceStep
						availablePlansData={availablePlansData}
						handleChangeStep={handleChangeStep}
						noServiceAccountLoader={noServiceAccountLoader}
						noServiceAccountError={noServiceAccountError}
						isFormReseting={isFormReseting}
						handleResetWizard={handleResetWizard}
						clearNoServiceAccountError={clearNoServiceAccountError}
						{...formProps}
					/>
				);
				break;
			case 'no-service-confirmation':
				stepContent = (
					<NotAvailableConfirmation
						handleResetWizard={handleResetWizard}
						isFormReseting={isFormReseting}
						{...formProps}
					/>
				);
				break;
			case 'new-signup':
				stepContent = (
					<SelectServiceStep
						availablePlansData={availablePlansData}
						handleChangeStep={handleChangeStep}
						populateServiceAndLines={populateServiceAndLines}
						isFormReseting={isFormReseting}
						handleResetWizard={handleResetWizard}
						{...formProps}
					/>
				);
				break;
			case 'packages-and-products':
				stepContent = (
					<ServiceDetailsStep
						handleBackStep={handleBackStep}
						handleChangeStep={handleChangeStep}
						plansDetailsData={plansDetailsData}
						isFormReseting={isFormReseting}
						handleResetWizard={handleResetWizard}
						planDetailsError={planDetailsError}
						{...formProps}
					/>
				);
				break;
			case 'confirmation':
				stepContent = (
					<Confirmation
						newOrderInfoData={newOrderInfoData}
						handleResetWizard={handleResetWizard}
						isFormReseting={isFormReseting}
						{...formProps}
					/>
				);
				break;

			default:
				stepContent = (
					<CheckAvailabilityStep
						availablePlansLoader={availablePlansLoader}
						availablePlansError={availablePlansError}
						isFormReseting={isFormReseting}
						handleResetWizard={handleResetWizard}
						{...formProps}
					/>
				);
				break;
		}

		return stepContent;
	};
	render() {
		return <Fragment>{this.renderStepContent()}</Fragment>;
	}
}

export default StepContent;

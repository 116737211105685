import {SET_USER_CONFIGURATION} from '../actions/userActions';

let initialState = {
	configuration: []
};

export const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_USER_CONFIGURATION.SUCCESS:
			return{
				...state,
				configuration: action.payload
			}
		default:
			return state;
	}
};

import axios from 'axios';
import { getToken, getRefreshToken, generateLocalStorageItem } from './helpers';
import { API_ROOT } from './api-config';
import store from '../store';
import { logout } from '../actions/authActions';

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

const axiosInstance = axios.create({
	paramsSerializer(params) {
		const searchParams = new URLSearchParams();
		for (const key of Object.keys(params)) {
			const param = params[key];
			if (Array.isArray(param)) {
				for (const p of param) {
					searchParams.append(key, p);
				}
			} else {
				searchParams.append(key, param);
			}
		}
		return searchParams.toString();
	},
	baseURL: `${API_ROOT}`,
	headers: {
		'Content-Type': 'application/json'
	}
});

axiosInstance.interceptors.request.use((config) => {
	config.headers['X-API-Token'] = getToken();
	config.headers['X-API-Refresh-Token'] = getRefreshToken();
	return config;
});

function onAccessTokenFetched(token) {
	subscribers = subscribers.filter((callback) => callback(token));
}

function addSubscriber(callback) {
	subscribers.push(callback);
}

axiosInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		const originalRequest = error.config;

		if (error.response.status === 401) {
			if(!getToken()){
				store.dispatch(logout());
			}else {
				if (!isAlreadyFetchingAccessToken) {
					isAlreadyFetchingAccessToken = true;
					axiosInstance
						.get('/auth/refreshtoken')
						.then((response) => {
							isAlreadyFetchingAccessToken = false;
							generateLocalStorageItem(response.data);
							store.dispatch({
								type: 'AUTH_SUCCESS',
								payload: response.data
							});
							onAccessTokenFetched(response.data.token);
						})
						.catch((err) => {
							isAlreadyFetchingAccessToken = false;
							store.dispatch(logout());
						});
				}
	
				const retryOriginalRequest = new Promise((resolve) => {
					addSubscriber((token) => {
						originalRequest.headers['X-API-Token'] = token;
						resolve(axios(originalRequest));
					});
				});
				return retryOriginalRequest;
			}
			
		
		} else if (error.response.status === 500) {
			if (error.response.data.error) {
				store.dispatch({
					type: 'ERROR_500',
					payload: {
						errorType: 'ERROR_500',
						errorMessage: error.response.data.error.message || '',
						errorCode: error.response.data.error.code || ''
					}
				});
			} else {
				store.dispatch({
					type: 'ERROR_500',
					payload: {
						errorType: 'ERROR_500',
						errorMessage: 'ERROR_500',
						errorCode: '500'
					}
				});
			}
		}

		return Promise.reject(error);
	}
);

export default axiosInstance;

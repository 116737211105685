import React from 'react';
import PropTypes from 'prop-types';

const FlyInRightPanel = ({ onClose, children, title, isVissible, preventPanelClosing, price }) => {

    // SCROLL BUBBLING PREVENTION - START
    let panelOverlay, panelBody, initialTouch, currentTouch, scrollTop, panelHeight, currentScroll;

    function handleOverlayTouchstart(e) {
        e.preventDefault();
    }

    function handleOverlayTouchmove(e) {
        e.preventDefault();
    }

    function handleBodyTouchstart(e) {
        initialTouch = e.touches[0].clientY;
    }

    function handleBodyTouchmove(e) {
        currentTouch = e.changedTouches[0].clientY;

        scrollTop = panelBody.scrollTop;
        panelHeight = panelBody.scrollHeight;
        currentScroll = scrollTop + panelBody.offsetHeight;

        // Prevent overscroll at top
        if (scrollTop === 0 && currentTouch > initialTouch) {
            e.preventDefault();
        }

        // Prevent overscroll at bottom
        else if (currentScroll === panelHeight && currentTouch < initialTouch) {
            e.preventDefault();
        }
    }

    if (isVissible) {

        setTimeout(() => {

            panelOverlay = document.querySelectorAll('.isVissible .overlay')[0];
            panelBody = document.querySelectorAll('.isVissible .panelBody')[0];

            if (panelOverlay) {

                panelOverlay.addEventListener('touchstart', handleOverlayTouchstart);
                panelOverlay.addEventListener('touchmove', handleOverlayTouchmove);
            }

            if (panelBody) {

                panelBody.addEventListener('touchstart', handleBodyTouchstart);
                panelBody.addEventListener('touchmove', handleBodyTouchmove);
            }

        }, 500)
    }
    // SCROLL BUBBLING PREVENTION - END

    return (
        <div className={'cp-fly-in-panel right ' + (isVissible ? 'isVissible' : 'hidden')}>

            <div className="fly-in-wrapper">

                <div className="fly-in-title">

                    <div className="left">

                        <button
                            type="button"
                            className="btn btn-quick"
                            disabled={preventPanelClosing}
                            onClick={() => {
                                if (panelOverlay) {
                                    panelOverlay.removeEventListener('touchstart', handleOverlayTouchstart);
                                    panelOverlay.removeEventListener('touchmove', handleOverlayTouchmove);
                                }
                                if (panelBody) {
                                    panelBody.removeEventListener('touchstart', handleBodyTouchstart);
                                    panelBody.removeEventListener('touchmove', handleBodyTouchmove);
                                }
                                onClose();
                            }}
                        >
                            <i className="fas fa-times" />
                        </button>

                        <h5>{title}</h5>

                    </div>

                    <div className="right">
                        {price}
                    </div>

                </div>

                <div className="fly-in-inner-wrapper panelBody" id="panelBody">
                    <div className="container">
                        {children}
                    </div>
                </div>

            </div>
            <div className="overlay" />
        </div>
    );
};

FlyInRightPanel.propTypes = {
    onClose: PropTypes.func,
    title: PropTypes.string,
    isVissible: PropTypes.bool,
    price: PropTypes.node
};

export default FlyInRightPanel;

import React, { Component } from 'react';
 import Recaptcha from 'react-recaptcha';
 
 class ConfirmNotABot extends Component {
 	render() {
 		const { errors, touched, setFieldValue } = this.props;
 
 		return (
 			<div class="form-section fx-from-right" id="fs2">
 				<h4 class="text-center">Confirm You're Not a Bobot</h4>
 				<p class="text-center">
 					After selecting the checkbox, a Captcha challenge may display. If you only use a keyboard or can’t
 					view images, please listen to the challenge.
 				</p>
 				<div class="d-flex ml-auto mr-auto w-75 mb-4">
 					<Recaptcha
 						sitekey="6Le2nREUAAAAALYuOv7X9Fe3ysDmOmghtj0dbCKW"
 						render="explicit"
 						theme="dark"
 						verifyCallback={(response) => {
 							setFieldValue('recaptcha', response);
 						}}
 						onloadCallback={() => {
 							console.log('done loading!');
 						}}
 					/>
 					{errors.recaptcha && touched.recaptcha && <p>{errors.recaptcha}</p>}
 				</div>
 				<div class="form-group form-group-footer">
 					<button type="button" id="" class="btn btn-outline-secondary btn-fs-action" data-target="fs1">
 						Go Back
 					</button>
 					<button type="submit" id="sendlogin" class="btn btn-primary btn-fs-action" data-target="fs3">
 						Continue
 					</button>
 				</div>
 			</div>
 		);
	 	}
 }
 
 export default ConfirmNotABot;
import React, { Component } from 'react';
import {connect} from "react-redux";
import isEmpty from '../../../../utils/helpers';
import {generateId} from '../../../../utils/helpers';
import {trans} from "../../../../utils/helpers";
import Bundle from './Bundle';
import Plan from './Plan';
import Loader from '../../../../components/common/Spinners/Loader';


export class SelectServiceStep extends Component {

	state = {
		slicedItems: 3,
		title:'',
		isLoading: true
	};

	componentDidMount(){
		(async() => {
			const title= await trans('en-us', 'confirmOrderScreen.selectService.title');
				
			this.setState({title});
		})()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		//IF only one plan we want to skip to serviceDetailsStep
		if(prevProps.formProps.values.order.plans !== this.props.formProps.values.order.plans && this.state.isLoading) {
			if(this.props.availablePlans.plans) {
				if (this.props.availablePlans.plans.length === 1) {
					this.setSelectedItem(this.props.availablePlans.plans[0], 'plan');
					this.setState({isLoading: false})
				}
			}
		}
	}


	addMoreItems = () => {
		this.setState((prevState) => ({
			slicedItems: prevState.slicedItems + 1
		}));
	};

	setSelectedItem = async (item, type) => {
		let allPlans = [];

		if (type === 'bundle') {

			item.plans.forEach((plan) => {
				let currentPlan = {
					planBundleId: item.id,
					planBundleDescription: item.description,
					planBundleName: item.name,
					planDescription: plan.description,
					planId: plan.id,
					planName: plan.name,
					servicelines: []
				};

				allPlans.push(currentPlan);
			});
		}
		else if (type === 'plan') {
			let currentPlan = {
				planDescription: item.description,
				planId: item.id,
				planName: item.name,
				servicelines: []
			};
			allPlans.push(currentPlan);
		}
		await this.props.setFieldValue('order.plans', allPlans);
		this.props.populateServiceAndLines(this.props.values, this.props.setFieldValue);
	};

	render() {

		const { slicedItems } = this.state;
		const { availablePlans, handleChangeStep, values, customerAddress} = this.props;

		let bundleAndPlans = [];

		if (isEmpty(availablePlans)) {
			return <Loader/>;
		}

		if (!isEmpty(availablePlans.planBundles)) {
			bundleAndPlans.push(...availablePlans.planBundles);
		}

		if (!isEmpty(availablePlans.plans)) {
			bundleAndPlans.push(...availablePlans.plans);
		}

		if (!isEmpty(bundleAndPlans)) {
			bundleAndPlans.sort((a, b) => b.fromPrice - a.fromPrice);
		}


		return (

			<div className="form-section">

				<h4>{this.state.title}</h4>
				<p className="form-divider"><i className="fas fa-map-marker-alt" /> {customerAddress}</p>

				<p className="text-center text-xs text-muted d-block d-sm-none">Scroll down to see map</p>

				<div className="card-product">

					<div className="card-body">
						{!isEmpty(bundleAndPlans) && bundleAndPlans.slice(0, slicedItems).map((item, index) => {
							return item.plans ? (
								<Bundle
									key={generateId()}
									bundle={item}
									handleChangeStep={handleChangeStep}
									setSelectedItem={this.setSelectedItem}
								/>
							) : (
								<Plan
									key={generateId()}
									plan={item}
									handleChangeStep={handleChangeStep}
									setSelectedItem={this.setSelectedItem}
								/>
							);
						})}
					</div>

					<div className="card-footer">
						{bundleAndPlans.length > slicedItems && (
							<button
								onClick={this.addMoreItems}
								type="button"
								className="btn btn-outline-secondary btn-product-more btn-block"
							>
								<i className="fas fa-plus" /> More
							</button>
						)}
					</div>

				</div>

			</div>
		);
	}
}

const mapStateToProps = (state) => {

	return {};
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectServiceStep);

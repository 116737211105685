import React, {Component, Fragment} from 'react';
import {
    IncludedFeatures,
    IncludedPackages,
    MandatoryFeatures,
    MandatoryPackages,
    OptionalFeatures,
    OptionalPackages
} from './ProductsItems';
import {validateRegisterEmail} from "../../../actions/signUpActions";

class ProductsServiceline extends Component {
    state = {
        isColapsed: true
    };

    showHideColaps = () => {
        this.setState((prevState) => ({
            isColapsed: !prevState.isColapsed
        }));
    };

    handleMandatoryPackages = (packageId, lineIndex, item) => {
        const {values, setFieldValue} = this.props;
        item['action'] = 'ENABLE';
        let updateFeatures = values.features.filter((feature) => feature.featurePackageId !== item.featurePackageId);

        updateFeatures = [...updateFeatures, item];
        setFieldValue(`features`, updateFeatures);
    };

    handleOptionalPackagesCheckbox = (packageId, lineIndex, item) => {
        const {values, setFieldValue} = this.props;
        let updateFeatures;
        if (values.features.findIndex((feature) => feature.featurePackageId === packageId) !== -1) {
            updateFeatures = values.features.filter((feature) => feature.featurePackageId !== item.featurePackageId);
        } else {
            updateFeatures = [...values.features];
            updateFeatures = [...updateFeatures, item];
        }

        setFieldValue(`features`, updateFeatures);
    };

    handleOptionalFeature = (featureId, lineIndex, item) => {
        const {values, setFieldValue} = this.props;
        item['action'] = 'ENABLE';
        let updateFeatures;
        if (values.features.findIndex((feature) => feature.id === featureId) !== -1) {
            updateFeatures = values.features.filter((feature) => feature.id !== item.id);
        } else {
            updateFeatures = [...values.features];
            updateFeatures = [...updateFeatures, item];
        }

        setFieldValue(`features`, updateFeatures);
    };

    handleOptionalPackages = (packageId, lineIndex, item) => {
        const {values, setFieldValue} = this.props;

        let updateFeatures = values.features.filter((feature) => feature.featurePackageId !== item.featurePackageId);

        const currentItemIndex = values.features.findIndex((feature) => (feature.featurePackageId === item.featurePackageId &&
            feature.featurePackageOptionId === item.featurePackageOptionId));

        if (currentItemIndex < 0) {
            updateFeatures = [...updateFeatures, item];
        }
        setFieldValue(`features`, updateFeatures);
    };

    linePlans = () => {
        const {planInfo, values} = this.props;
        let line = [];

        if (planInfo.planRelation === 'Main') {
            if (planInfo.mainIncludedFeatures) {
                line.push(<IncludedFeatures planFeature={planInfo.mainIncludedFeatures}/>);
            }
            if (planInfo.mainIncludedFeaturePackages) {
                line.push(<IncludedPackages planFeature={planInfo.mainIncludedFeaturePackages}/>);
            }
            if (planInfo.mainMandatoryFeatures) {
                line.push(<MandatoryFeatures planFeature={planInfo.mainMandatoryFeatures}/>);
            }
            if (planInfo.mainMandatoryFeaturePackages) {
                line.push(
                    <MandatoryPackages
                        features={values.features}
                        planFeature={planInfo.mainMandatoryFeaturePackages}
                        handleMandatoryPackages={this.handleMandatoryPackages}
                    />
                );
            }
            if (planInfo.mainOptionalFeatures) {
                line.push(
                    <OptionalFeatures
                        features={values.features}
                        planFeature={planInfo.mainOptionalFeatures}
                        handleOptionalFeature={this.handleOptionalFeature}
                    />
                );
            }
            if (planInfo.mainOptionalFeaturePackages) {
                line.push(
                    <OptionalPackages
                        features={values.features}
                        planFeature={planInfo.mainOptionalFeaturePackages}
                        handleOptionalPackages={this.handleOptionalPackages}
                        handleOptionalPackagesCheckbox={this.handleOptionalPackagesCheckbox}
                    />
                );
            }
        } else if (planInfo.planRelation === 'Required') {
            if (planInfo.requiredIncludedFeatures) {
                line.push(<IncludedFeatures planFeature={planInfo.requiredIncludedFeatures}/>);
            }
            if (planInfo.requiredIncludedFeaturePackages) {
                line.push(<IncludedPackages planFeature={planInfo.requiredIncludedFeaturePackages}/>);
            }
            if (planInfo.requiredMandatoryFeatures) {
                line.push(
                    <MandatoryFeatures features={values.features} planFeature={planInfo.requiredMandatoryFeatures}/>
                );
            }
            if (planInfo.requiredMandatoryFeaturePackages) {
                line.push(
                    <MandatoryPackages
                        features={values.features}
                        planFeature={planInfo.requiredMandatoryFeaturePackages}
                        handleMandatoryPackages={this.handleMandatoryPackages}
                    />
                );
            }

            if (planInfo.requiredOptionalFeatures) {
                line.push(
                    <OptionalFeatures
                        features={values.features}
                        planFeature={planInfo.requiredOptionalFeatures}
                        handleOptionalFeature={this.handleOptionalFeature}
                    />
                );
            }
            if (planInfo.requiredOptionalFeaturePackages) {
                line.push(
                    <OptionalPackages
                        features={values.features}
                        planFeature={planInfo.requiredOptionalFeaturePackages}
                        handleOptionalPackages={this.handleOptionalPackages}
                        handleOptionalPackagesCheckbox={this.handleOptionalPackagesCheckbox}
                    />
                );
            }
        } else if (planInfo.planRelation === 'Additional') {
            if (planInfo.additionalIncludedFeatures) {
                line.push(
                    <IncludedFeatures features={values.features} planFeature={planInfo.additionalIncludedFeatures}/>
                );
            }
            if (planInfo.additionalIncludedFeaturePackages) {
                line.push(
                    <IncludedPackages
                        features={values.features}
                        planFeature={planInfo.additionalIncludedFeaturePackages}
                    />
                );
            }
            if (planInfo.additionalMandatoryFeatures) {
                line.push(
                    <MandatoryFeatures features={values.features} planFeature={planInfo.additionalMandatoryFeatures}/>
                );
            }
            if (planInfo.additionalMandatoryFeaturePackages) {
                line.push(
                    <MandatoryPackages
                        features={values.features}
                        planFeature={planInfo.additionalMandatoryFeaturePackages}
                        handleMandatoryPackages={this.handleMandatoryPackages}
                    />
                );
            }
            if (planInfo.additionalOptionalFeatures) {
                line.push(
                    <OptionalFeatures
                        features={values.features}
                        planFeature={planInfo.additionalOptionalFeatures}
                        handleOptionalFeature={this.handleOptionalFeature}
                    />
                );
            }
            if (planInfo.additionalOptionalFeaturePackages) {
                line.push(
                    <OptionalPackages
                        features={values.features}
                        planFeature={planInfo.additionalOptionalFeaturePackages}
                        handleOptionalPackages={this.handleOptionalPackages}
                        handleOptionalPackagesCheckbox={this.handleOptionalPackagesCheckbox}
                    />
                );
            }
        }

        return line.map((item, index) => <Fragment key={index}>{item}</Fragment>);
    };

    render() {
        const {isColapsed} = this.state;
        const {planInfo} = this.props;

        return (
            this.linePlans()
        );
    }
}

export default ProductsServiceline;

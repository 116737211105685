import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import FlyInRightPanel from '../../../components/FlyInRightPanel'
import {trans} from "../../../utils/helpers";

class GuestNavbar extends Component {
    static propTypes = {};

    state = {
        companyName:'',
        isMobileMenuOpen: false
    };

    componentDidMount(){
        (async() => {
            const companyName=await trans('en-us', 'companyName')

            this.setState({companyName});
        })()
    }
    handleShowHideMenuDropdown = () => {
        this.setState((prevState) => ({
            isMobileMenuOpen: !prevState.isMobileMenuOpen
        }));
    };

    renderMenuContent = (menuLocation) => {

        const menuContent = (window.navbarLinks && window.navbarLinks.map(item => (
            <li className="nav-item" key={item.name}>
                <a className="nav-link" href={item.link} target={item.newWindow ? '_blank' : '_self'}>
                    <i className={'fas fa-fw' + (item.newWindow ? ' fa-external-link-alt' : '')}/>
                    {item.name}
                </a>
            </li>
        )));

        return (
            <ul className="navbar-nav">

                {menuContent}

                <li className="nav-item">
                    <Link to={{pathname: '/login'}}
                          className="nav-link nav-link-login"
                          onClick={(menuLocation === "panel" ? this.handleShowHideMenuDropdown : undefined)}
                    >
                        <i className="fas fa-sign-in-alt" />
                        Sign In
                    </Link>
                </li>

                {/*<li className="nav-item">*/}
                {/*	<Link to={{ pathname: '/register' }} className="nav-link" onClick={(menuLocation == "panel" ? this.handleShowHideMenuDropdown : undefined)}>*/}
                {/*		<i class="fas fa-edit"></i>Register*/}
                {/*	</Link>*/}
                {/*</li>*/}

                {window.guestOrderEnable &&
                <li className="nav-item">
                    <Link to={{pathname: '/guest-order'}}
                          className="nav-link nav-link-check"
                          onClick={(menuLocation === "panel" ? this.handleShowHideMenuDropdown : undefined)}
                    >
                        <i className="fas fa-map-marker-alt" aria-hidden="true" />
                        Check Availability
                    </Link>
                </li>
                }

                {/*<li className="nav-item dropdown">
                    <a
                        className="nav-link dropdown-toggle"

                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        Español <i className="fas fa-globe-americas" />
                    </a>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                        <a className="dropdown-item" href="#">
                            Español
                        </a>
                        <a className="dropdown-item" href="#">
                            English
                        </a>
                        <a className="dropdown-item" href="#">
                            French
                        </a>
                    </div>
                </li>*/}

            </ul>
        );
    };

    render() {
        const {isMobileMenuOpen} = this.state;

        return (
            <>

                <FlyInRightPanel title="Menu" onClose={this.handleShowHideMenuDropdown} isVissible={isMobileMenuOpen}>
                    {this.renderMenuContent('panel')}
                </FlyInRightPanel>

                <header className="cp-header cp-header-guest">
                    <nav className="navbar navbar-light cp-navbar cp-navbar-guest">

                        {(window.logoLink === '' ? (
                            <span className="navbar-left navbar-img">
                                <img src={`${process.env.PUBLIC_URL}/themes/active/img/app-banner.svg`} />
                            </span>
                        ) : (
                            <a className="navbar-left navbar-img" href={window.logoLink} target="_blank" rel="noopener noreferrer">
                                <img src={`${process.env.PUBLIC_URL}/themes/active/img/app-banner.svg`} />
                            </a>
                        ))}

                        <span className="navbar-brand">
                            {window.translations['en-us'].companyName !== '' &&this.state.companyName }
                        </span>

                        <div className="navbar-right">

                            {this.renderMenuContent('navbar')}

                            <button
                                className='navbar-toggler'
                                type="button"
                                data-toggle="collapse"
                                aria-expanded={isMobileMenuOpen ? 'true' : 'false'}
                                aria-label="Toggle navigation"
                                onClick={this.handleShowHideMenuDropdown}
                            >
                                <span className="navbar-toggler-icon"/>
                            </button>

                        </div>

                    </nav>
                </header>
            </>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GuestNavbar);

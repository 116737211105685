import React, { Component, Fragment } from 'react';
import OptionalFeature from './Options/OptionalFeature';

class OptionalFeatures extends Component {
	render() {
		const { planFeature, accountInitialStatus } = this.props;
		return (
			<Fragment>
				<hr />
				<h6>Optional</h6>
				{planFeature.map((feature) => (
					<OptionalFeature
						feature={feature}
						key={feature.id}
						accountInitialStatus={accountInitialStatus}
						{...this.props}
					/>
				))}
			</Fragment>
		);
	}
}

export default OptionalFeatures;

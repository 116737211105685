import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import isEmpty, {asNoneNegative, formatAmount} from "../../../utils/helpers";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {selectAccountInfo} from "../../../selectors";
import {paymentByACH} from "../../../actions/payment.actions";

const ACCOUNT_TYPE = {
    CHECKING: 'CHECKING',
    SAVINGS: 'SAVINGS',
    BUSINESS_CHECK: 'BUSINESS_CHECK',
}

const PayNowSchema = Yup.object().shape({
    routingNumber: Yup.string()
        .ensure()
        .required('This field is required.'),
    accountNumber: Yup.string()
        .required('This field is required.'),
    accountType: Yup.string()
        .ensure()
        .required('This field is required.'),
    holderName: Yup.string()
        .ensure()
        .required('This field is required.')
        .min(5, 'Please enter at least 5 characters.'),
    paymentAmount: Yup.number()
        .required('This field is required.')
        .transform((value, originalValue) => Number(transformAmount(originalValue)))
        .typeError('Invalid payment amount.')
        .positive('Invalid payment amount.')
        .test(
            'is-valid-amount',
            'Invalid payment Amount.',
            value => (value + '').match(/^(\d+\.\d{0,2}|\d+\.?|\.\d{1,2})$/gm) !== null),
});

const transformAmount = (amount) => {
    return amount.replace(/,/g, '');
}

class ACHForm extends Component {

    /* Disable closing of ACH form like we do with Card payments */
    /*
    componentDidMount() {

        // Prevent panel closing
        this.props.togglePreventPanelClosing(true);

        // Add tab close event listener
        window.addEventListener('beforeunload', this.preventTabClose);
    }

    componentWillUnmount() {

        // Unlock panel closing
        this.props.togglePreventPanelClosing(false);

        // Remove tab close event listener
        window.removeEventListener('beforeunload', this.preventTabClose);
    }

    preventTabClose = () => {
        if (this.props.preventPanelClosing) {
            let e = window.event;
            if (e) e.returnValue = true;
            return true;
        }
    }
    */

    handleSubmit = (values, {setSubmitting, setFieldValue}) => {

        const {paymentAmount} = values;
        const {guestOrderConfig} = window;
        const {
            accountInfo,
            paymentByACH,
            onSubmit,
            storeACHDetails,
            handlePaymentSuccess,
            handlePaymentFail,
            handlePaymentGeneric,
        } = this.props;

        if (typeof onSubmit === "function") {
            onSubmit();
        }

        const requestData = {...values};

        requestData.paymentOption = 'ALL_UNPAID_INVOICES';
        requestData.paymentAmount = transformAmount(paymentAmount);

        if (guestOrderConfig && guestOrderConfig.defaultLocationId) {
            requestData.locationId = guestOrderConfig.defaultLocationId;
        }
        else {
            console.warn("No default location ID");
        }

        // Enable quick AutoPay activation with current account
        storeACHDetails(values);

        // Proceed with payment
        paymentByACH(accountInfo.id, requestData).then(response => {
            setSubmitting(false);

            /* Disable closing of ACH form like we do with Card payments */
            /*
            // Unlock panel closing
            this.props.togglePreventPanelClosing(false);

            // Remove tab close event listener
            window.removeEventListener('beforeunload', this.preventTabClose);
            */

            if (response.success === false) {
                handlePaymentFail({status: 'FAILED', responseText: response.error.message});
            }
            else if (response.status === 'FAIL') {
                handlePaymentFail(response);
            }
            else if (response.status === 'SUCCESS') {
                handlePaymentSuccess(response);
            }
            else {
                handlePaymentGeneric(response);
            }
        }).catch(err => {
            setSubmitting(false);
            handlePaymentFail({status: 'FAILED', responseText: "Unexpected error"});

            /* Disable closing of ACH form like we do with Card payments */
            /*
            // Unlock panel closing
            this.props.togglePreventPanelClosing(false);

            // Remove tab close event listener
            window.removeEventListener('beforeunload', this.preventTabClose);
            */
        });
    }

    render() {
        const { accountBalance, amountDue, accountInfo, visible, currentProviderId } = this.props;

        return (

            <Formik
                initialValues={{
                    routingNumber: '',
                    accountNumber: '',
                    accountType: 'CHECKING',
                    holderName: accountInfo.primaryContact
                            && !isEmpty(accountInfo.primaryContact.firstName)
                            && !isEmpty(accountInfo.primaryContact.lastName)
                            && accountInfo.primaryContact.firstName + ' ' + accountInfo.primaryContact.lastName,
                    paymentAmount: formatAmount(asNoneNegative(accountBalance)),
                }}
                enableReinitialize
                validationSchema={PayNowSchema}
                onSubmit={this.handleSubmit}
                render={(props) => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        setValues,
                        setSubmitting,
                        setIn
                    } = props;

                    return (
                        <Form
                            style={{display: visible ? 'block' : 'none'}}
                            onSubmit={handleSubmit}
                            id="achForm"
                            autoComplete="off"
                        >

                            <div className="form-section form-section-customer-payment-content">
                                <div className="form-section form-section-customer-payment-options" style={{marginTop: '40px'}}>

                                    <h4>ACH Details</h4>

                                    <div className="form-group">
                                        <label>Routing Number</label>
                                        <input
                                            type="text"
                                            id="routingNumber"
                                            name="routingNumber"
                                            disabled={isSubmitting}
                                            className={'form-control' + (errors.routingNumber && touched.routingNumber ? ' is-invalid' : '')}
                                            value={values.routingNumber}
                                            placeholder="Enter routing number"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {errors.routingNumber && touched.routingNumber && (
                                            <div className="invalid-feedback">{errors.routingNumber}</div>
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <label>Account Number</label>
                                        <input
                                            type="text"
                                            id="accountNumber"
                                            name="accountNumber"
                                            disabled={isSubmitting}
                                            className={'form-control' + (errors.accountNumber && touched.accountNumber ? ' is-invalid' : '')}
                                            value={values.accountNumber}
                                            placeholder="Enter account number"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {errors.accountNumber && touched.accountNumber && (
                                            <div className="invalid-feedback">{errors.accountNumber}</div>
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <label>Account type</label>
                                        <select
                                            name="accountType"
                                            value={values.accountType}
                                            disabled={isSubmitting}
                                            onChange={(e) => setFieldValue('accountType', e.target.value)}
                                            id="sel-customer-pmnt-acccount-type"
                                            className={'form-control form-control-sm' + (errors.accountType && touched.accountType ? ' is-invalid' : '')}>
                                            <option value={ACCOUNT_TYPE.CHECKING}>Checking</option>
                                            <option value={ACCOUNT_TYPE.SAVINGS}>Savings</option>
                                            {currentProviderId === 'IPPAY' && <option value={ACCOUNT_TYPE.BUSINESS_CHECK}>Business Check</option>}
                                        </select>
                                        {errors.accountType && touched.accountType && (
                                            <div className="invalid-feedback">{errors.accountType}</div>
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <label>Name</label>
                                        <input
                                            type="text"
                                            id="holderNameACH"
                                            name="holderName"
                                            disabled={isSubmitting}
                                            className={'form-control' + (errors.holderName && touched.holderName ? ' is-invalid' : '')}
                                            value={values.holderName}
                                            placeholder="Enter name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {errors.holderName && touched.holderName && (
                                            <div className="invalid-feedback">{errors.holderName}</div>
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <label>Payment Amount</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">$</span>
                                            </div>
                                            <input
                                                type="text"
                                                data-type="currency"
                                                name="paymentAmount"
                                                disabled={isSubmitting}
                                                className={'form-control' + (errors.paymentAmount && touched.paymentAmount ? ' is-invalid' : '')}
                                                value={values.paymentAmount}
                                                data-amount={''}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                            {errors.paymentAmount && touched.paymentAmount && (
                                                <div className="invalid-feedback">{errors.paymentAmount}</div>
                                            )}
                                        </div>
                                    </div>

                                    {!isSubmitting &&
                                    <div className="form-group text-right">
                                        <div className="btn-group-sm">
                                            <button
                                                type="button"
                                                className="btn btn-outline-secondary btn-pay-balance"
                                                onClick={() => setFieldValue('paymentAmount', formatAmount(asNoneNegative(accountBalance)))}>

                                                Pay Balance
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-outline-secondary btn-pay-amdue ml-1"
                                                onClick={() => setFieldValue('paymentAmount', formatAmount(asNoneNegative(amountDue)))}>

                                                Pay Amount Due
                                            </button>
                                        </div>
                                    </div>
                                    }

                                    <div className="form-group text-right">
                                        <button
                                            disabled={isSubmitting}
                                            type="submit" form="achForm" className="btn btn-primary"
                                            id="btn-pmnt-ach-form-submit"
                                        >
                                            Submit {isSubmitting && <i className="fas fa-spinner fa-spin"/>}
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </Form>
                    )
                }}
            />
        );
    }
}

ACHForm.propTypes = {
    visible: PropTypes.bool,
    accountBalance: PropTypes.number.isRequired,
    amountDue: PropTypes.number.isRequired,
    onSubmit: PropTypes.func,
    handlePaymentSuccess: PropTypes.func,
    handlePaymentFail: PropTypes.func,
    handlePaymentGeneric: PropTypes.func,
};

const mapStateToProps = (state) => {
    const { accountInfo } = selectAccountInfo(state);

    return {
        accountInfo,
    };
};

const mapDispatchToProps = {
    paymentByACH,
};

export default connect(mapStateToProps, mapDispatchToProps)(ACHForm);
import React, { Component } from 'react';
import RoundedInput from '../../../components/common/Formik/RoundedInput';
import {trans} from "../../../utils/helpers";

export class ChoosePassword extends Component {
	state={
		choosepasswordtitle:''
	}
	componentDidMount(){
		(async() => {
			const choosepasswordtitle= await trans('en-us', 'registerScreen.choosePasswordSection.title');
			this.setState({choosepasswordtitle});
		})()
	}
	render() {
		const { values, handleChange, handleGoBack } = this.props;

		return (
			<div class="form-section fx-from-right" id="fs4">
				<h3 class="text-center">{this.state.choosepasswordtitle}</h3>
				<div class="form-group">
					<label for="">Email</label>
					<RoundedInput name="email" type="text" placeholder="Email" onChage={handleChange} />
				</div>
				<div class="form-group">
					<label for="">Password</label>
					<RoundedInput type="password" name="passwrd" placeholder="Password" onChange={handleChange} />
				</div>
				<div class="form-group">
					<label for="">Confirm Password</label>
					<RoundedInput type="password" name="passwrd" placeholder="Password" onChange={handleChange} />
				</div>
				<div class="form-check checkbox-slider--b-flat">
					<label>
						<input type="checkbox" name="" />
						<span>
							I accept the <a href="#">terms &amp; conditions</a>.
 						</span>
					</label>
				</div>
				<div class="form-group form-group-footer">
					<button type="button" class="btn btn-outline-secondary btn-fs-action" data-target="fs3" onClick={handleGoBack}>
						Go Back
 					</button>
					<button type="submit" class="btn btn-primary btn-fs-action" data-target="fs5">
						Register
 					</button>
				</div>
			</div>
		);
	}
}

export default ChoosePassword;
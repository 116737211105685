import React, { Component } from 'react';
import '../../resources/components/ui-component-notification-toggle-group.scss'
import {
    getNotificationCategories,
} from "../../actions/notifications";
import {connect} from "react-redux";

class NotificationCategories extends Component {

    state = {
        isToggled: false,
        isDisabled: false,
        isLoading: false,
        allCommunicationsToggled: null,
        notificationCategoriesState: [],
    };

    componentDidMount() {
        this.checkNotificationsToggled()
        this.setState({notificationCategoriesState: this.props.notificationCategoriesData})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.notificationCategoriesData !== this.props.notificationCategoriesData) {

            this.setState({notificationCategoriesState: this.props.notificationCategoriesData}, () => this.checkNotificationsToggled())
        }
    }


    //checks if all communications toggle needs to be checked or not on load
    checkNotificationsToggled = () => {
        let optOutNotificationPresent = false
        this.state.notificationCategoriesState.map((category) => {
            if(category.optedOut.includes('EMAIL') === true || category.optedOut.includes('TWILIOMSG') === true) {
                optOutNotificationPresent = true
                this.setState({allCommunicationsToggled: false})
                return false
            }
        })
        if(optOutNotificationPresent === false) {
            this.setState({allCommunicationsToggled: true})
            return true
        }
    }

    handleToggle = () => {
        this.setState((prevState) => ({
            isToggled: !prevState.isToggled
        }));
    };

    handleAllNotificationOptInOut = () => {
        //main toggle to optIn/Out for all categories
        const lastToggleValue = this.state.allCommunicationsToggled
        this.setState({allCommunicationsToggled: !this.state.allCommunicationsToggled})
        let newNotificationCategoriesState = this.state.notificationCategoriesState.map((category) => {
            category = {...category, optedIn: category.optedOut, optedOut: category.optedIn}
            return category
        })
        this.setState({notificationCategoriesState: newNotificationCategoriesState})
        this.props.notificationCategoriesData.map((notificationCategory => {
                if (lastToggleValue) {
                    this.props.handleNotificationUnsubscribe({
                        notificationCategoryId: notificationCategory.id
                    })
                } else {
                    this.props.handleNotificationSubscribe({
                        notificationCategoryId: notificationCategory.id,
                        type: "EMAIL"
                    })
                    this.props.handleNotificationSubscribe({
                        notificationCategoryId: notificationCategory.id,
                        type: "TWILIOMSG"
                    })
                }
            })
        )
        setTimeout(() => {
            this.props.getNotificationCategories(this.props.accountId)
        }, 5000)
    }


    notificationOnClickHandler = (notificationCategory, type) => {
        const {handleNotificationSubscribe, handleNotificationUnsubscribe} = this.props
        let newNotificationCategory
        //checks if it includes EMAIL or TWILIOMSG to determine if opt in/out
        if(!notificationCategory.optedIn.includes(type)) {
            newNotificationCategory = {...notificationCategory,
                optedOut: notificationCategory.optedOut.filter((category) => category !== type),
                optedIn: [...notificationCategory.optedIn, type]}
            let newCategoryState = this.state.notificationCategoriesState.map((notification) => {
                if (notification.id === newNotificationCategory.id) {
                    notification = newNotificationCategory
                    return notification
                }
                return notification
            })
            this.setState({notificationCategoriesState: newCategoryState}, () => this.checkNotificationsToggled())
            handleNotificationSubscribe({
                notificationCategoryId: notificationCategory.id,
                type: type
            })
        } else {
            newNotificationCategory = {...notificationCategory,
                optedIn: notificationCategory.optedIn.filter((category) => category !== type),
                optedOut: [...notificationCategory.optedOut, type]}
            let newCategoryState = this.state.notificationCategoriesState.map((notification) => {
                if (notification.id === newNotificationCategory.id) {
                    notification = newNotificationCategory
                    return notification
                }
                return notification
            })
            this.setState({notificationCategoriesState: newCategoryState}, () => this.checkNotificationsToggled())
            handleNotificationUnsubscribe({
                notificationCategoryId: notificationCategory.id,
                type: type
            })
        }
        setTimeout(() => {
            this.props.getNotificationCategories(this.props.accountId)
        }, 10000)
    }


    render() {
        const {
            allCommunicationsToggled,
            notificationCategoriesState,
        } = this.state;

        return (
            <div className="container-client-notifications-settings">
                <div>
                    <div className='comm-preferences-group-toggle'>
                                <div>All Communications</div>
                                <div className="form-check checkbox-slider checkbox-slider--b-flat" style={{marginTop:'3px'}}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={allCommunicationsToggled}
                                            onClick={() => {
                                                this.handleAllNotificationOptInOut()
                                            }}
                                        />
                                        <span>&nbsp;</span>
                                    </label>
                                </div>
                    </div>
                    {allCommunicationsToggled === false &&
                        <div className='toggle-group'>
                            <div className='toggle-col'>
                                <span>&nbsp;</span>
                                <label>Email</label>
                                <label>SMS</label>
                            </div>
                            {notificationCategoriesState.map((notificationCategory) => {
                                return (
                                    <div className='toggle-col'>
                                        <div>{notificationCategory.description}</div>
                                        {notificationCategory.optedIn.includes("EMAIL") || notificationCategory.optedOut.includes("EMAIL") ?
                                            <div className="form-check checkbox-slider checkbox-slider--b-flat" style={{marginTop:'5px'}}>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        checked={notificationCategory.optedIn.includes("EMAIL")}
                                                        onClick={() => {
                                                            this.notificationOnClickHandler(notificationCategory, "EMAIL")
                                                        }}
                                                    />
                                                    <span>&nbsp;</span>
                                                </label>
                                            </div>
                                            :
                                            null
                                        }
                                        {notificationCategory.optedIn.includes("TWILIOMSG") || notificationCategory.optedOut.includes("TWILIOMSG") ?
                                            <div className="form-check checkbox-slider checkbox-slider--b-flat" style={{marginTop:'5px'}}>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        checked={notificationCategory.optedIn.includes("TWILIOMSG")}
                                                        onClick={() => {
                                                            this.notificationOnClickHandler(notificationCategory, "TWILIOMSG")
                                                        }}
                                                    />
                                                    <span>&nbsp;</span>
                                                </label>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div>
            </div>
        );
    }
}



const mapDispatchToProps = {
    getNotificationCategories
};


export default connect(null, mapDispatchToProps)(NotificationCategories);

let backendHost;

if (window.apiUrl) {
	backendHost = window.apiUrl;
} else {
	const hostname = window && window.location && window.location.hostname;

	if (hostname === 'localhost') {
		backendHost = 'https://dev-api.camvio.cloud/aboss-api/rest/v1';
	} else {
		let splittedHostname = hostname.split('.');
		splittedHostname[0] = splittedHostname[0] + '-api';
		let splitedHostnameToString = splittedHostname.join('.');
		backendHost = `https://${splitedHostnameToString}/aboss-api/rest/v1`;
	}
}

 //backendHost = 'http://localhost:8080/aboss-api/rest/v1';

export const API_ROOT = `${backendHost}`;

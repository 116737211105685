import * as Yup from 'yup';

export const validationSchema = (activeStep) => {
	const messages = window.validationMessages; // This is from external js file

	const phoneRegExp = /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/
	const zipcodeRegExp = /(^\d{5}$)|(^\d{5}-\d{4}$)/

	if (activeStep === 'check-availability') {
		return Yup.object().shape({
			street: Yup.string().min(3, messages.street).required('Required'),
			zipcode: Yup.string().matches(zipcodeRegExp, 'Invalid zip').required('Required'),
		});
	} else if (activeStep === 'not-available-service') {
		return Yup.object().shape({
			account: Yup.object().shape({
				accountHolder: Yup.object().shape({
					personalEmail: Yup.string().email('Invalid email format').required('Required'),
				})
			})
		});
	} else if (activeStep === 'new-signup'){
		return Yup.object().shape({

		})
	} else if(activeStep === 'customer-info'){
		return Yup.object().shape({
			account: Yup.object().shape({
				accountHolder: Yup.object().shape({
					firstName: Yup.string().min(1, 'Min 1 character').required('Required'),
					lastName: Yup.string().min(1, 'Min 1 characters').required('Required'),
					personalEmail: Yup.string().email('Invalid email format').required('Required'),
					mobileNumber: Yup.object().shape({
						number: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
						verified: Yup.boolean()
					})
				}),
				termsAndCondition: Yup.bool().oneOf([true], messages.termsAndCondition)
			})
		})
	}
};

import axiosInstance from '../utils/axios-instance';
import isEmpty, {actionCreator, createRequestTypes, getErrorMessage} from '../utils/helpers';

export const PAYMENT_PROVIDER_FIND_CURRENT_PROVIDER_ID = createRequestTypes('@paymentProviderReducer/FIND_CURRENT_PROVIDER_ID');
export const PAYMENT_PROVIDER_SUPPORTS_ONE_TIME_PAYMENT = createRequestTypes('@paymentProviderReducer/PAYMENT_PROVIDER_SUPPORTS_ONE_TIME_PAYMENT');

export const getCurrentPaymentProviderId = () => async (dispatch) => {
	dispatch(actionCreator.request(PAYMENT_PROVIDER_FIND_CURRENT_PROVIDER_ID));
	try {
		const response = await axiosInstance.get(`/payment-provider/current/id`);

		dispatch(actionCreator.success(PAYMENT_PROVIDER_FIND_CURRENT_PROVIDER_ID, response.data.id));

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(PAYMENT_PROVIDER_FIND_CURRENT_PROVIDER_ID, getErrorMessage(err)));

		throw err;
	}
}

export const clearCurrentPaymentProviderId = () => (dispatch) => {
	dispatch(actionCreator.empty(PAYMENT_PROVIDER_FIND_CURRENT_PROVIDER_ID));
}

export const getCurrentPaymentProviderSupportsPaymentMethodStorage = () => async (dispatch) => {
	dispatch(actionCreator.request(PAYMENT_PROVIDER_FIND_CURRENT_PROVIDER_ID));
	try {
		const response = await axiosInstance.get(`/payment-provider/current/supports-payment-method-storage`);
		return response;
	} catch (err) {
		dispatch(actionCreator.failure(PAYMENT_PROVIDER_FIND_CURRENT_PROVIDER_ID, getErrorMessage(err)));

		throw err;
	}
}

export const getCurrentPaymentProviderSupportsOneTimePayment = () => async (dispatch) => {
	dispatch(actionCreator.request(PAYMENT_PROVIDER_SUPPORTS_ONE_TIME_PAYMENT));
	try {
		const response = await axiosInstance.get(`/payment-provider/current/supports-one-time-payment`);
		dispatch(actionCreator.success(PAYMENT_PROVIDER_SUPPORTS_ONE_TIME_PAYMENT, response.data));
		return response;
	} catch (err) {
		dispatch(actionCreator.failure(PAYMENT_PROVIDER_SUPPORTS_ONE_TIME_PAYMENT, getErrorMessage(err)));

		throw err;
	}
}

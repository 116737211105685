import React, {Component} from 'react';
import {connect} from "react-redux";
import {createLoadingSelector, selectGuestOrder} from "../../../../selectors";
import {searchAddress, getPlanCategories,emptyMapLinkLocation, getMinNumAddrSearchChars } from "../../../../actions/guestOrderActions";
import Autosuggest from "react-autosuggest";
import isEmpty, { trans } from "../../../../utils/helpers";
import { debounce, get } from "lodash";
import { toastr } from "react-redux-toastr";

const getApiSuggestion = (suggestion) => suggestion;

const renderApiAddress = (suggestion) => {
    return (
        <div className="autocomplete-dropdown-container">
            <div>{suggestion.fullAddress}</div>
        </div>
    )
}




class CheckAvailabilityStep extends Component {

	state = {
		value: "",
		suggestions: [],
		unitNumber: this.props.selectedUnit || "",
		noAddressesReturned: false,
		planCategoryId: '',
        accountTypeId: '',
        planCategoryError: '',
		title:'',
		surveys:'',
        signUps: '',
        noService: '',
        notFound:'',
        minNumAddrSearchChars: 3
	}

	componentDidMount() {
		(async() => {
			const title = await trans("en-us", "guestOrderScreen.checkAvailability.title");
			const surveys = await trans('en-us', 'guestOrderScreen.addressSearch.surveys');
            const signUps = await trans('en-us', 'guestOrderScreen.addressSearch.signUps');
            const noService = await trans('en-us', 'guestOrderScreen.addressSearch.noService');
            const notFound = await trans('en-us', 'guestOrderScreen.addressSearch.notFound');

			this.setState({title,surveys, signUps, noService, notFound});
		})()
        this.props.getMinNumAddrSearchChars().then(response => {
            this.setState({minNumAddrSearchChars: response,});
        })
        .catch((error) => {
            toastr.error("Configuration Issue", {position: 'top-center'});
        });
		let locationId = ""
        if(this.props.values.locationId){
            locationId = this.props.values.locationId;
        }
		this.props.getPlanCategories(locationId).then(response => {
            let sortedResponse = this.sortPlanCategories(response)
            this.setSearchedServiceType(sortedResponse);
		})
		this.props.emptyMapLinkLocation();
	}



    onAddressInputChange = (event, { newValue }) => {
		this.setState({
			value: !isEmpty(newValue.fullAddress) ? newValue.fullAddress : newValue,
			noAddressesReturned: false,
		});
	};

	setSearchedServiceType = (planCategories) => {
		let throwError = true;
		if(planCategories != undefined){
            let values = this.props.location.search
            if(this.props.location.search.includes('fbclid')) {
                let fbclidIndex = values.indexOf('fbclid')
                let valuesToBeReplaced = values.slice(fbclidIndex - 1, values.length)
                values = values.replace(valuesToBeReplaced, "")
                values = values.split('=')
            } else {
			    values = this.props.location.search.split('=');
            }
			if(values[1] != undefined){
				for(let i = 0; i < this.props.planCategories.length; i++){
					if(this.props.planCategories[i].description.toUpperCase() == values[1].toUpperCase()){
                        this.handleSelectAccountTypeId(planCategories,this.props.planCategories[i].id );
						this.setState({
							planCategoryId: this.props.planCategories[i].id
						})
                        this.props.setFieldValue('planCategoryId', this.props.planCategories[i].id)
						throwError = false;
						break;
					} else {
						throwError = true;
					}
				}
				if(throwError){
					this.setState({
						planCategoryId: planCategories[0].id
					})
                    this.props.setFieldValue('planCategoryId', planCategories[0].id)
                    this.handleSelectAccountTypeId(planCategories, planCategories[0].id);
                    toastr.error("Could not find type: " + values[1], { timeOut: 4000, position: 'top-center' });
				}
			} else {
			    if(planCategories.length > 0) {
                    this.setState({
                        planCategoryId: planCategories[0].id
                    })
                    this.handleSelectAccountTypeId(planCategories, planCategories[0].id);
                    this.props.setFieldValue('planCategoryId', planCategories[0].id);
                } else {
                    this.setState({
                        planCategoryId: '',
                        planCategoryError: 'No available Plan Categories.'
                    })
                    this.props.setFieldValue('planCategoryId', '');
                }
			}
		}
	}

	handleSelectAccountTypeId = (planCategories, planCategoryId) => {
        let accountTypeId = '';
        if(planCategories != undefined){
            planCategories.map(planCategory => {
                if(planCategory.id == planCategoryId){
                    if(planCategory.accountTypes !== undefined){
                        accountTypeId = planCategory.accountTypes[0].id
                    }
                }
            })
            this.setState({
                accountTypeId: accountTypeId
            })
        }

        this.props.setFieldValue('accountTypeId', accountTypeId);
    }

	handleSelectPlanCategoryType = (e, planCategories) => {
		if(e.target.value != undefined){
		    this.handleSelectAccountTypeId(planCategories, e.target.value);
			this.setState({
				planCategoryId: parseInt(e.target.value),
			})
		}
		this.props.setFieldValue('planCategoryId', parseInt(e.target.value))
	};

  handleSelectSuggestion = (e, value) => {
    let address = "";

    if (!isEmpty(value.suggestionValue)) {
      address = value.suggestionValue;
    } else {
      address = value;
    }

    this.props.setFieldValue("addressWrapper", address);
    this.props.onAddressSelect(address, this.state.unitNumber);
  };

  handleChangeUnit = (event) => {
    this.setState(
      {
        unitNumber: event.target.value,
      },
      () => {
        let address = {};

        if (!isEmpty(this.props.values["addressWrapper"])) {
          address = this.props.values["addressWrapper"];
        }

        address.address2 = this.state.unitNumber;
        this.props.setFieldValue("addressWrapper", address);

        if (this.unitChangeTimeout) {
          clearTimeout(this.unitChangeTimeout);
        }
        this.unitChangeTimeout = setTimeout(() => {
          if (!isEmpty(this.props.values["addressWrapper"].fullAddress)) {
            this.handleSelectSuggestion(
              event,
              this.props.values["addressWrapper"]
            );
          }
        }, 500);
      }
    );
  };

  onError = (status, clearSuggestions) => {
    this.props.onAddressSelect("error", "");
    clearSuggestions();
  };

  loadSuggestions = debounce(({ value, reason }) => {
    if (reason === "input-changed" && !isEmpty(this.state.value)) {
      this.props
        .searchAddress(value)
        .then((response) => {
          if (response && response.success && !isEmpty(response.addresses)) {
            this.setState({
              noAddressesReturned: false,
              suggestions: response.addresses,
            });
          } else {
            this.setState({
              noAddressesReturned: true,
              suggestions: [],
            });
          }
        })
        .catch((error) => {});
    }
  }, 0);


    loadSuggestionsButSlower = debounce(({ value, reason }) => {
        if (reason === "input-changed" && !isEmpty(this.state.value)) {
            this.props
                .searchAddress(value)
                .then((response) => {
                    if (response && response.success && !isEmpty(response.addresses)) {
                        this.setState({
                            noAddressesReturned: false,
                            suggestions: response.addresses,
                        });
                    } else {
                        this.setState({
                            noAddressesReturned: true,
                            suggestions: [],
                        });
                    }
                })
                .catch((error) => {});
        }
    }, 300);

  clearSuggestions = () => {
    this.setState({ suggestions: [] });
  };

  shouldRenderSuggestions=(value)=>{
      const {minNumAddrSearchChars} = this.state;
      return value.trim().length >= minNumAddrSearchChars;
  }

  renderSignupSurveyMessage = () => {
      const {validatedAddress, errorMessage} = this.props
      const {noAddressesReturned, value, unitNumber} = this.state
      let addressInputValue = value
      //formats address if unit # present
      if(unitNumber) addressInputValue = `${unitNumber}/${value}`

      //Conditionally checks attributes and compares unit number for display message
      if(!noAddressesReturned && validatedAddress && validatedAddress.addressSearchFormatted === addressInputValue &&
          !validatedAddress.error && validatedAddress.allowedServiceOrderType === "NEW_SURVEY" && !errorMessage) {
          if(validatedAddress && validatedAddress.address2) {
              return unitNumber === validatedAddress.address2 ? <div className="valid-feedback">{this.state.surveys}</div> :  null
          }
        return <div className="valid-feedback">{this.state.surveys}</div>
      }

      if(!noAddressesReturned && validatedAddress && validatedAddress.addressSearchFormatted === addressInputValue
          && !validatedAddress.error && validatedAddress.allowedServiceOrderType === "NEW_SIGNUP" && !errorMessage) {
          if(validatedAddress && validatedAddress.address2) {
              return unitNumber === validatedAddress.address2 ? <div className="valid-feedback">{this.state.signUps}</div> :  null
          }
        return <div className="valid-feedback">{this.state.signUps}</div>
      }
  }

    sortPlanCategories = (planCategories) => {
        let orderedOptions = []
        planCategories.map((category) => {
            if(category.description.toUpperCase().startsWith('RESIDENTIAL')) {
                orderedOptions.push(category)
            }
        })
        planCategories.map((category) => {
            if(category.description.toUpperCase().startsWith('BUSINESS')) {
                orderedOptions.push(category)
            }
        })
        planCategories.map((category) => {
            if(!category.description.toUpperCase().startsWith('BUSINESS') && !category.description.toUpperCase().startsWith('RESIDENTIAL')) {
                orderedOptions.push(category)
            }
        })
        return orderedOptions
    }

    planCategoriesAsOptions = (planCategories) => {
        /** if single category we just return one option */
        if(!Array.isArray(planCategories)) {
            return <option value={planCategories.id}>{planCategories.description}</option>
        } else {
            /** If multiple options we reorder array showing Residential first, then Business, then everything else */
            let orderedOptions = this.sortPlanCategories(planCategories)
            return orderedOptions.map((option) => option = <option value={option.id}>{option.description}</option>)
        }
    }

    formatAddressValue = (address) => {
      if(this.state.unitNumber) {
          return `${this.state.unitNumber}/${address}`
      } else {
          return address
      }
    }


  render() {
    const { value, suggestions, noAddressesReturned, planCategoryError } = this.state;

    const {
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      availablePlansError,
      availablePlansLoader,
      validatedAddress,
      addressesLoading,
      mapLoading,
      planCategories,
      errorMessage
    } = this.props;

    return (
      <div className="form-section fx-fadein" id="fs1">
        <h4 className="cp-form-title">
          {this.state.title}
        </h4>

        <div className="form-group">
          <label htmlFor={"street"}>Your street address</label>
          <div className="input-loader">
            <Autosuggest
              theme={{
                input: {},
                suggestionsContainerOpen: {
                  // top: "51px",
                  display: "block",
                  width: "100%",
                  fontSize: "16px",
                  backgroundColor: "#fff",
                  border: "1px solid #aaa",
                  zIndex: 2,
                },
                suggestionsList: {
                  margin: 0,
                  padding: 0,
                  listStyleType: "none",
                },
                suggestion: {
                  cursor: "pointer",
                  padding: "10px 20px",
                },
                suggestionHighlighted: {
                  backgroundColor: "#6ab3c1",
                },
              }}
              suggestions={suggestions}
              onSuggestionsFetchRequested={this.state.value.length < 5 ?  this.loadSuggestions : this.loadSuggestionsButSlower}
              onSuggestionsClearRequested={this.clearSuggestions}
              onSuggestionSelected={this.handleSelectSuggestion}
              getSuggestionValue={getApiSuggestion}
              renderSuggestion={renderApiAddress}
              shouldRenderSuggestions={this.shouldRenderSuggestions}
              inputProps={{
                class: "form-control",
                placeholder: "Start by typing an address",
                value: this.state.value,
                onChange: this.onAddressInputChange,
                disabled: availablePlansLoader,
              }}
              onError={this.onError}
            />

						{addressesLoading &&
						<div className="input-loader-container">
							<span className="input-loader-dot" />
							<span className="input-loader-dot" />
							<span className="input-loader-dot" />
						</div>
						}

					</div>


                    {validatedAddress && errorMessage &&
                    <div className="invalid-feedback"> {this.props.errorMessage}</div>
                    }

                    {!noAddressesReturned && validatedAddress && validatedAddress.error && !errorMessage &&
                    <div className="invalid-feedback">{validatedAddress.error.message}</div>
                    }

					{!noAddressesReturned && validatedAddress && validatedAddress.addressSearchFormated === this.formatAddressValue(value) && !validatedAddress.error && !validatedAddress.allowedServiceOrderType && !errorMessage &&
					<div className="invalid-feedback">{this.state.noService}</div>
					}

                    {this.renderSignupSurveyMessage()}

					{!isEmpty(value) && noAddressesReturned && isEmpty(suggestions) && !errorMessage &&
					<div className="invalid-feedback">{this.state.notFound}</div>
					}

                    {this.props.validatedAddress &&
                    this.props.validatedAddress.viewAddressZoneValues && (
                        <div className="row">
                            <div className="col-md-9">
                                <div className="valid-feedback">
                                    {this.props.validatedAddress.viewAddressZoneValues.map((addressZone) => {
                                        return(
                                            <div>
                                                <span>{addressZone.value}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    )}
				</div>

				<div className="form-row">

					<div className="form-group col-6">

						<label htmlFor="unit">Unit #</label>

						<input
							className={"form-control" + (validatedAddress && validatedAddress.error && touched.address ? " is-invalid" : "")}
							type="text"
							id="unit"
							name="unit"
							value={this.state.unitNumber}
							placeholder="Unit#"
							onChange={this.handleChangeUnit}
							onBlur={handleBlur}
							disabled={availablePlansLoader}
							autoComplete="off"
						/>

						{get(errors, "unit") && get(touched, "unit") &&
						<div className="invalid-feedback">{get(errors, "unit")}</div>
						}
					</div>
					<div className="form-group col-6">
						<label>Type</label>
						<select
							name="planCategoryType"
							className={'form-control form-control-sm' + (errors.accountType && touched.accountType ? ' is-invalid' : '')}
							onChange={(e) => this.handleSelectPlanCategoryType(e, planCategories)}
							value={this.state.planCategoryId}
						>
							{this.planCategoriesAsOptions(planCategories)}
						</select>
                        {planCategoryError &&
                        <div className="invalid-feedback">{planCategoryError}</div>
                        }
					</div>
				</div>

				<div className="form-row">
					<div className="form-group form-group-button col-12">
						<button
							type="submit"
							className="btn btn-primary btn-block"
							data-target="fs2"
							disabled={availablePlansLoader || !validatedAddress || validatedAddress.error || mapLoading || errorMessage}
						>
							Check Availability {availablePlansLoader && <i className="fas fa-fw fa-spin fa-spinner" />}
						</button>
					</div>

				</div>

			</div>
		);
	}
}

const addressLoader = createLoadingSelector(["GET_INTEGRATION_SEARCH_ADDRESS"]);
const mapLoader = createLoadingSelector(["MAP_LINK_LOCATION"]);

const mapStateToProps = (state) => {
  const addressesLoading = addressLoader(state);
  const mapLoading = mapLoader(state);
  const planCategories = selectGuestOrder(state).planCategories;

  return {
    addressesLoading,
    mapLoading,
    planCategories,
  };
};

const mapDispatchToProps = {
	searchAddress,
	getPlanCategories,
	emptyMapLinkLocation,
    getMinNumAddrSearchChars,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckAvailabilityStep);

import {
	GET_ORDER_AVAILABILITY,
	GET_ORDER_APPOINTMENTS,
	POST_ORDER_APPOINTMENT,
	VALIDATE_SIGNUP_TOKEN,
} from '../actions/signUpToServiceActions';

let initialState = {
	orderAvailability: {},
	orderAppointments: {},
	signUpForServiceTokenResponse: {},
};

export const signupToServiceReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ORDER_AVAILABILITY.SUCCESS:
			return {
				...state,
				orderAvailability: action.payload.dates
			};
		case GET_ORDER_APPOINTMENTS.SUCCESS:
			return {
				...state,
				orderAppointments: action.payload
			};
		case VALIDATE_SIGNUP_TOKEN.SUCCESS:
			return {
				...state,
				signUpForServiceTokenResponse: action.payload
			};
		default:
			return state;
	}
};

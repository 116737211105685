import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import isEmpty from "../../../../utils/helpers";

class MandatoryPackage extends Component {
	state = {
		isDescriptionToggled: false
	};

	toggleDescription = (e) => {
		e.stopPropagation();
		this.setState((prevState) => ({
			isDescriptionToggled: !prevState.isDescriptionToggled
		}));
	};

	isPriceRendered = () => {
		const { accountInitialStatus, packageOption } = this.props;

		if (accountInitialStatus === 'SIGNUP') {
			if (packageOption.features.some((x) => x.hideSignupPrice === true)) {
				return false;
			}
		} else if (accountInitialStatus === 'SURVEY') {
			if (packageOption.features.some((x) => x.hideSurveyPrice === true)) {
				return false;
			}
		}
		return true;
	};

	render() {
		const { isDescriptionToggled } = this.state;
		const { featurePackage, packageOption, lineInfo, lineIndex, handleMandatoryPackages } = this.props;

		return (
			<div className="package">
				<div className="package-header custom-switch-with-button">
					<div
						className="custom-control custom-switch"
						onClick={() =>
							handleMandatoryPackages(packageOption.featurePackageOptionId, lineIndex, {
								featurePackageId: featurePackage.featurePackageId,
								featurePackageDescription: featurePackage.description,
								...packageOption
							})}
					>
						<input
							type="checkbox"
							className="custom-control-input"
							// id="b1in1"
							checked={
								lineInfo.features.findIndex(
									(x) => x.featurePackageOptionId === packageOption.featurePackageOptionId
								) !== -1
							}
							readOnly
						/>
						<label className="custom-control-label" htmlFor="">
							<span>{packageOption.description}</span>
							<span>
								{packageOption.features && this.isPriceRendered() &&
									`$${packageOption.recurringMonthlyPrice
										.toFixed(2)
										.replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo`}
							</span>
						</label>
					</div>

					{!isEmpty(packageOption && packageOption.note)
						?
						<button
							type="button"
							onClick={this.toggleDescription}
							className={
								isDescriptionToggled ? (
									'btn btn-link btn-link-text btn-toggle-package-desc toggled'
								) : (
									'btn btn-link btn-link-text btn-toggle-package-desc'
								)
							}
						>
							<i className="fas fa-info-circle"/>
						</button>
						:
						(<></>)
					}

				</div>
				{isDescriptionToggled && <div className="package-description text-sm">{ReactHtmlParser(packageOption.note)}</div>}
			</div>
		);
	}
}

export default MandatoryPackage;

import axiosInstance from '../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../utils/helpers';
import { getErrorMessage } from '../utils/helpers';

export const VALIDATE_SIGNUP_TOKEN = createRequestTypes('VALIDATE_SIGNUP_TOKEN');
export const VALIDATE_INSTALL_TOKEN = createRequestTypes('VALIDATE_INSTALL_TOKEN');
export const GET_ORDER_AVAILABILITY = createRequestTypes('GET_ORDER_AVAILABILITY');
export const GET_ORDER_APPOINTMENTS = createRequestTypes('GET_ORDER_APPOINTMENTS');
export const POST_ORDER_APPOINTMENT = createRequestTypes('POST_ORDER_APPOINTMENT');
export const COMPLETE_SIGNUP_TO_SERVICE = createRequestTypes('COMPLETE_SIGNUP_TO_SERVICE');
export const COMPLETE_SCHEDULE_INSTALL = createRequestTypes('COMPLETE_SCHEDULE_INSTALL');
export const PATCH_SERVICE_ORDER = createRequestTypes('PATCH_SERVICE_ORDER');

export const validateSignupForServiceToken = (verificationToken) => async (dispatch, getState) => {
    dispatch(actionCreator.request(VALIDATE_SIGNUP_TOKEN));
    try {
        const response = await axiosInstance.post(`/verification/email/signupforservicetokenverify`, {verificationToken});
        dispatch(actionCreator.success(VALIDATE_SIGNUP_TOKEN, response.data));
        return response;
    } catch (err) {
        let language = getState().language.language;
        let mockError = getErrorMessage(err.response, language);
        dispatch(actionCreator.failure(VALIDATE_SIGNUP_TOKEN, mockError));
        return err.response;
    }
};

export const validateScheduleInstallToken = (verificationToken) => async (dispatch, getState) => {
    dispatch(actionCreator.request(VALIDATE_INSTALL_TOKEN));
    try {
        const response = await axiosInstance.post(`/verification/email/scheduleinstalltokenverify`, {verificationToken});
        dispatch(actionCreator.success(VALIDATE_INSTALL_TOKEN, response.data));
        return response;
    } catch (err) {
        let language = getState().language.language;
        let mockError = getErrorMessage(err.response, language);
        dispatch(actionCreator.failure(VALIDATE_INSTALL_TOKEN, mockError));
        return err.response;
    }
};

export const getOrderAvailability = (orderId) => async (dispatch) => {
    dispatch(actionCreator.request(GET_ORDER_AVAILABILITY));
    try {
        const response = await axiosInstance.get(`/appointments/service-order/${orderId}/availability`);
        dispatch(actionCreator.success(GET_ORDER_AVAILABILITY, response.data));
        return response
    } catch (err) {
        let mockError = {message: 'Not found'};
        dispatch(actionCreator.failure(GET_ORDER_AVAILABILITY, mockError));
    }
};

export const createOrderAppointment = (orderId, data) => async (dispatch) => {
    dispatch(actionCreator.request(POST_ORDER_APPOINTMENT));
    try {
        const response = await axiosInstance.post(`/public/order/${orderId}/appointment`, data);
        dispatch(actionCreator.success(POST_ORDER_APPOINTMENT, response.data));
        return response;
    } catch (err) {
        dispatch(actionCreator.failure(POST_ORDER_APPOINTMENT));
        return err;
    }
};

export const completeSignupToService = (verificationToken) => async (dispatch, getState) => {
    dispatch(actionCreator.request(COMPLETE_SIGNUP_TO_SERVICE));
    try {
        const response = await axiosInstance.post(`/verification/email/completesignupforservice`, {verificationToken});
        dispatch(actionCreator.success(COMPLETE_SIGNUP_TO_SERVICE, response.data));
        return response;
    } catch (err) {
        let language = getState().language.language;
        let mockError = getErrorMessage(err.response, language);
        dispatch(actionCreator.failure(COMPLETE_SIGNUP_TO_SERVICE, mockError));
        return err.response;
    }
};

export const completeScheduleInstall = (verificationToken) => async (dispatch, getState) => {
    dispatch(actionCreator.request(COMPLETE_SCHEDULE_INSTALL));
    try {
        const response = await axiosInstance.post(`/verification/email/completescheduleinstall`, {verificationToken});
        dispatch(actionCreator.success(COMPLETE_SCHEDULE_INSTALL, response.data));
        return response;
    } catch (err) {
        let language = getState().language.language;
        let mockError = getErrorMessage(err.response, language);
        dispatch(actionCreator.failure(COMPLETE_SCHEDULE_INSTALL, mockError));
        return err.response;
    }
};

export const patchServiceOrder = (data) => async (dispatch) => {
    dispatch(actionCreator.request(PATCH_SERVICE_ORDER));
    try {
        const response = await axiosInstance.patch(`public/order/patchserviceorder`, data);

        let responseData = {...response.data};
        responseData['type'] = 'service order';
        dispatch(actionCreator.success(PATCH_SERVICE_ORDER, responseData));
        return response;
    } catch (err) {
        dispatch(actionCreator.failure(PATCH_SERVICE_ORDER));
        return err;
    }
};

export const patchOrder = (data) => async (dispatch) => {
    dispatch(actionCreator.request(PATCH_SERVICE_ORDER));
    try {
        const response = await axiosInstance.patch(`public/order/patchorder`, data);

        let responseData = {...response.data};
        responseData['type'] = 'service order';
        dispatch(actionCreator.success(PATCH_SERVICE_ORDER, responseData));
        return response;
    } catch (err) {
        dispatch(actionCreator.failure(PATCH_SERVICE_ORDER));
        return err;
    }
};

export const validateRequiredOrOptionalFields = () => async (dispatch) => {
    //GET required or optional fields to display
    try {
        const response = await axiosInstance.get(`/public/account-types/attributes`, {params: {"customer-portal": "TRUE"}})
        return response.data.accountTypes
    } catch(err) {
        return err
    }
}
import {
	SIGN_IN,
	FORGOTTEN_PASSWORD,
	RESET_PASSWORD,
	IMPERSONATE_USER,
	SET_USER_PERMISSIONS
} from '../actions/authActions';
import { REGISTER_EMAIL_AND_ACCOUNT_NUMBER } from '../actions/accountNumberRegisterActions';

let initialState = {
	isLoggedIn: false,
	username: '',
	token: '',
	refreshToken: '',
	userid: '',
	accountId: '',
	isForgottenPasswordInputVissible: true,
	isResetPasswordSuccessful: false,
	isRegisterSuccess: false,
	userPermission: []
};

export const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case SIGN_IN.SUCCESS:
		case IMPERSONATE_USER.SUCCESS:
			return {
				...state,
				isLoggedIn: true,
				username: action.payload.username,
				token: action.payload.token,
				refreshToken: action.payload.refreshToken !== '' && action.payload.refreshToken,
				userid: action.payload.userid,
				accountId: action.payload.accountId
			};
		case FORGOTTEN_PASSWORD.SUCCESS:
			return {
				...state,
				isForgottenPasswordInputVissible: false
			}
		case FORGOTTEN_PASSWORD.EMPTY:
			return {
				...state,
				isForgottenPasswordInputVissible: true
			}

		case RESET_PASSWORD.SUCCESS:
			return {
				...state,
				isResetPasswordSuccessful: true
			}

		case REGISTER_EMAIL_AND_ACCOUNT_NUMBER.SUCCESS:
			return {
				...state,
				isRegisterSuccess: true
			}

		case SET_USER_PERMISSIONS.SUCCESS:
			return {
				...state,
				userPermission: action.payload
			}

		default:
			return state;
	}
};

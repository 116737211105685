//errorReducer.js
export const errorReducer = ( state = {}, action ) => {
	const { type, payload } = action,
		matches = /(.*)_(REQUEST|FAILURE|CLEAR)/.exec(type);
	
	if(type === 'EMPTY_ERROR_REDUCER'){
		state = {};
	}
	// not a *_REQUEST / *_FAILURE actions, so we ignore them
	if (!matches) return state;

	const [, requestName, requestState] = matches;

	return {
		...state,
		// Store errorMessage
		// e.g. stores errorMessage when receiving FAILURE
		//      else clear errorMessage when receiving REQUEST
		[requestName]: requestState === 'FAILURE' ? payload.message : '',
	};

};

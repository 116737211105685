import React, { Component } from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import ReactHtmlParser from 'react-html-parser';
import {trans} from "../../../../utils/helpers";
import {Link} from "react-router-dom";

class SuccessStep extends Component {
	state={
		successfullorder:'',
		successfullmessage1:'',
		successfullmessage2:'',
		signupurl:'',
		signupmess:'',
		signuptwiturl:'',
		signupsharemess:''
	}
	componentDidMount(){
		(async() => {
			const successfullorder = await trans('en-us', 'scheduleInstall.successfulOrder.title')
			const successfullmessage1= await trans('en-us', 'scheduleInstall.successfulOrder.installScheduleConfirmation')
			const successfullmessage2= await trans('en-us', 'scheduleInstall.successfulOrder.installScheduleConfirmationLine2')
			const signupurl= await trans('en-us', 'confirmOrderScreen.successfulOrder.signUpFacebookUrl')
			const signupmess= await trans('en-us', 'confirmOrderScreen.successfulOrder.signUpShareMessage')
			const signuptwiturl= await trans('en-us', 'confirmOrderScreen.successfulOrder.signUpTwitterUrl')
			const signupsharemess= await trans('en-us', 'confirmOrderScreen.successfulOrder.signUpShareMessage')
			this.setState({successfullorder,successfullmessage1,successfullmessage2,signupurl,signupmess,signuptwiturl,signupsharemess})
		})()
	}

	render() {

		const {
			values,
		} = this.props;

		return (
			<div className="form-section">

				<div className="message">

					<div className="message-left">
						<i className="fas fa-check-circle" />
					</div>

					<div className="message-right">

			<h4>{this.state.successfullorder}</h4>
						<p className="message-code">{this.state.successfullmessage1}</p>
						<p>{ReactHtmlParser(this.state.successfullmessage2)}</p>
					{/*<Link
							to={{
								pathname: '/emailregister',
								state: {email: values.account.accountHolder.personalEmail,}
							}}
							className="btn btn-primary btn-block btn-a"
						>
							Register
						</Link>

						<p></p>*/}

						<div className="message-footer">

							<FacebookShareButton
								url={this.state.signupurl}
								quote={this.state.signupmess}
							>
								<button type="button" className="btn btn-primary btn-facebook">
									<i className="fab fa-facebook"/>&nbsp;Share It
								</button>
							</FacebookShareButton>

							<TwitterShareButton
								url={this.state.signuptwiturl}
								title={this.state.signupsharemess}
							>
								<button type="button" className="btn btn-primary btn-twitter">
									<i className="fab fa-twitter"/>&nbsp;Tweet It
								</button>
							</TwitterShareButton>

							{/*<button type="button" formaction="cp-dashboard.html" id="" className="btn btn-primary btn-mail">
								<i className="fas fa-at" />&nbsp;Mail It
							</button>*/}

							{/*<button type="button" className="btn btn-primary btn-link">
								<i className="fas fa-link" />&nbsp;Link
							</button>*/}

						</div>

					</div>

				</div>

			</div>
		);
	}
}

export default SuccessStep;

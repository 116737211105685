import React, { Component, Fragment } from 'react';

import { ConfirmAccount, ConfirmNotABot, PinOptions, ChoosePassword, SignUpCompleted } from './Steps';

class StepContent extends Component {
	renderStepContent = () => {
		const {
			activeStep,
			formProps,
			checkedAccountData,
			sendAuthCode,
			checkedAccountLoader,
			checkedAccountError,
			handleGoBack
		} = this.props;

		let stepContent;
		switch (activeStep) {
			case 0:
				stepContent = (
					<ConfirmAccount
						checkedAccountLoader={checkedAccountLoader}
						checkedAccountError={checkedAccountError}
						{...formProps}
					/>
				);
				break;
			case 1:
				stepContent = (
					<PinOptions
						checkedAccountData={checkedAccountData}
						sendAuthCode={sendAuthCode}
						handleGoBack={handleGoBack}
						{...formProps}
					/>
				);
				break;
			case 2:
				stepContent = (
					<ChoosePassword
					checkedAccountData={checkedAccountData}
					handleGoBack={handleGoBack}
					{...formProps}
					/>)
				break;
			case 3:
				stepContent = <SignUpCompleted />;
				break;
			default:
				stepContent = (
					<ConfirmAccount
						checkedAccountLoader={checkedAccountLoader}
						checkedAccountError={checkedAccountError}
						{...formProps}
					/>
				);
				break;
		}

		return stepContent;
	};
	render() {
		return <Fragment>{this.renderStepContent()}</Fragment>;
	}
}

export default StepContent;
import React, {Component} from 'react'
import {connect} from 'react-redux'
import GuestLayout from '../Layout/GuestLayout'
import {Formik, Form} from 'formik';
import IconInput from '../../components/common/Formik/IconInput';
import {resetPassword, resetTokenValidation, forgottenPassword} from '../../actions/authActions';

import {Link} from 'react-router-dom';
import * as Yup from 'yup';
import {selectAuth, createLoadingSelector, selectErrorMessage} from '../../selectors'
import HandleChangeFormik from './HandleChangeFormik';
import {isEmpty} from '../../utils/helpers';
import Loader from '../../components/common/Spinners/Loader';

const resetPasswordSchema = (window.password_requirement_reg_expr && window.password_requirement_reg_expr !== '')
    ?
    Yup.object().shape({
        newPassword: Yup.string().matches(
            window.password_requirement_reg_expr,
            window.password_requirement_desc,
        ),
        repeatPassword: Yup.string().required('Required').test('passwords-match', 'Passwords must match', function (value) {
            return this.parent.newPassword === value;
        }),
    })
    :
    Yup.object().shape({
        newPassword: Yup.string().test('newPassword',
            'Use a minimum of 10 symbols, including numbers, both uppercase and lowercase letters, and special symbols.',
            (value) => !(/^(.{0,9}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/).test(value)
        ),
        repeatPassword: Yup.string().required('Required').test('passwords-match', 'Passwords must match', function (value) {
            return this.parent.newPassword === value;
        }),
    })
;

const forgotPasswordSchema = Yup.object().shape({
});

export class ResetPassword extends Component {

    state = {
        infoMessageType: ''
    }

    componentDidMount() {
        const resetPasswordToken = this.props.location.search.substring(7);
        this.props.resetTokenValidation(resetPasswordToken).then((response) => {
            if (response === undefined) {
                this.setState({
                    infoMessageType: 'expireToken'
                })
            } else if (response.success === true) {
                this.setState({
                    infoMessageType: 'resetPassword'
                })
            }
        });
        /* console.log(this.props)
        if (!isEmpty(this.props.location.state)) {
			if (this.props.location.state.message.length == 0) {
				this.setState({
					infoMessageType: 'expireToken'
				});
			}
		} */
    }

    componentDidUpdate(prevProps) {
        //console.log(this.props);

        if (prevProps.isResetPasswordSuccessful !== this.props.isResetPasswordSuccessful && this.props.isResetPasswordSuccessful) {
            this.props.history.push({pathname: '/login'})
        }

    }

    handleOnSubmit = (values) => {
        const {resetPassword, location} = this.props;
        const resetPasswordToken = location.search.substring(7);
        resetPassword(values.newPassword, resetPasswordToken);
    }

    handleSendMail = (values) => {
        const {forgottenPassword} = this.props;
        forgottenPassword(values.email)
    }

    render() {
        const {isResetPasswordSuccessful, isResetPasswordLoader, isResetPasswordError, isForgottenPasswordInputVissible, isForgottenPasswordInputSubmitting, forgottenPasswordError, isExpiredTokenLoader} = this.props;
        const {infoMessageType} = this.state;

        if (isExpiredTokenLoader) {
            return <Loader/>
        }

        return (
            <GuestLayout>
                <div className="cp-page cp-page-login justify-content-start">
                    <div className="card-guest card-full-height">
                        <div className="card-guest-header">
                            <div className="brand-container">
                            </div>
                            <p className="brand-header">Reset password</p>
                        </div>
                        {infoMessageType === 'resetPassword' && (<div className="card-guest-body">
                            {/* <p className="text-center">RESET PASSWORD</p> */}
                            <Formik initialValues={{
                                newPassword: '',
                                repeatPassword: ''
                            }}
                                    validationSchema={resetPasswordSchema}
                                    onSubmit={this.handleOnSubmit}
                            >
                                {({
                                      handleChange,
                                      handleSubmit,
                                      handleBlur,
                                      values,
                                      errors,
                                      touched,
                                      isSubmitting,
                                      resetForm,
                                      ...formProps
                                  }) => (
                                    <Form onSubmit={handleSubmit} className="cp-form">
                                        <div className="form-group">
                                            <div className="input-group mb-3">
                                                <HandleChangeFormik failure={isResetPasswordError}
                                                                    resetForm={resetForm} {...formProps} />

                                                <label><strong>New Password</strong></label>
                                                <IconInput
                                                    type='password'
                                                    name='newPassword'
                                                    divClass='input-group'
                                                    value={values.newPassword}
                                                    errors={errors}
                                                    touched={touched}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}/>
                                            </div>

                                            <label><strong>Confirm Password</strong></label>
                                            <IconInput
                                                type='password'
                                                name='repeatPassword'
                                                divClass='input-group'
                                                value={values.repeatPassword}
                                                errors={errors}
                                                touched={touched}
                                                onChange={handleChange}
                                                onBlur={handleBlur}/>
                                        </div>
                                        {isResetPasswordError && <p style={{color: 'red'}}>{isResetPasswordError}</p>}
                                        <div className="form-group form-group-footer">
                                            {/* <Link to={{ pathname: '/login' }}><button type="cancel" className="btn btn-outline-secondary">Go Back</button></Link> */}
                                            <button type="submit" className="btn btn-with-shadow btn-primary">Save
                                                Changes {isResetPasswordLoader &&
                                                <i className="fas fa-fw fa-spin fa-spinner"/>}</button>
                                        </div>
                                        {/* {isForgottenPasswordInputSubmitting && <i className="fas fa-fw fa-spin fa-spinner" />} */}

                                    </Form>
                                )}

                            </Formik>
                        </div>)}
                        {infoMessageType === 'expireToken' && (
                            <div>
                                <div>
                                    {isForgottenPasswordInputVissible && !forgottenPasswordError ? <div>
                                        <p style={{color: 'red'}}>
                                            The password reset link has expired. Please enter your email
                                            so we send you a new link.
                                        </p>
                                        <Formik initialValues={{
                                            email: ''
                                        }}
                                                validationSchema={forgotPasswordSchema}
                                                onSubmit={this.handleSendMail}
                                        >
                                            {({
                                                  handleChange,
                                                  handleSubmit,
                                                  handleBlur,
                                                  values,
                                                  errors,
                                                  touched,
                                                  isSubmitting
                                              }) => (
                                                <Form onSubmit={handleSubmit}>
                                                    {/* <input type="text" className="form-control" id="email" placeholder="Email" required /> */}
                                                    <IconInput
                                                        type='text'
                                                        name='email'
                                                        placeholder='Email'
                                                        iconClass='far fa-fw fa-envelope'
                                                        divClass='input-group'
                                                        value={values.email}
                                                        errors={errors}
                                                        touched={touched}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}/>
                                                    <br/>
                                                    <div className="form-group form-group-footer"
                                                         style={{position: "relative"}}>
                                                        <Link to={{pathname: '/login'}}>
                                                            <button type="cancel"
                                                                    className="btn btn-outline-secondary">Go Back
                                                            </button>
                                                        </Link>
                                                        <button style={{float: "right"}} type="submit"
                                                                className="btn btn-with-shadow btn-primary">Send
                                                            email {isForgottenPasswordInputSubmitting &&
                                                            <i className="fas fa-fw fa-spin fa-spinner"/>}</button>
                                                    </div>

                                                </Form>

                                            )}
                                        </Formik>
                                    </div> : <div className="form-group form-group-footer ">
                                        <p className="text-center">
                                            Thanks! If there's an account associated with this username or email, we'll send the password reset instructions immediately.</p>
                                        <Link to={{pathname: '/login'}}>
                                            <button type="cancel" className="btn btn-outline-secondary">Go Back</button>
                                        </Link>
                                    </div>}

                                </div>
                            </div>


                        )}

                    </div>
                </div>
            </GuestLayout>
        )
    }
}

const getLoader = createLoadingSelector(['RESET_PASSWORD']);
const getError = selectErrorMessage(['RESET_PASSWORD']);
const expireTokenLoader = createLoadingSelector(['RESET_FORGOTEN_PASSWORD_TOKEN'])

const forgottenPasswordSelector = 'FORGOTTEN_PASSWORD';
const getSendEmailLoader = createLoadingSelector([forgottenPasswordSelector]);
const getInvalidEmailError = selectErrorMessage([forgottenPasswordSelector])
const mapStateToProps = (state) => {
    const isResetPasswordSuccessful = selectAuth(state).isResetPasswordSuccessful,
        isResetPasswordLoader = getLoader(state),
        isResetPasswordError = getError(state),
        isForgottenPasswordInputVissible = selectAuth(state).isForgottenPasswordInputVissible,
        isForgottenPasswordInputSubmitting = getSendEmailLoader(state),
        forgottenPasswordError = getInvalidEmailError(state),
        isExpiredTokenLoader = expireTokenLoader(state);

    return {
        isResetPasswordSuccessful,
        isResetPasswordLoader,
        isResetPasswordError,
        isForgottenPasswordInputVissible,
        isForgottenPasswordInputSubmitting,
        forgottenPasswordError,
        isExpiredTokenLoader
    }
}

const mapDispatchToProps = {
    resetPassword,
    resetTokenValidation,
    forgottenPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)

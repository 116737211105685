import React, {Component} from 'react';
import {isEmpty} from '../../../utils/helpers';

import SummaryContainer from './SummaryContainer';
import ProductsPlan from './ProductsPlan';
import Loader from "../../../components/common/Spinners/Loader";
import {Form} from "formik";

class Products extends Component {
    state = {
        activeChange: false
    }

    valueUpdated = () => {
        this.setState({activeChange: true})
    }

    render() {
        const {
            planName,
            plansDetails,
            values,
            setFieldValue,
            myServiceInfoItem,
            showHideManagePlan,
            updateChangeServiceLoading,
        } = this.props;

        if (isEmpty(plansDetails)) {
            return <Loader/>;
        }

        return (
            <div className="card-product">

                <div className="card-body">
                    <ProductsPlan planName={planName} planInfo={plansDetails} values={values} setFieldValue={(field, value) => {
                        setFieldValue(field, value);
                        this.valueUpdated();
                    }}/>
                    <SummaryContainer
                        values={values}
                    />
                </div>

                <div className="card-footer">
                    <button className="btn btn-quick" onClick={showHideManagePlan}>Cancel</button>
                    <button
                        type="submit"
                        className="btn btn-primary"
                        data-target="manage-plan-success"
                        disabled={!this.state.activeChange || updateChangeServiceLoading}
                    >
                        Apply Changes
                    </button>
                </div>

            </div>
        );
    }
}

export default Products;
